import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Nav from "shared/Nav/Nav";
import NavItem2 from "components/NavItem2";
import { FaBeer } from 'react-icons/fa';
import { useParams, useSearchParams } from "react-router-dom";
import { useSiteSettingsApi } from "_common/hooks/api/siteSettingsApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import HomeSettings from "components/SiteSettings/HomeSettings";
import PageHeader from "shared/PageHeader/PageHeader";
import SocialMediaLink from "components/SiteSettings/SocialMediaLink";
import PinataKeys from "components/SiteSettings/PinataKeys";
import CookieConsent from "components/SiteSettings/CookieConsent";
import Analytics from "components/SiteSettings/Analytics";
import Seo from "components/SiteSettings/Seo";
import Spam from "components/SiteSettings/Spam";
import CustodialWallet from "components/SiteSettings/CustodialWallet";
import { BiHome, BiSearchAlt } from 'react-icons/bi';
import { FiLink, FiKey } from 'react-icons/fi';
import { RxCookie } from 'react-icons/rx';
import { IoMdAnalytics } from 'react-icons/io';
import { RiSpam2Line } from 'react-icons/ri';
import { MdPayment } from "react-icons/md";
import { TbBrandMeta } from "react-icons/tb";
import PaymentSettings from "components/SiteSettings/PaymentSettings";
import Metaverse from "components/SiteSettings/Metaverse";

export interface Props {
	className?: string;
}

const SiteSettings = ({ className = "" }) => {

	const { id } = useParams();
	const siteSettingApi = useSiteSettingsApi()
	const { showLoader, hideLoader } = useAppLoader()

	const [searchParams, setSearchParams] = useSearchParams();
	const tab = searchParams.get('tab');

	// const [searchKey, setSearchKey] = useState<string>('');
	// const [tabsName, setTabsName] = useState<any>([]);

	const tabs: any = [
		{ name: 'Home Settings', icon: <BiHome className="text-lg" />, component: <HomeSettings />, isDisabled: false },
		{ name: 'Social Media Link', icon: <FiLink className="text-lg" />, component: <SocialMediaLink />, isDisabled: (id == 'new') },
		{ name: 'Pinata Keys', icon: <FiKey className="text-lg" />, component: <PinataKeys />, isDisabled: (id == 'new') },
		{ name: 'Cookie Consent', icon: <RxCookie className="text-lg" />, component: <CookieConsent />, isDisabled: (id == 'new') },
		{ name: 'Analytics', icon: <IoMdAnalytics className="text-lg" />, component: <Analytics />, isDisabled: (id == 'new') },
		{ name: 'SEO', icon: <BiSearchAlt className="text-lg" />, component: <Seo />, isDisabled: (id == 'new') },
		{ name: 'Spam', icon: <RiSpam2Line className="text-lg" />, component: <Spam />, isDisabled: (id == 'new') },
		{ name: 'Custodial Wallet', icon: <RiSpam2Line className="text-lg" />, component: <CustodialWallet />, isDisabled: (id == 'new') },
		{ name: 'Payment Settings', icon: <MdPayment className="text-lg" />, component: <PaymentSettings />, isDisabled: (id == 'new') },
		{ name: 'Metaverse', icon: <TbBrandMeta className="text-lg" />, component: <Metaverse />, isDisabled: (id == 'new') }
	]

	const renderHeading2 = () => {
		return (
			<div>
				<Nav
					className="p-1 bg-white dark:bg-neutral-800 rounded-full shadow-lg overflow-auto horizonatal-scrollbar"
					containerClassName="mb-12 lg:mb-14 relative flex justify-center w-full text-sm md:text-base"
				>
					{tabs.map((item: any, index: any) => (
						<NavItem2
							key={index}
							isActive={tab === item.name}
							onClick={() => {
								if (!item.isDisabled) {
									setSearchParams({ 'tab': item.name })
								}
							}
							}
						>
							<div className="flex items-center justify-center sm:space-x-2.5 text-xs sm:text-sm ">
								<span
									className="hidden sm:inline-block"
								>{item.icon}</span>
								<span>{item.name}</span>
							</div>
						</NavItem2>
					))}
				</Nav>
			</div>
		);
	};

	const getSiteSettings = () => {
		showLoader()
		siteSettingApi.getSiteSettings({},
			(message: string, resp: any) => {
				// console.log({ resp })
				hideLoader()
			},
			(message: string) => {
				console.log({ message })
				hideLoader()
			})
	}

	useEffect(() => {
		if (tab == null) {
			setSearchParams({ 'tab': tabs[0].name })
		}
		getSiteSettings()
	}, [])


	return (

		<div className={`nc-PageCollection  ${className}`} data-nc-id="CategoryList" >
			<Helmet>
				<title>Site Settings</title>
			</Helmet>
			<div className="container relative">
				<div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
					<PageHeader title={'Site Settings'} />
					<div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
					{renderHeading2()}
					<div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
						{tabs.map((_tab: any, i: any) => {
							if (tab == _tab.name) {
								return <React.Fragment key={i}>{_tab.component}</React.Fragment>
							}
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SiteSettings;
