import React, { FC, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { BsFacebook } from "react-icons/bs";
import { AiFillLinkedin, AiFillTwitterCircle } from "react-icons/ai";



export interface NcShareDropDownProps {
  className?: string;
  panelMenusClass?: string;
  iconClass?: string;
  title?: string;
  url?: string;
}

const NcShareDropDown: FC<NcShareDropDownProps> = ({
  className = `h-8 w-8 flex items-center justify-center`,
  iconClass = "w-4 h-4 sm:h-5 sm:w-5",
  panelMenusClass = "origin-top-right",
  title = "More",
  url,
}) => {

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className={className} title={title}>
        <svg className={iconClass} viewBox="0 0 24 24" fill="none">
          <path d="M16.44 8.8999C20.04 9.2099 21.51 11.0599 21.51 15.1099V15.2399C21.51 19.7099 19.72 21.4999 15.25 21.4999H8.73998C4.26998 21.4999 2.47998 19.7099 2.47998 15.2399V15.1099C2.47998 11.0899 3.92998 9.2399 7.46998 8.9099" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M12 15.0001V3.62012" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M15.35 5.85L12 2.5L8.65002 5.85" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute ${panelMenusClass} right-0 w-56 mt-2 bg-white dark:bg-neutral-900 rounded-2xl divide-y divide-neutral-100 shadow-lg ring-1 ring-black dark:ring-white ring-opacity-5 dark:ring-opacity-10 focus:outline-none z-30`}
        >
          <div className="px-1 py-3 text-sm text-neutral-6000 dark:text-neutral-300">

            <FacebookShareButton
              url={url ? url : `${window.location.href}`}
              hashtag={"#NFT"}
              className="flex mb-2 mr-6"
            >
              <BsFacebook size={24} />&nbsp; Facebook
            </FacebookShareButton>

            <TwitterShareButton
              title={"NFT"}
              url={url ? url : `${window.location.href}`}
              hashtags={["NFT"]}
              className="flex mb-2 mr-6"
            >
              <AiFillTwitterCircle size={24} />&nbsp; Twitter
            </TwitterShareButton>

            <LinkedinShareButton
              title={"NFT"}
              url={url ? url : `${window.location.href}`}
              className="flex mb-2 mr-6"
            >
              <AiFillLinkedin size={24} />&nbsp; LinkedIn
            </LinkedinShareButton>

          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default NcShareDropDown;
