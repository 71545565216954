import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function useCouponApi() {

  const callApi = useApiCall()

  const getCouponList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUPON.LIST, data, onSuccess, onError);
  }

  const getCouponTypeList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUPON.LIST_TYPE, data, onSuccess, onError);
  }

  const getCouponListDiscounType = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUPON.LIST_DISCOUNT_TYPE, data, onSuccess, onError);
  }

  const couponAdd = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUPON.ADD, data, onSuccess, onError);
  }

  const couponEdit = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUPON.EDIT, data, onSuccess, onError);
  }

  const couponDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUPON.DELETE, data, onSuccess, onError);
  }

  return {
    getCouponList,
    getCouponTypeList,
    getCouponListDiscounType,
    couponAdd,
    couponEdit,
    couponDelete
  }
}