import React, { FC, useEffect, useRef, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcModal from "shared/NcModal/NcModal";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

export interface ProblemPlan {
  name: string;
  id: string;
  label: string;
}

export interface ModalReportItemProps {
  show: boolean;
  type_id: any;
  problemPlans?: ProblemPlan[];
  onCloseModalReportItem: () => void;
}

const problemPlansDemo = [
  { name: "Violence", id: "Violence", label: "Violence" },
  { name: "Trouble", id: "Trouble", label: "Trouble" },
  { name: "Spam", id: "Spam", label: "Spam" },
  { name: "Other", id: "Other", label: "Other" },
];

const ModalReportItem: FC<ModalReportItemProps> = ({
  problemPlans = problemPlansDemo,
  show,
  onCloseModalReportItem, type_id
}) => {
  const textareaRef = useRef(null);

  const [problemSelected, setProblemSelected] = useState<any>(null);
  const [reportType, setReportType] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const commonApi = useCommonApi()
  const { showLoader, hideLoader } = useAppLoader()
  const { id } = useParams()

  const { register, handleSubmit, control, reset, formState: { errors } } = useForm()

  const getReportType = () => {
    showLoader()
    commonApi.getReportType({},
      (message: string, resp: any) => {
        let list: any = []
        resp.reportTypes.forEach((item: any) => {
          let tempObj = { name: `${item.typeName}`, id: `${item.reportTypeID}`, label: `${item.typeName}` }
          list.push(tempObj)
        })
        setReportType(list)
        hideLoader()
      },
      (message: string) => {
        console.log(message)
        hideLoader()
      })
  }

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        const element: HTMLTextAreaElement | null = textareaRef.current;
        if (element) {
          (element as HTMLTextAreaElement).focus();
        }
      }, 400);
    }
  }, [show]);

  const handleClickSubmitForm = (data: any) => {
    console.log({ data, problemSelected })
    if (problemSelected == null) {
      setErrorMessage('please select a problem')
    } else {
      let params: any = {
        itemType: type_id,
        itemID: id,
        reportTypeID: problemSelected.id,
        details: problemSelected.name,
        otherDetails: data.message,
      }
      console.log({ params })
      showLoader()
      commonApi.postReport(params, (message: string, resp: any) => {
        toast.success(message)
        hideLoader()
        onCloseModalReportItem()
      },
        (message: string) => {
          toast.error(message)
          hideLoader()
        })
    }
  };

  const renderCheckIcon = () => {
    return (
      <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
        <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
        <path
          d="M7 13l3 3 7-7"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  useEffect(() => {
    if(show){
      getReportType()
    }
  }, [show])

  const renderContent = () => {
    return (
      <form onSubmit={handleSubmit(handleClickSubmitForm)}>
        {/* RADIO PROBLEM PLANS */}
        <RadioGroup value={problemSelected} onChange={setProblemSelected} >
          <RadioGroup.Label className="sr-only">Problem Plans</RadioGroup.Label>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
            {/* {problemPlans.map((plan) => ( */}
            {reportType && reportType.length && reportType.map((plan: any) => (
              <RadioGroup.Option
                key={plan.name}
                value={plan}
                className={({ checked }) => {
                  return `${checked
                    ? "bg-primary-6000 text-white dark:bg-primary-700"
                    : "bg-white dark:bg-black/20 border-t dark:border-0 border-neutral-50 "
                    } relative shadow-lg rounded-lg px-3 py-3 cursor-pointer flex sm:px-5 sm:py-4 focus:outline-none `;
                }}
              >
                {({ checked }) => (
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className={`font-medium ${checked
                            ? "text-white"
                            : "text-neutral-900 dark:text-white"
                            }`}
                        >
                          {plan.label}
                        </RadioGroup.Label>
                      </div>
                    </div>
                    {checked && (
                      <div className="flex-shrink-0 text-white">
                        {renderCheckIcon()}
                      </div>
                    )}
                  </div>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
        {
          errorMessage && errorMessage.length > 0 ?
            <p className='text-red-400'>{errorMessage}</p> : null
        }

        {/* TEXAREA MESSAGER */}
        <div className="mt-4">
          <h4 className="text-lg font-semibold text-neutral-700 dark:text-neutral-200">
            Message
          </h4>
          <span className="text-sm text-neutral-6000 dark:text-neutral-400 mb-4 block">
            Please provide any additional information or context that will help
            us understand and handle the situation.
          </span>
          <Controller
            name={`message`}
            control={control}
            render={({ field }) => <Textarea
              {...field}
              rows={5}
              {...register(`message`, { required: true })}
            />}
          />
          {
            errors && errors.message && errors.message?.type == "required" ?
              <p className='text-red-400'>Required</p> : ''
          }
        </div>
        <div className="mt-4 space-x-3">
          <ButtonPrimary type="submit">
            Submit
          </ButtonPrimary>
          <ButtonSecondary type="button" onClick={onCloseModalReportItem}>
            Cancel
          </ButtonSecondary>
        </div>
      </form>
    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={onCloseModalReportItem}
      contentExtraClass="max-w-screen-md"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle="Report Abuse"
    />
  );
};

export default ModalReportItem;
