import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Transition } from "@headlessui/react";

import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import NcImage from "shared/NcImage/NcImage";
import CardNFT from "components/CardNFT";
import Pagination from "shared/Pagination/PaginationHandler";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import collectionBanner from "images/nfts/collectionBanner.png";
import { nftsImgs } from "contains/fakeData";
import NftMoreDropdown from "components/NftMoreDropdown";
import ButtonDropDownShare from "components/ButtonDropDownShare";
// import TabFilters from "components/PayoutGroup/TabFilters";
import SectionSliderCollections from "components/SectionSliderCollections";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { usePayoutGroupApi } from "_common/hooks/api/payoutGroupApiHook";
import PayoutGroupCard from "components/PayoutGroup/PayoutGroupCard";
import Category from "components/CardCategory5/CardCategory5";
import Button from "shared/Button/Button";

import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import PageHeaderWithSearch from "shared/PageHeaderWithSearch/PageHeaderWithSearch";
import CouponCard from "components/Coupon/CouponCard";
import ItemTypeEditIcon from "components/ItemTypeEditIcon";
import TabFilters from "components/Filters/TabFilters";

import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useCouponApi } from "_common/hooks/api/CouponApiHook";
import ItemTypeDeleteIcon from "components/ItemTypeDeleteIcon";
import ButtonSecondary from 'shared/Button/ButtonSecondary';

export interface Props {
  className?: string;
}

interface SearchParams {
  searchText: string | '';
	collectionId: number | null;
	collectionTypeId: number | null;
	networkId: number | null;
}

const filterBy = [
	{ name: "Active", id: "active" },
	{ name: "Inactive", id: "inactive" },
];

const CouponList: FC<Props> = ({ className = "" }) => {

  const limit: number = 12;
	const { showLoader, hideLoader } = useAppLoader();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState<number>(1);
	const [searchKey, setSearchKey] = useState<string>('');
	const [isOpen, setIsOpen] = useState(false);
	const [filter, setFilter] = useState<any>({});
  const [couponList, setCouponList] = useState<any[]>([]);

  const { getCouponList, couponDelete } = useCouponApi();

  useEffect(() => {
		handleCouponList()
	}, [currentPage])

	useEffect(() => {
		console.log('Collection Filter => ',filter)
		if (currentPage == 1) {
      handleCouponList();
    } else {
      setCurrentPage(1);
    }
	}, [filter, searchKey]);

  const handleCouponList = () => {
    let params: any = {
			usePaging: true,
			pageSize: limit,
			pageNumber: currentPage
		}
		console.log('filter: ', filter)
		if(filter?.radioBtnFiltrations){
			if(filter.radioBtnFiltrations.status && filter.radioBtnFiltrations.status == 'active'){
				params['isActive'] = 1;
			}
			if(filter.radioBtnFiltrations.status && filter.radioBtnFiltrations.status == 'inactive'){
				params['isActive'] = 0;
			}
		}

		if(searchKey){
			params.searchText = searchKey
		}

    showLoader();
    getCouponList(params, (message: string, resp: any) => {
			hideLoader();
			setCouponList(resp.coupons);
			setTotalPage(Math.ceil(resp.totalRecord / limit));
		}, (message: string) => {
			hideLoader();
			toast.error(message)
		})
  }

  const deleteCoupon = (id: any) => {
      return new Promise((resolve: any, reject: any) => {
          showLoader();
          couponDelete({ couponID: id }, (message: string, resp: any) => {
              hideLoader();
              resolve(message)
          }, (message: string) => {
              hideLoader();
              reject(message);
          })
      })
  }

  const customfieldDeletehandler = (field: any) => {
      console.log(field.couponID)
      toast((t) => (
          <span>
              Are you sure?
              <br />You want to <b className="text-danger">delete</b> coupon{" "}<b>{field.couponName}</b><br />
              <hr className="mb-3 mt-3" />
              <div className="align-middle flex justify-end">
                  <ButtonPrimary className="bg-black m-1 sm:h-9" onClick={() => { toast.dismiss(t.id) }} >Cancel</ButtonPrimary>
                  <ButtonPrimary
                      className="bg-red-500 m-1 sm:h-9"
                      onClick={() => {
                          toast.dismiss(t.id);
                          toast.promise(
                            deleteCoupon(field.couponID), {
                              loading: "Deleting...",
                              success: (msg) => {
                                  handleCouponList();
                                  return <b>{field.couponName} Deleted Successfully!</b>
                              },
                              error: (msg) => { return <b>{msg}</b> },
                          }, { duration: 2000 });
                      }}
                  >
                      Delete
                  </ButtonPrimary>
              </div>
          </span>
      ),
          { duration: 95000 }
      );
  }

  return (
    <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
      <Helmet>
        <title>Coupon</title>
      </Helmet>
      <PageHeaderWithSearch title="Coupons" onSearch={setSearchKey} searchKey={searchKey} />
    
      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>

          {/* FILTER */}
          <div className={`flex flex-col relative ${className}`}>
            <div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
              <div className="relative flex w-full text-sm md:text-base hiddenScrollbar items-center">

              </div>
              <span className="flex flex-shrink-0 text-right items-center">
                <Link className="sm:space-x-2" to="/coupons/add">
                  <ButtonPrimary>Add New</ButtonPrimary>
                </Link>
                <ButtonSecondary
                  className="w-auto !pr-16 ml-2 border-primary-500 focus:ring-0 focus:ring-primary-200"
                  sizeClass="pl-4 py-2.5 sm:pl-6"
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <svg
                    className={`w-4 h-4 sm:w-6 sm:h-6`}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19.87 17.12L18.87 16.12"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="block truncate ml-2.5">Filter</span>
                  <span className="absolute top-1/2 -translate-y-1/2 right-5">
                    <ChevronDownIcon
                      className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpen ? "rotate-180" : ""
                        }`}
                      aria-hidden="true"
                    />
                  </span>
                </ButtonSecondary>
              </span>
            </div>

            <Transition
              show={isOpen}
              enter="transition-opacity duration-150"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
              {/* <TabFilters onApplyCollection={onApplyCollection} onSearch={onSearch} /> */}
              <TabFilters name="Coupons Filters"
								radioBtnFiltrations={[
									{
										key: 'status',
										options: filterBy
									}
								]} onApplyFilter={setFilter}/* onApplySort={onApplySort} onSearch={onSearch} */ />
            </Transition>
          </div>

          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-8 gap-y-10  mt-8 lg:mt-10">
						{couponList?.map((coupon: any, index: any) => (
							<div key={index}>
								<CouponCard data={coupon} onDelete={customfieldDeletehandler} />
							</div>
						))}
					</div>

					{couponList?.length == 0 && (
						<h2 className="font-medium text-2xl text-center w-full">No Result Found!</h2>
					)}

					{couponList?.length > 0 && totalPage > 1 && (
						<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
							<Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
						</div>
					)}

        </main>

      </div>
    </div>
  );
};

export default CouponList;
