import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function useGalleryApi() {

  const callApi = useApiCall()

  const getGalleryList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GALLERY.LIST, data, onSuccess, onError);
  }

  const getIndustryTypeList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GALLERY.INDUSTRY_LIST, data, onSuccess, onError);
  }

  const galleryAdd = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GALLERY.ADD, data, onSuccess, onError);
  }

  const galleryEdit = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GALLERY.EDIT, data, onSuccess, onError);
  }

  const galleryDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GALLERY.DELETE, data, onSuccess, onError);
  }

  return {
    getGalleryList,
    getIndustryTypeList,
    galleryAdd,
    galleryEdit,
    galleryDelete
  }
}