import React, { useState } from "react";

export interface InputProps {
    children: any;
    text: string;
    rest?: any;
}

const Tooltip = ({ children, text, ...rest }: InputProps) => {

  const [show, setShow] = useState<boolean>(false);

  return (
    <div>
      <div className="tooltip" style={show ? { display: "block" } : {}}>
        {text}
        <span className="tooltip-arrow" />
      </div>
      <div
        {...rest}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        {children}
      </div>
    </div>
  );
}

export default Tooltip
