import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Nav from "shared/Nav/Nav";
import NavItem2 from "components/NavItem2";
import { FaBeer } from 'react-icons/fa';
import { useParams, useSearchParams } from "react-router-dom";
import RegistrationList from "components/PreSales/RegistrationList";
import FormItem from "components/FormItem";
import Input from "shared/Input/Input";
import PageHeaderWithSearch from "shared/PageHeaderWithSearch/PageHeaderWithSearch";
import AirdropsLists from "components/Airdrops/AirdropsLists";
import AirdropsListsTwo from "components/Airdrops/AirdropsListsTwo";
import { GiAirplaneArrival } from 'react-icons/gi';
import { BsCardList } from 'react-icons/bs';

export interface Props {
	className?: string;
}

const Airdrops: FC<Props> = ({ className = "" }) => {

	const { id } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();
	const tab = searchParams.get('tab');

	const [searchKey, setSearchKey] = useState<string>('');

	//----------------
	const tabs: any = [
		{ name: 'Airdrops', icon: <GiAirplaneArrival className="text-lg" />, component: <AirdropsLists />, isDisabled: false },
		{ name: 'Airdrops List', icon: <BsCardList className="text-lg" />, component: <AirdropsListsTwo />, isDisabled: (id == 'new') }
	]

	const renderHeading2 = () => {
		return (
			<div>
				<Nav
					className="p-1 bg-white dark:bg-neutral-800 rounded-full shadow-lg"
					containerClassName="mb-12 lg:mb-14 relative flex justify-center w-full text-sm md:text-base"
				>
					{tabs.map((item: any, index: any) => (
						<NavItem2
							key={index}
							isActive={tab === item.name}
							onClick={() => {
								if (!item.isDisabled) {
									setSearchParams({ 'tab': item.name })
								}
							}
							}
						>
							<div className="flex items-center justify-center sm:space-x-2.5 text-xs sm:text-sm ">
								<span
									className="hidden sm:inline-block"
								/* dangerouslySetInnerHTML={{ __html: item.icon }} */
								>{item.icon}</span>
								<span>{item.name}</span>
							</div>
						</NavItem2>
					))}
				</Nav>
			</div>
		);
	};

	useEffect(() => {
		if (tab == null) {
			setSearchParams({ 'tab': tabs[0].name })
		}
	}, []);


	return (
		<div className={`nc-PageUploadItem ${className}`} data-nc-id="PageUploadItem">
			<Helmet>
				<title>Airdrops</title>
			</Helmet>
			<PageHeaderWithSearch title="Airdrops" onSearch={setSearchKey} searchKey={searchKey} />
			
			<div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
					{renderHeading2()}
					<div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
						{tabs.map((_tab: any, i: any) => {
							if (tab == _tab.name) {
								return <React.Fragment key={i}>{_tab.component}</React.Fragment>
							}
						})}
					</div>
				</div>
			</div>
	);
};

export default Airdrops;
