import FormItem from 'components/FormItem';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import Switch from "shared/Switch/Switch";
import { yupResolver } from '@hookform/resolvers/yup';
import { CustodialWalletFormSchemaValidation } from "utils/form-validation";
import { CustodialWalletFormValues } from "_common/interfaces/FormProps";
import CheckBoxNative from "shared/Checkbox/nativeCheckbox";

import { useSiteSettingsSelector } from '_common/hooks/selectors/siteSettingsSelector'
import { useSiteSettingsApi } from '_common/hooks/api/siteSettingsApiHook';
import { useAppLoader } from '_common/hooks/common/appLoaderHook';
import toast from 'react-hot-toast';

const PaymentSettings = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get('tab');

    const siteSettingsApi = useSiteSettingsApi()
    const siteSettingsList = useSiteSettingsSelector()
    const { showLoader, hideLoader } = useAppLoader()

    const [tabFields, setTabFields] = useState<any>()

    const { register, control, handleSubmit, reset, setValue, formState: { errors }, watch } = useForm()

    const getTabFiels = () => {
        //console.log("Sao paulo", siteSettingsList);
        siteSettingsList.map((item: any, index: number) => {
            if (item?.siteGroupName == "Stripe") {
                setTabFields(item)
            }
        })
    }

    const onSubmit = (data: any) => {
        // console.log({ data })
        let params: any = []

        for (const key in data) {
            let tempParams: any = {}
            tabFields?.keyValue.forEach((element: any) => {
                if (element?.appKey == key) {
                    tempParams["appKey"] = element?.appKey
                    tempParams["appValue"] = data[key]
                    tempParams["typeID"] = element?.typeID
                    params.push(tempParams)
                }
            });
        }

        console.log({ params })
        //return false;
        showLoader()
        siteSettingsApi.postSiteSettings({
            "siteSettings": params
        }, (message: string, resp: any) => {
            // console.log({ resp, message })
            toast.success(message)
            // handleCancel()
            getTabFiels()
            hideLoader();
            window.location.reload();
        }, (message: string) => {
            console.log({ message })
            toast.error(message)
            hideLoader()
        })
    }

    const handleCancel = () => {
        reset({
            IsStripeEnable: false,
            StripePublicKey: '',
            StripeSecretKey: ''
        })
    }

    useEffect(() => {
        if (tab && siteSettingsList) {
            getTabFiels()
        }
    }, [tab, siteSettingsList])

    useEffect(() => {
        reset({
            IsStripeEnable: (tabFields?.keyValue[0]?.appValue === "true"),
            StripePublicKey: tabFields?.keyValue[1]?.appValue,
            StripeSecretKey: tabFields?.keyValue[2]?.appValue
        })
    }, [tabFields])

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormItem label="Is Stripe Enable" className='mb-4 mt-4'>
                    <Switch label=""
                        {...register(`IsStripeEnable`)}
                    />
                </FormItem>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5">
                    <FormItem label="Stripe Public Key">
                        <Controller
                            name={`StripePublicKey`}
                            control={control}
                            render={({ field }) => <Input
                                {...field}
                                type="password"
                                placeholder={''}
                                autoComplete={'off'}
                                required={false}
                            />}
                        />
                        {
                            errors && errors.StripePublicKey && errors.StripePublicKey?.type == "required" ?
                                <p className='text-red-400'>Required</p> : ''
                        }
                    </FormItem>
                    <FormItem label="Stripe Secret Key">
                        <Controller
                            name={`StripeSecretKey`}
                            control={control}
                            render={({ field }) => <Input
                                {...field}
                                type="password"
                                placeholder={''}
                                autoComplete={'off'}
                                required={false}
                            />}
                        />
                        {
                            errors && errors.StripeSecretKey && errors.StripeSecretKey?.type == "required" ?
                                <p className='text-red-400'>Required</p> : ''
                        }
                    </FormItem>
                </div>

                <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 m-8">
                    <ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
                    <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Reset</ButtonSecondary>
                </div>
            </form>
        </React.Fragment>
    )
}

export default PaymentSettings