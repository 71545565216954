import { getPriceFormat, getPriceIconUrl } from "_common/functions";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/outline";

interface props {
    product: any
}

const ActivitiesHistory: FC<props> = ({ product }) => {
    const [list, setList] = useState<any[] | null>(null);


    const showCartPrice = (network: any, price: any) => {
        return (
            <div className="flex items-center">
                <NcImage
                    className="object-contain"
                    src={getPriceIconUrl(network)}
                    containerClassName="nc-NcImage flex aspect-w-11 aspect-h-12 p-3 mr-2 h-0 rounded-3xl overflow-hidden z-0"
                />
                <span className="font-medium text-md">{getPriceFormat(network, price)}</span>
            </div>
        )
    }

    useEffect(() => {
        if (product && product.activities) {
            let _list: any[] = [];
            product.activities.map((edition: any) => {
                _list.push(edition)
            });
            setList(_list);
        }
    }, [product])


    return (
        <div className="w-full rounded-2xl">
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
                            <span>Activity History</span>
                            <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-neutral-500`} />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-neutral-500 text-sm dark:text-neutral-400" as="p">
                            {list?.length ?
                                <div className="pb-9 pt-1">
                                    <div className="cstm-tab-filter-5 cstm-scrollbar">
                                        <table className="w-full table-fixed">
                                            <thead>
                                                <tr>
                                                    <th className="text-left dark:text-gray-100 text-gray-900 p-1">Event</th>
                                                    <th className="text-left dark:text-gray-100 text-gray-900 p-1">Unit Price</th>
                                                    <th className="text-left dark:text-gray-100 text-gray-900 p-1">From</th>
                                                    <th className="text-left dark:text-gray-100 text-gray-900 p-1">To</th>
                                                    <th className="text-left dark:text-gray-100 text-gray-900 p-1">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list.map((edition: any, i: any) => (
                                                    <tr key={i} className="border-t">
                                                        <td>
                                                            <p className="p-1 py-2">{edition.activityName}</p>
                                                        </td>
                                                        <td>
                                                            <p className="p-1 py-2">{showCartPrice(edition.network, edition.cryptoPrice)}</p>
                                                        </td>
                                                        <td>
                                                            <p className="p-1 py-2">{edition.fromUser || '--'}</p>
                                                        </td>
                                                        <td>
                                                            <p className="p-1 py-2">{edition.toUser || '--'}</p>
                                                        </td>
                                                        <td>
                                                            <p className="p-1 py-2">{moment(edition.addedDate).fromNow()}</p>
                                                            {/* <p className="p-1 py-2">{moment(edition.addedDate).format('DD MMM,YYYY')}</p> */}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                : null}
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    )
}


export default ActivitiesHistory;