import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi } from './api/callApi';
const API_BASE_URL = process.env.REACT_APP_API_URL;

const serialize = (obj: any) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

const asUrlParams = (obj: any) => {
    return '?' + serialize(obj);
}


export function* login(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.LOGIN, data);
        if (resp.status === 200 && 
            resp.data && 
            typeof resp.data === 'object' && 
            resp.data.hasOwnProperty('successful') && 
            resp.data.hasOwnProperty('code') && 
            resp.data.successful === true && 
            resp.data.code === 200 && 
            Array.isArray(resp.data.authenticateDataModel) && 
            resp.data.authenticateDataModel.length > 0 &&
            resp.data.authenticateDataModel[0].responseToken
        ) {
             sessionStorage.setItem(STORAGE, JSON.stringify(resp.data.authenticateDataModel[0]));
             yield put({
                 type: ACTIONS.USER.LOGIN,
                 payload: {
                     user:  resp.data.authenticateDataModel[0],
                     token: resp.data.authenticateDataModel[0].responseToken,
                     refreshToken: resp.data.authenticateDataModel[0].refreshToken,
                 }
             })
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



export function* logout(action: any): any {
    try {
        sessionStorage.removeItem(STORAGE)
        yield put({
            type: ACTIONS.USER.LOGOUT,
        });
        action && action.callbackSuccess && action.callbackSuccess({});
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* post(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_BASE_URL+'/ExecuteRequest', data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.isSuccess  && resp.data.outputParameters) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



export function* registerStepOne(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.REGISTER.STEP1, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.ProfileId) {
             sessionStorage.setItem("ProfileId", JSON.stringify(resp.data.ProfileId));
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* registerStepTwo(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.REGISTER.STEP2, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.ProfileId) {
             sessionStorage.setItem("ProfileId", JSON.stringify(resp.data.ProfileId));
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* registerStepThree(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.REGISTER.STEP3, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.ProfileId) {
             sessionStorage.setItem("ProfileId", JSON.stringify(resp.data.ProfileId));
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* registerStepFour(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.REGISTER.STEP4, data);
         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.ProfileId) {
             sessionStorage.setItem("ProfileId", JSON.stringify(resp.data.ProfileId));
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* loginstep1(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.LOGIN, data);
         if (resp && resp.status === 200 && resp.data && 
            typeof resp.data === 'object' && 
            resp.data.hasOwnProperty('successful') && 
            resp.data.hasOwnProperty('code') && 
            resp.data.successful === true && 
            resp.data.code === 200
        ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* forgotpassword(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.FORGOT_PASSWORD, data);
         if (resp && resp.status === 200 && resp.data && 
            typeof resp.data === 'object' && 
            resp.data.hasOwnProperty('successful') && 
            resp.data.hasOwnProperty('code') && 
            resp.data.successful === true && 
            resp.data.code === 200
        ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* setforgottenpassword(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.RESET_PASSWORD, data);
         if (resp && resp.status === 200 && resp.data && 
            typeof resp.data === 'object' && 
            resp.data.hasOwnProperty('successful') && 
            resp.data.hasOwnProperty('code') && 
            resp.data.successful === true && 
            resp.data.code === 200
        ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getStoreConfiguration(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.STORE.CONFIG, data, true);
         if (resp && resp.status === 200 && resp.data && 
            typeof resp.data === 'object' && 
            resp.data.hasOwnProperty('successful') && 
            resp.data.hasOwnProperty('code') && 
            resp.data.successful === true
        ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* saveStoreConfiguration(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.STORE.CONFIG, data, true);
         if (resp && resp.status === 200 && resp.data && 
            typeof resp.data === 'object' && 
            resp.data.hasOwnProperty('successful') && 
            resp.data.hasOwnProperty('code') && 
            resp.data.successful === true
        ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getProfileDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.USER.GET_PROFILE, data, true);
         if (resp.status >= 200 && resp.status < 300 && resp.data) {
            yield put({
                type: ACTIONS.USER.UPDATE_CUSTODIAL,
                payload: {
                    isCustodialWalletEnabled: resp.data && resp.data['users'] && resp.data['users'].length && resp.data['users'][0] && resp.data['users'][0]['isCustodialWalletEnabled'] || 'false',
                    custodialWalletAddress: resp.data && resp.data['users'] && resp.data['users'].length && resp.data['users'][0] && resp.data['users'][0]['custodialWalletAddress'] || ''
                }
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* updateProfile(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.PUT, API_URL.USER.EDIT_PROFILE, data, true);
         if (resp.status >= 200 && resp.status < 300 && resp.data) {
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* changePassword(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.PUT, API_URL.USER.CHANGE_PASSWORD, data, true);
         if (resp.status >= 200 && resp.status < 300 && resp.data) {
             action && action.callbackSuccess && action.callbackSuccess(resp.data);
         } else {
             action && action.callbackError && action.callbackError(resp.data);
         }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* connectWallet(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.CONNECT_WALLET, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            yield put({
                type: ACTIONS.USER.CONNECT_WALLET,
                payload: data && data['wallets'] || []
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* customerAdd(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.CUSTOMER_ADD, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* customerList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.USER.CUSTOMER_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteCustomer(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.USER.DELETE_CUSTOMER, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* signup(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.SIGNUP, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getUserProfile(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.USER.GET_USER_PROFILE + asUrlParams(data), {}, true,);
        if (resp && resp.status === 200 && resp.data &&
            typeof resp.data === 'object' &&
            resp.data.hasOwnProperty('successful') &&
            resp.data.hasOwnProperty('code') &&
            resp.data.successful === true
        ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
