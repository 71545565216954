import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageHeaderWithSearch from "shared/PageHeaderWithSearch/PageHeaderWithSearch";
import { useNavigate, Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";
import TabFilters from "components/Filters/TabFilters";
import Pagination from "shared/Pagination/PaginationHandler";
import toast from "react-hot-toast";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcCustomModal from "../../components/Modal/NcCustomModal"
import { useUserApi } from "_common/hooks/api/UserApiHook";
import EditFees from "components/FeesUpdate/feesUpdate";
import NcImage from "shared/NcImage/NcImage";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { ITEM_TYPE } from '../../_common/_constant'
import { AiOutlineMail } from 'react-icons/ai';
import { TfiWallet } from "react-icons/tfi";
import NftMoreDropdown from "components/NftMoreDropdown";

export interface Props {
	className?: string;
}

const CustomerListMkt: FC<Props> = ({ className = "" }) => {

	const limit: number = 12;
	const sortBy = [
		{ name: "Active Only", id: "name-active", direction: 1 },
		{ name: "InActive Only", id: "name-inactive", direction: 0 }
	];

	const navigate = useNavigate();
	const [searchKey, setSearchKey] = useState<string>('');
	const [customerList, setCustomerList] = useState<any>(null)
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState<number>(1);
	const [isOpen, setIsOpen] = useState(false);
	const [filter, setFilter] = useState<any>({});
	const [isEditting, setIsEditting] = useState(false);
	const [selectedCustomer, setSelectedCustomer] = useState<any | null>(null);

	const userApi = useUserApi()
	const commonApi = useCommonApi()
	const { showLoader, hideLoader } = useAppLoader()

	const openModalEditFees = (customerID: number) => {
		getCustomerDetails(customerID)
	}
	const closeModalEditFees = () => setIsEditting(false);

	const onSearch = (key: string) => {
		setSearchKey(key);
	}

	const onApplyFilter = (data: any) => {
		console.log('Category Filter =>', data)
		setFilter(data)
	}

	const getCustomerList = () => {
		let params: any = {
			usePaging: true,
			pageSize: limit,
			pageNumber: currentPage
		}
		if (searchKey) {
			params.searchText = searchKey
		}
		switch (filter?.radioBtnFiltrations?.Status) {
			case 'name-active':
				params.isActive = 1;
				break;
			case 'name-inactive':
				params.isActive = 0;
				break;
			default:
		}

		if (filter?.textBoxFilter?.Email) {
			params.emailID = filter?.textBoxFilter?.Email
		}

		if (filter?.textBoxFilter?.Name) {
			params.firstName = filter?.textBoxFilter?.Name
			// params.lastName = filter?.textBoxFilter?.Name
		}

		showLoader()
		userApi.customerList(params,
			(message: string, resp: any) => {
				console.log({ message, resp })
				let tempArr: any = resp?.customers || []
				tempArr.sort((a: any, b: any) => {
					if (a.customerID > b.customerID) return 1;
					if (a.customerID < b.customerID) return -1;
					return 0;
				});
				setCustomerList(tempArr)
				setTotalPage(Math.ceil(resp.totalRecord / limit))
				hideLoader()
			},
			(message: string) => {
				console.log(message)
				hideLoader()
			})
	}

	const getCustomerDetails = (id: number) => {
		let params: any = {
			customerID: +id
		}
		showLoader()
		userApi.customerList(params,
			(message: string, resp: any) => {
				hideLoader()
				const data: any = resp.customers && resp.customers.length && resp.customers[0] || null;
				if (data && data['ownerSettings'] && data['ownerSettings'].length) {
					setSelectedCustomer(data)
					setIsEditting(true);
				} else if (!data) {
					toast.error('Customer not found!')
				} else if (!data.hasOwnProperty('ownerSettings') || (data.hasOwnProperty('ownerSettings') && !data['ownerSettings'].length)) {
					toast.error('Owner settings not found!')
				}
			},
			(message: string) => {
				console.log(message)
				toast.error(message)
				hideLoader()
			})
	}

	const deleteCustomer = (customerID: any) => {
		return new Promise((resolve: any, reject: any) => {
			userApi.deleteCustomer({ customerID: customerID },
				(message: string, resp: any) => {
					resolve(message)
				}, (message: string) => {
					reject(message);
				})
		})
	}

	const customerDeleteHandler = (data: any) => {
		console.log({data})
		toast((t) => (
			<span>
				Are you sure?
				<br />
				You want to <b className="text-danger">delete</b> CUSTOMER{" "}
				<b>{`${data?.firstName} ${data?.lastName} (${data?.emailID})`}</b>
				<br />
				<hr className="mb-3 mt-3" />
				<div className="align-middle flex justify-end">
					<ButtonPrimary className="bg-black m-1 sm:h-9" onClick={() => { toast.dismiss(t.id) }} >Cancel</ButtonPrimary>
					<ButtonPrimary
						className="bg-red-500 m-1 sm:h-9"
						onClick={() => {
							toast.dismiss(t.id);
							toast.promise(
								deleteCustomer(data?.customerID), {
								loading: "Deleting...",
								success: (msg) => {
									getCustomerList();
									return <b>{data?.firstName + " " + data?.lastName} Deleted Successfully!</b>
								},
								error: (msg) => { return <b>{msg}</b> },
							}, { duration: 2000 });
						}}
					>
						Delete
					</ButtonPrimary>

				</div>
			</span>
		),
			{ duration: 95000 }
		);
	}

	const banCustomer = (customerID: any) => {
		return new Promise((resolve: any, reject: any) => {
			commonApi.postReport({ itemID: customerID, itemType: 4 },
				(message: string, resp: any) => {
					resolve(message)
				}, (message: string) => {
					reject(message);
				})
		})
	}

	const customerBanHandler = (data: any) => {
		console.log(data)
		toast((t) => (
			<span>
				Are you sure?
				<br />
				You want to <b className="text-danger">ban</b> CUSTOMER{" "}
				<b>{data?.firstName + " " + data?.lastName}</b>
				<br />
				<hr className="mb-3 mt-3" />
				<div className="align-middle flex justify-end">
					<ButtonPrimary className="bg-black m-1 sm:h-9" onClick={() => { toast.dismiss(t.id) }} >Cancel</ButtonPrimary>
					<ButtonPrimary
						className="bg-red-500 m-1 sm:h-9"
						onClick={() => {
							toast.dismiss(t.id);
							toast.promise(
								banCustomer(data?.customerID), {
								loading: "Deleting...",
								success: (msg) => {
									getCustomerList();
									return <b>{data?.firstName + " " + data?.lastName} Banned Successfully!</b>
								},
								error: (msg) => { return <b>{msg}</b> },
							}, { duration: 2000 });
						}}
					>
						Ban
					</ButtonPrimary>

				</div>
			</span>
		),
			{ duration: 95000 }
		);
	}

	useEffect(() => {
		console.log('on apply filter =>', filter);
		if (customerList) {
			if (currentPage == 1) {
				getCustomerList();
			} else {
				setCurrentPage(1);
			}
		}
	}, [filter, searchKey])

	useEffect(() => {
		getCustomerList();
	}, [currentPage])


	return (
		<div className={`nc-PageUploadItem`} data-nc-id="PageUploadItem">
			<Helmet>
				<title>Customer List (Marketplace)</title>
			</Helmet>
			<PageHeaderWithSearch title="Frontend Customer List (Marketplace)" onSearch={setSearchKey} searchKey={searchKey} />

			<div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
				<div className={`flex flex-col relative ${className}`}>
					<div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
						<div className="relative flex w-full text-sm md:text-base hiddenScrollbar items-center">

						</div>
						<span className="flex flex-shrink-0 text-right items-center">
							<Link className="sm:space-x-2" to="/customerlist-mkt/add">
								<ButtonPrimary>Add New</ButtonPrimary>
							</Link>
							<ButtonSecondary
								className="w-auto !pr-16 ml-2 border-primary-500 focus:ring-0 focus:ring-primary-200"
								sizeClass="pl-4 py-2.5 sm:pl-6"
								onClick={() => {
									setIsOpen(!isOpen);
								}}
							>
								<svg
									className={`w-4 h-4 sm:w-6 sm:h-6`}
									viewBox="0 0 24 24"
									fill="none"
								>
									<path
										d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M19.87 17.12L18.87 16.12"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>

								<span className="block truncate ml-2.5">Filter</span>
								<span className="absolute top-1/2 -translate-y-1/2 right-5">
									<ChevronDownIcon
										className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpen ? "rotate-180" : ""
											}`}
										aria-hidden="true"
									/>
								</span>
							</ButtonSecondary>
						</span>
					</div>

					<Transition
						show={isOpen}
						enter="transition-opacity duration-150"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity duration-150"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
						<TabFilters name="Customer List Filters"
							radioBtnFiltrations={[
								{
									key: 'Status',
									options: sortBy
								}
							]}
							textBoxFilter={[
								{
									key: "Name",
									placeholder: "Enter Name",
								},
								{
									key: "Email",
									placeholder: "Enter Email",
								}
							]}
							onApplyFilter={onApplyFilter} />
					</Transition>
				</div>

				<div className="grid sm:grid-cols-2 gap-x-8 gap-y-8  mt-8 lg:mt-10">
					{customerList && customerList.length > 0 ? (
						<React.Fragment>
							{customerList.map((item: any, index: number) => {
								return (
									<div key={'cstfld-' + index} className={`relative rounded-xl hover:shadow-lg hover:bg-neutral-50 border border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800 dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200 pb-10`}>

										<div className="flex items-center w-full">
											<div className="w-2/5 custom-fld-icon mr-4">
												{/* <ItemCustomFieldImageIcon /> */}
												<NcImage
													containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0 rounded-3xl overflow-hidden z-0"
													src={`${item?.profileImageUrl}`}
													className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
												/>
											</div>
											<div className="w-full">
												<div className="flex justify-between">
													<h3 className={`text-sm font-medium category-name`}>{''}</h3>
													<h3 className={`text-sm font-medium`}>{''}</h3>
												</div>
												<h2 className={`font-semibold mb-1 flex items-center text-2xl pr-10`}>
													{
														item && item.firstName && item.lastName && (
															<span className="font-normal ml-2">
																{item?.firstName + " " + item?.lastName}
															</span>
														) || ''
													}
												</h2>
												{
													item && item.emailID && (
														<h2 className={`font-semibold mb-1 flex items-center text-gray-500`}>
															<span className="font-normal ml-2 flex items-center gap-2 word-break">
																<AiOutlineMail className="flex-shrink-0" /> {item?.emailID}
															</span>
														</h2>
													) || ''
												}
												{/* {
													item && item.phoneNo && (
														<h2 className={`font-semibold mb-1 flex items-center`}><span className="font-normal ml-2 flex items-center gap-2"><SlScreenSmartphone /> {item?.phoneNo}</span></h2>
													) || ''
												} */}
												{
													item && item.cryptoAddress && (
														<h2 className={`font-semibold mb-1 flex items-center`}>

															<span className="font-normal ml-2 flex gap-2 word-break text-sm">
																<TfiWallet className="flex-shrink-0" />
																{item?.cryptoAddress}
															</span>
														</h2>
													) || ''
												}
											</div>
											<div className="flex justify-between pt-1.5">

											</div>
											<div className="absolute left-auto top-3 right-3 flex flex-row-reverse justify-end gap-2">
												<NftMoreDropdown
													actions={[
														{
															id: "customer_fees_update",
															name: "Update Fees",
															icon: "las la-pen",
															callBack: () => openModalEditFees(+item?.customerID)
														},
														{
															id: "customer_delete",
															name: "Delete",
															icon: "las la-trash",
															callBack: () => customerDeleteHandler(item)
														},
														{
															id: "customer_view",
															name: "View",
															icon: "las la-eye",
															callBack: () => { navigate(`/view-profile/${item?.customerID}`) }
														},
														{
															id: "customer_ban",
															name: "Ban",
															icon: "las la-ban",
															callBack: () => customerBanHandler(item)
														},
														{
															id: "admin_assume_login",
															name: "Assume Login",
															icon: "las la-sign-in-alt",
															callBack: () => { }
														},
													]}
													containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
												/>
											</div>
										</div>
										<div className="option ml-4 absolute bottom-3 right-3">
											<h4 className={`text-sm font-medium item-status ` + (item.isActive == 1 ? 'bg-lime-500 text-white' : 'bg-red-300 text-white')}>{item.isActive == 1 ? "Active" : "InActive"}
											</h4>
										</div>
									</div>
								)
							})}
						</React.Fragment>
					) : (
						<h2 className="font-medium text-2xl text-center w-full">No Result Found!</h2>
					)}
				</div>
				{customerList?.length > 0 && totalPage > 1 && (
					<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
						<Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
					</div>
				)}

			</div>
			<NcCustomModal contentExtraClass="max-w-3xl" modalTitle={'Update Fees'} modalStatus={isEditting} closeModal={closeModalEditFees}>
				<EditFees itemType={ITEM_TYPE.CUSTOMER} itemDetails={selectedCustomer} onCloseModalEdit={closeModalEditFees} />
			</NcCustomModal>
		</div>
	);
};

export default CustomerListMkt;
