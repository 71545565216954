import React, { FC, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

import Select from 'react-select'
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import DateInput from "shared/Date/DateInput"
import { AiOutlineCalendar } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useProductApi } from "_common/hooks/api/productApiHook";
import moment from "moment";
import { utcToLocal, localToUtc, CheckNumber, getSelectedOption } from "_common/functions";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";

import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { toast, Toaster } from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import ItemTypeDeleteIcon from "../../components/ItemTypeDeleteIcon";
import FormItem from "components/FormItem";
import { ProductRedemptionFormSchemaValidation } from "utils/form-validation";
import { ProductRedemption_Form } from "_common/interfaces/FormProps";
import Pagination from "shared/Pagination/PaginationHandler";

import { useDateRangeTypeSelector } from '../../_common/hooks/selectors/commonSelector'

import DetailsPayoutGroup from '../PayoutGroup/addUpdate'
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonDanger from "shared/Button/ButtonDanger";
import ItemTypeImageIcon from "components/ItemTypeEditIcon";
import ItemTypeEditIcon from "components/ItemTypeEditIcon";
import DisplayFile from "shared/DisplayNft/displayNftFile";
import NcImage from "shared/NcImage/NcImage";

export interface RedemptionNFTProps {
    className?: string;
}

const Redemption: FC<RedemptionNFTProps> = ({ className = '' }) => {

    const { id: productId } = useParams();
    const navigate = useNavigate();
    const { getProducts, deleteRedemptionProduct, assignRedemptionProduct, listRedemptionProducts } = useProductApi();

    const { register, control, handleSubmit, getValues, watch, reset, resetField, setValue, formState: { errors } } = useForm<ProductRedemption_Form>({
        defaultValues: {
            relationId: 0,
            product: null,
            startDate: null,
            endDate: null,
            quantity: null
        },
        resolver: yupResolver(ProductRedemptionFormSchemaValidation)
    });

    const limit: number = 9;
    const { showLoader, hideLoader } = useAppLoader();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [productList, setProductList] = useState<any>([])
    const [redemptionProductList, setRedemptionProductList] = useState<any>([])
    const [productDetails, setProductDetails] = useState<any>(null)
    const [isRedemptionOpen, setRedemptionOpen] = useState(true)

    useEffect(() => {
        fetchProductDetails();
        fetchProductListToAssign();
        fetchRedemptionProduct();
    }, [])

    const fetchProductDetails = () => {
        if (productId) {
            showLoader()
            getProducts({ productID: productId },
                (message: string, resp: any) => {
                    const data = resp && resp.products && resp.products.length && resp.products[0] || null;
                    if (data) {
                        hideLoader()
                        setProductDetails(data)
                    } else {
                        hideLoader()
                        toast.error('Product not found!')
                        navigate('/products')
                    }
                },
                (message: string, resp: any) => {
                    hideLoader()
                    console.log('error resp: ', resp)
                    toast.error(message)
                })
        } else {
            toast.error('Product ID not found!')
            navigate('/products')
        }
    }

    const fetchProductListToAssign = () => {
        getProducts({},
            (message: string, resp: any) => {
                const data = resp && resp.products && resp.products.length && resp.products.map((product: any) => ({
                    label: product['productName'] || '',
                    value: product['productID'] || ''
                })) || [];
                setProductList(data)
            },
            (message: string, resp: any) => {
                console.log('error resp: ', resp)
                toast.error(message)
            })
    }

    const fetchRedemptionProduct = () => {
        let params: any = {
            usePaging: true,
            pageSize: limit,
            pageNumber: currentPage,
            productID: productId
        }
        listRedemptionProducts(params,
            (message: string, resp: any) => {
                const redemptionList = resp.productRedeem && resp.productRedeem.length && resp.productRedeem.map((e: any) => ({
                    ...e,
                    startDate: e['startDate'] && moment.utc(e['startDate']).local().format('MM-DD-YYYY'),
                    endDate: e['endDate'] && moment.utc(e['endDate']).local().format('MM-DD-YYYY'),
                })) || [];
                setTotalPage(Math.ceil(resp.totalRecord / limit));
                setRedemptionProductList(redemptionList)
            },
            (message: string, resp: any) => {
                //toast.error(message)
            })
    }

    useEffect(() => {
        fetchRedemptionProduct();
    }, [currentPage])

    const onSubmit = (data: any) => {
        console.log('data: ', data)
        showLoader();
        const params: any = {
            productID: productId,
            redeemableProductID: data && data['product'] && data['product']['value'] || '',
            startDate: localToUtc(data['startDate']),
            endDate: localToUtc(data['endDate']),
            quantity: data['quantity']
        }
        if (data['relationId']) {
            params['redeemID'] = data['relationId']
        }
        assignRedemptionProduct(params, (message: string, resp: any) => {
            hideLoader();
            fetchRedemptionProduct();
            reset({
                relationId: 0,
                product: null,
                startDate: null,
                endDate: null,
                quantity: null
            })
            toast.success(message)
        },
            (message: string, resp: any) => {
                hideLoader();
                toast.error(message)
            })
    }

    const handleCancel = () => {
        navigate('/products')
    }

    const redemptionEdit = (item: any, index: number) => {
        reset({
            relationId: item['redeemID'],
            product: {
                value: item['redeemableProductID'],
                label: item['redeemableProductName']
            },
            startDate: utcToLocal(item['startDate']),
            endDate: utcToLocal(item['endDate']),
            quantity: item['quantity']
        })
    }

    const removeRedemption = (relationDetails: any): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            showLoader();
            const params = {
                productID: relationDetails['productID'],
                redeemableProductID: relationDetails['redeemableProductID']
            }
            deleteRedemptionProduct(params,
                (message: string, resp: any) => {
                    hideLoader();
                    return resolve(true)
                },
                (message: string, resp: any) => {
                    hideLoader();
                    // toast.error(message)
                    return reject(false)
                })
        })
    }

    const confirmationDelete = (item: any, index: number) => {
        toast((t) => (
            <span>
                Are you sure to delete this assignment?
                <br />
                Once you confirmed, product <b>{item.redeemableProductName}</b> will be deleted from this assignmnent.
                <br />
                <hr className="mb-3 mt-3" />
                <div className="align-middle flex justify-end">
                    <ButtonPrimary className="bg-black m-1 sm:h-9" onClick={() => { toast.dismiss(t.id) }} >
                        Cancel
                    </ButtonPrimary>
                    <ButtonPrimary
                        className="bg-red-500 m-1 sm:h-9"
                        onClick={() => {
                            toast.dismiss(t.id);
                            toast.promise(
                                removeRedemption(item), {
                                loading: "Deleting...",
                                success: (msg) => {
                                    const list = JSON.parse(JSON.stringify(redemptionProductList))
                                    list.splice(index, 1)

                                    if (list.length) {
                                        setRedemptionProductList(list);
                                    } else {
                                        const page = currentPage > 1 ? currentPage - 1 : 1
                                        setCurrentPage(page)
                                    }
                                    // fetchProductDetails();
                                    return <b>Assignment deleted successfully!</b>
                                },
                                error: (msg) => { return <b>{msg}</b> },
                            }, { duration: 2000 });
                        }}
                    >
                        Delete
                    </ButtonPrimary>
                </div>
            </span>
        ),
            { duration: 95000 }
        );
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className={'mb-8'}>
                <>{console.log('errors: ', errors)}</>
                <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
                    <div className="grid grid-cols-1 sm:grid-cols-4 gap-6 sm:gap-6">
                        <input type="hidden" {...register('relationId')} />
                        <FormItem label="Product" isRequired={true}>
                            <Controller
                                name={"product"}
                                control={control}
                                render={({ field: { value, onChange, onBlur } }) => (
                                    <Select
                                        className={`mt-1 rounded-2xl`}
                                        options={productList}
                                        placeholder={"Select Product"}
                                        onChange={onChange}
                                        value={value}
                                        defaultValue={value}
                                    />
                                )}
                            />
                            {
                                errors && errors.product && errors.product.message &&
                                (<p className='text-red-400'>
                                    {errors.product.message}
                                </p>)
                            }
                        </FormItem>
                        <FormItem label="Start Date" isRequired={true}>
                            <Controller
                                control={control}
                                name="startDate"
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                    <DateInput
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        onBlur={onBlur}
                                        placeholder="Start Date"
                                        showTimeSelect={false}
                                        dateFormat="MM-dd-yyyy"
                                    />
                                )}
                            />
                            {
                                errors && errors.startDate && errors.startDate.message &&
                                (<p className='text-red-400'>
                                    {errors.startDate.message}
                                </p>)
                            }
                        </FormItem>
                        <FormItem label="End Date" isRequired={true}>
                            <Controller
                                control={control}
                                name="endDate"
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                    <DateInput
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        onBlur={onBlur}
                                        placeholder="End Date"
                                        showTimeSelect={false}
                                        dateFormat="MM-dd-yyyy"
                                        startDate={new Date()}
                                    />
                                )}
                            />
                            {
                                errors && errors.endDate && errors.endDate.message &&
                                (<p className='text-red-400'>
                                    {errors.endDate.message}
                                </p>)
                            }
                        </FormItem>
                        <FormItem label="Quantity" isRequired={true}>
                            <Controller
                                control={control}
                                name="quantity"
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                    <Input placeholder={"Enter quantity"} {...register('quantity')} />
                                )}
                            />
                            {
                                errors && errors.quantity && errors.quantity.message &&
                                (<p className='text-red-400'>
                                    {errors.quantity.message}
                                </p>)
                            }
                        </FormItem>
                    </div>

                    <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
                        <ButtonPrimary className="flex-1">Save</ButtonPrimary>
                        <ButtonSecondary type={"button"} className="flex-1" onClick={handleCancel}>Cancel</ButtonSecondary>
                    </div>

                </div>
            </form>
            <div className={"accordion-item-wrapper"}>
                <div className={"accordion-title flex items-center justify-between"}>
                    <span className="text-lg font-bold">Redemption List</span>
                    <ButtonSecondary
                        type="button"
                        className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                        sizeClass={"py-2.5"}
                        onClick={() => setRedemptionOpen(!isRedemptionOpen)}
                    >
                        <span>
                            <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${isRedemptionOpen ? "rotate-180" : ""}`} aria-hidden="true" />
                        </span>
                    </ButtonSecondary>
                </div>
                <Transition
                    show={isRedemptionOpen}
                    enter="transition-opacity duration-150"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className={"accordion-body"}>

                        {redemptionProductList && redemptionProductList.length > 0 ? (
                            <>
                                <div className="gap-5 grid grid-cols-3 sm:gap-2.5">
                                    {redemptionProductList.map((item: any, index: number) => {
                                        return (
                                            <div key={'cstfld-' + index} className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ]`}>
                                                <div className="relative flex-shrink-0 ">
                                                    <div>
                                                        <NcImage
                                                            containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0 rounded-3xl overflow-hidden z-0"
                                                            src={item.productThumbUrl}
                                                            className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
                                                        />
                                                    </div>
                                                    <div className="absolute top-3 left-2 w-full justify-between !h-9 flex ">
                                                        <ButtonCircle type="button" className="" onClick={() => redemptionEdit(item, index)}> <ItemTypeEditIcon /></ButtonCircle>
                                                    </div>
                                                    <span onClick={() => confirmationDelete(item, index)} className="ml-2 delete-btn"><ItemTypeDeleteIcon /></span>
                                                </div>

                                                <div className="p-4 py-5 space-y-3">
                                                    <div className="flex justify-between">
                                                        <h3 className={`text-sm font-medium`}>
                                                            <div className="flex flex-start">
                                                                <AiOutlineCalendar size={20} /> &nbsp; {item.startDate || 'NA'}
                                                            </div>
                                                        </h3>
                                                        <h3 className={`text-sm font-medium`}>
                                                            <div className="flex flex-start">
                                                                <AiOutlineCalendar size={20} /> &nbsp; {item.endDate || 'NA'}
                                                            </div>
                                                        </h3>
                                                    </div>

                                                    <h2 className={`text-lg font-medium mb-3`}>{item.redeemableProductName}</h2>
                                                    <h3 className={`text-sm font-medium`}>Quantity: {item.quantity || 0}</h3>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {totalPage > 1 && (
                                    <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                                        <Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="flex flex-col justify-center p-6">
                                <div className="text-5xl text-center text-yellow-500"><i className="las la-box"></i></div>
                                <div className="text-center"><strong>No Records Yet!</strong></div>
                            </div>
                        )}
                        {/* <div className="table-overflow">
                                <table className="create-new-table">
                                    <thead>
                                        <tr>
                                            <th className="text-left pb-3">Image</th>
                                            <th className="text-left pb-3">Product</th>
                                            <th className="text-left pb-3">Start Date</th> 
                                            <th className="text-left pb-3">End Date</th>
                                            <th className="text-left pb-3">Quantity</th>
                                            <th className="text-left pb-3">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        redemptionProductList.map((item: any, index: number) => (
                                            <tr key={index}>
                                                <td>
                                                    <DisplayFile
                                                    link={item?.thumbFileUrl}
                                                    mimeType={'image/jpeg'} /> 
                                                </td>
                                                <td>{item?.redeemableProductName || 'NA'}</td>
                                                <td>{item.startDate || 'NA'}</td>
                                                <td>{item.endDate || 'NA'}</td>
                                                <td>{item?.quantity || 'NA'}</td>
                                                <td>
                                                    <div className="flex">
                                                        <ButtonCircle type="button" className="" onClick={() => redemptionEdit(item, index)}> <ItemTypeEditIcon /></ButtonCircle>
                                                        <ButtonDanger type="button" className="ml-3" onClick={() => confirmationDelete(item, index)}> <BsFillTrashFill /></ButtonDanger>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    {
                                        redemptionProductList.length === 0 ? (
                                            <tr><td colSpan={7}><span>No Data Available</span></td></tr>
                                        ) : ''
                                    }
                                    </tbody>
                                </table>
                            </div> */}

                    </div>
                </Transition>
            </div>
        </div>
    );
};

export default Redemption;