import React from "react";
import "@google/model-viewer";
import NcImage from "shared/NcImage/NcImage";
import AudioFile from "images/audio-file.png";
import VideoFile from "images/video-file.png";
import _3DFile from "images/3d-file.png";

declare global {
	namespace JSX {
		interface IntrinsicElements {
			'model-viewer': MyElementAttributes;
		}
		interface MyElementAttributes {
			src: string;
			poster: any;
			alt: string;
			ar: any
		}
	}
}

export const Model = ({ link, poster }: any) => (
	<div id="card">
		<model-viewer
			src={link}
			ios-src=""
			poster={poster}
			alt="A 3D model"
			shadow-intensity="1"
			camera-controls
			auto-rotate
			ar
		></model-viewer>
	</div>
);
const imgTypes = ['image/svg+xml','image/jpeg', 'image/png', 'image/gif','image/webp'];
const videoTypes = ['video/webm','video/mp4', 'video/ogg', 'video/webm'];
const audioTypes = ['audio/webm','audio/mpeg', 'audio/wav', 'audio/ogg'];
const THREE_D = ['model/gltf-binary','model/gltf+json'];

interface Props {
  file:any;
  selected:boolean;
  onClick:(file:any)=>void;
}

const ProductFileThumb = ({file, onClick, selected}:Props) => {



	const getVideoThumb = (url:any)=>{
		if (url.match(/\.(svg|jpg|jpeg|png|gif|webp)$/i)){
			return url
		} else {
			return VideoFile
		}
	}

	const getImageContainerClass = () =>{
		return `aspect-w-11 aspect-h-12 rounded-3xl overflow-hidden cursor-pointer border-4 ${selected?'  border-green-500 shadow-xl':'dark:border-neutral-800'}`
	}


	const DisplayFile = () => {
		if(file){
			return (
				<div className={``}>
					{imgTypes.includes(file.mimeType) ? (
						<NcImage src={file.file} containerClassName={getImageContainerClass()} />
					) : videoTypes.includes(file.mimeType) ? (
						<NcImage src={getVideoThumb(file.thumb)} containerClassName={getImageContainerClass()} />
					) : audioTypes.includes(file.mimeType) ? (
						<NcImage src={AudioFile} containerClassName={getImageContainerClass()} />
					) : THREE_D.includes(file.mimeType) && typeof window !== 'undefined' ? (
						<Model link={file.file} poster={file.thumb} />
					) : null}
				</div>
			);
		} else {
			return <NcImage src={''} containerClassName={getImageContainerClass()} />
		}

		
	};
	




  return (
    <div className="" onClick={()=>{onClick(file)}}>
      {DisplayFile()}
    </div>
  );
};

export default ProductFileThumb;
