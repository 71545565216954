import ItemDeleteIcon from "components/ItemTypeDeleteIcon";
import React, { InputHTMLAttributes, useEffect, useState, useMemo } from "react";
import { useDropzone } from 'react-dropzone';

import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonCircle from "shared/Button/ButtonCircle";
import DisplayFile from "shared/DisplayNft/displayNftFile";

const thumbsContainer = {
	display: 'flex',
	//   flexDirection: 'row',
	//   flexWrap: 'wrap',
	marginTop: 16
};

const thumb = {
	display: 'inline-flex',
	borderRadius: 2,
	border: '1px solid #eaeaea',
	marginBottom: 8,
	marginRight: 8,
	width: 155,
	height: 155,
	padding: 4,
	//   boxSizing: 'border-box'
};

const thumbInner = {
	display: 'flex',
	minWidth: 0,
	overflow: 'hidden'
};

const img = {
	display: 'block',
	width: 'auto',
	height: '100%'
};

export interface DropZoneProps extends InputHTMLAttributes<HTMLInputElement> {
	className?: string,
	setFiles: Function,
	files: any[],
	maxFiles?: number,
	maxSize?: number,
	acceptedFileTypes?: any
}

const DropZone = React.forwardRef<HTMLInputElement, DropZoneProps>(({
	className = "",
	setFiles,
	files = [],
	acceptedFileTypes = {},
	maxFiles = 5,
	maxSize = 2e+8
}) => {

	const { getRootProps, getInputProps } = useDropzone({
		multiple: true,
		maxFiles,
		maxSize,
		accept: acceptedFileTypes,
		onDrop: acceptedFiles => {
			const result = acceptedFiles.map((file: any) => {
				const fileExtension = file['name'].split('.').pop();
				const type = file['type'] || (['glb', 'gltf'].includes(fileExtension) ? 'model/gltf-binary' : 'image/jpeg')

				return Object.assign(file, {
					preview: URL.createObjectURL(file),
					mimeType: type
				})
			})
			setFiles(result);
		}
	});

	const deleteImage = (index: number) => {
		const latestFiles = JSON.parse(JSON.stringify(files))
		latestFiles.splice(index, 1)
		setFiles(latestFiles)
	}

	const thumbs = files.map((file: any, index: number) => (
		<div style={thumb} key={index}>
			<div style={thumbInner} className="relative flex-shrink-0">
				<DisplayFile link={file?.preview} mimeType={file?.mimeType} />
				{/* <img
              src={file.preview}
              style={img}
              // Revoke data uri after image is loaded
              onLoad={() => { URL.revokeObjectURL(file.preview) }}
            /> */}
				{/* <div className="absolute top-0 right-1 justify-between !h-9 flex ">
				<ButtonCircle type="button" size="w-6 h-6" onClick={() => deleteImage(index)}>
					<ItemDeleteIcon className="w-4 h-4 md:w-5 md:h-5" />
				</ButtonCircle>
			</div> */}
			</div>
		</div>
	));

	useEffect(() => {
		// Make sure to revoke the data uris to avoid memory leaks, will run on unmount
		return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview));
	}, []);

	return (
		<div className={`${className} check`}>
			<section >
				<div {...getRootProps({ className: 'dropzone dropzone-wrapper text-black' })}>
					<input {...getInputProps()} />
					<div className="flex items-center justify-between flex-col">
						<div>
							<svg
								className="mx-auto h-11 w-10 text-neutral-400"
								stroke="currentColor"
								fill="none"
								viewBox="0 0 48 48"
								aria-hidden="true"
							>
								<path
									d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								></path>
							</svg>
						</div>
						<p className="text-sm">Drag 'n' drop some files here, or click to select files</p>
						<p className="text-xs mt-1">File must be a image (*.png, *.jpg, *.jpeg), audio (*.mp3, *.wav), video (*.mp4) or 3D Model.</p>
					</div>					
				</div>
			</section>
			<aside className="thumb-wrapper" style={thumbsContainer}>
				{thumbs}
			</aside>
		</div>

	);
}
);

export default DropZone;
