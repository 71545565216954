import React, { useEffect, useState } from "react";
import Label from "components/Label/Label";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";

interface IncomingProps {
    user: any;
    getUserDetails: any;
    userDetaits: any
}

const UpdateProfile = ({ user, getUserDetails, userDetaits }: IncomingProps) => {

    const userApi = useUserApi()
    const commonApi = useCommonApi()
    const { showLoader, hideLoader } = useAppLoader()

    const [profileImageLink, setProfileImageLink] = useState<any>('')
    // const [profileImageFile, setProfileImageFile] = useState<any>(null)

    const { register, handleSubmit, reset, formState: { errors } } = useForm()

    const handleKeyPress = (event: any) => {
        // console.log("keypreess", event)
        // return (event.charCode == 8 ||  event.charCode >= 48 && event.charCode <= 57)
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    const mapCategoryImage = (id: any, file: any, type: string) => {
        console.log({ id, file, type })
        return new Promise((resolve: any, reject: any) => {
            let data: any = new FormData();
            data.append("", file);
            data.append("ItemId", user?.userID);
            data.append("ItemType", 8);
            data.append("FileType", type);

            commonApi.fileUpload(data,
                (message: string, resp: any) => {
                    console.log('f complete')
                    resolve(message)
                }, (message: string) => {
                    console.log('f failed')
                    reject(message)
                })
        })

    }

    const handleUpdateProfile = (data: any) => {
        // console.log({ data })
        const file = data["file"]
        delete data["file"]
        data["userID"] = user?.userID
        // console.log(data)

        showLoader()
        userApi.updateProfile(data,
            (message: string, resp: any) => {
                if (file[0] != undefined) {
                    mapCategoryImage(resp.returnID, file[0], "profile")
                        .then((msg: any) => {
                            toast.success(message);
                            hideLoader();
                            getUserDetails()
                        })
                        .catch((msg: any) => {
                            toast.success(message);
                            hideLoader();
                            getUserDetails()
                        })
                } else {
                    toast.success(message);
                    hideLoader();
                    getUserDetails()
                }
            }, (message: string) => {
                toast.error(message)
                hideLoader()
            })
    }

    const handleImageChange = (event: any) => {
        console.log({ event: event.target.files })
        if (event.target.files && event.target.files[0]) {
            // setProfileImageFile(event.target.files[0])
            setProfileImageLink(URL.createObjectURL(event.target.files[0]));
        }
        // if (event.target.files && event.target.files[0]) {
        //     let reader = new FileReader();
        //     reader.onload = (e: any) => {
        //         setProfileImageLink(e.target.result);
        //     };
        //     reader.readAsDataURL(event.target.files[0]);
        // }
    }

    useEffect(() => {
        console.log({ userDetaits })
        if (userDetaits) {
            reset({
                firstName: userDetaits.firstName,
                lastName: userDetaits.lastName,
                address: userDetaits.address,
                phoneNo: userDetaits.phoneNo,
                country: userDetaits.country,
                state: userDetaits.state,
                city: userDetaits.city,
                email: userDetaits.userName,
                zipCode: userDetaits.zipCode
            })
            setProfileImageLink(userDetaits?.profilePicturePath)
        }
    }, [userDetaits])


    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(handleUpdateProfile)} className='mt-6'>
                <div className="flex flex-col md:flex-row">
                    <div className="flex-shrink-0 flex items-start">
                        <div className="relative rounded-full overflow-hidden flex">
                            {profileImageLink.length > 0 ?
                                <Avatar sizeClass="w-32 h-32" imgUrl={profileImageLink} /> :
                                <Avatar sizeClass="w-32 h-32" />
                            }
                            <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                                <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                                        stroke="currentColor"
                                        strokeWidth={1.5}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>

                                <span className="mt-1 text-xs">Change Image</span>
                            </div>
                            <input
                                type="file"
                                className="absolute inset-0 opacity-0 cursor-pointer"
                                {...register(`file`, { required: false })}
                                onChange={(e: any) => handleImageChange(e)}
                            />
                        </div>
                    </div>
                    <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-5 sm:space-y-6 md:sm:space-y-7">

                        <div>
                            <Label>First Name</Label>
                            <Input className="mt-1.5"
                                {...register(`firstName`, { required: false })}
                            />
                        </div>

                        <div>
                            <Label>Last Name</Label>
                            <Input className="mt-1.5"
                                {...register(`lastName`, { required: false })}
                            />
                        </div>

                        <div>
                            <Label>Email</Label>
                            <div className="mt-1.5 flex">
                                <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                    <i className="text-2xl las la-envelope"></i>
                                </span>
                                <Input
                                    className="!rounded-l-none"
                                    placeholder="example@email.com"
                                    {...register(`email`, { required: false, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                                    disabled={true}
                                />
                            </div>
                            {errors?.email ? <p className='text-red-400'>
                                {errors.email.type === "required" ? 'Required' : null}
                                {errors.email.type === "pattern" ? 'Invalid email address' : null}
                            </p> : null}
                        </div>

                        <div>
                            <Label>Phone no</Label>
                            <Input className="mt-1.5"
                                {...register(`phoneNo`, { required: false })}
                                onKeyPress={(event: any) => handleKeyPress(event)}
                            />
                        </div>

                        <div>
                            <Label>Zip code</Label>
                            <Input className="mt-1.5"
                                {...register(`zipCode`, { required: false })}
                                onKeyPress={(event: any) => handleKeyPress(event)}
                            />
                        </div>

                        <div>
                            <Label>Country</Label>
                            <Input className="mt-1.5"
                                {...register(`country`, { required: false })}
                            />
                        </div>

                        <div>
                            <Label>State</Label>
                            <Input className="mt-1.5"
                                {...register(`state`, { required: false })}
                            />
                        </div>

                        <div>
                            <Label>City</Label>
                            <Input className="mt-1.5"
                                {...register(`city`, { required: false })}
                            />
                        </div>

                        <div>
                            <Label>Address</Label>
                            <Textarea
                                rows={5}
                                className="mt-1.5"
                                placeholder=""
                                {...register(`address`, { required: false })}
                            />
                        </div>

                        <div className="pt-2 m-4">
                            <ButtonPrimary className="w-full" type="submit">Update details</ButtonPrimary>
                        </div>
                    </div>
                </div>
            </form>
        </React.Fragment>
    )
}

export default UpdateProfile