
import React, { FC, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { BsImage } from "react-icons/bs";
import { BiCategory } from "react-icons/bi";
import { AiOutlineSetting, AiOutlinePlus, AiFillMacCommand, AiOutlineAlignLeft, AiOutlineAreaChart, AiOutlineApartment, AiOutlineFundProjectionScreen, AiOutlineGlobal, AiOutlineNotification } from "react-icons/ai";
import Select from 'react-select'
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";
import { yupResolver } from '@hookform/resolvers/yup';

import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Checkbox from "shared/Checkbox/Checkbox";
import Switch from "shared/Switch/Switch";
import InputFile from "shared/InputFile/InputFile";
import Radio from "shared/Radio/nativeRadio";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Product_Form } from "_common/interfaces/FormProps";

import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useBlockchainNetworksSelector } from "_common/hooks/selectors/commonSelector";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useProductApi } from "_common/hooks/api/productApiHook";

import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import ItemTypeDeleteIcon from "../../components/ItemTypeDeleteIcon";
import FormItem from "components/FormItem";
import { ProductFormSchemaValidation } from "utils/form-validation";
import DateInput from "shared/Date/DateInput";
import moment from "moment";
import { utcToLocal } from "_common/functions";
import { MdOutlineMonetizationOn } from "react-icons/md";
import { MdDelete, MdAddBox } from 'react-icons/md';
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { CiEdit } from "react-icons/ci";

export interface CardNFTProps {
    className?: string;
    isLiked?: boolean;
    onDelete?: any
}

const Details: FC<CardNFTProps> = ({ className = "", isLiked, onDelete }) => {

    const { id: productId } = useParams();
    const { showLoader, hideLoader } = useAppLoader();
    const navigate = useNavigate();
    //const blockchainNetworks = useBlockchainNetworksSelector();
    const collectionApi = useCollectionApi();
    const commonApi = useCommonApi();
    const productApi = useProductApi();
    const userApi = useUserApi();

    const { register, control, handleSubmit, getValues, setFocus, watch, reset, setValue, formState: { errors } } = useForm<Product_Form>({
        defaultValues: {
            productName: '',
            collection: null,
            category: null,
            subCategory: null,
            productType: "1",
            quantity: null,
            productGroup: null,
            description: '',
            // productFileUrl: string,
            // productThumbUrl: string,
            // compressedImageFileUrl: string,
            // compressedThumbFileUrl: string,
            blockchainNetwork: null,
            isUnlockableContent: false,
            unlockableContent: '',
            isExplicitSensitiveContent: false,
            isEnableLazyMinting: false,
            isFreezeMetadata: false,
            isFeatured: false,
            isEdition: false,
            editionNumber: "",
            productFile: null,
            productThumb: null,
            // isCreatedByCustomer: boolean,
            productProperty: [
                //{ traitTypeID: 0, propertyName: '', propertyValue: '' }
            ],
            productLevel: [
                //{ traitTypeID: 0, levelName: '', minValue: 0, maxValue: 0 }
            ],
            productStat: [
                //{ traitTypeID: 0, statName: '', minValue: 0, maxValue: 0 }
            ],
            productBoosts: [
                //{ name: "", type: { value: 0, label: 'Number' }, parcentageValue: 0, minValue: 0, maxValue: 0 }
            ],
            productDates: [
                //{ name: "", dateValue: "" }
            ],
            creatorFee: [],
            metaTitle: '',
            metaDescription: '',
        },
        resolver: yupResolver(ProductFormSchemaValidation)
    });
    const { fields: productPropertyFields, append: productPropertyAppend, remove: productPropertyRemove } = useFieldArray({
        control,
        name: "productProperty"
    });
    const { fields: productLevelFields, append: productLevelAppend, remove: productLevelRemove } = useFieldArray({
        control,
        name: "productLevel"
    });
    const { fields: productStatFields, append: productStatAppend, remove: productStatRemove } = useFieldArray({
        control,
        name: "productStat"
    });
    const { fields: productBoostsFields, append: productBoostsAppend, remove: productBoostsRemove } = useFieldArray({
        control,
        name: "productBoosts"
    });
    const { fields: productDatesFields, append: productDatesAppend, remove: productDateRemove } = useFieldArray({
        control,
        name: "productDates"
    });
    const { fields: productCreatorFeesFields, append: productCreatorFeesAppend, remove: productCreatorFeesRemove } = useFieldArray({
        control,
        name: "creatorFee"
    });

    const [categorycollapseopen, setCategorycollapseopen] = useState(false);
    const [imagecollapseopen, setImagecollapseopen] = useState(false);
    const [settingscollapseopen, setSettingscollapseopen] = useState(false);
    const [propertycollapseopen, setPropertycollapseopen] = useState(false);
    const [levelcollapseopen, setLevelcollapseopen] = useState(false);
    const [statscollapseopen, setStatscollapseopen] = useState(false);
    const [seocollapseopen, setSeocollapseopen] = useState(false);
    const [boostcollapseopen, setBoostcollapseopen] = useState(false);
    const [creatorEarningOpen, setCreatorEarningOpen] = useState<boolean>(false)
    const [editionOpen, setEditionOpen] = useState<boolean>(false);

    const [showEdition, setShowEdition] = useState<boolean>(false);
    const [disableEdition, setDisableEdition] = useState<boolean>(false);

    const [productDetails, setProductDetails] = useState<any>(null)
    const [blockchainNetworksOption, setBlockchainNetworksOption] = useState<any>([])
    const [categoriesOption, setCategoriesOption] = useState<any>([]);
    const [subCategoriesOption, setSubCategoriesOption] = useState<any>([]);
    const [payoutGroupList, setPayoutGroupList] = useState<any>([]);
    const [collections, setCollections] = useState<any>([]);
    const [blockchainNetworks, setBlockchainNetworks] = useState<any>([]);

    const [storeconfiguration, setStoreconfiguration] = useState<any>(null);
    const [isFiat, setIsFiat] = useState<Boolean | null>(false);
    const [fiatDecide, setFiatDecide] = useState<Boolean>(false);
    const [fiatDecide_show, setFiatDecide_show] = useState<Boolean>(false);
    const [propertiesDisable, setPropertiesDisable] = useState<any>([]);
    const [selectedCollection, setSelectedCollection] = useState<any>(null);

    const watchCategory: any = watch('category', {})
    const watchCollection: any = watch('collection')


    const addNewProductProperty = () => {
        productPropertyAppend({ traitTypeID: 0, propertyName: '', propertyValue: '' })
    }
    const addNewProductLevel = () => {
        productLevelAppend({ traitTypeID: 0, levelName: '', minValue: 0, maxValue: 0 })
    }
    const addNewProductStat = () => {
        productStatAppend({ traitTypeID: 0, statName: '', minValue: 0, maxValue: 0 })
    }
    const addCreatorFee = () => {
        productCreatorFeesAppend({ earningWalletAddress: '', earningPercentage: 0 })
    }

    const addNewProductBoost = () => {
        productBoostsAppend({
            name: '',
            type: { value: 0, label: 'Number' },
            parcentageValue: 0,
            minValue: 0,
            maxValue: 100
        })
    }

    const addNewProductDate = () => {
        productDatesAppend({
            name: '',
            dateValue: ''
        })
    }

    const onChangeDate = (type: string, value: string, cb: Function) => {
        if (type === 'dateValue') {
            cb(value)
        }
    }

    const fetchSingleCollection = (id: number) => {
        showLoader()
        collectionApi.getCollections({ collectionID: id }, (message: string, resp: any) => {
            hideLoader();
            if (resp.code == 204) {
                toast.error(message)
            } else {
                setSelectedCollection(resp.collections[0])
                let fiatvarx = false;
                if (Math.abs(resp.collections[0].isFiat) === 0) {
                    fiatvarx = false;
                    setIsFiat(false)
                    setFiatDecide_show(false)
                } else if (Math.abs(resp.collections[0].isFiat) === 1) {
                    fiatvarx = true;
                    setIsFiat(true)
                    setFiatDecide_show(false)
                } else if (Math.abs(resp.collections[0].isFiat) === 2) {
                    fiatvarx = false;
                    setFiatDecide_show(true)
                    setIsFiat(productDetails?.isFiat || false)
                }
                //console.log("Dhurbba", resp.collections[0].isFiat, fiatvarx, productDetails?.isFiat);
                if (Math.abs(resp.collections[0].isFiat) === 0) {
                    setValue('blockchainNetwork', { label: resp.collections[0].chainLabel, value: resp.collections[0].blockchainNetworkID });
                } else if (Math.abs(resp.collections[0].isFiat) === 1) {
                    //setValue('blockchainNetwork', null)
                }  else if (Math.abs(resp.collections[0].isFiat) === 2 && fiatvarx === false) {
                    if (productDetails && productDetails.isFiat && blockchainNetworksOption) {
                        const selectedNetwork = blockchainNetworksOption.find((network: any) => productDetails['blockchainNetworkID'] && +productDetails['blockchainNetworkID'] === +network['value'])
                        setValue('blockchainNetwork', selectedNetwork);
                    } else {
                        setValue('blockchainNetwork', { label: resp.collections[0].chainLabel, value: resp.collections[0].blockchainNetworkID });
                    }
                } else if (Math.abs(resp.collections[0].isFiat) === 2 && productDetails && productDetails.isFiat) {
                    //setValue('blockchainNetwork', null)

                }

                if (Math.abs(resp.collections[0].collectionTypeID) === 1) {
                    setShowEdition(true);
                } else {
                    setShowEdition(false);
                }
                if (!productDetails || (productDetails && Array.isArray(productDetails.creatorFee) && productDetails.creatorFee.length === 0)) {
                    productCreatorFeesRemove()
                    if (resp.collections[0]['creatorFee'].length > 0) {
                        resp.collections[0]['creatorFee'].map((e: any) => productCreatorFeesAppend({
                            earningWalletAddress: e['earningWalletAddress'],
                            earningPercentage: e['earningPercentage']
                        }, { shouldFocus: false }));
                    }
                }
            }
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        })
    }

    const getCollection = () => {
        showLoader()
        collectionApi.getCollections({sortingBy: 'collectionName', sortingOrder: 'asc'}, (message: string, resp: any) => {
            hideLoader();
            const data = resp.collections && resp.collections.map((e: any) => ({
                label: e['collectionName'],
                value: +e['collectionID'],
                creatorFee: e['creatorFee']
            })) || []
            setCollections(data);
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        })
    }

    const getCategories = (parentCategoryID: any = 0) => {
        showLoader();
        const params: any = {
            sortingBy: 'categoryName',
            sortingOrder: 'asc'
        };
        params['parentCategoryID'] = parentCategoryID;
        productApi.getCategories(params, (message: string, resp: any) => {
            hideLoader();
            const data = resp.categorydm && resp.categorydm.map((cat: any) => {
                cat.label = cat.categoryName;
                cat.value = +cat.categoryID;
                return cat;
            }) || []
            if (parentCategoryID) {
                setSubCategoriesOption(data);
            } else {
                setCategoriesOption(data);
            }
        }, (message: string) => {
            hideLoader();
            toast.error(message);
        })
    }

    const getPayoutGroups = () => {
        showLoader();
        productApi.listProductGroup({}, (message: string, resp: any) => {
            hideLoader();
            const data = resp.productGroupdm && resp.productGroupdm.map((group: any) => ({
                value: +group['productGroupID'],
                label: group['productGroupName']
            })) || []
            setPayoutGroupList(data);
        }, (message: string) => {
            hideLoader();
            toast.error(message);
        })
    }

    const getBlockchainNetworks = () => {
        const params: any = {}
        if (isFiat !== null) {
            params['isFiat'] = isFiat;
        }
        commonApi.getBlockchainNetworks(params, (message: string, resp: any) => { }, (message: string, resp: any) => { console.log(message, resp) })
    }

    const getBlockChainNetwork_new = () => {
        const params: any = {}
        if (isFiat !== null) {
            params['isFiat'] = isFiat;
        }
        showLoader();
        commonApi.getBlockchainNetworks(params, (message: string, resp: any) => {
            hideLoader();
            if (resp.successful === true && resp.code === 200) {
                setBlockchainNetworks(resp.networks);
                let options: any[] = [];
                JSON.parse(JSON.stringify(resp.networks)).map((opt: any) => {
                    opt.label = opt.chainLabel;
                    opt.value = +opt.networkID;
                    options.push(opt);
                })
                setBlockchainNetworksOption(options)                
                if (selectedCollection && Math.abs(selectedCollection.isFiat) === 2 && !isFiat) {
                    setValue('blockchainNetwork', { label: selectedCollection.chainLabel, value: selectedCollection.blockchainNetworkID });
                }
            }
        }, (message: string, resp: any) => { console.log(message, resp) })
    }

    const fetchProductDetails = () => {
        if (productId) {
            showLoader();
            productApi.getProducts({ productID: productId },
                (message: string, resp: any) => {
                    hideLoader();
                    const data = resp && resp.products && resp.products.length && resp.products[0] || null;
                    if (data) {
                        setIsFiat(data.isFiat);
                        setProductDetails(data)
                    } else {
                        toast.error('Product not found!')
                        navigate('/products')
                    }
                },
                (message: string, resp: any) => {
                    hideLoader();
                    console.log('error resp: ', resp)
                    toast.error(message)
                })
        } else {
            //addCreatorFee();
        }
    }

    const onSubmit = (datax: any) => {
        let data: any = Object.assign({}, JSON.parse(JSON.stringify(datax)));
        showLoader();
        console.log("Before Parsed", data);
        const params: any = {
            productName: data['productName'],
            collectionID: data['collection'] && +data['collection']['value'] || null,
            categoryID: data['category'] && +data['category']['value'] || null,
            subCategoryID: data['subCategory'] && +data['subCategory']['value'] || null,
            productType: +data['productType'] || 1,
            quantity: data['quantity'],
            productGroupID: data['productGroup'] && +data['productGroup']['value'] || null,
            description: data['description'],
            //productFileUrl: data['productFile'] && data['productFile'][0] ? '' : data['productFileUrl'],
            //productThumbUrl: data['productThumb'] && data['productThumb'][0] ? '' : data['productThumbUrl'],
            blockchainNetworkID: data['blockchainNetwork'] && +data['blockchainNetwork']['value'] || null,
            isUnlockableContent: data['isUnlockableContent'] || false,
            unlockableContent: data['unlockableContent'],
            isExplicitSensitiveContent: data['isExplicitSensitiveContent'] || false,
            isEnableLazyMinting: data['isEnableLazyMinting'] || false,
            isFreezeMetadata: data['isFreezeMetadata'] || false,
            isFeatured: data['isFeatured'] ? 1 : 0,
            isEdition: data['isEdition'] ? 1 : 0,
            editionNumber: data['editionNumber'],
            // isCreatedByCustomer: 0,
            productProperty: data['productProperty'] || [],
            productLevel: data['productLevel'] || [],
            productStat: data['productStat'] || [],
            metaTitle: data['metaTitle'] || '',
            metaDescription: data['metaDescription'] || '',
            productBoosts: data.productBoosts.map((o: any) => {
                o['type'] = o.type.value
                return o;
            }),
            productDates: data.productDates.map((o: any) => {
                o.dateValue = moment(o.dateValue).utc().format();
                return o;
            }),
            creatorFee: data['creatorFee'].filter((e: any) => e['earningWalletAddress']) || [],
        }
        if (productId) {
            params['productID'] = +productId
            productApi.updateProduct(params,
                (message: string, resp: any) => {
                    if (resp.successful) {
                        if (datax['productFile'] && datax['productFile'].length || datax['productThumb'] && datax['productThumb'].length) {
                            requestFileUpload(datax, + productId).then(r => {
                                hideLoader();
                                toast.success('Successfully updated the product')
                                setValue('productFile', null);
                                setValue('productThumb', null);
                                fetchProductDetails();
                                // navigate('/products')
                            }).catch(err => {
                                hideLoader();
                                toast.error('Error to update the product')
                            })
                        } else {
                            hideLoader();
                            toast.success(message)
                            // navigate('/products')
                        }
                    } else {
                        hideLoader();
                        toast.error(message)
                    }
                },
                (message: string, resp: any) => {
                    hideLoader();
                    console.log('error resp: ', resp)
                    toast.error(message)
                })
        } else {
            productApi.addProduct(params,
                (message: string, resp: any) => {
                    if (resp.successful) {
                        if (datax['productFile'] && datax['productFile'].length || datax['productThumb'] && datax['productThumb'].length) {
                            requestFileUpload(datax, +resp['returnID']).then(r => {
                                hideLoader();
                                toast.success('Successfully added the product');
                                setValue('productFile', null);
                                setValue('productThumb', null);
                                //navigate('/products/edit/' + resp['returnID'] + '?tab=Details');
                                window.location.href = '/products/edit/' + resp['returnID'] + '?tab=Details';
                            }).catch(err => {
                                hideLoader();
                                toast.error('Error to add product')
                            })
                        } else {
                            hideLoader();
                            toast.success(message);
                            window.location.href = '/products/edit/' + resp['returnID'] + '?tab=Details';
                            //navigate('/products/edit/' + resp['returnID'] + '?tab=Details')
                        }
                    } else {
                        hideLoader();
                        toast.error(message)
                    }
                },
                (message: string, resp: any) => {
                    hideLoader();
                    console.log('error resp: ', resp)
                    toast.error(message)
                })
        }
    }

    const requestFileUpload = (data: any, product_id: number): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            const promiseAll = [];
            if (data['productFile'] && data['productFile'].length) {
                promiseAll.push(handleSingleFileUpload(data['productFile'][0], product_id, 'productfile'))
            }
            if (data['productThumb'] && data['productThumb'].length) {
                promiseAll.push(handleSingleFileUpload(data['productThumb'][0], product_id, 'productthumb'))
            }

            if (promiseAll.length) {
                Promise.all(promiseAll)
                    .then((resp: any) => {
                        return resolve(true)
                    }).catch((err: any) => {
                        return reject(false)
                    })
            } else {
                return resolve(true)
            }
        })
    }

    const handleSingleFileUpload = (file: any, itemId: number, fileType: string): Promise<any> => {
        console.log('handleFileUpload: file => ', file)
        return new Promise<any>((resolve, reject) => {
            console.log('before file: ', file)
            const formData: any = new FormData();
            formData.append("", file)
            formData.append("ItemId", itemId);
            formData.append("ItemType", 2);
            formData.append("FileType", fileType);

            commonApi.fileUpload(formData,
                (message: string, resp: any) => {
                    if (resp['successful']) {
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                }, (message: string) => {
                    reject(false)
                })
        })
    }

    const onChangeCollection = (data: any, callback: Function) => {
        fetchSingleCollection(+data['value'])
        callback();
    }

    const handleCancel = () => {
        navigate('/products')
    }

    const getStoreConfiguration = () => {
        showLoader();
        userApi.getStoreConfiguration({}, (message: any, resp: any) => {
            hideLoader();
            if (resp.successful === true && resp.code === 200 && resp.storeConfiguration.length > 0) {
                //console.log("Get configuration", resp.storeConfiguration[0]);
                setStoreconfiguration(resp.storeConfiguration[0]);
            }
        }, (message: string) => {
            hideLoader();
            toast.error(message);
        });
    }

    /*
    useEffect(() => {
        if (blockchainNetworks && blockchainNetworks.length) {
            let options: any[] = [];
            JSON.parse(JSON.stringify(blockchainNetworks)).map((opt: any) => {
                opt.label = opt.chainLabel;
                opt.value = +opt.networkID;
                options.push(opt);
            })
            setBlockchainNetworksOption(options)
        } else {
            getBlockchainNetworks()
        }
    }, [blockchainNetworks]);
    */

    useEffect(() => {
        console.log('productDetails => %cDone', 'color: darkgreen; font-weight:bold;background: yellow;')
        console.log(`collections.length => %c${collections.length}`, 'color: darkgreen; font-weight:bold background: yellow;')
        console.log(`categoriesOption.length => %c${categoriesOption.length}`, 'color: darkgreen; font-weight:bold; background: yellow;')
        console.log(`payoutGroupList.length => %c${payoutGroupList.length}`, 'color: darkgreen; font-weight:bold; background: yellow;')
        console.log(`blockchainNetworksOption.length => %c${blockchainNetworksOption.length}`, 'color: darkgreen; font-weight:bold; background: yellow;')
        //if (productDetails && collections.length && categoriesOption.length && payoutGroupList.length && blockchainNetworksOption.length) {
        if (productDetails && collections.length && categoriesOption.length && blockchainNetworksOption.length) {
            const selectedCollection = collections.find((collection: any) => productDetails['collectionID'] && +productDetails['collectionID'] === +collection['value'])
            //console.log("selectedCollection", selectedCollection);
            const selectedCategory = categoriesOption.find((category: any) => productDetails['categoryID'] && +productDetails['categoryID'] === +category['value'])
            const selectedProductGroup = payoutGroupList.find((productGroup: any) => productDetails['productGroupID'] && +productDetails['productGroupID'] === +productGroup['value'])
            //console.log("Edittime", blockchainNetworksOption);
            const selectedNetwork = blockchainNetworksOption.find((network: any) => productDetails['blockchainNetworkID'] && +productDetails['blockchainNetworkID'] === +network['value'])
            setIsFiat(productDetails.isFiat);
            // const creatorFees = productDetails['creatorFee'] && productDetails['creatorFee'].length ? productDetails['creatorFee'] : (selectedCategory && selectedCategory['creatorFee'] && selectedCategory['creatorFee'].length ? selectedCategory['creatorFee'] : [{ earningWalletAddress: '', earningPercentage: 0}])
            /*
            collectionApi.getCollections({ collectionID: selectedCollection.value }, (message: string, resp: any) => {

            })
            */
            const productBoots = productDetails.productBoosts.map((o: any) => {
                //o.type = { value: Math.abs(o.type), label: (o.type === 0 ? 'Number' : '%') }
                //delete o.boostID;
                //return o;
                return {
                    name: o.name,
                    type: { value: Math.abs(o.type), label: (Math.abs(o.type) === 0 ? 'Number' : '%') },
                    parcentageValue: o.parcentageValue,
                    minValue: o.minValue,
                    maxValue: o.maxValue
                }
            });
            //console.log("productBoots", productBoots);
            const productDates = productDetails.productDates.map((o: any) => {
                return {
                    name: o.name,
                    dateValue: utcToLocal(o.dateValue)
                };
            });
            const creatorFees = productDetails['creatorFee'] && productDetails['creatorFee'].map((o: any) => ({
                earningWalletAddress: o['earningWalletAddress'],
                earningPercentage: o['earningPercentage']
            })) || [];
            if (Math.abs(productDetails['isEdition']) === 1) {
                setDisableEdition(true);
            } else {
                setDisableEdition(false);
            }
            if (productDetails.productProperty.length > 0) {
                const propertiesEditableArr = productDetails.productProperty.map((o: any) => {
                    return Math.abs(o.isEditable);
                });
                setPropertiesDisable(propertiesEditableArr);
            }
            
            reset({
                productName: productDetails['productName'],
                collection: selectedCollection || null,
                category: selectedCategory || null,
                // subCategory: selectedSubCategory || null,
                productType: '' + productDetails['productType'] || "2",
                quantity: productDetails['quantity'] || null,
                productGroup: selectedProductGroup || null,
                description: productDetails['description'] || '',
                productFileUrl: productDetails['productFileUrl'] || '',
                productThumbUrl: productDetails['productThumbUrl'] || '',
                blockchainNetwork: selectedNetwork || null,
                isUnlockableContent: productDetails['isUnlockableContent'] || false,
                unlockableContent: productDetails['unlockableContent'] || '',
                isExplicitSensitiveContent: productDetails['isExplicitSensitiveContent'] || false,
                isEnableLazyMinting: productDetails['isEnableLazyMinting'] || false,
                isFreezeMetadata: productDetails['isFreezeMetadata'] || false,
                isFeatured: productDetails['isFeatured'] || false,
                isEdition: Math.abs(productDetails['isEdition']) === 1 ? true : false,
                editionNumber: productDetails.editionNumber,
                metaTitle: productDetails['metaTitle'] || '',
                metaDescription: productDetails['metaDescription'] || '',
                // isCreatedByCustomer: boolean,
                productProperty: productDetails['productProperty'] || [],
                productLevel: productDetails['productLevel'] || [],
                productStat: productDetails['productStat'] || [],
                productBoosts: productBoots || [],
                productDates: productDates || [],
                creatorFee: creatorFees
            })
        }
    }, [productDetails, collections, categoriesOption, payoutGroupList])

    useEffect(() => {
        if (productDetails) {
            const selectedNetwork = blockchainNetworksOption.find((network: any) => productDetails['blockchainNetworkID'] && +productDetails['blockchainNetworkID'] === +network['value'])
            //console.log(selectedNetwork, blockchainNetworksOption);
            //setValue('blockchainNetwork', selectedNetwork || null)
        }
    }, [blockchainNetworksOption])

    useEffect(() => {
        if (productDetails && subCategoriesOption.length) {
            const selectedSubCategory = subCategoriesOption.find((subCategory: any) => productDetails['subCategoryID'] && +productDetails['subCategoryID'] === +subCategory['value'])
            setValue('subCategory', selectedSubCategory || null)
        }
    }, [productDetails, subCategoriesOption])

    useEffect(() => {
        if (watchCategory && watchCategory['value']) {
            getCategories(watchCategory['value'])
        }
    }, [watchCategory])

    useEffect(() => {
        if (watchCollection) {
            fetchSingleCollection(+watchCollection['value'])
        }
    }, [watchCollection])


    useEffect(() => {
        getBlockChainNetwork_new();
    }, [isFiat])


    useEffect(() => {
        const firstError = (Object.keys(errors) as Array<keyof typeof errors>).reduce<keyof typeof errors | null>((field, a) => {
            const fieldKey = field as keyof typeof errors;
            return !!errors[fieldKey] ? fieldKey : a;
        }, null);
        if (firstError) {
            switch (firstError) {
                case 'productBoosts':
                case 'productDates':
                    setBoostcollapseopen(true)
                    break;
                case 'productStat':
                    setStatscollapseopen(true)
                    break;
                case 'productLevel':
                    setLevelcollapseopen(true)
                    break;
                case 'productProperty':
                    setPropertycollapseopen(true)
                    break;
                case 'unlockableContent':
                case 'isUnlockableContent':
                    setSettingscollapseopen(true)
                    break;
                case 'category':
                    setCategorycollapseopen(true)
                    break;
                case 'productFile':
                case 'productThumb':
                    setImagecollapseopen(true)
                    break;
                case 'editionNumber':
                    setEditionOpen(true)
                    break;
            }
            setFocus(firstError);
        }
    }, [errors, setFocus]);

    useEffect(() => {
        //console.log("Call root functions ")
        getBlockChainNetwork_new()
        fetchProductDetails();
        getCollection();
        getCategories();
        getPayoutGroups();

        setTimeout(() => {
            console.log("productId", productId);
            if (productId !== 'new' && productId)
                fetchProductDetails();
        }, 2000);
        //getStoreConfiguration();
    }, [])

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
                    <>{console.log('errors: ', errors)}</>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
                        <FormItem label="Collection" isRequired={true}>
                            <Controller
                                name={"collection"}
                                control={control}
                                render={({ field: { value, onChange, onBlur } }) => (
                                    <Select
                                        className={`mt-1 rounded-2xl` + (productId ? ' bg-neutral-100 dark:bg-transparent ' : '')}
                                        options={collections}
                                        placeholder={"Select Collection"}
                                        styles={productId ? {
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                opacity: 0.8,
                                                pointerEvents: 'none'
                                            }),
                                        } : {
                                            control: (baseStyles, state) => ({
                                                ...baseStyles
                                            }),
                                        }}
                                        // onChange={(e: any)=>onChangeCollection(e)}
                                        onChange={(e) => {
                                            //setValue('blockchainNetwork', null)
                                            onChange(e)
                                        }}
                                        value={value}
                                        defaultValue={value}
                                    />
                                )}
                            />
                            {
                                errors && errors.collection && errors.collection.message &&
                                (<p className='text-red-400'>
                                    {errors.collection.message}
                                </p>)
                            }
                        </FormItem>


                        {fiatDecide_show === true && (
                            <FormItem label="Choose Currency type">
                                <div className="grid grid-cols-2 gap-4">
                                    <Radio label="Crypto" type="radio" name="fiatDecide" defaultChecked={!isFiat} value="0" onClick={(e: any) => {
                                        setValue('blockchainNetwork', null)
                                        setIsFiat(false)
                                    }} />
                                    <Radio label="Fiat" type="radio" name="fiatDecide" value="1" defaultChecked={isFiat !== null && isFiat === true ? true : false} onClick={(e: any) => {
                                        setValue('blockchainNetwork', null)
                                        setIsFiat(true)
                                    }} />
                                </div>
                            </FormItem>
                        )}

                        {isFiat !== null && (
                            <FormItem label={(isFiat === false) ? `Blockchain Network` : `Blockchain Network`} isRequired={true}>
                                <Controller
                                    name={"blockchainNetwork"}
                                    control={control}
                                    render={({ field: { value, onChange, onBlur } }) => (
                                        <Select
                                            className={`mt-1 rounded-2xl ` + (((isFiat === false) || isFiat === null) ? ' bg-neutral-100 dark:bg-transparent ' : '')}
                                            styles={((isFiat === false) || isFiat === null) ? {
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    opacity: 0.8,
                                                    pointerEvents: 'none'
                                                }),
                                            } : {
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles
                                                }),
                                            }}
                                            options={blockchainNetworksOption}
                                            placeholder={"Select Network"}
                                            onChange={onChange}
                                            value={value}
                                            defaultValue={value}
                                        />
                                    )}
                                />
                                {
                                    errors && errors.blockchainNetwork && errors.blockchainNetwork.message &&
                                    (<p className='text-red-400'>
                                        {errors.blockchainNetwork.message}
                                    </p>)
                                }
                            </FormItem>
                        )}
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
                        <FormItem label="Name" isRequired={true}>
                            <Input placeholder={"Enter product name"} {...register('productName')} />
                            {
                                errors && errors.productName && errors.productName.message &&
                                (<p className='text-red-400'>
                                    {errors.productName.message}
                                </p>)
                            }
                        </FormItem>
                        <FormItem label="Total Supply" isRequired={true}>
                            <Input placeholder={"Enter quantity"} {...register('quantity')} />
                            {
                                errors && errors.quantity && errors.quantity.message &&
                                (<p className='text-red-400'>
                                    {errors.quantity.message}
                                </p>)
                            }
                        </FormItem>
                    </div>

                    <FormItem label="Description" isRequired={true} desc={
                        <div>
                            The description will be included on the item's detail page
                            underneath its image.{" "}
                            <span className="text-green-500">Markdown</span> syntax is supported.
                        </div>
                    }>
                        <Textarea rows={6} className="mt-1.5" placeholder="..." {...register('description')} />
                        {
                            errors && errors.description && errors.description.message &&
                            (<p className='text-red-400'>
                                {errors.description.message}
                            </p>)
                        }
                    </FormItem>

                    <div className={"accordion-item-wrapper mb-4"}>
                        <div className={"accordion-title flex items-center justify-between"}>
                            <div className="text-lg font-bold flex gap-2"><BiCategory className="text-2xl mt-1" /> <span className="flex flex-col"><p>Categories</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">This is the catgetories where your item will appear.</small></span></div>
                            <ButtonSecondary
                                type="button"
                                className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                                sizeClass={"py-2.5"}
                                onClick={() => {
                                    let categorycollapseopenX = !categorycollapseopen;
                                    setCategorycollapseopen(categorycollapseopenX)
                                }}
                            >
                                <span>
                                    <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${categorycollapseopen ? "rotate-180" : ""}`} aria-hidden="true" />
                                </span>
                            </ButtonSecondary>
                        </div>
                        <Transition
                            show={categorycollapseopen}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className={"accordion-body"}>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mb-4 mt-4">
                                    <FormItem label="Product Type" isRequired={true}>
                                        <div className="grid grid-cols-2 gap-4">
                                            <Radio label="Simple" type="radio" value="1" {...register(`productType`)} />
                                            <Radio label="Group" type="radio" value="2" {...register(`productType`)} />
                                            {
                                                errors && errors.productType && errors.productType.message &&
                                                (<p className='text-red-400'>
                                                    {errors.productType.message}
                                                </p>)
                                            }
                                        </div>
                                    </FormItem>
                                    <FormItem label="Category" isRequired={true} className="mb-5">
                                        <Controller
                                            name={"category"}
                                            control={control}
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <Select
                                                    className={`mt-1 rounded-2xl`}
                                                    options={categoriesOption}
                                                    placeholder={"Select Category"}
                                                    onChange={onChange}
                                                    value={value}
                                                    defaultValue={value}
                                                />
                                            )}
                                        />
                                        {
                                            errors && errors.category && errors.category.message &&
                                            (<p className='text-red-400'>
                                                {errors.category.message}
                                            </p>)
                                        }
                                    </FormItem>

                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
                                    <FormItem label="Sub Category" className="mb-1">
                                        <Controller
                                            name={"subCategory"}
                                            control={control}
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <Select
                                                    className={`mt-1 rounded-2xl`}
                                                    options={subCategoriesOption}
                                                    placeholder={"Select Sub Category"}
                                                    onChange={onChange}
                                                    value={value}
                                                    defaultValue={value}
                                                    isClearable={true}
                                                />
                                            )}
                                        />
                                        {
                                            errors && errors.subCategory && errors.subCategory.message &&
                                            (<p className='text-red-400'>
                                                {errors.subCategory.message}
                                            </p>)
                                        }
                                    </FormItem>
                                    <FormItem label="Product Group" className="mb-1">
                                        <Controller
                                            name={"productGroup"}
                                            control={control}
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <Select
                                                    className={`mt-1 rounded-2xl`}
                                                    options={payoutGroupList}
                                                    placeholder={"Select Product Group"}
                                                    onChange={onChange}
                                                    value={value}
                                                    defaultValue={value}
                                                    isClearable={true}
                                                />
                                            )}
                                        />
                                        {
                                            errors && errors.productGroup && errors.productGroup.message &&
                                            (<p className='text-red-400'>
                                                {errors.productGroup.message}
                                            </p>)
                                        }
                                    </FormItem>

                                </div>
                            </div>
                        </Transition>
                    </div>

                    <div className={"accordion-item-wrapper mb-4"}>
                        <div className={"accordion-title flex items-center justify-between"}>
                            <div className="text-lg font-bold flex gap-2"><BsImage className="text-xl mt-1" /> <span className="flex flex-col"><p>File</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">Image, Video, Audio, or 3D Model</small></span></div>
                            <ButtonSecondary
                                className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                                sizeClass={"py-2.5"}
                                type="button"
                                onClick={() => {
                                    let imagecollapseopenX = !imagecollapseopen;
                                    setImagecollapseopen(imagecollapseopenX)
                                }}
                            >
                                <span>
                                    <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${imagecollapseopen ? "rotate-180" : ""}`} aria-hidden="true" />
                                </span>
                            </ButtonSecondary>
                        </div>
                        <Transition
                            show={imagecollapseopen}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className={"accordion-body mt-4"}>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
                                    <FormItem label="NFT Content" isRequired={true} desc={
                                        <div>
                                            File must be a image (*.png, *.jpg, *.jpeg), audio (*.mp3, *.wav), video (*.mp4) or 3D Model.
                                        </div>
                                    }>
                                        {/* <InputFile  {...register("productFileUrl", { required: false })} uploadedUrl={''} onCanPlayCapture={(e: any) => { if (e == null) { 
                                        //On change file get data
                                    }}} /> */}
                                        <InputFile uploadedUrl={getValues('productFileUrl')} {...register('productFile')} onCanPlayCapture={(e: any) => console.log('e: ', e)} accept=".jpg,.jpeg,.png,.gif,.svg,.mp4,.webm,.mp3,.wav,.ogg,.glb,.gltf" uploadedMimeType={productDetails && productDetails['mimeType'] || ''} />
                                        {
                                            errors && errors.productFile && errors.productFile.message &&
                                            (<p className='text-red-400'>
                                                {errors?.productFile?.message}
                                            </p>)
                                        }
                                    </FormItem>
                                    <FormItem label="Placeholder Image" isRequired={true} desc={
                                        <div>
                                            File must be a image (*.png, *.jpg, *.jpeg).
                                        </div>
                                    }>
                                        {/* <InputFile  {...register("productThumbUrl", { required: false })} uploadedUrl={''} onCanPlayCapture={(e: any) => { if (e == null) { 
                                        //On change file get data
                                    }}} /> */}
                                        <InputFile uploadedUrl={getValues('productThumbUrl')} {...register('productThumb')} accept=".jpg,.jpeg,.png,.gif,.svg,.webp,vnd.microsoft.icon" onCanPlayCapture={(e: any) => {
                                            if (e == null) {
                                                //On change file get data
                                            }
                                        }} />
                                        {
                                            errors && errors.productThumb && errors.productThumb.message &&
                                            (<p className='text-red-400'>
                                                {errors?.productThumb?.message}
                                            </p>)
                                        }
                                    </FormItem>
                                </div>
                            </div>
                        </Transition>
                    </div>

                    <div className={"accordion-item-wrapper mb-4"}>
                        <div className={"accordion-title flex items-center justify-between"}>
                            <div className="text-lg font-bold flex gap-2"><AiOutlineSetting className="text-2xl mt-1" /> <span className="flex flex-col"><p>Settings</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">On or off some of the features related to your NFT</small></span></div>
                            <ButtonSecondary
                                className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                                sizeClass={"py-2.5"}
                                type="button"
                                onClick={() => {
                                    let settingscollapseopenX = !settingscollapseopen;
                                    setSettingscollapseopen(settingscollapseopenX)
                                }}
                            >
                                <span>
                                    <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${settingscollapseopen ? "rotate-180" : ""}`} aria-hidden="true" />
                                </span>
                            </ButtonSecondary>
                        </div>
                        <Transition
                            show={settingscollapseopen}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className={"accordion-body"}>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mb-4 mt-4">
                                    {
                                        /*<div>
                                            Freezing your metadata will allow you to permanently lock and store all of this item's content in decentralized file storage.
                                        </div>*/
                                    }
                                    <FormItem label="Enable Lazy Minting" desc={''}>
                                        <Switch label={""} {...register('isEnableLazyMinting')} />
                                        {
                                            errors && errors.isEnableLazyMinting && errors.isEnableLazyMinting.message &&
                                            (<p className='text-red-400'>
                                                {errors.isEnableLazyMinting.message}
                                            </p>)
                                        }
                                    </FormItem>
                                    <FormItem label="Explicit & Sensitive Content" desc={
                                        <div>
                                            Set this item as explicit and sensitive content.
                                        </div>
                                    }>
                                        <Switch label={""} {...register('isExplicitSensitiveContent')} />
                                        {
                                            errors && errors.isExplicitSensitiveContent && errors.isExplicitSensitiveContent.message &&
                                            (<p className='text-red-400'>
                                                {errors.isExplicitSensitiveContent.message}
                                            </p>)
                                        }
                                    </FormItem>
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mb-4 mt-4">
                                    <FormItem label="Freeze metadata info" desc={
                                        <div>
                                            Freezing your metadata will allow you to permanently lock and store all of this item's content in decentralized file storage.
                                        </div>
                                    }>
                                        <Switch label={""} {...register('isFreezeMetadata')} />
                                        {
                                            errors && errors.isFreezeMetadata && errors.isFreezeMetadata.message &&
                                            (<p className='text-red-400'>
                                                {errors.isFreezeMetadata.message}
                                            </p>)
                                        }
                                    </FormItem>
                                    {
                                        /*
                                        <div>
                                            Include unlockable content that can only be revealed by the owner of the item.
                                        </div>
                                        */
                                    }
                                    <FormItem label="Is Featured?" desc={''}>
                                        <Switch label={""} {...register('isFeatured')} />
                                        {
                                            errors && errors.isFeatured && errors.isFeatured.message &&
                                            (<p className='text-red-400'>
                                                {errors.isFeatured.message}
                                            </p>)
                                        }
                                    </FormItem>
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mb-4 mt-4">
                                    <FormItem label="Unlockable Content" desc={
                                        <div>
                                            Include unlockable content that can only be revealed by the owner of the item.
                                        </div>
                                    }>
                                        <Switch label={""} {...register('isUnlockableContent')} />
                                        {
                                            errors && errors.isUnlockableContent && errors.isUnlockableContent.message &&
                                            (<p className='text-red-400'>
                                                {errors.isUnlockableContent.message}
                                            </p>)
                                        }
                                    </FormItem>
                                </div>

                                {watch('isUnlockableContent') && (
                                    <FormItem label="Content" isRequired={true}>
                                        <Textarea rows={4} className="mt-1.5" placeholder="Enter content (access key, code to redeem, link to a file, etc.)" {...register('unlockableContent')} />
                                        {
                                            errors && errors.unlockableContent && errors.unlockableContent.message &&
                                            (<p className='text-red-400'>
                                                {errors.unlockableContent.message}
                                            </p>)
                                        }
                                    </FormItem>
                                )}
                            </div>
                        </Transition>
                    </div>

                    {showEdition === true && (
                        <div className={"accordion-item-wrapper mb-4"}>
                            <div className={"accordion-title flex items-center justify-between"}>
                                <div className="text-lg font-bold flex gap-2">
                                    <CiEdit className="text-2xl mt-1" />
                                    <span className="flex flex-col"><p>Edition</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">The way to mint a limited supply of identical NFTs</small></span>
                                </div>
                                <ButtonSecondary
                                    className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                                    sizeClass={"py-2.5"}
                                    type="button"
                                    onClick={() => {
                                        let editionOpenX = !editionOpen;
                                        setEditionOpen(editionOpenX)
                                    }}
                                >
                                    <span>
                                        <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${editionOpen ? "rotate-180" : ""}`} aria-hidden="true" />
                                    </span>
                                </ButtonSecondary>
                            </div>
                            <Transition
                                show={editionOpen}
                                enter="transition-opacity duration-150"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity duration-150"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className={"accordion-body"}>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mb-4 mt-4">
                                        <FormItem label="Is Edition">
                                            <Switch label={""} {...register('isEdition')} /> {/* disabled={disableEdition} style={disableEdition ? { opacity: '0.5' } : {}} */}
                                            {
                                                errors && errors.isEdition && errors.isEdition.message &&
                                                (<p className='text-red-400'>
                                                    {errors.isEdition.message}
                                                </p>)
                                            }
                                        </FormItem>
                                        {watch('isEdition') && (
                                            <FormItem label="Edition Number" isRequired={true}>
                                                <Input className="mt-1.5" placeholder={"Enter edition number"} {...register('editionNumber')} />
                                                {
                                                    errors && errors.editionNumber && errors.editionNumber.message &&
                                                    (<p className='text-red-400'>
                                                        {errors.editionNumber.message}
                                                    </p>)
                                                }
                                            </FormItem>
                                        )}
                                    </div>


                                </div>
                            </Transition>
                        </div>
                    )}

                    <div className={"accordion-item-wrapper mb-4"}>
                        <div className={"accordion-title flex items-center justify-between"}>
                            <div className="text-lg font-bold flex gap-2"><AiFillMacCommand className="text-2xl mt-1" /> <span className="flex flex-col"><p>Properties</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">Textual traits that show up as rectangles</small></span></div>
                            <ButtonSecondary
                                className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                                sizeClass={"py-2.5"}
                                type="button"
                                onClick={() => {
                                    let propertycollapseopenX = !propertycollapseopen;
                                    setPropertycollapseopen(propertycollapseopenX)
                                }}
                            >
                                <span>
                                    <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${propertycollapseopen ? "rotate-180" : ""}`} aria-hidden="true" />
                                </span>
                            </ButtonSecondary>
                        </div>
                        <Transition
                            show={propertycollapseopen}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className={"accordion-body"}>
                                {productPropertyFields.length > 0 ? (
                                    <div>
                                        <div className="flex items-center justify-between mb-4 mt-4">
                                            <h4 className="text-lg font-bold">&nbsp;</h4>
                                            <div className="sm:text-sm flex items-center text-blue-800 cursor-pointer mt-4" onClick={addNewProductProperty}><AiOutlinePlus className="mr-2" /> Add New</div>
                                        </div>
                                        {
                                            productPropertyFields.map((field: any, index: number) => (
                                                <div className={"flex items-start mb-4" + (propertiesDisable[index] === 0 ? ' disabled pointer-events-none opacity-50 ' : '')} key={'prpty-' + index}>
                                                    <Input type={'hidden'} {...register(`productProperty.${index}.traitTypeID`)} />
                                                    <FormItem label="Property Name" className="mr-4 w-full custom-sm-w-40">
                                                        <Input placeholder={"Enter Property name"} {...register(`productProperty.${index}.propertyName`)} />
                                                        {
                                                            errors && errors.productProperty && errors.productProperty[index] && errors.productProperty[index]?.propertyName && errors.productProperty[index]?.propertyName?.message ?
                                                                <p className='text-red-400'>{errors.productProperty[index]?.propertyName?.message}</p> : ''
                                                        }
                                                    </FormItem>
                                                    <FormItem label="Peroperty Value" className="w-2/5">
                                                        <Input placeholder={"Enter Property value"} {...register(`productProperty.${index}.propertyValue`)} />
                                                        {
                                                            errors && errors.productProperty && errors.productProperty[index] && errors.productProperty[index]?.propertyValue && errors.productProperty[index]?.propertyValue?.message ?
                                                                <p className='text-red-400'>{errors.productProperty[index]?.propertyValue?.message}</p> : ''
                                                        }
                                                    </FormItem>
                                                    <div className="relative flex-shrink-0 flex mb-1">
                                                        <span onClick={() => productPropertyRemove(index)} className="ml-2 delete-btn relative pt-7 sm-top-8"><ItemTypeDeleteIcon /></span>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ) : (
                                    <div className="flex flex-col justify-center p-6">
                                        <div className="text-5xl text-center text-yellow-500"><i className="las la-box"></i></div>
                                        <div className="text-center"><strong>No Records Yet!</strong></div>
                                        <div className="text-center pointer cursor-pointer text-primary-700 mt-1.5">Please click <a className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm font-medium px-3 py-1 min-w-[84px]  ttnc-ButtonSecondary border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 relative z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0" onClick={addNewProductProperty}>+ Add New</a> to create a new property.</div>
                                    </div>
                                )}

                            </div>
                        </Transition>
                    </div>

                    {/*
                    <div>
                        <div className="flex items-center justify-between mb-4">
                            <h4 className="text-lg font-bold">Properties</h4>
                            <div className="sm:text-sm flex items-center text-blue-800 cursor-pointer" onClick={addNewProductProperty}><AiOutlinePlus className="mr-2" /> Add New</div>
                        </div>
                        {
                            productPropertyFields.map((field: any, index: number) => (
                                <div className="flex items-start mb-4" key={'prpty-' + index}>
                                    <Input type={'hidden'} {...register(`productProperty.${index}.traitTypeID`)} />
                                    <FormItem label="Property Name" className="mr-4 w-full custom-sm-w-40">
                                        <Input placeholder={"Enter Property name"} {...register(`productProperty.${index}.propertyName`)} />
                                        {
                                            errors && errors.productProperty && errors.productProperty[index] && errors.productProperty[index]?.propertyName && errors.productProperty[index]?.propertyName?.message ?
                                                <p className='text-red-400'>{errors.productProperty[index]?.propertyName?.message}</p> : ''
                                        }
                                    </FormItem>
                                    <FormItem label="Peroperty Value" className="w-2/5">
                                        <Input placeholder={"Enter Property value"} {...register(`productProperty.${index}.propertyValue`)} />
                                        {
                                            errors && errors.productProperty && errors.productProperty[index] && errors.productProperty[index]?.propertyValue && errors.productProperty[index]?.propertyValue?.message ?
                                                <p className='text-red-400'>{errors.productProperty[index]?.propertyValue?.message}</p> : ''
                                        }
                                    </FormItem>
                                    <div className="relative flex-shrink-0 flex mb-1">
                                        <span onClick={() => productPropertyRemove(index)} className="ml-2 delete-btn relative pt-7 sm-top-8"><ItemTypeDeleteIcon /></span>
                                    </div>
                                </div>
                            ))
                        }                        
                    </div>
                    */}

                    <div className={"accordion-item-wrapper mb-4"}>
                        <div className={"accordion-title flex items-center justify-between"}>
                            <div className="text-lg font-bold flex gap-2"><AiOutlineApartment className="text-2xl mt-1" /> <span className="flex flex-col"><p>Levels</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">Numerical traits that show as a progress bar</small></span></div>
                            <ButtonSecondary
                                className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                                sizeClass={"py-2.5"}
                                type="button"
                                onClick={() => {
                                    let levelcollapseopenX = !levelcollapseopen;
                                    setLevelcollapseopen(levelcollapseopenX)
                                }}
                            >
                                <span>
                                    <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${levelcollapseopen ? "rotate-180" : ""}`} aria-hidden="true" />
                                </span>
                            </ButtonSecondary>
                        </div>
                        <Transition
                            show={levelcollapseopen}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className={"accordion-body"}>
                                {productLevelFields.length > 0 ? (
                                    <div>
                                        <div className="flex items-center justify-between mb-4 mt-4">
                                            <h4 className="text-lg font-bold"></h4>
                                            <div className="sm:text-sm flex items-center text-blue-800 cursor-pointer mt-4" onClick={addNewProductLevel}><AiOutlinePlus className="mr-2" /> Add New</div>
                                        </div>
                                        {
                                            productLevelFields.map((field: any, index: number) => (
                                                <div className="flex items-start mb-4" key={'prlevel-' + index}>
                                                    <Input type={'hidden'} {...register(`productLevel.${index}.traitTypeID`)} />
                                                    <FormItem label="Level Name" className="mr-4 w-full">
                                                        <Input placeholder={"Enter Level name"} {...register(`productLevel.${index}.levelName`)} />
                                                        {
                                                            errors && errors.productLevel && errors.productLevel[index] && errors.productLevel[index]?.levelName && errors.productLevel[index]?.levelName?.message ?
                                                                <p className='text-red-400'>{errors.productLevel[index]?.levelName?.message}</p> : ''
                                                        }
                                                    </FormItem>
                                                    <FormItem label="Min Value" className="mr-4 w-2/5">
                                                        <Input placeholder={"Enter Min value"} {...register(`productLevel.${index}.minValue`)} />
                                                        {
                                                            errors && errors.productLevel && errors.productLevel[index] && errors.productLevel[index]?.minValue && errors.productLevel[index]?.minValue?.message ?
                                                                <p className='text-red-400'>{errors.productLevel[index]?.minValue?.message}</p> : ''
                                                        }
                                                    </FormItem>
                                                    <FormItem className="w-72" label="Max Value">
                                                        <Input placeholder={"Enter Max value"} {...register(`productLevel.${index}.maxValue`)} />
                                                        {
                                                            errors && errors.productLevel && errors.productLevel[index] && errors.productLevel[index]?.maxValue && errors.productLevel[index]?.maxValue?.message ?
                                                                <p className='text-red-400'>{errors.productLevel[index]?.maxValue?.message}</p> : ''
                                                        }
                                                    </FormItem>
                                                    <div className="relative flex-shrink-0 flex mb-1">
                                                        <span onClick={() => productLevelRemove(index)} className="ml-2 delete-btn relative pt-7"><ItemTypeDeleteIcon /></span>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ) : (
                                    <div className="flex flex-col justify-center p-6">
                                        <div className="text-5xl text-center text-yellow-500"><i className="las la-box"></i></div>
                                        <div className="text-center"><strong>No Records Yet!</strong></div>
                                        <div className="text-center pointer cursor-pointer text-primary-700 mt-1.5">Please click <a className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm font-medium px-3 py-1 min-w-[84px]  ttnc-ButtonSecondary border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 relative z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0" onClick={addNewProductLevel}>+ Add New</a> to create a new level.</div>
                                    </div>
                                )}
                            </div>
                        </Transition>
                    </div>

                    <div className={"accordion-item-wrapper mb-4"}>
                        <div className={"accordion-title flex items-center justify-between"}>
                            <div className="text-lg font-bold flex gap-2"><AiOutlineFundProjectionScreen className="text-2xl mt-1" /> <span className="flex flex-col"><p>Stats</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">Numerical traits that just show as numbers</small></span></div>
                            <ButtonSecondary
                                className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                                sizeClass={"py-2.5"}
                                type="button"
                                onClick={() => {
                                    let statcollapseopenX = !statscollapseopen;
                                    setStatscollapseopen(statcollapseopenX)
                                }}
                            >
                                <span>
                                    <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${statscollapseopen ? "rotate-180" : ""}`} aria-hidden="true" />
                                </span>
                            </ButtonSecondary>
                        </div>
                        <Transition
                            show={statscollapseopen}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className={"accordion-body"}>
                                {productStatFields.length > 0 ? (
                                    <div>
                                        <div className="flex items-center justify-between mb-4 mt-4">
                                            <h4 className="text-lg font-bold"></h4>
                                            <div className="sm:text-sm flex items-center text-blue-800 cursor-pointer mt-4" onClick={addNewProductStat}><AiOutlinePlus className="mr-2" /> Add New</div>
                                        </div>
                                        {
                                            productStatFields.map((field: any, index: number) => (
                                                <div className="flex items-start mb-4" key={'prlevel-' + index}>
                                                    <Input type={'hidden'} {...register(`productStat.${index}.traitTypeID`)} />
                                                    <FormItem label="Stat Name" className="mr-4 w-full">
                                                        <Input placeholder={"Enter Stat name"} {...register(`productStat.${index}.statName`)} />
                                                        {
                                                            errors && errors.productStat && errors.productStat[index] && errors.productStat[index]?.statName && errors.productStat[index]?.statName?.message ?
                                                                <p className='text-red-400'>{errors.productStat[index]?.statName?.message}</p> : ''
                                                        }
                                                    </FormItem>
                                                    <FormItem label="Min Value" className="mr-4 w-2/5">
                                                        <Input placeholder={"Enter Min value"} {...register(`productStat.${index}.minValue`)} />
                                                        {
                                                            errors && errors.productStat && errors.productStat[index] && errors.productStat[index]?.minValue && errors.productStat[index]?.minValue?.message ?
                                                                <p className='text-red-400'>{errors.productStat[index]?.minValue?.message}</p> : ''
                                                        }
                                                    </FormItem>
                                                    <FormItem className="w-72" label="Max Value">
                                                        <Input placeholder={"Enter Max value"} {...register(`productStat.${index}.maxValue`)} />
                                                        {
                                                            errors && errors.productStat && errors.productStat[index] && errors.productStat[index]?.maxValue && errors.productStat[index]?.maxValue?.message ?
                                                                <p className='text-red-400'>{errors.productStat[index]?.maxValue?.message}</p> : ''
                                                        }
                                                    </FormItem>
                                                    <div className="relative flex-shrink-0 flex mb-1">
                                                        <span onClick={() => productStatRemove(index)} className="ml-2 delete-btn relative pt-7"><ItemTypeDeleteIcon /></span>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ) : (
                                    <div className="flex flex-col justify-center p-6">
                                        <div className="text-5xl text-center text-yellow-500"><i className="las la-box"></i></div>
                                        <div className="text-center"><strong>No Records Yet!</strong></div>
                                        <div className="text-center pointer cursor-pointer text-primary-700 mt-1.5">Please click <a className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm font-medium px-3 py-1 min-w-[84px]  ttnc-ButtonSecondary border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 relative z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0" onClick={addNewProductStat}>+ Add New</a> to create a new stat.</div>
                                    </div>
                                )}
                            </div>
                        </Transition>
                    </div>

                    <div className={"accordion-item-wrapper mb-4"}>
                        <div className={"accordion-title flex items-center justify-between"}>
                            <div className="text-lg font-bold flex gap-2"><AiOutlineNotification className="text-2xl mt-1" /> <span className="flex flex-col"><p>Boosts & Dates</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">Numerical traits that show as circle progress bars & Date traits that show as date boxes.</small></span></div>
                            <ButtonSecondary
                                className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                                sizeClass={"py-2.5"}
                                type="button"
                                onClick={() => {
                                    let boostcollapseopenX = !boostcollapseopen;
                                    setBoostcollapseopen(boostcollapseopenX)
                                }}
                            >
                                <span>
                                    <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${boostcollapseopen ? "rotate-180" : ""}`} aria-hidden="true" />
                                </span>
                            </ButtonSecondary>
                        </div>
                        <Transition
                            show={boostcollapseopen}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className={"accordion-body"}>
                                <div className="px-3">
                                    <div className="flex items-center justify-between mb-4 mt-4">
                                        <h4 className="text-lg font-bold">Boosts</h4>
                                        {productBoostsFields.length > 0 && <div className="sm:text-sm flex items-center text-blue-800 cursor-pointer mt-4" onClick={addNewProductBoost}><AiOutlinePlus className="mr-2" /> Add New</div>}
                                    </div>
                                    {productBoostsFields.length > 0 ? (
                                        <div>
                                            {
                                                productBoostsFields.map((field: any, index: number) => (
                                                    <div className="flex items-start mb-4" key={'prboosts-' + index}>
                                                        <FormItem label="Name" className="mr-4 w-80">
                                                            <Input placeholder={"Enter name"} {...register(`productBoosts.${index}.name`)} />
                                                            {
                                                                errors && errors.productBoosts && errors.productBoosts[index] && errors.productBoosts[index]?.name && errors.productBoosts[index]?.name?.message ?
                                                                    <p className='text-red-400'>{errors.productBoosts[index]?.name?.message}</p> : ''
                                                            }
                                                        </FormItem>
                                                        <FormItem label="Type" className={`w-64 mr-4`}>
                                                            <Controller
                                                                name={`productBoosts.${index}.type`}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur } }) => (
                                                                    <Select
                                                                        className={`mt-1 rounded-2xl`}
                                                                        options={[{
                                                                            value: 0,
                                                                            label: 'Number'
                                                                        }, {
                                                                            value: 1,
                                                                            label: '%'
                                                                        }]}
                                                                        placeholder={"Select Type"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                        isClearable={true}
                                                                    />
                                                                )}
                                                            />

                                                            {/*errors && errors.productBoosts && errors.productBoosts[index] && errors.productBoosts[index]?.type && errors.productBoosts[index]?.type?.message && (
                                                        <p className='text-red-400'>
                                                            {errors?.productBoosts[index]?.type?.message}
                                                        </p>
                                                    )*/}
                                                        </FormItem>
                                                        {watch(`productBoosts.${index}.type`)?.value === 1 && (
                                                            <React.Fragment>
                                                                <FormItem label="Percentage Value" className="mr-4 w-1/4">
                                                                    <Input placeholder={"Enter Percentage value"} {...register(`productBoosts.${index}.parcentageValue`)} />
                                                                    {
                                                                        errors && errors.productBoosts && errors.productBoosts[index] && errors.productBoosts[index]?.parcentageValue && errors.productBoosts[index]?.parcentageValue?.message ?
                                                                            <p className='text-red-400'>{errors.productBoosts[index]?.parcentageValue?.message}</p> : ''
                                                                    }
                                                                </FormItem>
                                                                <FormItem className="w-1/4"></FormItem>
                                                            </React.Fragment>
                                                        )}
                                                        {watch(`productBoosts.${index}.type`)?.value === 0 && (
                                                            <React.Fragment>
                                                                <FormItem label="Min Value" className="mr-4 w-1/4">
                                                                    <Input placeholder={"Enter Min value"} {...register(`productBoosts.${index}.minValue`)} />
                                                                    {
                                                                        errors && errors.productBoosts && errors.productBoosts[index] && errors.productBoosts[index]?.minValue && errors.productBoosts[index]?.minValue?.message ?
                                                                            <p className='text-red-400'>{errors.productBoosts[index]?.minValue?.message}</p> : ''
                                                                    }
                                                                </FormItem>
                                                                <FormItem className="w-1/4" label="Max Value">
                                                                    <Input placeholder={"Enter Max value"} {...register(`productBoosts.${index}.maxValue`)} />
                                                                    {
                                                                        errors && errors.productBoosts && errors.productBoosts[index] && errors.productBoosts[index]?.maxValue && errors.productBoosts[index]?.maxValue?.message ?
                                                                            <p className='text-red-400'>{errors.productBoosts[index]?.maxValue?.message}</p> : ''
                                                                    }
                                                                </FormItem>
                                                            </React.Fragment>
                                                        )}
                                                        <div className="relative flex-shrink-0 flex mb-1">
                                                            <span onClick={() => productBoostsRemove(index)} className="ml-2 delete-btn relative pt-7"><ItemTypeDeleteIcon /></span>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ) : (
                                        <div className="flex flex-col justify-center p-6">
                                            <div className="text-5xl text-center text-yellow-500"><i className="las la-box"></i></div>
                                            <div className="text-center"><strong>No Records Yet!</strong></div>
                                            <div className="text-center pointer cursor-pointer text-primary-700 mt-1.5">Please click <a className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm font-medium px-3 py-1 min-w-[84px]  ttnc-ButtonSecondary border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 relative z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0" onClick={addNewProductBoost}>+ Add New</a> to create a new boost.</div>
                                        </div>
                                    )}
                                </div>
                                <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
                                <div className="px-3">
                                    <div className="flex items-center justify-between mb-4 mt-4">
                                        <h4 className="text-lg font-bold">Dates</h4>
                                        {productDatesFields.length > 0 && <div className="sm:text-sm flex items-center text-blue-800 cursor-pointer mt-4" onClick={addNewProductDate}><AiOutlinePlus className="mr-2" /> Add New</div>}
                                    </div>
                                    {productDatesFields.length > 0 ? (
                                        <div>
                                            {
                                                productDatesFields.map((field: any, index: number) => (
                                                    <div className="flex items-start mb-4" key={'prboosts-' + index}>
                                                        <FormItem label="Name" className="mr-4 w-80">
                                                            <Input placeholder={"Enter name"} {...register(`productDates.${index}.name`)} />
                                                            {
                                                                errors && errors.productDates && errors.productDates[index] && errors.productDates[index]?.name && errors.productDates[index]?.name?.message ?
                                                                    <p className='text-red-400'>{errors.productDates[index]?.name?.message}</p> : ''
                                                            }
                                                        </FormItem>
                                                        <FormItem label="Date Value">
                                                            <Controller
                                                                control={control}
                                                                name={`productDates.${index}.dateValue`}
                                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                    <DateInput
                                                                        name={name}
                                                                        onChange={(value: string) => onChangeDate(`dateValue`, value, onChange)}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter Date"
                                                                        showTimeSelect={false}
                                                                        dateFormat="MM-dd-yyyy"
                                                                    />
                                                                )}
                                                            />
                                                            {
                                                                errors && errors.productDates && errors.productDates[index] && errors.productDates[index]?.dateValue && errors.productDates[index]?.dateValue?.message &&
                                                                (<p className='text-red-400'>
                                                                    {errors.productDates[index]?.dateValue?.message}
                                                                </p>)
                                                            }
                                                        </FormItem>
                                                        <div className="relative flex-shrink-0 flex mb-1">
                                                            <span onClick={() => productDateRemove(index)} className="ml-2 delete-btn relative pt-7"><ItemTypeDeleteIcon /></span>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ) : (
                                        <div className="flex flex-col justify-center p-6">
                                            <div className="text-5xl text-center text-yellow-500"><i className="las la-box"></i></div>
                                            <div className="text-center"><strong>No Records Yet!</strong></div>
                                            <div className="text-center pointer cursor-pointer text-primary-700 mt-1.5">Please click <a className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm font-medium px-3 py-1 min-w-[84px]  ttnc-ButtonSecondary border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 relative z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0" onClick={addNewProductDate}>+ Add New</a> to create a new date.</div>
                                        </div>
                                    )}

                                </div>


                            </div>
                        </Transition>
                    </div>

                    <div className={"accordion-item-wrapper mb-4"}>
                        <div className={"accordion-title flex items-center justify-between"}>
                            <div className="text-lg font-bold flex gap-2"><MdOutlineMonetizationOn className="text-3xl mt-1" /> <span className="flex flex-col"><p>Creator Earning</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">Collect a fee when a user re-sells an item you originally created. This is deducted from the final sale price and paid monthly to a payout address of your choosing.</small></span></div>
                            <ButtonSecondary
                                className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                                sizeClass={"py-2.5"}
                                type="button"
                                onClick={() => {
                                    setCreatorEarningOpen(!creatorEarningOpen)
                                }}
                            >
                                <span>
                                    <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${creatorEarningOpen ? "rotate-180" : ""}`} aria-hidden="true" />
                                </span>
                            </ButtonSecondary>
                        </div>
                        <Transition
                            show={creatorEarningOpen}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className={"accordion-body"}>

                                {productCreatorFeesFields.length > 0 ? (
                                    <div>
                                        <div className="flex items-center justify-between mb-4 mt-4">
                                            <h4 className="text-lg font-bold"></h4>
                                            <div className="sm:text-sm flex items-center text-blue-800 cursor-pointer mt-4" onClick={addCreatorFee}><AiOutlinePlus className="mr-2" /> Add New</div>
                                        </div>
                                        {
                                            productCreatorFeesFields.map((field: any, index: number) => (
                                                <div className="flex items-start mb-4" key={'prlevel-' + index}>
                                                    <FormItem label="Wallet Address" className="mr-4 w-full">
                                                        <Input placeholder={"Your Payout Wallet Address"} {...register(`creatorFee.${index}.earningWalletAddress`)} />
                                                        {
                                                            errors && errors.creatorFee && errors.creatorFee[index] && errors.creatorFee[index]?.earningWalletAddress && errors.creatorFee[index]?.earningWalletAddress?.message ?
                                                                <p className='text-red-400'>{errors.creatorFee[index]?.earningWalletAddress?.message}</p> : ''
                                                        }
                                                    </FormItem>
                                                    <FormItem label="Earning Percentage" className="mr-4 w-full">
                                                        <Input placeholder={"Primary Sales %"} {...register(`creatorFee.${index}.earningPercentage`)} />
                                                        {
                                                            errors && errors.creatorFee && errors.creatorFee[index] && errors.creatorFee[index]?.earningPercentage && errors.creatorFee[index]?.earningPercentage?.message ?
                                                                <p className='text-red-400'>{errors.creatorFee[index]?.earningPercentage?.message}</p> : ''
                                                        }
                                                    </FormItem>
                                                    <div className="relative flex-shrink-0 flex mb-1">
                                                        <span onClick={() => productCreatorFeesRemove(index)} className="ml-2 delete-btn relative pt-7"><ItemTypeDeleteIcon /></span>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ) : (
                                    <div className="flex flex-col justify-center p-6">
                                        <div className="text-5xl text-center text-yellow-500"><i className="las la-box"></i></div>
                                        <div className="text-center"><strong>No Records Yet!</strong></div>
                                        <div className="text-center pointer cursor-pointer text-primary-700 mt-1.5">Please click <a className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm font-medium px-3 py-1 min-w-[84px]  ttnc-ButtonSecondary border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 relative z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0" onClick={addCreatorFee}>+ Add New</a> to create a new creator earning.</div>
                                    </div>
                                )}

                            </div>
                        </Transition>
                    </div>

                    <div className={"accordion-item-wrapper mb-4"}>
                        <div className={"accordion-title flex items-center justify-between"}>
                            <div className="text-lg font-bold flex gap-2"><AiOutlineGlobal className="text-2xl mt-1" /> <span className="flex flex-col"><p>Search Engine Listing Preview</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">Add title, description to see how this collection might appear on search engines.</small></span></div>
                            <ButtonSecondary
                                className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                                sizeClass={"py-2.5"}
                                type="button"
                                onClick={() => {
                                    let seocollapseopenX = !seocollapseopen;
                                    setSeocollapseopen(seocollapseopenX)
                                }}
                            >
                                <span>
                                    <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${seocollapseopen ? "rotate-180" : ""}`} aria-hidden="true" />
                                </span>
                            </ButtonSecondary>
                        </div>
                        <Transition
                            show={seocollapseopen}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className={"accordion-body"}>
                                <div className={"mt-4 px-4"}>
                                    <FormItem label="Meta Title" isRequired={false} className={'mb-6'}>
                                        <Input placeholder={"Enter Meta title"} {...register('metaTitle')} />
                                        {
                                            errors && errors.metaTitle && errors.metaTitle.message &&
                                            (<p className='text-red-400'>
                                                {errors.metaTitle.message}
                                            </p>)
                                        }
                                    </FormItem>
                                    <FormItem label="Meta Description" isRequired={false} desc={
                                        <div>
                                            Max 320 character limit
                                        </div>
                                    }>
                                        <Textarea rows={6} maxLength={320} className="mt-1.5" placeholder="..." {...register('metaDescription')} />
                                        {
                                            errors && errors.metaDescription && errors.metaDescription.message &&
                                            (<p className='text-red-400'>
                                                {errors.metaDescription.message}
                                            </p>)
                                        }
                                    </FormItem>
                                </div>
                            </div>
                        </Transition>
                    </div>

                    <div className={ `pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ` + (productDetails && (Math.abs(productDetails.isEdition) === 1 || Math.abs(productDetails.quantitySold) > 0 || productDetails.isFreezeMetadata === true) ? `pointer-events-none opacity-50` : ``)}>
                        <ButtonPrimary className="flex-1">Save</ButtonPrimary>
                        <ButtonSecondary type={"button"} className="flex-1" onClick={handleCancel}>Cancel</ButtonSecondary>
                    </div>

                </div>
            </form>

        </div>
    );
};

export default Details;