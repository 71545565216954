import React, { FC, useEffect, useRef, useState } from "react";
import Select from 'react-select'
import { useNavigate, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import DateInput from "shared/Date/DateInput";
import CheckBox from "shared/Checkbox/nativeCheckbox";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import FormItem from "components/FormItem";

import { Coupon_Form } from '../../_common/interfaces/FormProps'
import { CouponFormSchemaValidation } from '../../utils/form-validation'
import { useCouponApi } from '../../_common/hooks/api/CouponApiHook'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import { useProductApi } from '../../_common/hooks/api/productApiHook'
import { utcToLocal, localToUtc } from '../../_common/functions'
import Textarea from "shared/Textarea/Textarea";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";

export interface CouponAddUpdateProps {
  classNames?: string;
  handleCancel: () => void;
}

const DetailsCoupon: FC<CouponAddUpdateProps> = ({ handleCancel, classNames }) => {

    const { id: couponId } = useParams();

    const { showLoader, hideLoader } = useAppLoader();
  const [ customersList, setCustomersList ] = useState([])
  const [ productList, setProductList ] = useState([])
  const [ couponDiscountType, setCouponDiscountType ] = useState<any[]>([])
  const [ couponTypeList, setCouponTypeList ] = useState<any[]>([])
  const [ couponDetails, setCouponDetails ] = useState<any>({})

  const { getCouponTypeList, getCouponListDiscounType, getCouponList, couponAdd, couponEdit } = useCouponApi();
  const { customerList } = useUserApi()
  const { getProducts } = useProductApi()

  const navigate = useNavigate();

  const { register, control, getValues, setValue, watch, setError, resetField, handleSubmit, reset, formState: { errors } } = useForm<Coupon_Form>({
    defaultValues: {
      couponName: '',
      couponCode: '',
      quantity: 1,
      discountType: null,
      discountValue: 0,
      discountPercentage: 0,
      isActive: false,
      startDate: new Date(),
      expiryDate: new Date(),
      isLifetimeOffer: false,
      couponType: null,
      customers: null,
      products: null,
      description: ''
    },
    resolver: yupResolver(CouponFormSchemaValidation)
  });

  const watchPriceType: any = watch("couponType", null)

  useEffect(() => {
    console.log('1. watchPriceType: ', watchPriceType)
    if ( watchPriceType && +watchPriceType['value'] == 1 ) {
        console.log('2. watchPriceType: ', +watchPriceType['value'])
        setValue('products', [])
        setValue('customers', [])
    } else if ( watchPriceType && +watchPriceType['value'] == 2 ) {
      setValue('products', [])
    } else if ( watchPriceType && +watchPriceType['value'] == 3 ) {
      setValue('customers', [])
    }
  }, [watchPriceType])

  useEffect(() => {
    if ( couponId ) {
        getCouponDetails()
    }
    handleCouponTypeList();
    handleCouponDiscountTypeList();
    handleProductList();
    handleCustomerList();
  }, [])

  useEffect(() => {
    if ( couponDetails && Object.keys(couponDetails).length && couponDiscountType.length && couponTypeList.length ) {
        const selectedDiscountType = couponDiscountType.filter((e : any) => +e['value'] == +couponDetails['discountType'])
        const selectedCouponType = couponTypeList.filter((e : any) => +e['value'] == +couponDetails['couponType'])
        const selectedCustomers = couponDetails['couponCustomers'] && couponDetails['couponCustomers'].map((e: any) => ({
            label: `${e['firstName']} ${e['lastName']}(${e['emailID']})`,
            value: e['customerID']
        })) || []
        const selectedProducts = couponDetails['couponProducts'] && couponDetails['couponProducts'].map((e: any) => ({
            label: e['productName'],
            value: e['productID']
        })) || []

        reset({
            couponName: couponDetails['couponName'] || '',
            couponCode: couponDetails['couponCode'] || '',
            quantity: +couponDetails['quantity'] || 1,
            discountType: selectedDiscountType.length && selectedDiscountType[0] || null,
            discountValue: couponDetails['discountValue'] || 0,
            discountPercentage: couponDetails['discountPercentage'] || 0,
            isActive: !!couponDetails['isActive'] || false,
            startDate: couponDetails['startDate'] && utcToLocal(couponDetails['startDate']) || new Date(),
            expiryDate: couponDetails['expiryDate'] && utcToLocal(couponDetails['expiryDate']) || new Date(),
            isLifetimeOffer: !!couponDetails['isLifetimeOffer'] || false,
            couponType: selectedCouponType.length && selectedCouponType[0] || null,
            customers: selectedCustomers || [],
            products: selectedProducts || [],
            description: couponDetails['description'] || ''
        })
    }
  }, [couponDetails, couponDiscountType, couponTypeList])

  const handleCouponTypeList = () => {
    getCouponTypeList({}, (message: string, resp: any) => {
        const data = resp.couponType.map((e: any) => ({
            label: e['typeName'],
            value: +e['typeID']
        }))
        setCouponTypeList(data);
    }, (message: string) => {
        toast.error(message)
    })
  }

  const handleCouponDiscountTypeList = () => {
    getCouponListDiscounType({}, (message: string, resp: any) => {
        const data = resp.couponDiscountType.map((e: any) => ({
            label: e['typeName'],
            value: +e['typeID']
        }))
        setCouponDiscountType(data);
    }, (message: string) => {
        toast.error(message)
    })
  }

  const handleProductList = () => {
    let params: any = {
        usePaging: true,
        pageSize: 10,
        pageNumber: 1
    }
    getProducts(params, (message: string, resp: any) => {
        const data = resp.products.map((e: any) => ({
            label: e['productName'],
            value: +e['productID']
        }))
        setProductList(data);
    }, (message: string) => {
        toast.error(message)
    })
  }

  const handleCustomerList = () => {
    let params: any = {
        usePaging: true,
        pageSize: 10,
        pageNumber: 1
    }
    customerList(params, (message: string, resp: any) => {
        const data = resp.customers.map((e: any) => ({
            label: `${e['firstName']} ${e['lastName']}(${e['emailID']})`,
            value: +e['customerID']
        }))
        setCustomersList(data);
    }, (message: string) => {
        toast.error(message)
    })
  }

  const getCouponDetails = () => {
    const params = {
        couponID: couponId
    }
    showLoader()
    getCouponList(params, (message: string, resp: any) => {
        hideLoader()
        if ( resp['coupons'] && resp['coupons'].length ) {
            setCouponDetails(resp['coupons'][0]);
        } else {
            toast.error('Coupon not found!')
            navigate('/coupons')
        }
    }, (message: string) => {
        hideLoader()
        toast.error(message)
        navigate('/coupons')
    })
  }
  
  
  const couponSubmitted = (data: any) => {
    console.log('data: ', data)
    const params : any = {
        couponName: data['couponName'] || '',
        couponCode: data['couponCode'] || '',
        quantity: data['quantity'] || 1,
        discountType: data['discountType'] && +data['discountType']['value'] || 1,
        discountValue: data['discountValue'] || 0,
        discountPercentage: data['discountPercentage'] || 0,
        isActive: data['isActive'] ? 1 : 0,
        startDate: data['startDate'] && localToUtc(data['startDate']) || null,
        expiryDate: data['expiryDate'] && localToUtc(data['expiryDate']) || null,
        isLifetimeOffer: data['isLifetimeOffer'] ? 1 : 0,
        couponType: data['couponType'] && +data['couponType']['value'] || 1,
        customers: data['customers'] && data['customers'].length && data['customers'].map((e: any) => +e['value']) || [],
        products: data['products'] && data['products'].length && data['products'].map((e: any) => +e['value']) || [],
        description: data['description'] || ''
    }
    showLoader();
    if ( couponDetails && couponDetails['couponID'] ) {
        params['couponID'] = couponDetails['couponID'];
        couponEdit(params, (message: string, resp: any) => {
            hideLoader()
            if ( resp.successful ) {
                toast.success(message)
                navigate('/coupons')
            } else {
                toast.error(message)
            }
        }, (message: string) => {
            hideLoader()
            toast.error(message)
        })
    } else {
        couponAdd(params, (message: string, resp: any) => {
            hideLoader()
            if ( resp.successful ) {
                toast.success(message)
                navigate('/coupons')
            } else {
                toast.error(message)
            }
        }, (message: string) => {
            hideLoader()
            toast.error(message)
        })
    }
  }

  return (
    <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              { couponDetails && couponDetails['couponID'] ? 'Update Coupon' : 'Create Coupon' }
            </h2>
            {/* <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              You can set preferred display name, create your profile URL and
              manage other personal settings.
            </span> */}
          </div>
          
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          <form className="" onSubmit={handleSubmit(couponSubmitted)}>
              <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
                {/* ---- */}
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 sm:gap-5">
                      <FormItem label="Name" isRequired={true}>
                          <Input { ...register('couponName') } autoComplete={'off'} placeholder={'Name'} tabIndex={1} />
                          { 
                              errors && errors.couponName && errors.couponName.message &&  
                              (<p className='text-red-400'>
                                  {errors.couponName.message}
                              </p>)
                          }
                      </FormItem>
                      <FormItem label="Code" isRequired={true}>
                          <Input { ...register('couponCode') } autoComplete={'off'} placeholder={'Code'} tabIndex={2} />
                          { 
                              errors && errors.couponCode && errors.couponCode.message &&  
                              (<p className='text-red-400'>
                                  {errors.couponCode.message}
                              </p>)
                          }
                      </FormItem>
                      <FormItem label="Quantity" isRequired={true}>
                          <Input { ...register('quantity') } placeholder={'Quantity'} autoComplete={'off'} tabIndex={3} />
                          { 
                              errors && errors.quantity && errors.quantity.message &&  
                              (<p className='text-red-400'>
                                  {errors.quantity.message}
                              </p>)
                          }
                      </FormItem>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-4 gap-5 sm:gap-5">
                      <FormItem label="Discount Type" isRequired={true}>
                          <Controller
                              name={"discountType"}
                              control={control}
                              render={({ field: { value, onChange, onBlur } }) => (
                                  <Select
                                      className={`mt-1`}
                                      classNamePrefix="select"
                                      options={couponDiscountType}
                                      placeholder={"Select .."}
                                      onChange={onChange}
                                      value={value}
                                      defaultValue={value}
                                      tabIndex={4}
                                  />
                              )}
                          />
                          { 
                              errors && errors.discountType && errors.discountType.message &&  
                              (<p className='text-red-400'>
                                  {errors.discountType.message}
                              </p>) || ''
                          }
                      </FormItem>
                      {
                        watch('discountType') && watch('discountType')?.value === 1 ? (
                            <FormItem label="Discount Value" isRequired={true}>
                                <Input { ...register('discountValue') } autoComplete={'off'} placeholder={'Discount value'} tabIndex={5} />
                                { 
                                    errors && errors.discountValue && errors.discountValue.message &&  
                                    (<p className='text-red-400'>
                                        {errors.discountValue.message}
                                    </p>)
                                }
                            </FormItem>
                        ) : watch('discountType') && watch('discountType')?.value === 2 ? (
                            <FormItem label="Discount Percentage(%)" isRequired={true}>
                                <Input { ...register('discountPercentage') } autoComplete={'off'} placeholder={'Discount percentage'} tabIndex={5} />
                                { 
                                    errors && errors.discountPercentage && errors.discountPercentage.message &&  
                                    (<p className='text-red-400'>
                                        {errors.discountPercentage.message}
                                    </p>)
                                }
                            </FormItem>
                        ) : ''
                      }
                      <div className="grid grid-cols-1 sm:grid-cols-1 gap-5 sm:gap-5 items-center mt-6">
                        <CheckBox label="Active" {...register('isActive')} tabIndex={6} />
                        
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-5 sm:gap-5 items-center mt-6">
                        <CheckBox label="Unlimited times" {...register('isLifetimeOffer')} tabIndex={7} />
                    </div>
                  </div>
                  
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5">
                      <FormItem label="Start date">
                          <Controller
                              control={control}
                              name={`startDate`}
                              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                  <DateInput
                                      name={name}
                                      onChange={onChange}
                                      value={value}
                                      onBlur={onBlur}
                                      placeholder="Enter Date"
                                      showTimeSelect={false}
                                      dateFormat="MM-dd-yyyy"
                                      tabIndex={8}
                                  />
                              )}
                          />
                          {
                              errors && errors.startDate && errors.startDate.message &&
                              (<p className='text-red-400'>
                                  {errors.startDate.message}
                              </p>)
                          }
                      </FormItem>

                      <FormItem label="End date">
                          <Controller
                              control={control}
                              name={`expiryDate`}
                              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                  <DateInput
                                      name={name}
                                      onChange={onChange}
                                      value={value}
                                      onBlur={onBlur}
                                      placeholder="Enter Date"
                                      showTimeSelect={false}
                                      dateFormat="MM-dd-yyyy"
                                      tabIndex={9}
                                  />
                              )}
                          />
                          {
                              errors && errors.expiryDate && errors.expiryDate.message &&
                              (<p className='text-red-400'>
                                  {errors.expiryDate.message}
                              </p>)
                          }
                      </FormItem>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5">
                      <FormItem label="Type" isRequired={true}>
                          <Controller
                              name={"couponType"}
                              control={control}
                              render={({ field: { value, onChange, onBlur } }) => (
                                  <Select
                                      className={`mt-1`}
                                      classNamePrefix="select"
                                      options={couponTypeList}
                                      placeholder={"Select .."}
                                      onChange={onChange}
                                      value={value}
                                      defaultValue={value}
                                      tabIndex={10}
                                  />
                              )}
                          />
                          { 
                              errors && errors.couponType && errors.couponType.message &&  
                              (<p className='text-red-400'>
                                  {errors.couponType.message}
                              </p>)
                          }
                      </FormItem>

                      {
                        watch('couponType') && watch('couponType')?.value == 2 ? (
                          <FormItem label="Customer(s)" isRequired={true}>
                              <Controller
                                  name={"customers"}
                                  control={control}
                                  render={({ field: { value, onChange, onBlur } }) => (
                                      <Select
                                          className={`mt-1`}
                                          classNamePrefix="select"
                                          options={customersList}
                                          placeholder={"Select .."}
                                          onChange={onChange}
                                          value={value}
                                          isMulti
                                          defaultValue={value}
                                          isClearable={true}
                                          tabIndex={11}
                                      />
                                  )}
                              />
                              { 
                                  errors && errors.customers && errors.customers.message &&  
                                  (<p className='text-red-400'>
                                      {errors.customers.message}
                                  </p>)
                              }
                          </FormItem>
                        ) : 
                        watch('couponType') && watch('couponType')?.value === 3 ? (
                          <FormItem label="Product(s)" isRequired={true}>
                              <Controller
                                  name={"products"}
                                  control={control}
                                  render={({ field: { value, onChange, onBlur } }) => (
                                      <Select
                                          className={`mt-1`}
                                          classNamePrefix="select"
                                          options={productList}
                                          placeholder={"Select .."}
                                          onChange={onChange}
                                          value={value}
                                          defaultValue={value}
                                          isMulti
                                          isClearable={true}
                                          tabIndex={12}
                                      />
                                  )}
                              />
                              { 
                                  errors && errors.products && errors.products.message &&  
                                  (<p className='text-red-400'>
                                      {errors.products.message}
                                  </p>)
                              }
                          </FormItem>
                        ) : ''
                      }
                      
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-1 gap-5 sm:gap-5">
                      <FormItem label="Description" isRequired={true}>
                          <Textarea rows={6} className="mt-1.5" placeholder="Description here .." {...register('description')} tabIndex={13} />
                          {
                              errors && errors.description && errors.description.message &&
                              (<p className='text-red-400'>
                                  {errors.description.message}
                              </p>)
                          }
                      </FormItem>
                  </div>

                      
                  <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
                    <ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
                    <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Cancel</ButtonSecondary>
                  </div>
              </div>
          </form>
    </div>
  );
};

export default DetailsCoupon;
