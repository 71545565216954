import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi } from './api/callApi';
const API_BASE_URL = process.env.REACT_APP_API_URL;


export function* getEmailTemplaits(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EMAIL_TEMPLATES.GET, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* updateEmailTemplate(action: any): any {
    try {
        const data = action.payload;
        console.log(data)
        const resp = yield call(CallApi.PUT, API_URL.EMAIL_TEMPLATES.EDIT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* changeStatus(action: any): any {
    try {
        const data = action.payload;
        console.log(data)
        const resp = yield call(CallApi.PUT, API_URL.EMAIL_TEMPLATES.STATUS_CHANGE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}