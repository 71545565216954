import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import FormItem from "components/FormItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import InputFile from 'shared/InputFile/InputFile';
import Textarea from "shared/Textarea/Textarea";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useEmployeesApi } from "_common/hooks/api/employeesApi";
import Select from "react-select";


const AddToEmployees = () => {

    const employeeApi = useEmployeesApi()
    const commonApi = useCommonApi()
    const navigate = useNavigate()
    const { type } = useParams()
    const { showLoader, hideLoader } = useAppLoader()
    const [searchParams] = useSearchParams()
    const id: any = searchParams.get("id")

    const [employeeDetails, setEmployeeDetails] = useState<any>(null)
    const [countryList, setCountryList] = useState<any>(null)

    const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm();

    const getEmployeeDetails = () => {
        showLoader()
        employeeApi.getEmployees({ employeeID: id },
            (message: string, resp: any) => {
                console.log({ message, resp })
                setEmployeeDetails(resp?.employees)
                if (resp.employees && resp.employees.length > 0) {
                    reset({
                        firstName: resp.employees[0].firstName,
                        lastName: resp.employees[0].lastName,
                        emailID: resp.employees[0].emailID,
                        phoneNo: resp.employees[0].phoneNo,
                        zipCode: resp.employees[0].zipCode,
                        countryID: getCountryById(resp.employees[0].countryID),
                        state: resp.employees[0].state,
                        city: resp.employees[0].city,
                        address: resp.employees[0].address
                    })
                }
                hideLoader()
            },
            (message: string) => {
                console.log(message)
                hideLoader()
            })
    }

    const addEditEmployee = (params: any, file: any) => {
        console.log({ params })

        if (type == 'add') {
            showLoader()
            employeeApi.addEmployees(params,
                (message: string, resp: any) => {
                    console.log({ message, resp })
                    if (file[0] != undefined) {
                        mapCategoryImage(resp.returnID, file[0], "profile").then((msg: any) => {
                            toast.success(message);
                            handleCancel()
                            hideLoader();
                        }).catch((msg: any) => {
                            toast.success(message);
                            handleCancel()
                            hideLoader();
                        })
                    } else {
                        toast.success(message);
                        handleCancel()
                        hideLoader();
                    }
                },
                (message: string) => {
                    toast.error(message)
                    hideLoader()
                })
        }
        else if (type == 'edit') {
            showLoader()
            employeeApi.updateEmployees(params,
                (message: string, resp: any) => {
                    console.log({ message, resp })
                    if (file[0] != undefined) {
                        mapCategoryImage(resp.returnID, file[0], "profile").then((msg: any) => {
                            toast.success(message);
                            handleCancel()
                            hideLoader();
                        }).catch((msg: any) => {
                            toast.error(message);
                            handleCancel()
                            hideLoader();
                        })
                    } else {
                        toast.success(message);
                        handleCancel()
                        hideLoader();
                    }
                },
                (message: string) => {
                    toast.error(message)
                    hideLoader()
                })
        }
    }

    const mapCategoryImage = (id: any, file: any, type: string) => {
        console.log({ id, file, type })
        return new Promise((resolve: any, reject: any) => {
            let data: any = new FormData();
            data.append("", file);
            data.append("ItemId", id);
            data.append("ItemType", 7);
            data.append("FileType", type);

            commonApi.fileUpload(data,
                (message: string, resp: any) => {
                    console.log('f complete')
                    resolve(message)
                }, (message: string) => {
                    console.log('f failed')
                    reject(message)
                })
        })
    }

    const onSubmit = (data: any) => {
        // console.log({ data })
        const file = data["file"]
        delete data["file"]
        data["employeeID"] = id
        data.countryID = data.countryID.value
        // console.log({ edited: data }); return

        addEditEmployee(data, file)
    }

    const getCountries = () => {
        commonApi.getCountries({},
            (message: string, resp: any) => {
                // console.log({ message, resp })
                if (resp.countries.length > 0) {
                    setCountryList(resp.countries.map((data: any) => {
                        data.label = data.countryName;
                        data.value = data.countryID;
                        return data;
                    }))
                }
            }, (message: string) => {
                console.log({ message })
            })
    }

    const countryOptionUI = (props: any) => {
        const { innerProps, innerRef, data } = props;
        return (
            <div ref={innerRef} {...innerProps} className="m-2 cursor-pointer" >
                {data?.countryName}{" "}
                <span className="ml-2 text-gray-500">{data?.threeLetterIsoCode ? data?.threeLetterIsoCode : null}</span>
            </div>
        );
    }

    const getCountryById = (id: number) => {
        let option = null
        if(countryList){
            countryList.forEach((item: any) => {
                if(item.countryID == id) {
                    option =  item
                }
            })
        }
        return option
    }

    const handleKeyPress = (event: any) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    const handleCancel = () => {
        navigate(`/employees`)
    }

    useEffect(() => {
        if (id && countryList) {
            getEmployeeDetails()
        }
    }, [id,countryList])

    useEffect(() => {
        getCountries()
    }, [])


    return (
        <React.Fragment>
            <div className="container">
                <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
                    {/* HEADING */}
                    <div className="max-w-2xl">
                        <h2 className="text-3xl sm:text-4xl font-semibold">
                            Add / Update Employee
                        </h2>
                    </div>
                    <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                            <FormItem label="Profile Picture" isRequired>
                                <InputFile
                                    uploadedUrl={employeeDetails ? employeeDetails[0]?.profilePicturePath : ''}
                                    {...register(`file`, { required: false })}
                                    onCanPlayCapture={(e: any) => {
                                        if (e == null) {
                                            setValue(`file`, '');
                                        }
                                    }}
                                />
                            </FormItem>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5 mt-6">
                            <FormItem label="First Name" isRequired className='m-2' >
                                <Controller
                                    name={`firstName`}
                                    control={control}
                                    render={({ field }) => <Input
                                        {...field}
                                        placeholder={''}
                                        autoComplete={'off'}
                                        required={true}
                                    />}
                                />
                                {
                                    errors && errors.firstName && errors.firstName?.type == "required" ?
                                        <p className='text-red-400'>Required</p> : ''
                                }
                            </FormItem>

                            <FormItem label="Last Name" isRequired className='m-2' >
                                <Controller
                                    name={`lastName`}
                                    control={control}
                                    render={({ field }) => <Input
                                        {...field}
                                        placeholder={''}
                                        autoComplete={'off'}
                                        required={true}
                                    />}
                                />
                                {
                                    errors && errors.lastName && errors.lastName?.type == "required" ?
                                        <p className='text-red-400'>Required</p> : ''
                                }
                            </FormItem>

                            <FormItem label="Email" isRequired className='m-2' >
                                <Controller
                                    name={`emailID`}
                                    control={control}
                                    render={({ field }) => <Input
                                        {...field}
                                        placeholder={''}
                                        readOnly={id?true:false}
                                        autoComplete={'off'}
                                        required={true}
                                        pattern="([a-zA-Z})"
                                        type={"email"}
                                    />}
                                />
                                {errors?.emailID ? <p className='text-red-400'>
                                    {errors.emailID.type === "required" ? 'Required' : null}
                                    {errors.emailID.type === "pattern" ? 'Invalid emailID address' : null}
                                </p> : null}
                            </FormItem>

                            <FormItem label="Phone no" isRequired className='m-2' >
                                <Controller
                                    name={`phoneNo`}
                                    control={control}
                                    render={({ field }) => <Input
                                        {...field}
                                        placeholder={''}
                                        autoComplete={'off'}
                                        required={true}
                                        onKeyPress={(event: any) => handleKeyPress(event)}
                                    />}
                                />
                                {
                                    errors && errors.phoneNo && errors.phoneNo?.type == "required" ?
                                        <p className='text-red-400'>Required</p> : ''
                                }
                            </FormItem>

                            <FormItem label="Zip code" isRequired className='m-2' >
                                <Controller
                                    name={`zipCode`}
                                    control={control}
                                    render={({ field }) => <Input
                                        {...field}
                                        placeholder={''}
                                        autoComplete={'off'}
                                        required={true}
                                        onKeyPress={(event: any) => handleKeyPress(event)}
                                    />}
                                />
                                {
                                    errors && errors.zipCode && errors.zipCode?.type == "required" ?
                                        <p className='text-red-400'>Required</p> : ''
                                }
                            </FormItem>

                            <FormItem label="Country" isRequired className='m-2' >
                                <Controller
                                    name={`countryID`}
                                    control={control}
                                    render={({ field: { value, onChange, onBlur } }) => (
                                        <Select
                                            // className={`mt-1`}
                                            components={{ Option: countryOptionUI }}
                                            options={countryList ? countryList : []}
                                            placeholder={""}
                                            onChange={onChange}
                                            value={value}
                                            defaultValue={value}
                                        />
                                    )}
                                />
                                {
                                    errors && errors.countryID && errors.countryID?.type == "required" ?
                                        <p className='text-red-400'>Required</p> : ''
                                }
                            </FormItem>

                            <FormItem label="State" isRequired className='m-2' >
                                <Controller
                                    name={`state`}
                                    control={control}
                                    render={({ field }) => <Input
                                        {...field}
                                        placeholder={''}
                                        autoComplete={'off'}
                                        required={true}
                                    />}
                                />
                                {
                                    errors && errors.state && errors.state?.type == "required" ?
                                        <p className='text-red-400'>Required</p> : ''
                                }
                            </FormItem>

                            <FormItem label="City" isRequired className='m-2' >
                                <Controller
                                    name={`city`}
                                    control={control}
                                    render={({ field }) => <Input
                                        {...field}
                                        placeholder={''}
                                        autoComplete={'off'}
                                        required={true}
                                    />}
                                />
                                {
                                    errors && errors.city && errors.city?.type == "required" ?
                                        <p className='text-red-400'>Required</p> : ''
                                }
                            </FormItem>
                        </div>

                        <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
                            <FormItem label="Address" isRequired className='m-2' >
                                <Controller
                                    name={`address`}
                                    control={control}
                                    render={({ field }) => <Textarea
                                        {...field}
                                        rows={5}
                                        {...register(`address`, { required: true })}
                                    />}
                                />
                                {
                                    errors && errors.address && errors.address?.type == "required" ?
                                        <p className='text-red-400'>Required</p> : ''
                                }
                            </FormItem>
                        </div>

                        <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 m-6">
                            <ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
                            <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Cancel</ButtonSecondary>
                        </div>

                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddToEmployees