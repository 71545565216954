import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function useEmployeesApi() {

  const callApi = useApiCall()

  const getEmployees = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EMPLOYEE.GET, data, onSuccess, onError);
  }

  const deleteEmployee = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EMPLOYEE.DELETE, data, onSuccess, onError);
  }

  const addEmployee = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EMPLOYEE.ADD, data, onSuccess, onError);
  }

  const updateEmployee = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EMPLOYEE.UPDATE, data, onSuccess, onError);
  }

  return {
    getEmployees: getEmployees,
    deleteEmployees: deleteEmployee,
    addEmployees: addEmployee,
    updateEmployees: updateEmployee,
  }
}