import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import * as rdd from 'react-device-detect';
import { toast } from "react-hot-toast";

import { ACTIONS } from '../../_config';
import NcImage from "shared/NcImage/NcImage";
import QrCodeImg from "images/qr-code.png";
import CryptoWallet from "images/bitcoin.png"
import metamaskImg from "images/metamask.webp";
import walletconnectImg from "images/walletconnect.webp";
import walletlinkImg from "images/walletlink.webp";
import fortmaticImg from "images/fortmatic.webp";

import useMetaMask from "../../_common/provider/metamask-provider";
import { METAMASK_KEYS } from '../../_common/_constant'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import { useUserSelector, useUserCryptoDetails, useUserAuthSelector } from '../../_common/hooks/selectors/userSelector'
import { useAppLoader } from "_common/hooks/common/appLoaderHook";

import NetworkBalance from './NetworkBalance'

export interface AddFundProps {
  className?: string;
  cancel?: Function;
  currency: string;
  address: string;
}

const AddFund: FC<AddFundProps> = ({ className = "", cancel, currency, address }) => {
  const { connect, disconnect, isLoading, isActive, chainId, account, addNetwork, switchNetwork, isConnected, authenticateWallet, getBalance } = useMetaMask()
  const { connectWallet, login } = useUserApi()
  const navigate = useNavigate();
  const userDetails: any = useUserSelector()
  const dispatch = useDispatch()
  const isAuthenticated = useUserAuthSelector();
  const { showLoader, hideLoader } = useAppLoader();

  const copyText = (data: string) => {
		navigator.clipboard.writeText(data)
		toast.success('Copied')
	}

  return (
    <div className={`nc-AddFund ${className}`} data-nc-id="AddFund">
      <div className="container">
      <div className="my-4 sm:lg:my-4 lg:my-4 max-w-3xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
            <div className="max-w-2xl">
              <h2 className="text-3xl sm:text-4xl font-semibold">
                Deposit Crypto
              </h2>
              <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                Only send {currency} to this address.
              </span>
            </div>
            <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
            <div className="space-y-5 sm:space-y-6 md:sm:space-y-8">
                <NcImage
                    containerClassName="flex p-4 mr-2 rounded-3xl overflow-hidden z-0 justify-center" src={CryptoWallet} className="object-contain w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform w-1/4"
                />
            </div>
            <div className="space-y-5 sm:space-y-6 md:sm:space-y-8 flex justify-between">
              <div>
                  <span>Transfer funds from an <span className="text-amber-600" title="An exchange allows individuals to trade cryptocurrencies. Compatible crypto exchanges include Coinbase, Gemini, Kraken, eToro, and many other exchanges.">exchange info</span> or another wallet to your wallet address below:</span>
                  <br />
                  <div className="border-2 flex justify-between mt-2 p-3 rounded-3xl">
+                      <strong className="word-break">{address} </strong>

                      <div className="cursor-pointer" onClick={() => copyText(address)}>
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
                          <path
                            d="M18.05 9.19992L17.2333 12.6833C16.5333 15.6916 15.15 16.9083 12.55 16.6583C12.1333 16.6249 11.6833 16.5499 11.2 16.4333L9.79999 16.0999C6.32499 15.2749 5.24999 13.5583 6.06665 10.0749L6.88332 6.58326C7.04999 5.87492 7.24999 5.25826 7.49999 4.74992C8.47499 2.73326 10.1333 2.19159 12.9167 2.84993L14.3083 3.17493C17.8 3.99159 18.8667 5.71659 18.05 9.19992Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.5498 16.6583C12.0331 17.0083 11.3831 17.3 10.5915 17.5583L9.2748 17.9917C5.96646 19.0583 4.2248 18.1667 3.1498 14.8583L2.08313 11.5667C1.01646 8.25833 1.8998 6.50833 5.20813 5.44167L6.5248 5.00833C6.86646 4.9 7.19146 4.80833 7.4998 4.75C7.2498 5.25833 7.0498 5.875 6.88313 6.58333L6.06646 10.075C5.2498 13.5583 6.3248 15.275 9.7998 16.1L11.1998 16.4333C11.6831 16.55 12.1331 16.625 12.5498 16.6583Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                  </div>
                  
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFund;
