import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
  BlankReq,
  LoginReq,
} from '../../interfaces/ApiReqRes'


export function useThemeApi() {

  const callApi = useApiCall();

  const getThemes = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.THEMES.GET, data, onSuccess, onError);
  }

  const saveTheme = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.THEMES.POST, data, onSuccess, onError);
  }

  return {
    getThemes: getThemes,
    saveTheme: saveTheme
  }
}