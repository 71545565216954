import React from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO_2 } from "data/navigation";
import { useSelector } from "react-redux";
import { StateExtended } from "_common/interfaces/StateExtended";

function Navigation() {  
  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {NAVIGATION_DEMO_2.map((item) => {          
        return (<NavigationItem key={item.id} menuItem={item} />)              
      })}
    </ul>
  );
}

export default Navigation;
