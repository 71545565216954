import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import catImg from 'images/nfts/collection.png'
import { nftsImgs } from "contains/fakeData";
import ItemTypeImageIcon from "../ItemTypeImageIcon";
import ItemTypeEditIcon from "../ItemTypeEditIcon";
import ItemTypeDeleteIcon from "../ItemTypeDeleteIcon";
import LikeButton from "../LikeButton";
import Prices from "../Prices";
import ItemCustomFieldImageIcon from 'components/Customfield/ItemCustomFieldImageIcon'
import Checkbox from "shared/Checkbox/nativeCheckbox"
import { TfiWallet } from "react-icons/tfi";

export interface CardPreSaleProps {
	className?: string;
	item: any;
	selectedPreSales?: number[];
	handleSelectPreSales?: Function;
}

const PreSaleCard: FC<CardPreSaleProps> = ({ 
	className = "", 
	handleSelectPreSales = () => {}, 
	selectedPreSales = [],
	item 
}) => {

	const [isSelected, setIsSelected] = useState(false)

	useEffect(() => {
		if ( selectedPreSales.length ) {
			const index = selectedPreSales.findIndex((e: number) => e === +item.presaleRegistrationID)
			if ( index != -1 ) {
				setIsSelected(true)
			} else {
				setIsSelected(false)
			}
		} else {
			setIsSelected(false)
		}
	}, [selectedPreSales])
	
	return (
		<div className={`relative rounded-xl hover:shadow-lg hover:bg-neutral-50 border border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800 dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200`}>
			<div className="absolute top-3 left-0 w-full justify-between !h-9 flex ">
				<span className="ml-3">
					<Checkbox checked={isSelected} onChange={(e: any) => {
						if (e.target.checked) {
							handleSelectPreSales(true, +item.presaleRegistrationID)
						} else {
							handleSelectPreSales(false, +item.presaleRegistrationID)
						}
					}} />
				</span>
			</div>
			<div className="flex items-center w-full">
			<div className="w-20 custom-fld-icon mr-4">
				<ItemCustomFieldImageIcon />
			</div>
			<div className="w-full">
				<div className="flex justify-between">
					<h3 className={`text-sm font-medium category-name`}></h3>
					<h3 className={`text-sm font-medium`}>{''}</h3>
				</div>
				{
					item && item.cryptoAddress && (
						<h2 className={`font-semibold mb-1 flex items-center`}>
							<span className="font-normal ml-2 flex gap-2 word-break text-sm">
								<TfiWallet />{item?.cryptoAddress}
							</span>
						</h2>
					) || ''
				}
				<h2 className={`font-semibold mb-1 flex items-center ml-2`}>
					Approved Quantity <span className="font-normal">&nbsp;{item?.approvedQTY}</span>
				</h2>
				<h2 className={`font-semibold mb-1 flex items-center`}>
					<span className="font-normal ml-2">{item?.collectionName}</span>
				</h2>
                <h2 className={`font-semibold mb-1 flex items-center`}>
					<span className="font-normal ml-2">{item?.collectionType}</span>
				</h2>
			</div>
			<div className="flex justify-between pt-1.5">
				<div className="option">
				<h4 className={`text-sm font-medium item-status ` + (item.isActive === 1 ? 'bg-lime-500 text-white' : 'bg-red-300 text-white')}>{item.isActive === 1 ? "Approved" : "Disapproved"}</h4>
				</div>
			</div>
			</div>
		</div>
	);
};

export default PreSaleCard;
