import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import NftMoreDropdown from "components/NftMoreDropdown";

export interface CardNFTProps {
    className?: string;
    isLiked?: boolean;
    data: any;
    onDelete?: any
}

const CategoryNFT: FC<CardNFTProps> = ({ className = "", isLiked, data, onDelete }) => {

    const navigate = useNavigate()

    return (
        <div className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md`}>
            <div className="relative flex-shrink-0 ">
                <div>
                    <NcImage
                        containerClassName="flex aspect-w-11 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0 bg-gray-500 rounded-b-none"
                        src={data.categoryIconUrl}
                        className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
                    />
                </div>
                <div className="absolute flex flex-row-reverse gap-2 justify-end right-2 sm:left-auto top-2.5">
                    <NftMoreDropdown
                        actions={[
                            {
                                id: "customer_fees_update",
                                name: "Edit",
                                icon: "las la-pen",
                                callBack: () => { navigate("/category/" + data.categoryID) }
                            },
                            {
                                id: "customer_delete",
                                name: "Delete",
                                icon: "las la-trash",
                                callBack: () => onDelete(data)
                            },
                        ]}
                        containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
                    />
                </div>
            </div>

            <div className="p-4 py-5 space-y-3">
                <div className="flex justify-between">
                    <h3 className={`text-sm font-medium category-name`}>{data.parentCategoryName ? data.parentCategoryName : ''}</h3>
                </div>

                <h2 className={`text-lg font-medium mb-3`}>{data.categoryName}</h2>

                <div className="flex flex-wrap gap-1">
                    {
                        data.isDeleted ? <span className="bg-red-500 font-medium inline-flex nc-Badge px-2.5 py-1 relative rounded-full text-white text-xs mb-1">Deleted</span> : ''
                    }
                    {
                        !data.isActive ? <span className="bg-gray-500 font-medium inline-flex mb-1 nc-Badge px-2.5 py-1 relative rounded-full text-white text-xs">In-Active</span> : ''
                    }
                </div>
            </div>
        </div>

    );
};

export default CategoryNFT;
