import React, { FC, useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import Checkbox from "shared/Checkbox/nativeCheckbox";
import { useUserSelector } from '../../_common/hooks/selectors/userSelector'
import NftMoreDropdown from "components/NftMoreDropdown";
import { CONTRACT_DEPLOYED_STATUS } from "_common/_constant";

export interface CardNFTProps {
	className?: string;
	data: any;
	onDelete?: any;
	selectedCollections?: number[];
	handleSelectCollections?: Function;
	deployContract?: Function;
	editFees?: Function;
	hideContents?: any;
	navigateDetails?: Function;
	viewBalance?: Function;
	depositBalance?: Function;
	withdrawBalance?: Function;
	sendLink?: Function;
	viewLinkBalance?: Function;
}

const CollectionCard: FC<CardNFTProps> = ({
	className = "",
	editFees,
	data,
	onDelete,
	handleSelectCollections = () => { },
	selectedCollections = [],
	deployContract = () => { },
	hideContents = {},
	navigateDetails = () => { },
	viewBalance = () => { },
	depositBalance = () => { },
	withdrawBalance = () => { },
	sendLink = () => { },
	viewLinkBalance = () => { },
}) => {
	const [isSelected, setIsSelected] = useState(false)
	const user: any = useUserSelector();
	const navigate = useNavigate();
	const [hideCollectionContent, setHideCollectionContent] = useState<any>({})
	const [actions, setActions] = useState([])
	const [custodialWalletSettings, setCustodialWalletSettings] = useState<any>({
		isEnabled: false,
		custodialWalletAddress: ''
	})

	useEffect(() => {
		if ( hideContents && Object.keys(hideContents).length ) {
			const params = {
				...hideContents,
				sendLink: typeof hideContents['sendLink'] === 'function' ? hideContents['sendLink'](data) : hideContents['sendLink'],
				viewLinkBalance: typeof hideContents['viewLinkBalance'] === 'function' ? hideContents['viewLinkBalance'](data) : hideContents['viewLinkBalance'],
				deployContract: typeof hideContents['deployContract'] === 'function' ? hideContents['deployContract'](data) : hideContents['deployContract'],
				contractBalance: typeof hideContents['contractBalance'] === 'function' ? hideContents['contractBalance'](data, custodialWalletSettings) : hideContents['contractBalance'],
				withdrawContractBalance: typeof hideContents['withdrawContractBalance'] === 'function' ? hideContents['withdrawContractBalance'](data, custodialWalletSettings) : hideContents['withdrawContractBalance'],
				transferContractBalance: typeof hideContents['transferContractBalance'] === 'function' ? hideContents['transferContractBalance'](data, custodialWalletSettings) : hideContents['transferContractBalance'],
			}
			setHideCollectionContent(params)
		}
	}, [hideContents, custodialWalletSettings])
	useEffect(() => {
		if (selectedCollections.length) {
			const index = selectedCollections.findIndex((e: number) => e === +data.collectionID)
			if (index != -1) {
				setIsSelected(true)
			} else {
				setIsSelected(false)
			}
		} else {
			setIsSelected(false)
		}
	}, [selectedCollections])

	useEffect(() => {
		if (user && Object.keys(user).length) {
			setCustodialWalletSettings({
				isEnabled: user['isCustodialWalletEnabled'] && (user['isCustodialWalletEnabled'] === 'true' || user['isCustodialWalletEnabled'] === true) || false,
				custodialWalletAddress: user['custodialWalletAddress'] || ''
			})
		}
	}, [user])

	const handleEditFees = (id: number) => {
		if (editFees) {
			editFees(id)
		}
	}

	return (
		<React.Fragment>
			<div className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md`}>
				<div className="relative flex-shrink-0 ">
					<div>
						<NcImage
							containerClassName="flex aspect-w-11 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0 bg-gray-500 rounded-b-none"
							src={data.logoUrl}
							className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
						/>
					</div>
					<div className="absolute top-3 left-0 w-full justify-between !h-9 flex ">
						{
							hideCollectionContent['toSelectCheckbox'] ? '' : (
								<span className="ml-3">
									<Checkbox checked={isSelected} onChange={(e: any) => {
										if (e.target.checked) {
											handleSelectCollections(true, +data.collectionID)
										} else {
											handleSelectCollections(false, +data.collectionID)
										}
									}} />
								</span>
							)
						}
						<div className="absolute flex flex-row-reverse gap-2 justify-end right-2 sm:left-auto top-0">
							<NftMoreDropdown
								actions={[
									!hideCollectionContent['editIcon'] ? {
										id: "update",
										name: "Edit",
										icon: "las la-pen",
										callBack: () => navigate("/collection/" + data.collectionID)
									} : {
										id: "",
										name: "",
										icon: "",
										callBack: () => {}
									},
									!hideCollectionContent['deleteIcon'] ? {
										id: "customer_delete",
										name: "Delete",
										icon: "las la-trash",
										callBack: () => onDelete(data)
									} : {
										id: "",
										name: "",
										icon: "",
										callBack: () => { }
									},
									!hideCollectionContent['viewCollection'] ? {
										id: "customer_view",
										name: "View",
										icon: "las la-eye",
										callBack: () => { navigateDetails(data) }
									} : {
										id: "",
										name: "",
										icon: "",
										callBack: () => { }
									},
									!hideCollectionContent['sendLink'] ? {
										id: "send_contract_link",
										name: "Send Link",
										icon: "las la-wallet",
										callBack: () => sendLink()
									} : {
										id: "",
										name: "",
										icon: "",
										callBack: () => { }
									},
									!hideCollectionContent['viewLinkBalance'] ? {
										id: "view_link_balance",
										name: "View Link Balance",
										icon: "las la-wallet",
										callBack: () => viewLinkBalance()
									} : {
										id: "",
										name: "",
										icon: "",
										callBack: () => { }
									},
									!hideCollectionContent['deployContract'] ? {
										id: "deploy_contract",
										name: "Deploy Contract",
										icon: "las la-wallet",
										callBack: () => deployContract(data)
									} : {
										id: "",
										name: "",
										icon: "",
										callBack: () => { }
									},
									!hideCollectionContent['contractBalance'] ? {
										id: "contract_balance",
										name: "View Contract Balance",
										icon: "las la-eye",
										callBack: () => viewBalance(data)
									} : {
										id: "",
										name: "",
										icon: "",
										callBack: () => { }
									},
									!hideCollectionContent['withdrawContractBalance'] ? {
										id: "withdraw_contract_balance",
										name: "Withdraw Contract Balance",
										icon: "las la-wallet",
										callBack: () => withdrawBalance(data)
									} : {
										id: "",
										name: "",
										icon: "",
										callBack: () => { }
									},
									!hideCollectionContent['transferContractBalance'] ? {
										id: "transfer_contract_balance",
										name: "Deposit Contract Balance",
										icon: "las la-wallet",
										callBack: () => depositBalance(data)
									} : {
										id: "",
										name: "",
										icon: "",
										callBack: () => { }
									},
									!hideCollectionContent['editFees'] && user && user['store'] && user['store'].length && user['store'][0]['type'] === "Marketplace" ? {
										id: "edit_fees",
										name: "Edit Fees",
										icon: "las la-coins",
										callBack: () => handleEditFees(+data?.collectionID)
									} : {
										id: "",
										name: "",
										icon: "",
										callBack: () => { }
									},
								]}
								containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
							/>
						</div>
					</div>
					{/* {
						hideCollectionContent['deleteIcon'] ? '' : (
							<span onClick={() => { onDelete(data) }} className="ml-2 delete-btn cursor-pointer"><ItemTypeDeleteIcon /></span>
						)
					} */}
				</div>

				<div className="p-4 py-5 space-y-3">
					<div className="flex justify-between">
						<h3 className={`text-sm font-medium category-name`}>{data.collectionType}</h3>
						<h3 className={`text-sm font-medium`}>{data.chainLabel}</h3>
					</div>
					<div className="flex flex-wrap">
					{
						[...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+data['contractStatus']) ? <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-blue-100 relative mb-1">Deployed</span> : ''
					}
					{
						data.isFetured ? <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-blue-100 relative mb-1">Featured</span> : ''
					}
					{
						data.isPublished ? <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-blue-100 relative mb-1">Published</span> : ''
					}
					{
						data.isDeleted ? <span className="bg-red-500 font-medium inline-flex nc-Badge px-2.5 py-1 relative rounded-full text-white text-xs mb-1">Deleted</span> : ''
					}
					{
						!data.isActive ? <span className="bg-gray-500 font-medium inline-flex mb-1 nc-Badge px-2.5 py-1 relative rounded-full text-white text-xs">In-Active</span> : ''
					}
					</div>
					
					<h2 className={`text-lg font-medium mb-3`}>{data.collectionName}</h2>
				</div>
			</div>


		</React.Fragment>

	);
};

export default CollectionCard;
