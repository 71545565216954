
import React, { useEffect, useRef } from "react";
import "@google/model-viewer";
import NcImage from "shared/NcImage/NcImage";

declare global {
	namespace JSX {
		interface IntrinsicElements {
			'model-viewer': MyElementAttributes;
		}
		interface MyElementAttributes {
			src: string;
			poster: any;
			alt: string;
			ar: any
		}
	}
}

export const Model = ({ link, poster }: any) => (
	<div id="card">
		<model-viewer
			src={link}
			ios-src=""
			poster={poster}
			alt="A 3D model"
			shadow-intensity="1"
			camera-controls
			auto-rotate
			ar
		></model-viewer>
	</div>
);

const imgTypes = ['image/svg+xml','image/jpeg', 'image/png', 'image/gif','image/webp'];
const videoTypes = ['video/webm','video/mp4', 'video/ogg', 'video/webm'];
const audioTypes = ['audio/webm','audio/mpeg', 'audio/wav', 'audio/ogg'];
const THREE_D = ['model/gltf-binary','model/gltf+json'];

interface Props {
	file: any
}

const ProductFile = ({ file }: Props) => {

	const video:any = useRef();
	const audio:any = useRef();

	const DisplayFile = () => {
		if(file){
			return (
				<>
					{imgTypes.includes(file.mimeType) ? (
						<NcImage src={file.file} containerClassName={'aspect-w-11 aspect-h-12 rounded-3xl overflow-hidden cursor-pointer'} className="object-contain" />
					) : videoTypes.includes(file.mimeType) ? (
						<video width={'100%'}  controls  ref={video}>
							<source src={file.file} type={file.mimeType} />
							Your browser does not support the video tag.
						</video>
					) : audioTypes.includes(file.mimeType) ? (
						<div className="pt-24">
							<audio controls className="w-full" ref={audio}>
							<source src={file.file} type={file.mimeType} />
							Your browser does not support the audio element.
						</audio>
						</div>
					) : THREE_D.includes(file.mimeType) && typeof window !== 'undefined' ? (
						<Model link={file.file} poster={file.thumb} />
					) : null}
				</>
			);
		} else {
			return <NcImage src={''} containerClassName="aspect-w-11 aspect-h-12 rounded-3xl overflow-hidden cursor-pointer" />
		}

		
	};

	useEffect(()=>{
		if(video.current){
			video.current.load();
		}
		if(audio.current){
			audio.current.load();
		}
	},[file])


	return (
		<>
			{DisplayFile()}
		</>
	);
};

export default ProductFile;
