import React, { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import NcImage from "shared/NcImage/NcImage";
import CardNFT from "components/CardNFT";
import Pagination from "shared/Pagination/PaginationHandler";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import collectionBanner from "images/nfts/collectionBanner.png";
import { nftsImgs } from "contains/fakeData";
import NftMoreDropdown from "components/NftMoreDropdown";
import ButtonDropDownShare from "components/ButtonDropDownShare";
/*import TabFilters from "components/Products/TabFilters";*/
import TabFilters from "components/Filters/TabFilters";
import SectionSliderCollections from "components/SectionSliderCollections";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useNavigate, useParams } from "react-router";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Controller, useController, useForm } from "react-hook-form";
//import Select from "shared/Select/Select";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { toast } from "react-hot-toast";
import { URLS } from "_config";
import Checkbox from "shared/Checkbox/nativeCheckbox";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { Link } from "react-router-dom";
import ItemTypeEditIcon from "../../../components/ItemTypeEditIcon";
import ItemTypeDeleteIcon from "../../../components/ItemTypeDeleteIcon";
import Select from 'react-select';
import Switch from "shared/Switch/Switch";
import { usePayoutGroupApi } from "_common/hooks/api/payoutGroupApiHook";
import Button from "shared/Button/Button";
import ButtonCircle from "shared/Button/ButtonCircle";
import HeaderFilterSearchPage from "components/Products/HeaderFilterSearchPage";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import PageHeaderWithSearch from "shared/PageHeaderWithSearch/PageHeaderWithSearch";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useThemeApi } from "_common/hooks/api/themeApiHook.";
import previewBanner from "images/Theme3.png";

export interface Props {
    className?: string;
}

const PageTheme: FC<Props> = ({ className = "" }) => {
    const { register, handleSubmit, setValue, formState: { errors }, control, reset } = useForm();
    const [themes, setThemmes] = useState<any>(null);
    const [pages, setPages] = useState<any>(null);
    
    const [selectedTheme, setSelectedTheme] = useState<any>(null);
    const [selectedpreviewmode, setSelectedpreviewmode] = useState<any> (1)
    const [selectedpage, setSelectedpage] = useState<any> (1)
    const [previewImage, setPreviewImage] = useState<any>(null);
    
    const { showLoader, hideLoader } = useAppLoader();

    const themeApi = useThemeApi();

    const siteThemeSubmit = (theme:any) => {
        console.log("Submit theme", theme);
        showLoader();
        themeApi.saveTheme({
            "theamId": theme.theamID,
            "theamIndex": theme.theamIndex
        }, (message: string, resp: any) => {
            hideLoader();
            if (resp.code === 200 && resp.successful === true) {
                getThemesApi();
            } else {
                toast.error(message);
            }
        }, (message: string) => {
            hideLoader();
            toast.error(message);
            console.log(message);
        });
    }

    const updatePreviewImage = (theme:any, page:any, mode: number) => {
        if (theme) {
            const selectedItem = theme.pageDetails.filter((o:any) => Math.abs(o.pageID) === Math.abs(page) && Math.abs(o.viewIn) === mode);
            if (selectedItem.length > 0) {
                setPreviewImage(selectedItem[0].previewImageUrl);
            }
        }
    }

    const getThemesApi = () => {
        showLoader();
        themeApi.getThemes({}, (message: string, resp: any) => {
            hideLoader();
            if (resp.code === 200 && resp.successful === true && resp.hasOwnProperty('theams') && resp.theams.length > 0) {
                setThemmes(resp.theams);
                let pages:any = resp.theams[0].pageDetails.map((o:any) => Math.abs(o.pageID)).filter((value:any, index:number, self:any) => {
                    return self.indexOf(value) === index;
                }).map((ID:any) => {
                    const key = resp.theams[0].pageDetails.map((o:any) => Math.abs(o.pageID)).indexOf(Math.abs(ID));
                    return {
                        value: resp.theams[0].pageDetails[key].pageID, 
                        label: resp.theams[0].pageDetails[key].pageName
                    };                
                });
                setPages(pages);  
                let activeTheme = resp.theams.filter((o:any) => (o.isDefault === true));
                if (activeTheme.length > 0) {
                    activeTheme = activeTheme[0];
                } else {
                    activeTheme = resp.theams[0]; 
                }
                setSelectedTheme(activeTheme);
                setSelectedpage(activeTheme.value);
                setSelectedpreviewmode(1);
                setValue("pageID", pages[0]);
                updatePreviewImage(activeTheme, pages[0].value, selectedpreviewmode)
            } else {
                toast.error(message);
            }
        }, (message: string) => {
            hideLoader();
            toast.error(message);
            console.log(message);
        });

    }

    useEffect(() => {
        if (themes && selectedpage && selectedpreviewmode) {
            updatePreviewImage(selectedTheme, selectedpage, selectedpreviewmode)
        }

    }, [selectedTheme]);

    useEffect(() => {
        if (themes && selectedpage && selectedpreviewmode) {
            updatePreviewImage(selectedTheme, selectedpage, selectedpreviewmode)
        }

    }, [selectedpage]);

    useEffect(() => {
        if (themes && selectedpage && selectedpreviewmode) {
            updatePreviewImage(selectedTheme, selectedpage, selectedpreviewmode)
        }

    }, [selectedpreviewmode]);

    useEffect(() => {
        getThemesApi()
    }, []);

    return (
        <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
            <Helmet>
                <title>PageTheme</title>
            </Helmet>
            <div className="container">
                <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
                    <div className="max-w-2xl">
                        <h2 className="text-3xl sm:text-4xl font-semibold">
                            Theme Settings
                        </h2>
                        <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                            You can set preferrred theme for your store/market place.
                        </span>
                    </div>
                    <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
                    <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 relative text-center lg:text-left">
                        <div className="max-w-full xl:max-w-lg space-y-5 lg:space-y-7 border-r sm-border-0 pr-5">
                            <p className="m-0 text-gray-600 pl-1 text-sm">Click on a themes to preview.</p>
                            {themes && themes.length > 0 ? (
                                <div className="mt-5">
                                    {themes.map((item: any, index: number) => {
                                        return (
                                            <div key={`thms-${index}`} className={`text-center border rounded-3xl p-3 mb-6 shadow-lg ` + (item.theamID === selectedTheme.theamID ? 'bg-primary-500' : '')} onClick={() => {
                                                setSelectedTheme(item);
                                            }}>
                                                <div className={`themeTitlex font-semibold text-3xl ` + (item.theamID === selectedTheme.theamID ? 'text-white' : '')}>{item.theamName}</div>
                                                {item.isDefault && (
                                                    <div className="text-lime-500 mt-2">Published</div>
                                                )}
                                                {item.theamID === selectedTheme.theamID ? (
                                                    <ButtonSecondary className="mt-7 p-3 text-primary-500" onClick={() => {
                                                        setSelectedTheme(item);
                                                        siteThemeSubmit(item)
                                                    }} disabled={item.isDefault}>{item.isDefault ? 'Applied' : 'Apply Theme'}</ButtonSecondary>
                                                ) : (
                                                    <ButtonPrimary className="mt-7 p-2" onClick={() => {
                                                        setSelectedTheme(item);
                                                        siteThemeSubmit(item)
                                                    }} disabled={item.isDefault}>{item.isDefault ? 'Applied' : 'Apply Theme'}</ButtonPrimary>
                                                )}
                                            </div>
                                        )
                                    })}
                                    {/*
                                    <div className="text-center border rounded-3xl p-3 mb-6 shadow-lg">
                                        <div className="themeTitlex font-semibold text-3xl">Game Theme</div>
                                        <div className="text-lime-500 mt-2">Published</div>
                                        <ButtonPrimary className="mt-7 p-2" disabled>Applied</ButtonPrimary>
                                    </div>
                                    <div className="text-center border rounded-3xl p-3 shadow-lg bg-primary-500">
                                        <div className="themeTitlex font-semibold text-3xl text-white">Modern Theme</div>
                                        <ButtonSecondary className="mt-7 p-3 text-primary-500">Apply Theme</ButtonSecondary>
                                    </div>
                                    */}
                                </div>
                            ) : (
                                <p>No theme found!</p>
                            )}
                        </div>
                        <div className="flex-grow">
                            <div className="flex justify-between mobile-column">
                                <div className="w-80">
                                    <h2 className="font-semibold">Previewing: Gametheme</h2>
                                    <p className="m-0 text-sm mt-1">This is the theme, customers see when they visit youre store.</p>
                                </div>
                                <div className="flex items-center flex-nowrap m-justify-center">

                                    <div className="flex items-center justify-between border rounded-lg overflow-hidden two-tab">
                                        <div className={`px-3 py-2 ` + (selectedpreviewmode === 2? 'bg-slate-200': '')} onClick={() => {
                                            setSelectedpreviewmode(2);
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                                            </svg>
                                        </div>
                                        <div className={`px-3 py-2 ` + (selectedpreviewmode === 1? 'bg-slate-200': '')} onClick={() => {
                                            setSelectedpreviewmode(1);
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                                            </svg>
                                        </div>
                                    </div>

                                    <Controller
                                        name={"pageID"}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange, onBlur } }) => (
                                            <Select
                                                className={` ml-3 mt-1 rounded-2xl`}
                                                options={pages}
                                                placeholder={"Select Page"}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    setSelectedpage(e.value);
                                                }}
                                                value={value}
                                                defaultValue={value}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="mt-5 py-5">
                                <div className="theme-preview-wrap">
                                    <img src={previewImage} />
                                    {/*
                                    <NcImage containerClassName="inset-0" src={previewImage} className="object-cover w-full h-full" />
                                    */}
                                </div>
                                <ButtonPrimary className="w-full mt-5" onClick={() => {
                                    siteThemeSubmit(selectedTheme);
                                }} disabled={selectedTheme?.isDefault}>Apply Theme</ButtonPrimary>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default PageTheme;