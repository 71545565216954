import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";
import rootReducer from "./reducers";
import { sagas } from './sagas';

const isDev = process.env.NODE_ENV === "development";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewareLogger: any = !!isDev ? logger : [];

const sagaMiddleware = createSagaMiddleware();
//middlewares.push(sagaMiddleware);


export const store = configureStore({
  reducer: persistedReducer,
  middleware: [...getDefaultMiddleware({
    serializableCheck: false
  }), sagaMiddleware],
});

sagaMiddleware.run(sagas);

export let persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


/* 
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistPartial } from "redux-persist/es/persistReducer";
import rootReducer from "./reducers";
import { sagas } from './sagas';
import { configureStore, combineReducers } from "@reduxjs/toolkit"

let middlewares = [];

const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

let middleware = applyMiddleware(...middlewares);


if (['dev'].indexOf((process.env as any).REACT_APP_STAGE) >= 0) {
  if((window as any).devToolsExtension){
    middleware = compose(middleware, (window as any).devToolsExtension());
  }
  if((window as any).__REDUX_DEVTOOLS_EXTENSION__){
    middleware = compose(middleware, (window as any).__REDUX_DEVTOOLS_EXTENSION__());
  }
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['form', 'user']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore<any & PersistPartial, any, any, any>(persistedReducer, middleware);
const persistor = persistStore(store)


sagaMiddleware.run(sagas);

export { store, persistor }; */