import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import FormItem from "components/FormItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import InputFile from 'shared/InputFile/InputFile';
import Textarea from "shared/Textarea/Textarea";
import CheckBoxNative from "shared/Checkbox/nativeCheckbox";
import { useCmsApi } from "_common/hooks/api/cmsApi";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { Editor } from "@tinymce/tinymce-react";
import toast from "react-hot-toast";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import DisplayFile from "shared/DisplayNft/displayNftFile";


const AddToCms = () => {

    const cmsApi = useCmsApi()
    const commonApi = useCommonApi()
    const navigate = useNavigate()
    const { type } = useParams()
    const { showLoader, hideLoader } = useAppLoader()
    const [searchParams] = useSearchParams()
    const id: any = searchParams.get("cmsid")

    const editorRef: any = useRef('')
    const [cmsDetails, setCmsDetails] = useState<any>(null)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm();

    const getCmsDetails = () => {
        showLoader()
        cmsApi.getCmsList({ cmsid: id },
            (message: string, resp: any) => {
                console.log({ message, resp })
                setCmsDetails(resp.cmSdm && resp.cmSdm[0] || null)
                if (resp.cmSdm && resp.cmSdm.length > 0) {
                    reset({
                        bannerImage: null,
                        title: resp.cmSdm[0].title,
                        orders: resp.cmSdm[0].orders,
                        metaKeywords: resp.cmSdm[0].metaKeywords,
                        metaDescription: resp.cmSdm[0].metaDescription,
                        body: resp.cmSdm[0].body,
                        header: resp.cmSdm[0].header_Footer == 1 || resp.cmSdm[0].header_Footer == 3,
                        footer: resp.cmSdm[0].header_Footer == 2 || resp.cmSdm[0].header_Footer == 3,
                        isActive: resp.cmSdm[0].isActive == 1
                    })
                }
                hideLoader()
            },
            (message: string) => {
                console.log(message)
                hideLoader()
            })
    }

    const onEditorChange = (e: any) => {
        // console.log({e})
        if (editorRef.current) {
            let editorContent = editorRef?.current.getContent()
            if (editorContent == '') {
                setErrorMessage("Story text is required")
            }
            else {
                setErrorMessage('')
            }
        }
    }

    const addEditCms = (params: any, file: any) => {
        console.log('file: ', file)
        if (type == 'add') {
            showLoader()
            cmsApi.addCms(params,
                (message: string, resp: any) => {
                    console.log({ message, resp })
                    if (file[0] != undefined) {
                        mapCategoryImage(resp.returnID, file[0], "banner ").then((msg: any) => {
                            toast.success(message);
                            handleCancel()
                            hideLoader();
                        }).catch((msg: any) => {
                            toast.success(message);
                            handleCancel()
                            hideLoader();
                        })
                    } else {
                        toast.success(message);
                        handleCancel()
                        hideLoader();
                    }
                },
                (message: string) => {
                    toast.error(message)
                    hideLoader()
                })
        }
        else if (type == 'edit') {
            showLoader()
            cmsApi.editCms(params,
                (message: string, resp: any) => {
                    if (file[0] != undefined) {
                        mapCategoryImage(resp.returnID, file[0], "banner ").then((msg: any) => {
                            toast.success(message);
                            handleCancel()
                            hideLoader();
                        }).catch((msg: any) => {
                            toast.error(message);
                            handleCancel()
                            hideLoader();
                        })
                    } else {
                        toast.success(message);
                        handleCancel()
                        hideLoader();
                    }
                },
                (message: string) => {
                    toast.error(message)
                    hideLoader()
                })
        }
    }

    const mapCategoryImage = (id: any, file: any, type: string) => {
        console.log({ id, file, type })
        return new Promise((resolve: any, reject: any) => {
            let data: any = new FormData();
            data.append("", file);
            data.append("ItemId", id);
            data.append("ItemType", 6);
            data.append("FileType", type);

            commonApi.fileUpload(data,
                (message: string, resp: any) => {
                    console.log('f complete')
                    resolve(message)
                }, (message: string) => {
                    console.log('f failed')
                    reject(message)
                })
        })
    }

    const onSubmit = (data: any) => {
        console.log({ data })
        let params: any = {
            title: data.title,
            orders: data.orders,
            metaKeywords: data.metaKeywords,
            metaDescription: data.metaDescription,
            bannerImageURL: data.bannerImage ? '' : cmsDetails?.bannerImageURL,
            isActive: data.isActive == true ? 1 : 0
        }
        params["cmsid"] = id || 0

        if (editorRef.current) {
            let editorContent = editorRef?.current.getContent()
            if (editorContent == '') {
                setErrorMessage("Story text is required")
            }
            else {
                params["body"] = editorContent
            }
        }

        if (data.header == true && data.footer == true) {
            params["header_Footer"] = 3
        }
        if (data.header == false && data.footer == false) {
            params["header_Footer"] = 0
        }
        if (data.header == true && data.footer == false) {
            params["header_Footer"] = 1
        }
        if (data.header == false && data.footer == true) {
            params["header_Footer"] = 2
        }

        addEditCms(params, data.bannerImage || [])
    }

    const handleKeyPress = (event: any) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    const handleCancel = () => {
        navigate(`/cms`)
    }

    useEffect(() => {
        if (id) {
            getCmsDetails()
        }
    }, [id])

    return (
        <React.Fragment>
            <div className="container">
                <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
                    {/* HEADING */}
                    <div className="max-w-2xl">
                        <h2 className="text-3xl sm:text-4xl">
                            Add / Update <span className="font-semibold">{ cmsDetails ? cmsDetails['title'] : ''}</span>
                        </h2>
                    </div>
                    <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                            <FormItem label="Banner/Header Image" isRequired>
                                <InputFile
                                    uploadedUrl={cmsDetails?.bannerImageURL}
                                    {...register(`bannerImage`, { required: cmsDetails && cmsDetails.bannerImageURL ? false : true })}
                                />
                            </FormItem>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                            <FormItem label="Title" isRequired className='m-2' >
                                <Controller
                                    name={`title`}
                                    control={control}
                                    render={({ field }) => <Input
                                        {...field}
                                        placeholder={''}
                                        autoComplete={'off'}
                                        required={true}
                                    />}
                                />
                                {
                                    errors && errors.title && errors.title?.type == "required" ?
                                        <p className='text-red-400'>Required</p> : ''
                                }
                            </FormItem>
                            <FormItem label="Order" isRequired className='m-2' >
                                <Controller
                                    name={`orders`}
                                    control={control}
                                    render={({ field }) => <Input
                                        {...field}
                                        placeholder={''}
                                        autoComplete={'off'}
                                        required={true}
                                        onKeyPress={(event: any) => handleKeyPress(event)}
                                    />}
                                />
                                {
                                    errors && errors.orders && errors.orders?.type == "required" ?
                                        <p className='text-red-400'>Required</p> : ''
                                }
                            </FormItem>
                            <FormItem label="Meta Keywords" isRequired className='m-2' >
                                <Controller
                                    name={`metaKeywords`}
                                    control={control}
                                    render={({ field }) => <Textarea
                                        {...field}
                                        rows={5}
                                        {...register(`metaKeywords`, { required: true })}
                                    />}
                                />
                                {
                                    errors && errors.metaKeywords && errors.metaKeywords?.type == "required" ?
                                        <p className='text-red-400'>Required</p> : ''
                                }
                            </FormItem>
                            <FormItem label="Meta Description" isRequired className='m-2' >
                                <Controller
                                    name={`metaDescription`}
                                    control={control}
                                    render={({ field }) => <Textarea
                                        {...field}
                                        rows={5}
                                        {...register(`metaDescription`, { required: true })}
                                    />}
                                />
                                {
                                    errors && errors.metaDescription && errors.metaDescription?.type == "required" ?
                                        <p className='text-red-400'>Required</p> : ''
                                }
                            </FormItem>
                        </div>

                        <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
                            <FormItem label="Description" isRequired className='m-2' >
                                <Editor
                                    onInit={(evt: any, editor: any) => {
                                        editorRef.current = editor
                                        // if(createPetition && createPetition.editor)
                                        // {
                                        //   editorRef.current.setContent(createPetition.editor)
                                        // }
                                    }}
                                    initialValue={cmsDetails ? cmsDetails?.body : null}
                                    onEditorChange={onEditorChange}

                                    init={{
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar: 'undo redo | formatselect | ' +
                                            'bold italic underline | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                                {
                                    errorMessage && errorMessage.length ?
                                        <p className='text-red-400'>{errorMessage}</p> : ''
                                }
                            </FormItem>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                            <FormItem label="Position" isRequired className='m-2' >
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                                    <CheckBoxNative label="Header" {...register('header')} />
                                    <CheckBoxNative label="Footer" {...register('footer')} />
                                    <CheckBoxNative label="Active" {...register('isActive')} />
                                </div>
                            </FormItem>
                        </div>


                        <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 m-6">
                            <ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
                            <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Cancel</ButtonSecondary>
                        </div>

                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddToCms