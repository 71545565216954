import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { toast } from "react-hot-toast";
import { NETWORK_URLS } from "_common/_constant";


interface props {
  product: any
}


export default function AccordionDetails({ product }: props) {
  return (
    <div className="w-full rounded-2xl">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
              <span>Details</span>
              <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-neutral-500`} />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-4 pb-2 text-neutral-500 text-sm dark:text-neutral-400" as="p">

              <span>Chain Label</span>
              <span className="text-base text-neutral-900 dark:text-neutral-100 line-clamp-1">
                {product?.collectionNetwork?.chainLabel}
              </span>
              <br />

              <span>Contract Address</span>
              <span className="text-base text-primary-500 dark:text-primary-400 line-clamp-1 cursor-pointer">
                <a href={`${NETWORK_URLS['' + product?.collectionNetwork?.chainID]}/address/${product?.contractAddress}`} target="_blank" rel="noreferrer">{product?.contractAddress}</a>
              </span>
              <br />

              <span>Token</span>
              <span className="text-base text-primary-500 dark:text-primary-400 line-clamp-1 cursor-pointer">
                <a href={`${NETWORK_URLS['' + product?.collectionNetwork?.chainID]}/token/${product?.contractAddress}?a=${product?.token}`} target="_blank" rel="noreferrer">{product?.token}</a>
              </span>
              <br />

              <span>Transaction Hash</span>
              <div className="text-primary-500 dark:text-primary-400 flex gap-3 items-center mt-1.5 p-3 relative rounded-2xl shadow-md justify-between">
                <span className="line-clamp-1 word-break">
                  {product.transactionHash ?
                    <a href={`${NETWORK_URLS['' + product?.collectionNetwork?.chainID]}/tx/${product.transactionHash}`} target="_blank" rel="noreferrer">{product.transactionHash}</a>
                    : 'N/A'}
                </span>
                <span className="cursor-pointer" onClick={() => {
                  const address = product.transactionHash || '';
                  if (address) {
                    toast.success("Copied.")
                    navigator.clipboard.writeText(address);
                  }
                }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M21.6602 10.44L20.6802 14.62C19.8402 18.23 18.1802 19.69 15.0602 19.39C14.5602 19.35 14.0202 19.26 13.4402 19.12L11.7602 18.72C7.59018 17.73 6.30018 15.67 7.28018 11.49L8.26018 7.30001C8.46018 6.45001 8.70018 5.71001 9.00018 5.10001C10.1702 2.68001 12.1602 2.03001 15.5002 2.82001L17.1702 3.21001C21.3602 4.19001 22.6402 6.26001 21.6602 10.44Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.0603 19.3901C14.4403 19.8101 13.6603 20.1601 12.7103 20.4701L11.1303 20.9901C7.16034 22.2701 5.07034 21.2001 3.78034 17.2301L2.50034 13.2801C1.22034 9.3101 2.28034 7.2101 6.25034 5.9301L7.83034 5.4101C8.24034 5.2801 8.63034 5.1701 9.00034 5.1001C8.70034 5.7101 8.46034 6.4501 8.26034 7.3001L7.28034 11.4901C6.30034 15.6701 7.59034 17.7301 11.7603 18.7201L13.4403 19.1201C14.0203 19.2601 14.5603 19.3501 15.0603 19.3901Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>

            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
