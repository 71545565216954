import React, { useEffect, useState } from "react";
import FormItem from "components/FormItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useNavigate, useParams } from "react-router-dom";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { useOrdersApi } from "_common/hooks/api/ordersApi";
import moment from "moment";
import PriceText from "components/PriceText";
import ProductNFT from "components/ProductNFT";
import { Helmet } from "react-helmet";
import { NETWORK_URLS } from "_common/_constant";
import { Link } from "react-router-dom";


const OrderDetails = () => {

    const orderApi = useOrdersApi()
    const navigate = useNavigate()
    const { id } = useParams()
    const { showLoader, hideLoader } = useAppLoader()

    const [orderDetails, setOrderDetails] = useState<any>(null)
	const [nfts, setNfts] = useState<any[]>([]);


    const getOrderDetails = () => {
        showLoader()
        orderApi.getOrdersList({ orderID: id },
            (message: string, resp: any) => {
                // console.log({ message, resp })
                setOrderDetails(resp.orders[0])
                hideLoader()
            },
            (message: string) => {
                console.log(message)
                hideLoader()
            })
    }

    const handleCancel = () => {
        navigate(`/orders`)
    }

    const redirectTxnLink = () => {
        const url = orderDetails['collectionNetwork'] && orderDetails['collectionNetwork']['chainID'] ? NETWORK_URLS['' + orderDetails['collectionNetwork']['chainID']] + '/tx/' + orderDetails['transactionHash'] : ''

        if (url) {
            window.open(url, '_blank');
        }
    }

    const getNftsList = (order: any) => {
		let tempArr: any = [];
		order?.orderItems?.map((item: any) => {
			// console.log(item?.nfTs)
			tempArr = [...tempArr, ...item.nfTs]
		})
		setNfts(tempArr)
	}

    const getPrice = (item: any) => {
        // if (item.productPrice.length) {
        //     let activePrice = item.productPrice.filter((price: any) => price.isActive == 1);
        //     return activePrice.length ? activePrice[0].cryptoStartPrice.toFixed(8) : 'NA'
        // } else {
        //     return 'NA';
        // }
    }

    useEffect(() => {
        if (id) {
            getOrderDetails()
        }
    }, [id])

    useEffect(() => {
		if (orderDetails) {
			getNftsList(orderDetails)
		}
	}, [orderDetails])

    return (
        <React.Fragment>
            <Helmet>
                <title>Order Details</title>
            </Helmet>
            <div className="container">
                <div className="my-4 sm:lg:my-4 lg:my-24 mx-auto space-y-8 sm:space-y-10">
                    {/* HEADING */}
                    <div className="flex flex-col items-center justify-between relative">
                        <div className="text-center">
                            <h2 className="text-3xl sm:text-4xl font-semibold">
                                Thank you for your order
                            </h2>
                            <FormItem className='m-2 order-details-text text-4xl' >
                                Order Details
                            </FormItem>

                        </div>
                        <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 mt-2 justify-center lg:absolute top-0 right-0">
                            <ButtonSecondary className="" type="button" onClick={handleCancel}>Back</ButtonSecondary>
                        </div>
                    </div>
                    <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>

                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-8 sm:gap-5">
                        <div className="grid lg:grid-cols-3 sm:grid-cols-3 gap-5">
                            <FormItem label="Order Information" className='order-details-text shadow-md p-6 rounded-2xl' >
                                <span className='block mt-3'> {orderDetails && `# ${orderDetails?.orderNo}`}</span>
                                <span className='block mt-3 text-gray-500 mb-4'>{orderDetails && orderDetails?.orderDate && `Placed On : ${moment(orderDetails?.orderDate).format('DD/MM/YYYY')}`}</span>
                            </FormItem>
                            <FormItem label="Customer Information" className='order-details-text shadow-md p-6 rounded-2xl' >
                                <span className='block mt-3'>{orderDetails && orderDetails?.buyerName}</span>
                                <span className='block mt-3 text-gray-500'>{orderDetails && orderDetails?.emailID}</span>
                            </FormItem>
                            <FormItem label="Payment Information" className='order-details-text shadow-md p-6 rounded-2xl' >
                                <div className="flex justify-between items-center">
                                    {orderDetails?.cryptoOrderAmount ?
                                        <div className="w-full ">
                                            <PriceText price={orderDetails?.cryptoOrderAmount} network={orderDetails?.orderItems[0].network} className="flex" />
                                        </div>
                                        : null}

                                    <span className='text-gray-600 whitespace-nowrap'>{/* {orderDetails && orderDetails?.isCouponUsed == 1 ? "Offline Payment" : "Online Payment"} */}</span>
                                </div>
                                {orderDetails && orderDetails?.isCouponUsed == 1 ?
                                    <span className='block mt-3'>Paid By Coupon : <strong>{orderDetails?.couponCode}</strong></span>
                                    :
                                    null
                                }
                                <span className='block mt-2'>{orderDetails && orderDetails?.isCouponUsed == 1 ? "Offline Payment" : "Online Payment"}</span>
                            </FormItem>
                        </div>
                        {orderDetails && orderDetails.transactionHash && orderDetails.transactionHash.length > 0 ?
                            <div className="grid lg:grid-cols-1 sm:grid-cols-1">
                                <FormItem label="Transaction Hash" className='order-details-text shadow-md p-6 rounded-2xl' >
                                    <div className="dark:text-neutral-300 flex gap-3 items-center mt-1.5 p-3 text-neutral-700 justify-between">
                                        <span className="line-clamp-1 word-break cursor-pointer" onClick={redirectTxnLink}>{orderDetails.transactionHash ? orderDetails.transactionHash : 'N/A'}</span>
                                        <span className="cursor-pointer" onClick={() => {
                                            const address = orderDetails.transactionHash || '';
                                            if (address) {
                                                toast.success("Copied.")
                                                navigator.clipboard.writeText(address);
                                            }
                                        }}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path
                                                    d="M21.6602 10.44L20.6802 14.62C19.8402 18.23 18.1802 19.69 15.0602 19.39C14.5602 19.35 14.0202 19.26 13.4402 19.12L11.7602 18.72C7.59018 17.73 6.30018 15.67 7.28018 11.49L8.26018 7.30001C8.46018 6.45001 8.70018 5.71001 9.00018 5.10001C10.1702 2.68001 12.1602 2.03001 15.5002 2.82001L17.1702 3.21001C21.3602 4.19001 22.6402 6.26001 21.6602 10.44Z"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M15.0603 19.3901C14.4403 19.8101 13.6603 20.1601 12.7103 20.4701L11.1303 20.9901C7.16034 22.2701 5.07034 21.2001 3.78034 17.2301L2.50034 13.2801C1.22034 9.3101 2.28034 7.2101 6.25034 5.9301L7.83034 5.4101C8.24034 5.2801 8.63034 5.1701 9.00034 5.1001C8.70034 5.7101 8.46034 6.4501 8.26034 7.3001L7.28034 11.4901C6.30034 15.6701 7.59034 17.7301 11.7603 18.7201L13.4403 19.1201C14.0203 19.2601 14.5603 19.3501 15.0603 19.3901Z"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                </FormItem>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
            <div className="container lg:pb-28 lg:pt-5 space-y-16 lg:space-y-28">
                <h2 className="text-2xl font-semibold mb-6">
                    Items in this order
                </h2>
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-8 gap-y-10 mt-0 custom-xs-col-2 cstm-mt-10">
                    {orderDetails && orderDetails?.orderItems?.map((product: any, i: any) => (
                        <ProductNFT data={product} key={i} />
                        ))}
                </div>
            </div>
            <div className="container lg:pb-28 lg:pt-5 space-y-16 lg:space-y-28">
                <h2 className="text-2xl font-semibold mb-6">
                    Nfts in this order
                </h2>
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-8 gap-y-10 mt-0 custom-xs-col-2 cstm-mt-10">
                    {nfts && nfts.length > 0 ? nfts?.map((item: any, i: any) => (
                        <ProductNFT data={item} key={i} isNft={true} />
                        // <Link to={`/nft/${item?.nftid}`}><p>NFTID: {item?.nftid}</p></Link>
                    ))
                        :
                        <p>No NFT</p>
                    }
                </div>
            </div>

        </React.Fragment>
    )
}

export default OrderDetails