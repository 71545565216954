import React, { FC, useState, useEffect } from "react";
import Radio from "shared/Radio/Radio";



export interface Props {
  data: any;
  state: any;
  onUpdate: (str: any) => void;
  label?: string;
  name: string;
}

const DateFilterMobile: FC<Props> = ({ data, onUpdate, state = "", label = "Sort order", name }) => {

  const [dateStates, setDateStates] = useState<string>(state);

  useEffect(() => {
    if (onUpdate) (onUpdate(JSON.parse(`{"${data.key}": "${dateStates}"}`)));
  }, [dateStates]);

  useEffect(() => {
    setDateStates(state)
  }, [state])

  return (
    <div className="py-7">
      <h3 className="text-xl font-medium">{label}</h3>
      <div className="mt-6 relative ">
        <div className="relative flex flex-col space-y-5">
          <input type={'date'} onChange={(e: any) => setDateStates(e.target.value)} defaultValue={dateStates} />
        </div>
      </div>
    </div>
  )
}

export default DateFilterMobile;