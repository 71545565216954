import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function useOrdersApi() {

  const callApi = useApiCall()

  const getOrdersList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.ORDERS.GET, data, onSuccess, onError);
  }

  return {
    getOrdersList: getOrdersList,
  }
}