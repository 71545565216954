import React, { FC } from "react";
import {getPriceIconUrl, getPriceFormat} from '_common/functions'

import NcImage from "shared/NcImage/NcImage";


export interface PricesProps {
  className?: string;
  price?: any;
  network?: any;
  contentClass?: string;
  labelTextClassName?: string;
  labelText?: string;
  size?: string;
}

const PriceText: FC<PricesProps> = ({
  className = "pt-3",
  price = "000",
  network,
  contentClass = "py-1.5 md:py-2 px-2.5 md:px-3.5 text-sm sm:text-base font-semibold",
  labelTextClassName = "bg-white",
  labelText = "Price",
  size = "sm",
}) => {


  


  return (
    <div className={`${className}`}>

      <div className={`flex items-baseline border-2 border-green-500 rounded-lg relative cstm-price-fld ${contentClass} `}>
        <span className={`block absolute font-normal bottom-full translate-y-1 p-1 -mx-1 text-xs text-neutral-500 dark:text-neutral-400 dark:bg-neutral-800 dark:group-hover:bg-neutral-900 cstm-price-label ${labelTextClassName}`}        >
          {labelText}
        </span>
        <span className="flex items-center text-green-500 !leading-none text-sm">
          {network ?
            <NcImage
              containerClassName="flex aspect-w-11 aspect-h-12 w-full p-3 mr-2 h-0 rounded-3xl overflow-hidden z-0"
              src={getPriceIconUrl(network)}
              className="object-contain w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
            />
          :<div className="flex aspect-w-11 aspect-h-12 w-full pt-3 pb-3 mr-0 h-0 rounded-3xl overflow-hidden z-0"></div>}
          {getPriceFormat(network,price)}
        </span>
      </div>
      
    </div>
  );
};

export default PriceText;