import React from 'react';
import "@google/model-viewer";
import NcImage from "shared/NcImage/NcImage";
import AudioForNft from 'components/AudioForNft';
import { nanoid } from '@reduxjs/toolkit';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': MyElementAttributes;
    }
    interface MyElementAttributes {
      src: string;
      poster: any;
      alt: string;
      ar: any
    }
  }
}

const imgTypes = ['image/svg+xml', 'image/jpeg', 'image/png', 'image/gif', 'image/webp'];
const videoTypes = ['video/webm', 'video/mp4', 'video/ogg', 'video/webm'];
const audioTypes = ['audio/webm', 'audio/mpeg', 'audio/wav', 'audio/ogg'];
const THREE_D = ['model/gltf-binary', 'model/gltf+json'];
const DisplayFile = ({ mimeType, link, placeholder, className, from = 'internal' }: any) => {
  const [DEMO_NFT_ID] = React.useState(nanoid());
  return (
    <>
      {imgTypes.includes(mimeType) ? (
        <NcImage src={link} className={className} />
      ) : videoTypes.includes(mimeType) ? (
        <div className={`videowrpdz ${from}`}>
          <video width={'100%'} height="240" controls>
            <source src={link} type={mimeType} />
            Your browser does not support the video tag.
          </video>
        </div>
      ) : audioTypes.includes(mimeType) ? (
        <div className={`audiowrpdz ${from}`}>
          <audio controls className={`${from === 'external'? 'w-full': ''}`}>
          <source src={link} type={mimeType} />
          Your browser does not support the audio element.
        </audio></div>
      ) : THREE_D.includes(mimeType) && typeof window !== 'undefined' ? (
        <div className={`modelwrpdz ${from}`}><Model link={link} poster={placeholder} /></div>
      ) : null}
    </>
  );
};

export default DisplayFile;


export const Model = ({ link, poster }: any) => (
  <div id="card">
    <model-viewer
      src={link}
      ios-src=""
      poster={poster}
      alt="A 3D model"
      shadow-intensity="1"
      camera-controls
      auto-rotate
      ar
    ></model-viewer>
  </div>
);