import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageHeaderWithSearch from "shared/PageHeaderWithSearch/PageHeaderWithSearch";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";
import TabFilters from "components/Filters/TabFilters";
import ItemCustomFieldImageIcon from 'components/Customfield/ItemCustomFieldImageIcon';
import Pagination from "shared/Pagination/PaginationHandler";
import ItemTypeEditIcon from "components/ItemTypeEditIcon";
import toast from "react-hot-toast";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useEmailTempltApi } from "_common/hooks/api/emailTempltApi";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ItemTypeViewIcon from "components/ItemTypeViewIcon";
import { useOrdersApi } from "_common/hooks/api/ordersApi";
import PriceText from "components/PriceText";

export interface Props {
	className?: string;
}

const Orders: FC<Props> = ({ className = "" }) => {

	const limit: number = 12;
	const sortBy = [
		{ name: "Offline Orders Only", id: "name-offline", direction: 1 },
		{ name: "Online Orders Only", id: "name-online", direction: 0 }
	];

	const [searchKey, setSearchKey] = useState<string>('');
	const [orderList, setOrderList] = useState<any>(null);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState<number>(1);
	const [isOpen, setIsOpen] = useState(false);
	const [filter, setFilter] = useState<any>({});

	const orderApi = useOrdersApi()
	const navigate = useNavigate()
	const { showLoader, hideLoader } = useAppLoader()

	const onSearch = (key: string) => {
		setSearchKey(key);
	}

	const onApplyFilter = (data: any) => {
		// console.log('Category Filter =>', data)
		setFilter(data)
	}

	const getOrdersList = () => {
		let params: any = {
			usePaging: true,
			pageSize: limit,
			pageNumber: currentPage,
			sortingOrder: 'desc'
		}
		if (searchKey) {
			params.searchText = searchKey
		}
		switch (filter?.radioBtnFiltrations?.Status) {
			case 'name-offline':
				params.isCouponUsed = 1;
				break;
			case 'name-online':
				params.isCouponUsed = 0;
				break;
			default:
		}
		showLoader()
		orderApi.getOrdersList(params,
			(message: string, resp: any) => {
				console.log({ message, resp })
				setOrderList(resp.orders)
				setTotalPage(Math.ceil(resp.totalRecord / limit))
				hideLoader()
			},
			(message: string) => {
				console.log(message)
				hideLoader()
			})
	}

	useEffect(() => {
		console.log('on apply filter =>', filter);
		if (orderList) {
			if (currentPage == 1) {
				getOrdersList();
			} else {
				setCurrentPage(1);
			}
		}
	}, [filter, searchKey])

	useEffect(() => {
		getOrdersList();
	}, [currentPage])

	// useEffect(() => {
	// 	getOrdersList()
	// }, [])


	return (
		<div className={`nc-PageUploadItem`} data-nc-id="PageUploadItem">
			<Helmet>
				<title>Orders</title>
			</Helmet>
			<PageHeaderWithSearch title="Orders" onSearch={setSearchKey} searchKey={searchKey} />

			<div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
				<div className={`flex flex-col relative ${className}`}>
					<div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
						<div className="relative flex w-full text-sm md:text-base hiddenScrollbar items-center">

						</div>
						<span className="flex flex-shrink-0 text-right items-center">
							{/* <Link className="sm:space-x-2" to="/email-template/update">
								<ButtonPrimary>Add New</ButtonPrimary>
							</Link> */}
							<ButtonSecondary
								className="w-auto !pr-16 ml-2 border-primary-500 focus:ring-0 focus:ring-primary-200"
								sizeClass="pl-4 py-2.5 sm:pl-6"
								onClick={() => {
									setIsOpen(!isOpen);
								}}
							>
								<svg
									className={`w-4 h-4 sm:w-6 sm:h-6`}
									viewBox="0 0 24 24"
									fill="none"
								>
									<path
										d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M19.87 17.12L18.87 16.12"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>

								<span className="block truncate ml-2.5">Filter</span>
								<span className="absolute top-1/2 -translate-y-1/2 right-5">
									<ChevronDownIcon
										className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpen ? "rotate-180" : ""
											}`}
										aria-hidden="true"
									/>
								</span>
							</ButtonSecondary>
						</span>
					</div>

					<Transition
						show={isOpen}
						enter="transition-opacity duration-150"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity duration-150"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
						<TabFilters name="Orders List Filters"
							radioBtnFiltrations={[
								{
									key: 'Status',
									options: sortBy
								}
							]} onApplyFilter={onApplyFilter} />
					</Transition>
				</div>

				<div className="grid sm:grid-cols-2 gap-x-8 gap-y-8  mt-8 lg:mt-10">
					{orderList && orderList.length > 0 ? (
						<React.Fragment>
							{orderList.map((item: any, index: number) => {
								return (
									<div key={'cstfld-' + index} className={`relative rounded-xl hover:shadow-lg hover:bg-neutral-50 border border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800 dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200`}>

										<div className="flex items-center w-full" onClick={() => navigate(`/order-details/${item?.orderID}`)}>
											<div className="w-20 custom-fld-icon mr-4">
												<ItemCustomFieldImageIcon />
											</div>
											<div className="w-full">
												<div className="flex justify-between">
													<h3 className={`text-sm font-medium category-name`}>{""}</h3>
													<h3 className={`text-sm font-medium`}>{''}</h3>
												</div>
												<h2 className={`font-semibold mb-1 flex items-center`}> <span className="font-normal font-semibold text-2xl">{item?.orderNo}</span></h2>
												<h2 className={`font-semibold mb-1 flex items-center`}><span className="font-normal text-xl">{item?.buyerName}</span></h2>
												<h2 className={`font-semibold mb-1 flex items-center mb-5`}><span className="font-normal text-gray-600 text-gray-400">{item?.emailID}</span></h2>
												{/* <h2 className={`font-semibold mb-1 flex items-center`}>Payment Currency- <span className="font-normal ml-2 text-lime-500">{item?.currency}</span></h2> */}
												{/* <PriceText price={item?.cryptoOrderAmount} network={item?.currency} className="w-2/3 mt-4" /> */}
												{/* <h2 className={`font-semibold mb-1 flex items-center mt-2`}>Action-
													<span className="font-normal ml-2">
														<Link to={`/order-details/${item?.orderID}`}><ItemTypeViewIcon /></Link>
													</span>
												</h2> */}
											</div>
											<div className="option absolute bottom-3 right-3">
												<h4 className={`text-sm font-medium item-status ` + (item?.isCouponUsed == 1 ? 'bg-gray-500 text-white' : 'bg-red-300 text-white')}>{item?.isCouponUsed == 1 ? "Offline" : "Online"}</h4>
											</div>
											<div className="flex justify-between pt-1.5">
												
											</div>
										</div>
									</div>
								)
							})}
						</React.Fragment>
					) : (
						<h2 className="font-medium text-2xl text-center w-full">No Result Found!</h2>
					)}
				</div>
				{orderList?.length > 0 && totalPage > 1 && (
					<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
						<Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
					</div>
				)}
			</div>
		</div>
	);
};

export default Orders;
