import React, { FC, Fragment, useState, useEffect } from "react";
import Select from 'react-select'
import AsyncSelect from 'react-select/async';

export interface Props {
  data: any;
  state: any;
  onUpdate: (str: any) => void;
  label?: string;
  placeholder?: string;
  callback: Function
}

const DropDownFilterMobile: FC<Props> = ({ data, onUpdate, state = "", label = "Sort order", callback, placeholder = 'Select any' }) => {

    const { options, isAsync = false } = data;
    const [sortOrderStates, setSortOrderStates] = useState<any>(state);

    const defaultFormat = (str: string) => (
      <div>
        {str}
      </div>
    )
  
    const formatOptionLabel = (obj: any) => {
      if ( data.formatOptionLabel ) {
        return data.formatOptionLabel(obj)
      } else {
        return defaultFormat(obj.label)
      }
    }

    const loadOptions = ( inputValue: string, callbackFn: (options: any[]) => void) => {
      setTimeout(async() => {
        const data = await callback(inputValue)
        callbackFn(data);
      }, 1000);
    };

    useEffect(() => {
        onUpdate({ [data.key]: sortOrderStates })
    },[sortOrderStates]);
    
    useEffect(() => {
        setSortOrderStates(state)
    },[state])
    
    return (
      <div className="py-7">
      <h3 className="text-xl font-medium">{label}</h3>
      <div className="mt-6 relative ">
        <div className="relative flex flex-col space-y-5">
            {
              isAsync && (
                <AsyncSelect 
                  className={`mt-1`}
                  cacheOptions 
                  loadOptions={loadOptions} 
                  placeholder={placeholder}
                  formatOptionLabel={formatOptionLabel}
                  onChange={setSortOrderStates}
                  defaultOptions />
              ) || (
                <Select
                  className={`mt-1`}
                  options={options}
                  placeholder={placeholder}
                  formatOptionLabel={formatOptionLabel}
                  onChange={setSortOrderStates}
                  defaultValue={sortOrderStates}
                />
              )
            }
        </div>
      </div>
    </div>
    )
}

export default DropDownFilterMobile;