import React, { useEffect, useRef, useState } from 'react'
import ButtonDanger from 'shared/Button/ButtonDanger'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import Select from 'react-select'
import Input from 'shared/Input/Input'
import FormItem from 'components/FormItem'
import { Controller, useForm } from 'react-hook-form'
import ButtonSecondary from 'shared/Button/ButtonSecondary'
import Pagination from "shared/Pagination/PaginationHandler"
import TabFilters from "components/Filters/TabFilters"
import { Link } from 'react-router-dom'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Transition } from '@headlessui/react'
import ItemCustomFieldImageIcon from 'components/Customfield/ItemCustomFieldImageIcon'
import Checkbox from "shared/Checkbox/nativeCheckbox"
import { usePreSaleApi } from "_common/hooks/api/preSaleApi";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import toast from 'react-hot-toast';
import PreSaleCard from './PreSaleCard'

const ActionList = [
  { value: 'approved', label: 'Approved' },
  { value: 'disapproved', label: 'Disapproved' }
];
const StatusList = [
  { name: 'Approved', id: '1' },
  { name: 'Disapproved', id: '0' }
];

const RegistrationList = ({ className = "", searchKey = "" }) => {

  const quanityRef = useRef<any>(null);

  const limit = 12;
  const { getPreSaleList, statusChangePreSale } = usePreSaleApi();
  const { getCollections, getCollectionTypes } = useCollectionApi();

  const [registrationList, setPreSalesList] = useState<any[]>([])
  const [collectionList, setCollectionList] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [selectedOperation, setSelectedOperation] = useState<any>(ActionList[0])
  const [selectedPreSales, setSelectedPreSales] = useState<number[]>([])
  const [collectionTypeList, setCollectionTypeList] = useState<any[]>([]);
  const { showLoader, hideLoader } = useAppLoader();
  const [chcekAll, setCheckAll] = useState<boolean>(false)

  const listPreSale = () => {
      let params: any = {
          usePaging: true,
          pageSize: limit,
          pageNumber: currentPage,
          isCreatedByCustomer: 1
      }
      
      if ( searchKey ) {
        params['searchText'] = searchKey;
      }
      if (filter?.dropdownFilter?.collection) {
        params.collectionID = filter.dropdownFilter?.collection['value']
      }
      if (filter?.textBoxFilter?.cryptoAddress) {
        params.cryptoAddress = filter.textBoxFilter?.cryptoAddress
      }
      if (filter?.textBoxFilter?.cryptoAddress) {
        params.cryptoAddress = filter.textBoxFilter?.cryptoAddress
      }
      if(filter?.radioBtnFiltrations?.collectionType){
				params['collectionTypeId'] = +filter.radioBtnFiltrations.collectionType;
			}
      if(filter?.radioBtnFiltrations?.status){
				params['isActive'] = +filter.radioBtnFiltrations.status;
			}
  
      showLoader();
      getPreSaleList(params, (message: string, resp: any) => {
          hideLoader();
          setPreSalesList(resp.preSaleRegistrations);
          setTotalPage(Math.ceil(resp.totalRecord / limit));
      }, (message: string) => {
          hideLoader();
          toast.error(message);
      })
  }

  const handleSelectPreSales = (check: boolean, preSaleId: number|null = null) => {
		if ( check && preSaleId ) {
			setSelectedPreSales((prevState: any) => {
				let updatedState = [ ...prevState ]
				updatedState.push(preSaleId)
				updatedState = updatedState.filter((item, index) => updatedState.indexOf(item) === index);
				if ( updatedState.length == registrationList.length ) {
					setCheckAll(true)
				} else {
					setCheckAll(false)
				}
				return updatedState;
			})
		} else if ( check && !preSaleId ) {
			const preSaleIds = registrationList.map((e: any) => e['presaleRegistrationID'])
			setSelectedPreSales(preSaleIds)
			setCheckAll(true)
		} else if ( !check && preSaleId ) {
			setSelectedPreSales((prevState: any) => {
				const updatedPreSales = [ ...prevState ];
				const index = prevState.findIndex((e: number) => e === preSaleId)
				if ( index != -1 ) {
					updatedPreSales.splice(index, 1)
					return updatedPreSales;
				} else {
					return prevState;
				}
			})
			setCheckAll(false)
		} else {
			setSelectedPreSales([])
			setCheckAll(false)
		}
	}

  const listCollection = (searchText: string = '') => {
      showLoader()
      getCollections({}, (message: string, resp: any) => {
          hideLoader();
          const data = resp.collections && resp.collections.map((e: any) => ({
              label: e['collectionName'],
              value: +e['collectionID']
          })) || []
          setCollectionList(data);
      }, (message: string, resp: any) => {
          hideLoader();
          toast.error(message)
      })
  }

  const fetchCollectionTypes = () => {
		getCollectionTypes({}, (message: string, resp: any) => {
        const data = resp.collectionTypes && resp.collectionTypes.map((e: any) => ({
          name: e['collectionType'], 
          id: ''+e['collectionTypeID']
        }))
		  	setCollectionTypeList(data)
		}, (message: string) => {
			toast.error(message)
		})
	}

  useEffect(() => {
    if (registrationList) {
        if (currentPage == 1) {
            listPreSale();
        } else {
            setCurrentPage(1);
        }
    }
  }, [searchKey]);

  useEffect(() => {
      listPreSale();
  }, [currentPage])

  useEffect(() => {
      listPreSale();
  }, [filter])

  useEffect(() => {
    listCollection();
    fetchCollectionTypes();
  }, [])

  const onSubmitListHeaderAction = () => { 
    const quantity = quanityRef.current['value'] || 0;
    console.log('quanity: ', quantity)

    if ( !selectedPreSales.length ) {
      toast.error('Please select presale record!')
    } else if ( selectedOperation['value'] === 'approved' ) {
      if ( isNaN(quantity) ) {
        quanityRef.current.focus()
        toast.error('Please enter valid quantity!')
      } else if ( quantity < 1 ) {
        quanityRef.current.focus()
        toast.error('Quantity should be greater than 0!')
      }
    } else {
      const params: any = {
        "preSaleRegistartions": selectedPreSales.map((e: number) => ({
            presaleRegistrationID: e
        })),
      }
      if ( selectedOperation['value'] === 'disapproved' ) {
        params['statusID'] = 0;
      } else if ( selectedOperation['value'] === 'approved' && quantity && !isNaN(quantity) ) {
        params['approvedQty'] = +quantity
        params['statusID'] = 1;
      }
      showLoader();
      statusChangePreSale(params, (message: string, resp: any) => {
          hideLoader();
          if ( resp.successful ) {
            toast.success(message)
            listPreSale();
          } else {
            toast.error(message)
          }          
      }, (message: string, resp: any) => {
          hideLoader();
          toast.error(message)
      })
    }
  }

  return (
    <div className={`nc-PreSale  ${className}`} data-nc-id="PreSaleList" >
      <div className="container-full py-16 lg:pb-28 lg:pt-20 space-y-20 lg:space-y-28 pl-10 pr-10">
				<main>
					<div className={`flex flex-col relative`}>
						<div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
							{registrationList.length > 0 && (
								<div className="checkalloption ml-3 ">
									<label>
										<Checkbox label="Check All" checked={chcekAll} onChange={(e: any) => {
											if (e.target.checked) {
												handleSelectPreSales(true)
											} else {
												handleSelectPreSales(false)
											}
										}} />
									</label>
								</div>
							) || (<div></div>)}
							<span className="flex flex-shrink-0 text-right items-center">
								<ButtonSecondary className="w-auto !pr-16 ml-2 border-primary-500 focus:ring-0 focus:ring-primary-200" sizeClass="pl-4 py-2.5 sm:pl-6" onClick={() => {setIsOpen(!isOpen);}}>

									<svg
										className={`w-4 h-4 sm:w-6 sm:h-6`}
										viewBox="0 0 24 24"
										fill="none"

									>
										<path
											d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M19.87 17.12L18.87 16.12"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>

									<span className="block truncate ml-2.5">Filter</span>
									<span className="absolute top-1/2 -translate-y-1/2 right-5">
										<ChevronDownIcon
											className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpen ? "rotate-180" : ""
												}`}
											aria-hidden="true"
										/>
									</span>
								</ButtonSecondary>
							</span>
						</div>

						<Transition
							show={!!selectedPreSales.length}
							enter="transition-opacity duration-150"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity duration-150"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
              <div className="grid grid-cols-1 sm:grid-cols-4 gap-5 sm:gap-2.5 mb-4">
                  <Select
                      className={`mt-1 rounded-2xl`}
                      options={ActionList}
                      placeholder={"Select any"}
                      onChange={(e: any) => setSelectedOperation(e)}
                      defaultValue={ActionList[0]}
                  />

                  {
                    selectedOperation['value'] == 'approved' && (
                      <Input placeholder={'Enter quantity'} autoComplete={'off'} ref={quanityRef} />
                    ) || ''
                  }

                  <ButtonPrimary type="button" onClick={onSubmitListHeaderAction} className="w-2/5">Submit</ButtonPrimary>
                  
              </div>
							{/* <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10  mt-8 lg:mt-10 custom-xs-col-2">
                  
                  <ButtonPrimary type="button" onClick={onSubmitListHeaderAction} className="w-2/5">Submit</ButtonPrimary>
                
							</div> */}
						</Transition>

						<Transition
							show={isOpen}
							enter="transition-opacity duration-150"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity duration-150"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
							<TabFilters name="Pre-Sale Filters"
                dropdownFilter={[
									{
										key: 'collection',
										placeholder: 'Select Collection',
										options: collectionList,
										callback: listCollection
									}
								]} 
                textBoxFilter={[{
                  key: 'cryptoAddress',
                  placeholder: 'Crypto address',
                }]}
                radioBtnFiltrations={[
                  {
                    key: 'collectionType',
                    options: collectionTypeList
                  },
                  {
                    key: 'status',
                    options: StatusList
                  }
                ]}
								onApplyFilter={setFilter} 
							/>
						</Transition>
					</div>
					<div className="grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-8 gap-y-10  mt-8 lg:mt-10 custom-xs-col-2">
						{registrationList?.map((item: any, index: any) => (
							<PreSaleCard key={index} item={item} handleSelectPreSales={handleSelectPreSales} selectedPreSales={selectedPreSales} />
						))}
					</div>

					{registrationList?.length == 0 && (
						<h2 className="mt-8 font-medium text-2xl text-center w-full">No Result Found!</h2>
					)}

					{registrationList?.length > 0 && totalPage > 1 && (
						<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
							<Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
						</div>
					)}
					
				</main>

			</div>
    </div>
  )
}

export default RegistrationList