import React, { FC } from "react";
import { getPriceIconUrl, getPriceFormat } from '_common/functions'
import NcImage from "shared/NcImage/NcImage";

export interface PricesProps {
  className?: string;
  price?: any;
  network?: any;
  contentClass?: string;
  labelTextClassName?: string;
  labelText?: string;
  size?: string;
}

const Prices: FC<PricesProps> = ({
  className = "pt-3",
  price = "000",
  size = "sm",
  network,
  contentClass = "py-1.5 md:py-2 px-2.5 md:px-3.5 text-sm sm:text-base font-semibold",
  labelTextClassName = "bg-white",
  labelText = "Price",
}) => {










  return (
    <div className={`${className}`}>


      {size == "lg" && (
        <div className="flex-1 flex flex-col sm:flex-row items-baseline p-6 border-2 border-green-500 rounded-xl relative">
          <span className="absolute bottom-full translate-y-11 py-1 px-1.5 bg-white dark:bg-neutral-900 text-sm text-neutral-500 dark:text-neutral-400">
            {labelText}
          </span>
          <span className="flex items-center text-3xl xl:text-4xl font-semibold text-green-500">
            {network ?
              <NcImage
                containerClassName="flex aspect-w-11 aspect-h-12 w-full p-6 mr-2 h-0 rounded-3xl overflow-hidden z-0"
                src={getPriceIconUrl(network)}
                className="object-contain w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
              />
              : <div className="flex aspect-w-11 aspect-h-12 w-full pt-3 pb-3 mr-0 h-0 rounded-3xl overflow-hidden z-0"></div>}
            {getPriceFormat(network, price)}
          </span>
        </div>
      )}




      {size == "sm" && (
        <div className={`flex items-baseline border-2 border-green-500 rounded-lg relative ${contentClass} `}>
          <span className={`block absolute font-normal bottom-full translate-y-11 p-1 -mx-1 text-xs text-neutral-500 dark:text-neutral-400 ${labelTextClassName}`}        >
            {labelText}
          </span>
          <span className="flex items-center text-green-500 !leading-none">
            {network ?
              <NcImage
                containerClassName="flex aspect-w-11 aspect-h-12 w-full p-3 mr-2 h-0 rounded-3xl overflow-hidden z-0"
                src={getPriceIconUrl(network)}
                className="object-contain w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
              />
              : <div className="flex aspect-w-11 aspect-h-12 w-full pt-3 pb-3 mr-0 h-0 rounded-3xl overflow-hidden z-0"></div>}
            {getPriceFormat(network, price)}
          </span>
        </div>
      )}



    </div>
  );
};

export default Prices;
