import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router";

import PageHeader from "shared/PageHeader/PageHeader";
import Details from '../../components/Customers/Details'
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import toast from "react-hot-toast";

export interface Props {
	className?: string;
}

const CustomerDetails: FC<Props> = ({ className = "" }) => {

	const { id: customerId } = useParams();

	const { customerList } = useUserApi()

	const [customerDetails, setCustomerDetails] = useState<any>(null);
	const { showLoader, hideLoader } = useAppLoader();

	useEffect(() => {
		detailsCustomer()
	}, [])

	const detailsCustomer = () => {
		let params: any = {
			customerID: customerId
		}

		console.log('params => ', params);
		showLoader();
		customerList(params, (message: string, resp: any) => {
			hideLoader();
			if ( resp.customers && resp.customers.length ) {
				setCustomerDetails(resp.customers[0]);
			} else {
				toast.error('Customer not found!')
			}
		}, (message: string) => {
			hideLoader();
			toast.error(message)
		})
	}

	return (
		<div className={`nc-PageCollection  ${className}`} data-nc-id="CategoryList" >
			<Helmet>
				<title>Customer Details</title>
			</Helmet>

			<Details data={customerDetails} />
			{/* <div className="container relative">
				<div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
					<PageHeader title="Customer details" />
					<div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
					<Details data={customerDetails} />
				</div>
			</div> */}
		</div>
	);
};

export default CustomerDetails;
