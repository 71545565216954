import React, { FC } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import PriceText from "./PriceText";
import DummyImage from '../images/nfts/1.png';

export interface CardNFTProps {
	className?: string;
	isLiked?: boolean;
	isNft?: boolean;
	data: any;
}

const ProductNFT: FC<CardNFTProps> = ({ data, className = "", isLiked, isNft = false }) => {

	const renderAvatars = () => {
		return (
			<div className="flex -space-x-1 ">
				<Avatar
					containerClassName="ring-2 ring-white dark:ring-neutral-900"
					sizeClass="h-5 w-5 text-sm"
				/>
				<Avatar
					containerClassName="ring-2 ring-white dark:ring-neutral-900"
					sizeClass="h-5 w-5 text-sm"
				/>
				<Avatar
					containerClassName="ring-2 ring-white dark:ring-neutral-900"
					sizeClass="h-5 w-5 text-sm"
				/>
				<Avatar
					containerClassName="ring-2 ring-white dark:ring-neutral-900"
					sizeClass="h-5 w-5 text-sm"
				/>
			</div>
		);
	};


	const getPrice = () => {
		if (data.productPrice.length) {
			let activePrice = data.productPrice.filter((price: any) => price.isActive == 1);
			return activePrice.length ? activePrice[0].cryptoStartPrice : 'NA'
		} else {
			return 'NA';
		}

	}


	return (
		<div
			className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md ${className}`}
			data-nc-id="CardNFT"
		>
			<div className="relative flex-shrink-0 ">
				<div>
					<NcImage
						containerClassName="flex aspect-w-11 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0 rounded-b-none"
						src={isNft ? data.thumbUrl : data.productThumbUrl}
						className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
					/>
				</div>
				<div className="absolute top-3 inset-x-3 flex"></div>
			</div>

			<div className="p-4 py-4 space-y-3">
				<div className="flex justify-between">
					<span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-gray-100 dark:bg-neutral-700 relative">
						{isNft ? data.nftid : data?.collectionName}
					</span>
				</div>
				<h2 className={`text-lg font-medium mt-0`}>
					{isNft ? data.name : data?.productName}
				</h2>
				{!isNft ?
					<div className="flex justify-between items-end ">
						<PriceText price={data?.cryptoAmount} network={data?.network} />
					</div>
					: null
				}
			</div>

			<Link to={isNft ? `/nft/${data?.nftid}` : "/product/" + data.productID} className="absolute inset-0"></Link>
		</div>
	);
};

export default ProductNFT;
