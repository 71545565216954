import React, { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from 'react-router';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import toast from 'react-hot-toast';

import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";

import { SOCIALS_ARRAY, REG_EXP } from '../../_common/_constant'
import { useAppLoader } from "_common/hooks/common/appLoaderHook";

import { useCollectionApi } from '../../_common/hooks/api/collectionApiHook'
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface CollectionSocialsProps {
    className?: string;
}

const Socials: FC<CollectionSocialsProps> = ({ className = "" }) => {

    const { id: collectionId } = useParams();
    const navigate = useNavigate()
    const { getCollections, updateSocials } = useCollectionApi()
    const { showLoader, hideLoader } = useAppLoader();

    const [collectionDetails, setCollectionDetails] = useState(null)
    const [collectionSocials, setCollectionSocials] = useState([])
    const { register, control, getValues, watch, setError, clearErrors, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            socials: SOCIALS_ARRAY
        }
    });
    const { fields: socialFields } = useFieldArray({
        control,
        name: "socials"
    });

    useEffect(() => {
        fetchCollection()
    }, [])

    useEffect(() => {
        if (collectionSocials.length) {
            let temp = JSON.parse(JSON.stringify(SOCIALS_ARRAY));
            collectionSocials.map((item: any, index: number) => {
                if (temp[index] && temp[index]['socialKey'] === item['socialKey']) {
                    temp[index].socialKeyValue = item.socialKeyValue;
                }
            });
            reset({
                socials: temp,
            });
        } else {
            reset({
                socials: SOCIALS_ARRAY,
            });
        }
    }, [collectionSocials]);

    const fetchCollection = () => {
        if (collectionId) {
            getCollections({ collectionID: collectionId },
                (message: string, resp: any) => {
                    const data = resp && resp.collections && resp.collections.length && resp.collections[0] || null;
                    if (data) {
                        const socialURLs = data && data['socialURL'] || []
                        setCollectionDetails(data)
                        setCollectionSocials(socialURLs)
                    } else {
                        toast.error('Collection not found!')
                        navigate('/collection')
                    }
                },
                (message: string, resp: any) => {
                    console.log('error resp: ', resp)
                    toast.error(message)
                })
        } else {
            toast.error('Collection ID not found!')
            navigate('/collection')
        }
    }

    const socialSubmitted = (data: any) => {
        if (collectionId) {
            showLoader()
            const params = {
                collectionID: collectionId,
                socialURL: data && data.socials && data.socials.length && data.socials.map((social: any) => ({
                    socialKey: social['socialKey'],
                    socialKeyValue: social['socialKeyValue']
                })) || []
            }

            updateSocials(params,
                (message: string, resp: any) => {
                    hideLoader()
                    toast.success(message)
                },
                (message: string, resp: any) => {
                    hideLoader()
                    console.log('error resp: ', resp)
                    toast.error(message)
                })
        } else {
            toast.error('Collection ID not found!')
        }
    }

    const handleCancel = () => {
        navigate('/collection')
    }

    return (
        <form onSubmit={handleSubmit(socialSubmitted)}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5">
                {socialFields.map((field, index: number) => (
                    <label className="block" key={index}>
                        <Label>{SOCIALS_ARRAY[index].label}</Label>
                        <Input
                            placeholder={SOCIALS_ARRAY[index].placeholder}
                            type="text"
                            className="mt-1"
                            {...register(`socials.${index}.socialKeyValue`, {
                                pattern: {
                                    value: REG_EXP.VALID_URL,
                                    message: `Invalid ${SOCIALS_ARRAY[index].placeholder}`
                                }
                            })}
                        />
                        {
                            errors && errors.socials && errors.socials[index] && errors.socials[index]?.socialKeyValue && errors.socials[index]?.socialKeyValue?.message &&
                            (<p className='text-red-400'>
                                {errors.socials[index]?.socialKeyValue?.message}
                            </p>)
                        }
                    </label>

                ))}
            </div>


            <div className="w-full border-b-2 mb-5 mt-5 border-neutral-100 dark:border-neutral-700 mt-10 mb-10"></div>
            {/* <div className="text-center">
                <ButtonPrimary type="submit">Save</ButtonPrimary>
            </div> */}
            <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
                <ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
                <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Cancel</ButtonSecondary>
            </div>
        </form>
    );
};

export default Socials;
