import React from "react";
import FloatSearchBox from './FloatSearchBox'

export interface props {
    title: string;
    onSearch: any;
    searchKey?:string;
}

const PageHeaderWithSearch: React.FC<props> = ({title, onSearch, searchKey=""}) => {
  return (
        <React.Fragment>
            <div className={`nc-HeadBackgroundCommon h-24 2xl:h-28 pt-6 top-0 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 pb-24`} >
                <h1 className={"text-2xl sm:text-3xl lg:text-4xl font-semibold text-center"}>{title}</h1>
            </div>
            <FloatSearchBox onSearch={onSearch} searchKey={searchKey} />
        </React.Fragment>
    );
};

export default PageHeaderWithSearch;