import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import VerifyIcon from "components/VerifyIcon";
import LikeButton from "../../components/LikeButton";
import AccordionInfo from "./AccordionInfo";
import NoUserImage from "images/avatars/Image-1.png";
import NoImage from "images/placeholder-small.png";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useParams, Link } from "react-router-dom";
import { useAuthStatus } from "_common/hooks/authHook";
import NftMoreDropdown from "components/NftMoreDropdown";
import { toast } from "react-hot-toast";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useSelector } from "react-redux";
import { StateExtended } from "_common/interfaces/StateExtended";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { HiOutlineEyeOff } from "react-icons/hi";
import { AiOutlineWarning } from "react-icons/ai";
import NcModal from "shared/NcModal/NcModal";
import FavoriteButton from "components/FavoriteButton";
import ProductFile from "./ProductFile";
import ProductFileThumb from "./ProductFileThumb";
import NcShareDropDown from "shared/NcDropDown/NcShareDropDown";
import ViewCount from "components/ViewCount";
import Details from "./Details";
import { useSiteSettingsApi } from "_common/hooks/api/siteSettingsApiHook";
import { useSiteSettingsSelector } from "_common/hooks/selectors/siteSettingsSelector";
import AccordionDetails from "./AccordionDetails";
import ActivitiesHistory from "./ActivitiesHistory";
import QRCode from "react-qr-code";

const ActualNftDetailsPage: any = () => {
	const isAuth = useAuthStatus();
	const settings: any = useSiteSettingsSelector();

	const { id } = useParams();
	const productApi = useProductApi();
	const commonApi = useCommonApi();
	const userApi = useUserApi();
	const collectionApi = useCollectionApi();
	const siteSettingsApi = useSiteSettingsApi();
	const { showLoader, hideLoader } = useAppLoader();


	const [product, setProduct] = useState<any>(null);
	const [moreoptions, setMoreoptions] = useState<any>([]);
	const [isfollowed, setIsfollowed] = React.useState(false);
	const [followdata, setFollowdata] = useState<any>(null);
	const [totalFollowers, setTotalFollowers] = useState<any>(0);
	const [followerCount, setFollowerCount] = useState<number>(0);
	const [favouriteCount, setFavouriteCount] = useState<number>(0);
	const [likeCount, setLikeCount] = useState<number>(0);
	const [createdBy, setCreatedBy] = useState<any>(null);
	const [collection, setCollection] = useState<any>(null);
	const [productfiles, setProductFiles] = useState<any[] | null>(null);
	const [selectedProductFile, setSelectedProductFile] = useState<any>(null);
	const [showBlurFile, setShowBlurFile] = useState<boolean>(false)
	const [showSuspiciousMsg, setShowSuspiciousMsg] = useState<boolean>(false);
	const [viewCount, setViewCount] = useState<number>(0);
	const [owners, setOwners] = useState<any>(null);
	const [show, setShow] = useState<boolean>(false);



	const user: any = useSelector((state: StateExtended) => state.user);

	const getSiteSettings = () => {
		siteSettingsApi.getSiteSettings({}, (message: any, resp: any) => { }, (message: any) => { })
	}

	const getFollowUnfollow = () => {
		showLoader();
		commonApi.getFollowunfollow({
			itemType: 3,
			itemID: id,
			addedBy: (isAuth) ? user.user.userID : null,
			authrequired: isAuth
		}, (message: string, resp: any) => {
			hideLoader();
			if (resp.code === 200 && resp.successful === true && resp.followUnfollows.length > 0) {
				setIsfollowed(true);
				setFollowdata(resp.followUnfollows[0]);
				setTotalFollowers(resp.totalRecord);
				console.log({ updateMoreOption: resp })
				if (isAuth) {
					setMoreoptions([{
						id: "unfollow",
						name: `(${followerCount} followers) Unfollow`,
						icon: "las la-times-circle",
					}, {
						id: "report",
						name: "Report abuse",
						icon: "las la-flag",
					}]);
				} else {
					setMoreoptions([{
						id: "nofollow",
						name: `(${followerCount}) Follower(s)`,
						icon: "las la-check-circle",
					}, {
						id: "report",
						name: "Report abuse",
						icon: "las la-flag",
					}]);
				}
			} else {
				setIsfollowed(false);
				setFollowdata(null);
				setTotalFollowers(0);
				if (isAuth) {
					setMoreoptions([{
						id: "follow",
						name: `(${followerCount} followers) Follow`,
						icon: "las la-check-circle",
					}, {
						id: "report",
						name: "Report abuse",
						icon: "las la-flag",
					}])
				} else {
					setMoreoptions([{
						id: "nofollow",
						name: `(${followerCount}) Follower(s)`,
						icon: "las la-check-circle",
					}, {
						id: "report",
						name: "Report abuse",
						icon: "las la-flag",
					}]);
				}
			}
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

	const applyFollowUnfollow = (value: number) => {
		if (!isAuth) {
			toast.error('please login to follow')
		} else {
			showLoader();
			commonApi.postFollowunfollow({
				itemType: 3,
				itemID: id,
				isFollow: value,
			}, (message: string, resp: any) => {
				hideLoader();
				getFollowUnfollow();
				getProduct()
			}, (message: string) => {
				hideLoader();
				console.log(message);
			});
		}
	}

	const getProduct = () => {
		showLoader();
		productApi.getMyNft({ nftid: id }, (message: string, resp: any) => {
			hideLoader();
			setProduct(resp.nfTs[0]);
			// getCollectionFollowUnFollow();
			setFavouriteCount(resp?.totalFavouriteCount)
			setLikeCount(resp?.totalLikeCount)
			setFollowerCount(resp?.totalFollowCount)
			setViewCount(resp.totalViewCount)
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

	const getPrice = () => {
		if (product.nftPrice.length) {
			let activePrice = product.nftPrice.filter((price: any) => price.isActive == 1);
			// if (cartProduct) {
			// 	return activePrice.length ? inCart ? activePrice[0].cryptoStartPrice * cartProduct.__cart_quantity : activePrice[0].cryptoStartPrice : 'NA'
			// }
			// return activePrice.length ? inCart ? activePrice[0].cryptoStartPrice * 1 : activePrice[0].cryptoStartPrice : 'NA'
		} else {
			return 'NA';
		}
	}


	// const getCreatedBy = () => {
	// 	userApi.getUserProfile({ customerID: product.addedBy }, (message: string, resp: any) => {
	// 		if (resp.customers.length) {
	// 			setCreatedBy(resp.customers[0]);
	// 		}
	// 	}, (message: string) => {
	// 		console.log(message);
	// 	})
	// }

	const getCollection = () => {
		collectionApi.getCollections({ collectionID: product.collectionID }, (message: string, resp: any) => {
			setCollection(resp.collections[0])
		}, (message: string) => {
			console.log(message);
		})
	}

	const collectProductFiles = () => {
		let files: any = [];
		let prodFile = {
			file: product.nftFileUrl,
			thumb: product.nftThumbUrl,
			mimeType: product.mimeType
		}
		files.push(prodFile);
		setSelectedProductFile(prodFile)
		if (product.isShowPreviewInFrontend) {
			product?.productPreview.map((preview: any) => {
				files.push({
					file: preview.fileUrl,
					thumb: preview.thumbFileUrl,
					mimeType: preview.mimeType
				});
			})
		}
		setProductFiles(files)
	}

	const getOwnerByNft = () => {
		showLoader();
		productApi.getOwnerByNft({ nftid: id }, (message: string, resp: any) => {
			hideLoader();
			// console.log("nft====>",{ message, resp })
			setOwners(resp.owners)
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

	const onCloseModal = () => {
		setShow(false)
	}

	const renderContent = () => {
		return (
			<React.Fragment>
				{owners && owners.length > 0 && owners.map((item: any, index: number) =>
				(
					<div key={index}>
						<h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
							{`${item?.firstName} ${item?.lastName}`}
						</h3>
						<span className="text-sm">
							{`${item?.emailID}`}
						</span>
					</div>
				))
				}
			</React.Fragment>
		)
	};

	const renderTrigger = () => {
		return null;
	};

	useEffect(() => {
		getSiteSettings();
		getProduct();
		getOwnerByNft()
	}, []);

	useEffect(() => {
		if (settings && Object.keys(settings).length && product) {
			const spamSettings = settings && settings['siteSettings'] && settings['siteSettings'].filter((e: any) => e['siteGroupName'] == "Spam") || []
			if (spamSettings.length) {
				const banItemSettings = spamSettings[0]['keyValue'].filter((e: any) => e["MaxReporttoBanItemSpam"]) || []
				const blurItemSettings = spamSettings[0]['keyValue'].filter((e: any) => e["MaxReporttoBlurItemSpam"]) || []

				if (banItemSettings.length && +banItemSettings[0]['appValue'] <= product['reportedSpamCount']) {
					setShowSuspiciousMsg(true)
				} if (blurItemSettings.length && +blurItemSettings[0]['appValue'] <= product['reportedSpamCount']) {
					setShowBlurFile(true)
				}
			}
		}
	}, [settings, product]);

	const removeBlurEffect = () => {
		setShowBlurFile(false)
	}

	useEffect(() => {
		if (product) {
			console.log('Product=>', product);
			collectProductFiles();
			// getCreatedBy();
			getCollection();
			getFollowUnfollow();
		}
	}, [product]);

	// useEffect(() => {
	// 	if (inCart) {
	// 		setCartProduct(cart.filter((item: any) => item.productID == id)[0])
	// 	} else {
	// 		setCartProduct(null)
	// 	}
	// }, [inCart, cart]);


	return (
		<React.Fragment>
			<Helmet>
				<title>{product?.name}</title>
				<meta name="description" content={product?.description} />
			</Helmet>

			<div className={`nc-ActualNftDetailsPage`} data-nc-id="NftDetailPage">
				{/* MAIn */}
				<main className="container mt-11 flex pl-10 pr-10 mb-11">
					<div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-10 md:gap-14">
						{/* CONTENT */}
						<div className="space-y-8 lg:space-y-10">
							<div className={"bg-gray-100 dark:bg-neutral-800 overflow-hidden relative rounded-2xl w-full" + (showBlurFile ? ' spam-product-card' : '')}>
								<div className="center-icon w-full">
									{
										showBlurFile && (
											<div className="spam-overlay spam-overlay-rounded-full">
												<HiOutlineEyeOff />
												<p className="text-sm mb-4">Inappropriate content.</p>
												<ButtonSecondary type="button" className="pt-1 pb-1 py-0" onClick={removeBlurEffect}>view</ButtonSecondary>
											</div>
										) || ''
									}
									<ProductFile file={selectedProductFile} />
								</div>
								{product && (<FavoriteButton className="absolute left-3 top-3 " type="NFT" _id={product?.nftid} favouriteCount={favouriteCount} getProduct={getProduct} isAuth={isAuth} />)}
								{product && (<LikeButton className="absolute right-3 top-3 " type="NFT" _id={product?.nftid} likeCount={likeCount} getProduct={getProduct} isAuth={isAuth} />)}
							</div>
							{product?.isShowPreviewInFrontend ?
								<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-4 mt-4 lg:mt-10 custom-xs-col-2">
									{productfiles?.map((file: any, i: any) => (
										<ProductFileThumb key={i} file={file} selected={selectedProductFile == file} onClick={setSelectedProductFile} />
									))}
								</div>
								: null}
							<div className="space-y-2 lg:space-y-2">
								{product && (<AccordionInfo product={product} showListing={false} />)}
								{product && (<AccordionDetails product={product} />)}
								{product && (<ActivitiesHistory product={product} />)}
							</div>

						</div>

						{/* SIDEBAR */}
						<div className="pt-10 lg:pt-0 xl:pl-10 border-t-2 border-neutral-200 dark:border-neutral-700 lg:border-t-0">
							<div className="divide-y divide-neutral-100 dark:divide-neutral-800">
								{/* ---------- 1 ----------  */}
								<div className="pb-9 space-y-5">
									{
										showSuspiciousMsg ?
											<div className="bg-pink-100 flex font-medium inline-flex justify-center nc-Badge px-2.5 py-1 relative rounded-full text-center text-pink-800 w-full" role="">
												<AiOutlineWarning size={20} /> &nbsp; <p>Reported for suspicious activity!</p>
											</div> : ''
									}

									<div className="flex justify-between items-center">
										<div>
											<Badge name={product?.categoryName} color="blue" />
										</div>
										<div className="flex">
											<NcShareDropDown
												className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer mx-2"
												panelMenusClass="origin-top-right !-right-5 !w-40 sm:!w-52"
											/>
											<NftMoreDropdown
												type_id={3}
												actions={moreoptions}
												containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer ml-3"
												onClick={(data: any) => {
													if (data === 'follow') {
														applyFollowUnfollow(1);
													}

													if (data === 'unfollow') {
														applyFollowUnfollow(0);
													}

													if (data === 'nofollow') {
														applyFollowUnfollow(0);
													}
												}}
											/>
										</div>

									</div>
									<div>
										<h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-3 flex gap-2 items-center">
											{product?.name}
										</h2>
										{product?.isFeatured ? (<Badge name={"Featured"} color="green" />) : null}
									</div>

									{/* ---------- 4 ----------  */}
									<div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-8 text-sm">
										<div className="flex items-center ">
											{owners && owners.length == 0 || owners && owners.length == 1 ?
												<>
													<Avatar imgUrl={owners && owners.length == 1 && createdBy?.profileImageUrl ? createdBy?.profileImageUrl : NoUserImage} sizeClass="h-9 w-9" radius="rounded-full" />
													<span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
														<span className="text-sm">Author</span>
														<span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
															{owners && owners.length == 1 ?
																<Link to={`/author/${product?.addedBy}`}><span>{`${owners[0]?.firstName} ${owners[0]?.lastName}`}</span></Link>
																:
																<span>Unknown</span>}
														</span>
													</span>
												</>
												:
												<>
													<Avatar imgUrl={NoUserImage} sizeClass="h-9 w-9" radius="rounded-full" />
													<span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col cursor-pointer" onClick={() => setShow(!show)}>
														<span className="text-sm">Owners</span>
														<span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
															<span>{`${owners && owners.length}`}</span>
														</span>
													</span>
												</>
											}
										</div>
										<div className="hidden sm:block h-6 border-l border-neutral-200 dark:border-neutral-700"></div>
										<div className="flex items-center">
											<Avatar
												imgUrl={collection?.logoUrl ? collection?.logoUrl : NoImage}
												sizeClass="h-9 w-9"
												radius="rounded-full"
											/>
											<span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
												<span className="text-sm">Collection</span>
												<span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
													<Link to={`/collection/${product?.collectionID}`} className={`flex items-center`}><span>{product?.collectionName}</span> {collection?.isVerify ? <VerifyIcon iconClass="w-6 h-6" /> : null} </Link>

												</span>
											</span>
										</div>
									</div>
									<ViewCount count={viewCount} />
								</div>

								{
									product && product['nftguid'] && (
										<div className="pb-9 space-y-5">
											<QRCode value={product['nftguid']} />
										</div>
									) || ''
								}

								<div className="pt-9">
									{product && (<Details product={product} />)}
								</div>

								{/* ---------- 9 ----------  */}

							</div>
						</div>
					</div>
				</main>
			</div>
			<NcModal
				isOpenProp={show}
				onCloseModal={onCloseModal}
				contentExtraClass="max-w-screen-sm"
				renderContent={renderContent}
				renderTrigger={renderTrigger}
				modalTitle="Owner List"
			/>
		</React.Fragment>
	);
};

export default ActualNftDetailsPage;
