import React, { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import NcImage from "shared/NcImage/NcImage";
import CardNFT from "components/CardNFT";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import collectionBanner from "images/nfts/collectionBanner.png";
import { nftsImgs } from "contains/fakeData";
import NftMoreDropdown from "components/NftMoreDropdown";
import ButtonDropDownShare from "components/ButtonDropDownShare";
import TabFilters from "components/TabFilters";
import SectionSliderCollections from "components/SectionSliderCollections";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useNavigate, useParams } from "react-router";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select'
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { toast } from "react-hot-toast";
import { URLS } from "_config";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import InputFile from "shared/InputFile/InputFile";
import PageHeader from "shared/PageHeader/PageHeader";
import { utcToLocal, localToUtc, CheckNumber, getSelectedOption } from "_common/functions";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Switch from "shared/Switch/Switch";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import Checkbox from "shared/Checkbox/Checkbox";

export interface Props {
	className?: string;
}

const AddUpdateCategory: FC<Props> = ({ className = "" }) => {

	const { id } = useParams();
	const commonApi = useCommonApi();
	const productApi = useProductApi();
	const { register, handleSubmit, setValue, formState: { errors }, control, reset } = useForm();
	const { showLoader, hideLoader } = useAppLoader();
	const navigate = useNavigate();
	const [categories, setCategories] = useState<any>(null);
	const [category, setCategory] = useState<any>(null);

	const inputFile = useRef();

	const getCategories = () => {
		productApi.getCategories({}, (message: string, resp: any) => {
			setCategories([{
				label: '(root)',
				value: 0
			}, ...resp.categorydm.map((cat: any) => {
				cat.label = cat.categoryName;
				cat.value = cat.categoryID;
				return cat;
			})]);


		}, (message: string) => {
			console.log(message);
		})
	}

	const mapCategoryImage = (id: any, file: any) => {
		return new Promise((resolve: any, reject: any) => {
			let data: any = new FormData();
			data.append("", file);
			data.append("ItemId", id);
			data.append("ItemType", 3);
			data.append("FileType", 'icon');

			commonApi.fileUpload(data,
				(message: string, resp: any) => {
					resolve(message)
				}, (message: string) => {
					reject(message)
				})
		})

	}

	const addUpdateCategory = (data: any) => {
		console.log(data);
		//return;
		showLoader()
		let params: any = {
			"categoryID": id == 'new' ? 0 : id,
			"categoryName": data.categoryName,
			"parentCategoryID": data.parentCategoryID.value,
			"categoryIconUrl": '',
			"isActive": data.isActive? 1: 0
		}
		productApi.addUpdateCategory(params,
			(message: string, resp: any) => {
				if (data.categoryIconUrl.length) {
					mapCategoryImage(resp.returnID, data.categoryIconUrl[0]).then((msg: any) => {
						hideLoader();
						toast.success(message);
						navigate(URLS.CATEGORIES)
					}).catch((msg: any) => {
						toast.success(message);
						toast.error(msg);
						navigate(URLS.CATEGORIES)
						hideLoader();
					})
				} else {
					hideLoader();
					toast.success(message);
					navigate(URLS.CATEGORIES)
				}

			}, (message: string) => {
				hideLoader();
				toast.error(message)
			})
	}

	const getCategory = (id: any) => {
		showLoader();
		productApi.getCategories({ categoryID: id },
			(message: string, resp: any) => {
				hideLoader();
				setCategory(resp.categorydm[0]);
				reset({
					categoryName: resp.categorydm[0].categoryName,
					parentCategoryID: getSelectedOption(categories, ['value'], resp.categorydm[0].parentCategoryID),  //resp.categorydm[0].parentCategoryID
					isActive: resp.categorydm[0].isActive === 1? true: false
				})
			}, (message: string) => {
				hideLoader();
				console.log(message);
			})
	}

	useEffect(() => {
		setValue('isActive', true);
		getCategories();
	}, []);

	useEffect(() => {
		console.log('categories =>', categories)
		if (categories) {
			if (id == 'new') {
				reset({
					parentCategoryID: 0
				})
			} else {
				getCategory(id)
			}
		}
	}, [categories]);

	const resetForm = () =>{
		reset()
	}


	return (
		<div className={`nc-PageCollection  ${className}`} data-nc-id="CategoryList" >
			<Helmet>
				<title>{id == 'new' ? 'Add' : 'Update'} Category</title>
			</Helmet>



			<div className="container">
				<div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
					<PageHeader title={(id == 'new' ? 'Add' : 'Update') + ' Category'} subtitle="" />
					<div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>

					<form onSubmit={handleSubmit(addUpdateCategory)}>
						<div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5">
							<label className="block mb-2">
								<Label>Name</Label>
								<Input
									placeholder="Example Doe"
									type="text"
									className="mt-1"
									{...register("categoryName", { required: true, maxLength: 80 })}
								/>
							</label>

							<label className="block  mb-2">
								<Label>Parent Category</Label>
								<Controller
									name="parentCategoryID"
									control={control}
									rules={{ required: false }}
									render={({ field: { value, onChange, onBlur } }) => (
										<Select
											className={`mt-1`}
											options={categories ? categories : []}
											placeholder={""}
											onChange={onChange}
											value={value}
											defaultValue={value}
										/>
									)}
								/>
							</label>

							<label className="block  mb-2">
								<Label className="block mb-2">Icon Image</Label>
								<InputFile  {...register("categoryIconUrl", { required: false })} uploadedUrl={category?.categoryIconUrl} onCanPlayCapture={(e: any) => { if (e == null) { setValue('categoryIconUrl', ''); } }} />
							</label>
							<div>
								<Switch label="Is Active" value="true"  {...register("isActive", { required: false })}  />
							</div>
							{/*
							<span></span>
							*/}
						</div>

						<div className="w-full border-b-2 mb-5 mt-5 border-neutral-100 dark:border-neutral-700 mt-10 mb-10"></div>

						<div className="text-center flex gap-5 justify-center gap-3">
							<ButtonPrimary type="submit">{(id == 'new') ? 'Create Category' : 'Update Category'}</ButtonPrimary>
							<ButtonSecondary type="button" onClick={resetForm}>Reset</ButtonSecondary>
						</div>
					</form>
					
				</div>
			</div>
		</div>
	);
};

export default AddUpdateCategory;
