import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Web3 from 'web3';
import Select from 'react-select';

import Pagination from "shared/Pagination/PaginationHandler";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import TabFilters from "components/Filters/TabFilters";
import { useProductApi } from "_common/hooks/api/productApiHook";
import CollectionCard from "components/collection/CollectionCard";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import PageHeaderWithSearch from "shared/PageHeaderWithSearch/PageHeaderWithSearch";
import Checkbox from "shared/Checkbox/nativeCheckbox";
import { Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import NcCustomModal from "components/Modal/NcCustomModal";
import ConnectWallet from '../../components/Crypto/ConnectWallet'
import SwitchNetwork from '../../components/Crypto/SwitchNetwork'
import ContractBalance from '../../components/Crypto/ContractBalance'
import LinkBalance from '../../components/Crypto/LinkBalance'
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useSiteSettingsApi } from '_common/hooks/api/siteSettingsApiHook';
import { useUserApi } from '_common/hooks/api/UserApiHook';
import { CONTRACT_META_BASE_PATH, API_NODE_API_KEY, CONTRACT_DEPLOY_NEW_TOKEN_URL, CONTRACT_DEPLOY_NEW_CONTRACT_URL, CONTRACT_DEPLOY_ORDER_API_BASE_URL, CONTRACT_DEPLOY_REDEEM_API_BASE_URL, CONTRACT_DEPLOY_PRESALE_API_BASE_URL } from "../../_config/index"
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { USER_TYPE, CONTRACT_DEPLOYED_STATUS } from '../../_common/_constant'

import useMetaMask from "../../_common/provider/metamask-provider";
import { useUserSelector, useUserCryptoDetails } from '../../_common/hooks/selectors/userSelector'
import { useBlockchainNetworksSelector } from "_common/hooks/selectors/commonSelector";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import EditFees from "components/FeesUpdate/feesUpdate";
import { ITEM_TYPE } from "_common/_constant";

const selectoperations = [
	{ value: 'published', label: 'Published' },
	{ value: 'unpublished', label: 'Un Published' },
	{ value: 'feature', label: 'Feature' },
	{ value: 'nonfeature', label: 'Non Feature' },
];

export interface Props {
	className?: string;
}
const CollectionCardViewSection = {
	toSelectCheckbox: false,
	editIcon: false,
	sendLink: (data: any) => ![...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+data['contractStatus']),
	viewLinkBalance: (data: any) => ![...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+data['contractStatus']),
	deployContract: (data: any) => [...CONTRACT_DEPLOYED_STATUS.INITIATE, ...CONTRACT_DEPLOYED_STATUS.IN_PROCESS, ...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+data['contractStatus']),
	contractBalance: (data: any, custodialWalletSettings: any) => ![...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+data['contractStatus']) || custodialWalletSettings['isEnabled'],
	withdrawContractBalance: (data: any, custodialWalletSettings: any) => ![...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+data['contractStatus']) || custodialWalletSettings['isEnabled'],
	transferContractBalance: (data: any, custodialWalletSettings: any) => ![...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+data['contractStatus']) || custodialWalletSettings['isEnabled'],
	editFees: true,
	deleteIcon: false,
	viewCollection: true
}

const CategoryList: FC<Props> = ({ className = "" }) => {

	const { chainId, account } = useMetaMask()
	const { ethereum }: any = window;

	const { getCollectionTypes, deployContractThroughAPI } = useCollectionApi();
	const { getStoreConfiguration } = useUserApi();
	const productApi = useProductApi();
	const commonApi = useCommonApi();
	const collectionApi = useCollectionApi();
	const userDetails: any = useUserSelector()
	const blockchainNetworks = useBlockchainNetworksSelector();
	const [collections, setCollections] = useState<any>(null);
	const [blockchainNetworksOption, setBlockchainNetworksOption] = useState<any[]>([]);
	const [collectionTypeList, setCollectionTypeList] = useState([]);
	const [networkList, setNetworkList] = useState([]);
	// for pagination
	const limit: number = 12;
	const { showLoader, hideLoader } = useAppLoader();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState<number>(1);
	const [searchKey, setSearchKey] = useState<string>('');
	const [isOpen, setIsOpen] = React.useState(false);
	const [isShowWalletModal, setIsShowWalletModal] = useState(false);

	const [isShowModal, setIsShowModal] = useState(false);
	const [modalTitle, setModalTitle] = useState<string>('');

	// const [isShowContractBalanceModal, setIsShowContractBalanceModal] = useState(false);
	const [filter, setFilter] = useState<any>({});
	const [modalContent, setModalContent] = useState<any>('')
	const [chcekAll, setCheckAll] = useState<boolean>(false)
	const [selectedCollections, setSelectedCollections] = useState<number[]>([])
	const [collectionToUpdateFees, setCollectionToUpdateFees] = useState<any | null>(null)
	const [isFeesModalOpen, setIsFeesModalOpen] = useState(false);
	const [selectedOperation, setSelectedOperation] = useState<any>(selectoperations[0])
	const [isFiatEnabled, setIsFiatEnabled] = useState<boolean>(false)
	const [custodialWalletSettings, setCustodialWalletSettings] = useState<any>({
		isEnabled: false,
		custodialWalletAddress: ''
	})

	useEffect(() => {
		console.log('collection list account: ', account, ' chian: ', chainId)
	}, [account, chainId])

	const filterBy = [
		{ name: "isActive", id: "isActive" },
		{ name: "isPublished", id: "isPublished" },
		{ name: "isFetured", id: "isFetured" },
		{ name: "isEnabledPresale", id: "isEnabledPresale" },
		{ name: "isEnablePresaleRegistration", id: "isEnablePresaleRegistration" },
		{ name: "isEnableGenesis", id: "isEnableGenesis" },
		{ name: "isBanned", id: "isBanned" },
		{ name: "isCreatedByCustomer", id: "isCreatedByCustomer" }
	];

	const sortBy = [
		{ name: "Name (ASC)", id: "name-asc" },
		{ name: "Name (DESC)", id: "name-desc" },
		{ name: "Category (ASC)", id: "category-asc" },
		{ name: "Category (DESC)", id: "category-desc" }
	];

	const handleSelectCollections = (check: boolean, collectionId: number | null = null) => {
		if (check && collectionId) {
			setSelectedCollections((prevState: any) => {
				let updatedState = [...prevState]
				updatedState.push(collectionId)
				updatedState = updatedState.filter((item, index) => updatedState.indexOf(item) === index);
				if (updatedState.length == collections.length) {
					setCheckAll(true)
				} else {
					setCheckAll(false)
				}
				return updatedState;
			})
		} else if (check && !collectionId) {
			const collectionIds = collections.map((e: any) => e['collectionID'])
			setSelectedCollections(collectionIds)
			setCheckAll(true)
		} else if (!check && collectionId) {
			setSelectedCollections((prevState: any) => {
				const updatedCollecions = [...prevState];
				const index = prevState.findIndex((e: number) => e === collectionId)
				if (index != -1) {
					updatedCollecions.splice(index, 1)
					return updatedCollecions;
				} else {
					return prevState;
				}
			})
			setCheckAll(false)
		} else {
			setSelectedCollections([])
			setCheckAll(false)
		}
	}

	const getCollections = () => {
		let params: any = {
			usePaging: true,
			pageSize: limit,
			pageNumber: currentPage
		}
		showLoader();
		if (filter?.radioBtnFiltrations) {
			if (filter.radioBtnFiltrations.filter) {
				params[filter.radioBtnFiltrations.filter] = 1;
			}
			if (filter.radioBtnFiltrations.collectionType) {
				params['collectionTypeId'] = +filter.radioBtnFiltrations.collectionType;
			}
			if (filter.radioBtnFiltrations.network) {
				params['blockchainNetworkID'] = +filter.radioBtnFiltrations.network;
			}

			switch (filter.radioBtnFiltrations.sortBy) {
				case 'name-asc':
					params.sortingBy = 'collectionName';
					params.sortingOrder = 'asc';
					break;
				case 'name-desc':
					params.sortingBy = 'collectionName';
					params.sortingOrder = 'desc';
					break;
				case 'category-asc':
					params.sortingBy = 'categoryID';
					params.sortingOrder = 'asc'; // desc
					break;
				case 'category-desc':
					params.sortingBy = 'categoryID';
					params.sortingOrder = 'desc'; // desc
					break;
				default:
			}
		}

		if (searchKey) {
			params.searchText = searchKey
		}

		console.log('params => ', params);
		collectionApi.getCollections(params, (message: string, resp: any) => {
			hideLoader();
			console.log(resp.collections)
			setCollections(resp.collections);
			setTotalPage(Math.ceil(resp.totalRecord / limit));
			console.log(resp);
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

	const getSettingDetails = () => {
		getStoreConfiguration({}, (message: string, resp: any) => {
			if (resp['successful'] == true) {
				const isFiatEnable = resp && resp['storeConfiguration'] && resp['storeConfiguration'][0] && resp['storeConfiguration'][0]['isFiatEnable'] || 0
				console.log('isFiatEnable: ', isFiatEnable, !!isFiatEnable)
				setIsFiatEnabled(!!isFiatEnable)
			} else {
				toast.error(message)
			}
		}, (message: string, resp: any) => {
			toast.error(message)
		})
	}

	const closeModalEditFees = () => setIsFeesModalOpen(false);

	const deleteCollection = (id: any) => {
		return new Promise((resolve: any, reject: any) => {
			collectionApi.deleteCollection({
				collectionID: id
			}, (message: string, resp: any) => {
				resolve(message)
			}, (message: string) => {
				reject(message);
			})
		})
	}

	const categoryDeletehandler = (collection: any) => {
		console.log(collection)
		toast((t) => (
			<span>
				Are you sure?
				<br />
				You want to <b className="text-danger">delete</b> Collection{" "}
				<b>{collection.collectionName}</b>
				<br />
				<hr className="mb-3 mt-3" />
				<div className="align-middle flex justify-end">
					<ButtonPrimary className="bg-black m-1 sm:h-9" onClick={() => { toast.dismiss(t.id) }} >Cancel</ButtonPrimary>
					<ButtonPrimary
						className="bg-red-500 m-1 sm:h-9"
						onClick={() => {
							toast.dismiss(t.id);
							toast.promise(
								deleteCollection(collection.collectionID), {
								loading: "Deleting...",
								success: (msg) => {
									getCollections();
									return <b>{collection.collectionName} Deleted Successfully!</b>
								},
								error: (msg) => { return <b>{msg}</b> },
							}, { duration: 2000 });
						}}
					>
						Delete
					</ButtonPrimary>

				</div>
			</span>
		),
			{ duration: 95000 }
		);
	}

	const getBlockchainNetworks = () => {
		commonApi.getBlockchainNetworks({}, (message: string, resp: any) => { }, (message: string, resp: any) => { console.log(message, resp) })
	}

	const handleContractDeploySaveTxn = (id: number, hash: string, abi: any, bytecode: string) => {
		const params = {
			transactionHash: [{
				itemID: id,
				hashToken: hash,
				itemTypeID: 0,
				collectionID: id,
				byteCode: bytecode,
				abi: JSON.stringify(abi)
			}]
		}
		showLoader();
		commonApi.saveContractDeployTxn(params, (message: string, resp: any) => {
			hideLoader();
			toast.success(message)
		}, (message: string, resp: any) => {
			hideLoader();
			toast.error(message)
		})
	}

	const handleFetchAbi = (collection: any) => {
		const network = blockchainNetworksOption.find((e: any) => collection['blockchainNetworkID'] == e['networkID'])
		const store = userDetails && userDetails['store'] && userDetails['store'].length && userDetails['store'][0] || null;
		const storeId = store['storeID'] && store['storeID'].split('-')[0] || null;
		if (network && storeId) {
			showLoader();
			const params = {
				chainName: collection['chainName'],
				web3Url: network['web3Url'],
				contractType: collection['collectionTypeID'] === 2 ? "ERC1155" : 'ERC721',
				className: collection['className'],
				shortName: collection['shortName'],
				name: collection['collectionName'],
				uuid: collection['collectionID'],
				storeId: store['type'] != 'Marketplace' ? +storeId : null,
				marketplaceId: store['type'] === 'Marketplace' ? +storeId : null,
				collectionId: collection['collectionID']
			}
			collectionApi.fetchContractAbi(params, (message: string, resp: any) => {
				if (resp.data && resp.data.bytecode && resp.data.abi) {
					if (custodialWalletSettings['isEnabled'] && custodialWalletSettings['custodialWalletAddress']) {
						showLoader();
						const deployParams = {
							byteCode: resp.data.bytecode,
							abi: JSON.stringify(resp.data.abi),
							collectionID: collection['collectionID'],
							custodialWalletAddress: custodialWalletSettings['custodialWalletAddress'],
							userType: USER_TYPE.ADMIN
						}
						deployContractThroughAPI(deployParams,
						(message: string, resp: any) => {
							hideLoader();
							toast.success('Deployment initiated, once completed you will get contract address in details page')
							const gotIndex = collections.findIndex((collection: any) => +collection['collectionID'] == +collection['collectionID'])
							const collectionsClone = JSON.parse(JSON.stringify(collections))
							if (gotIndex != -1) {
								collectionsClone.splice(gotIndex, 1, { ...collection, contractStatus: resp['deployContractStatusID'] })
							} else {
								collectionsClone.splice(gotIndex, 1)
							}
							setCollections(collectionsClone)
						},
						(message: string, resp: any) => {
							hideLoader();
							toast.error(message);
						});
					} else {
						hideLoader();
						doTransaction(collection, resp.data.abi, resp.data.bytecode);
					}
				} else {
					toast.error('ABI and bytecode required!')
				}
			}, (message: string, resp: any) => {
				hideLoader();
				toast.error(message)
			})
		} else {
			toast.error('Network and store are required!')
		}
	}

	const doTransaction = (collection: any, abi: any, bytecode: string) => {
		try {
			if ( blockchainNetworks && blockchainNetworks.length ) {
				const network = blockchainNetworks.find((e: any) => e['networkID'] === collection['blockchainNetworkID'] )
				if ( network ) {
					var newTokenURL = CONTRACT_DEPLOY_NEW_TOKEN_URL + collection['collectionID'] + "/" + collection['chainName'] + "/";
					if (collection['collectionTypeID'] == 2)//1155
					{
						newTokenURL += "{id}";
					}

					const newContractURL = CONTRACT_DEPLOY_NEW_CONTRACT_URL + collection['collectionID'];
					const _linkToken = network['linkToken']
					const _oracle = network['oracle']
					const _jobId = network['jobId']
					const _OrderAPIBaseUrl = CONTRACT_DEPLOY_ORDER_API_BASE_URL
					const _RedeemAPIBaseUrl = CONTRACT_DEPLOY_REDEEM_API_BASE_URL
					const _PresaleAPIBaseUrl = CONTRACT_DEPLOY_PRESALE_API_BASE_URL

					// string memory newTokenURL, string memory newContractURL, address _linkToken, address _oracle, string memory _jobId, string memory _OrderAPIBaseUrl, string memory _RedeemAPIBaseUrl, string memory _PresaleAPIBaseUrl

					showLoader();
					let web3 = new Web3(ethereum);
					ethereum
						.request({ method: 'eth_requestAccounts' })
						.then((accounts: string[]) => {
							new web3.eth.Contract(abi)
								.deploy({
									data: bytecode,
									arguments: [newTokenURL, newContractURL, _linkToken, _oracle, _jobId, _OrderAPIBaseUrl, _RedeemAPIBaseUrl, _PresaleAPIBaseUrl]
									/*arguments: ["https://nftwardsv2.dreamztesting.com/", "https://nftwardsv2.dreamztesting.com/",
										"0x326C977E6efc84E512bB9C30f76E30c160eD06FB", "0xDCb5a0a1AdDDc246a8455d9CA03ce150F95dd056", "ac704ed51e1b47cda7c220f75e5c684e", "", "", ""]*/
								})
								.send({ from: accounts[0] })
								.on('error', function (err: any) {
									hideLoader();
									toast.error('An unexpected error has occurred, please try again');
								})
								.on('transactionHash', function (txn: string) {
									hideLoader();
									handleContractDeploySaveTxn(collection.collectionID, txn, abi, bytecode)
								});
						});
				} else {
					toast.error('Network not found!')
				}
			} else {
				toast.error('Network list empty!')
			}
		} catch (err: any) {
			console.log('err: ', err)
			hideLoader();
			toast.error(err.message)
		}
	}

	const handleDeployContract = (collection: any, _account: string, _chainId: number) => {
		if (collection && collection.deployContract === 1) {
			if (isFiatEnabled) {
				handleFetchAbi(collection);
			} else {
				handleWalletConnect(collection, _account, _chainId, () => handleFetchAbi(collection))
			}
		} else {
			toast.error('Shared contract can\'t be deployed again!')
		}
	};

	const handleWalletConnect = async (collection: any, _account: string, _chainId: number, callBack: Function) => {
		if (typeof window !== 'undefined') {
			if (!ethereum) {
				toast.error('Please install the metamask extension');
			} else {
				if (_account) {
					console.log('_account: ', _account, ' _chainId: ', _chainId)
					if (_chainId === collection['chainID']) {
						closeModal();
						callBack();
						// handleFetchAbi(collection);
					} else {
						setModalContent(<SwitchNetwork changeChainId={collection['chainID']} callback={handleWalletConnect} item={collection} cancel={closeModal} onSuccess={callBack} />)
						showModal('Switch Network');
					}
				} else {
					setModalContent(<ConnectWallet cancel={closeModal} callback={handleWalletConnect} item={collection} onSuccess={callBack} />)
					showModal('Connect Wallet')
				}
			}
		}
	}

	const handleViewContractBalance = (collection: any, type: string) => {
		handleWalletConnect(collection, account, chainId, () => showModalContractBalance(collection, type))
	}

	const showModalContractBalance = (collection: any, type: string) => {
		setModalContent(<ContractBalance type={type} changeChainId={collection['chainID']} item={collection} cancel={closeModal} />)
		showModal('Contract Balance');
	}

	const handleViewLinkBalance = (collection: any, type: string) => {
		handleWalletConnect(collection, account, chainId, () => showModalLinkBalance(collection, type))
	}

	const showModalLinkBalance = (collection: any, type: string) => {
		setModalContent(<LinkBalance type={type} changeChainId={collection['chainID']} item={collection} cancel={closeModal} />)
		showModal('Chain Link');
	}

	const fetchCollectionTypes = () => {
		getCollectionTypes({}, (message: string, resp: any) => {
			const data = resp.collectionTypes && resp.collectionTypes.map((e: any) => ({
				name: e['collectionType'],
				id: '' + e['collectionTypeID']
			}))
			setCollectionTypeList(data)
		}, (message: string) => {
			toast.error(message)
		})
	}

	const onSubmitListHeaderAction = () => {
		const params: any = {
			collections: selectedCollections.map((e: number) => ({ collectionID: e }))
		}
		if (selectedOperation && ['published', 'unpublished'].includes(selectedOperation.value)) {
			params['isPublished'] = selectedOperation.value === 'published' ? 1 : 0
			showLoader();
			collectionApi.updatPublishStatus(params, (message: string, resp: any) => {
				hideLoader();
				if (resp && resp.successful === true) {
					toast.success(resp.message);
					setSelectedOperation(null)
					getCollections();
				} else {
					toast.error(message);
				}
			}, (message: string) => {
				hideLoader();
				toast.error(message);
			})
		} else if (selectedOperation && ['feature', 'nonfeature'].includes(selectedOperation.value)) {
			params['isFeatured'] = selectedOperation.value === 'feature' ? 1 : 0
			showLoader();
			collectionApi.updateFeature(params, (message: string, resp: any) => {
				hideLoader();
				if (resp && resp.successful === true) {
					toast.success(resp.message);
					setSelectedOperation(null)
					getCollections();
				} else {
					toast.error(message);
				}
			}, (message: string) => {
				hideLoader();
				toast.error(message);
			})
		}
	}

	const showModal = (title: string) => {
		setIsShowModal(true)
		setModalTitle(title)
		// setIsShowWalletModal(true);
	}
	/*const showContractBalanceModal = () => {
		setIsShowContractBalanceModal(true);
	}*/
	const closeModal = () => {
		setIsShowModal(false);
		setModalTitle('')
		// setIsShowWalletModal(false);
		setModalContent('')
	}
	/*const closeContractBalanceModal = () => {
		setIsShowContractBalanceModal(false)
		setModalContent('')		
	}*/

	useEffect(() => {
		console.log('Collection Filter => ', filter)
		if (collections) {
			if (currentPage == 1) {
				getCollections();
			} else {
				setCurrentPage(1);
			}
		}
	}, [filter, searchKey]);

	useEffect(() => {
		if (blockchainNetworks && blockchainNetworks.length) {
			const data = blockchainNetworks.map((e: any) => ({
				name: e['chainLabel'],
				id: '' + e['networkID']
			}))
			setNetworkList(data)
			// setNetworkList(blockchainNetworks)
		}
	}, [blockchainNetworks])

	useEffect(() => {
		getCollections();
	}, [currentPage])

	useEffect(() => {
		setBlockchainNetworksOption(blockchainNetworks)
	}, [blockchainNetworks])

	useEffect(() => {
		if (userDetails && Object.keys(userDetails).length) {
			setCustodialWalletSettings({
				isEnabled: userDetails['isCustodialWalletEnabled'] && (userDetails['isCustodialWalletEnabled'] === 'true' || userDetails['isCustodialWalletEnabled'] === true) || false,
				custodialWalletAddress: userDetails['custodialWalletAddress'] || ''
			})
		}
	}, [userDetails])

	useEffect(() => {
		fetchCollectionTypes();
		getBlockchainNetworks();
		getSettingDetails();
	}, [])


	return (
		<div className={`nc-PageCollection  ${className}`} data-nc-id="CategoryList" >
			<Helmet>
				<title>Collections</title>
			</Helmet>
			<PageHeaderWithSearch title="Collections" onSearch={setSearchKey} searchKey={searchKey} />

			{/* <ButtonPrimary type='button' onClick={showModal}>Connect wallet</ButtonPrimary> */}

			<div className="container-full py-16 lg:pb-28 lg:pt-20 space-y-20 lg:space-y-28 pl-10 pr-10">
				<main>


					<div className={`flex flex-col relative`}>
						<div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
							{collections?.length > 0 && (
								<div className="checkalloption ml-3 ">
									<label>
										<Checkbox label="Check All" checked={chcekAll} onChange={(e: any) => {
											if (e.target.checked) {
												handleSelectCollections(true)
											} else {
												handleSelectCollections(false)
											}
										}} />
									</label>
								</div>
							) || (<div></div>)}
							<span className="flex flex-shrink-0 text-right items-center">
								<Link className="sm:space-x-2" to="/collection/new">
									<ButtonPrimary>Add New</ButtonPrimary>
								</Link>
								<ButtonSecondary className="w-auto !pr-16 ml-2 border-primary-500 focus:ring-0 focus:ring-primary-200" sizeClass="pl-4 py-2.5 sm:pl-6" onClick={() => { setIsOpen(!isOpen); }}>

									<svg
										className={`w-4 h-4 sm:w-6 sm:h-6`}
										viewBox="0 0 24 24"
										fill="none"

									>
										<path
											d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M19.87 17.12L18.87 16.12"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>


									<span className="block truncate ml-2.5">Filter</span>
									<span className="absolute top-1/2 -translate-y-1/2 right-5">
										<ChevronDownIcon
											className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpen ? "rotate-180" : ""
												}`}
											aria-hidden="true"
										/>
									</span>
								</ButtonSecondary>
							</span>
						</div>

						<Transition
							show={!!selectedCollections.length}
							enter="transition-opacity duration-150"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity duration-150"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
							<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-8 gap-y-10  mt-8 lg:mt-10 custom-xs-col-2">
								<Select
									className={` ml-3 mt-1 rounded-2xl`}
									options={selectoperations ? selectoperations : []}
									placeholder={"Select Option"}
									onChange={(data: any) => {
										setSelectedOperation(data)
									}}
									defaultValue={selectoperations[0]}
								/>
								<ButtonPrimary type="button" onClick={onSubmitListHeaderAction} className="w-2/5">Submit</ButtonPrimary>
							</div>
						</Transition>

						<Transition
							show={isOpen}
							enter="transition-opacity duration-150"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity duration-150"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
							<TabFilters name="Category Filters"
								radioBtnFiltrations={[
									{
										key: 'filter',
										options: filterBy
									},
									{
										key: 'sortBy',
										options: sortBy
									}, {
										key: 'collectionType',
										options: collectionTypeList
									}, {
										key: 'network',
										options: networkList
									}
								]} onApplyFilter={setFilter}/* onApplySort={onApplySort} onSearch={onSearch} */ />
						</Transition>
					</div>


					{/* TABS FILTER */}
					{/* <Link to="/collection/new"><ButtonPrimary>Add New Collection</ButtonPrimary></Link>
					<br />
					<br />
					<br />

					<TabFilters onApplySort={onApplySort} onSearch={() => { }} /> */}
					{/* LOOP ITEMS */}







					<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-8 gap-y-10  mt-8 lg:mt-10 custom-xs-col-2">
						{collections?.map((collection: any, index: any) => (
							<CollectionCard data={collection} handleSelectCollections={handleSelectCollections} selectedCollections={selectedCollections} key={index} onDelete={categoryDeletehandler} deployContract={() => handleDeployContract(collection, account, chainId)} hideContents={CollectionCardViewSection} viewBalance={() => handleViewContractBalance(collection, 'viewBalance')} withdrawBalance={() => handleViewContractBalance(collection, 'withdrawBalance')} depositBalance={() => handleViewContractBalance(collection, 'depositBalance')} sendLink={() => handleViewLinkBalance(collection, 'sendLink')} viewLinkBalance={() => handleViewLinkBalance(collection, 'viewBalance')} />
							// <CollectionCard data={collection} handleSelectCollections={handleSelectCollections} selectedCollections={selectedCollections} key={index} onDelete={categoryDeletehandler} deployContract={() => handleWalletConnect(collection, account, chainId)} editFees={getSingleCollection} />
						))}
					</div>

					{collections?.length == 0 && (
						<h2 className="font-medium text-2xl text-center w-full">No Result Found!</h2>
					)}

					{collections?.length > 0 && totalPage > 1 && (
						<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
							<Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
						</div>
					)}

				</main>

			</div>
			<NcCustomModal contentExtraClass="max-w-3xl" modalTitle={modalTitle} modalStatus={isShowModal} closeModal={closeModal}>
				{modalContent}
			</NcCustomModal>
			{/* <NcCustomModal contentPaddingClass="" contentExtraClass="max-w-3xl" modalTitle="Contract Balance" modalStatus={isShowContractBalanceModal} closeModal={closeModal}>
				{modalContent}
			</NcCustomModal> */}
			<NcCustomModal contentExtraClass="max-w-3xl" modalTitle={'Update Fees'} modalStatus={isFeesModalOpen} closeModal={closeModalEditFees}>
				<EditFees itemType={ITEM_TYPE.COLLECTION} itemDetails={collectionToUpdateFees} onCloseModalEdit={closeModalEditFees} />
			</NcCustomModal>
		</div>
	);
};

export default CategoryList;
