import React, { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import Pagination from "shared/Pagination/PaginationHandler";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import TabFilters from "components/Filters/TabFilters";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { Controller, useController, useForm } from "react-hook-form";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { toast } from "react-hot-toast";
import Checkbox from "shared/Checkbox/nativeCheckbox";
import { Link } from "react-router-dom";
import ItemTypeEditIcon from "../../../components/ItemTypeEditIcon";
import ItemTypeDeleteIcon from "../../../components/ItemTypeDeleteIcon";
import Select from 'react-select';
import { usePayoutGroupApi } from "_common/hooks/api/payoutGroupApiHook";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import PageHeaderWithSearch from "shared/PageHeaderWithSearch/PageHeaderWithSearch";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useUserSelector } from '../../../_common/hooks/selectors/userSelector'
import EditFees from "components/FeesUpdate/feesUpdate";
import PriceText from "components/PriceText";
import NcCustomModal from "components/Modal/NcCustomModal";
import { ITEM_TYPE } from "_common/_constant";
import { BsCashCoin } from "react-icons/bs";
import ItemTypeViewIcon from "components/ItemTypeViewIcon";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import ButtonCircle from "shared/Button/ButtonCircle";
import NftMoreDropdown from "components/NftMoreDropdown";

export interface Props {
    className?: string;
}

const PageProducts: FC<Props> = ({ className = "" }) => {

    const productApi = useProductApi();
    const collectionApi = useCollectionApi();
    const userApi = useUserApi()

    const user: any = useUserSelector();
    const [products, setProducts] = useState<any>(null);
    // for pagination
    const limit: number = 10;
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [sort, setSort] = useState<string>('');
    const [searchKey, setSearchKey] = useState<string>('');
    const [collections, setCollections] = useState<any>(null);
    const { showLoader, hideLoader } = useAppLoader();
    const [isOpen, setIsOpen] = React.useState(false);
    const [isFeesModalOpen, setIsFeesModalOpen] = useState(false);
    const [filterparams, setFilterparams] = React.useState<any>({});
    const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
    const [storeBaseUrl, setStoreBaseUrl] = useState<string | null>(null)

    const sortBy = [
        { name: "Collection Name ASC", id: "name" },
        { name: "Collection Name DESC", id: "name:desc" }
    ];

    const closeModalEditFees = () => setIsFeesModalOpen(false);

    const getCollections = () => {
        let params: any = {
            usePaging: false,
            sortingBy: 'collectionName',
            sortingOrder: 'asc'
        }
        console.log('params => ', params);
        collectionApi.getCollections(params, (message: string, resp: any) => {
            const data = resp.collections.map((o:any) => {
                return {
                    id: '' + o.collectionID,
                    name: o.collectionName
                }
            });
            setCollections(data);
        }, (message: string) => {
            console.log(message);
        })
    }

    const getProducts = () => {
        let params: any = {
            usePaging: true,
            pageSize: limit,
            pageNumber: currentPage,
            isCreatedByCustomer: 1,
            searchText: searchKey
        }
        console.log(sort);
        switch (filterparams?.radioBtnFiltrations?.sortBy) {
            case 'name':
                params.sortingBy = 'collectionname';
                params.sortingOrder = 'asc';
                break;
            case 'name:desc':
                params.sortingBy = 'collectionname';
                params.sortingOrder = 'desc';
                break;
            default:
        }
        if (filterparams?.radioBtnFiltrations?.pageSize)
            params.pageSize = Math.abs(filterparams?.radioBtnFiltrations?.pageSize);
        if (filterparams?.radioBtnFiltrations?.Collection)
            params.collectionID = Math.abs(filterparams?.radioBtnFiltrations?.Collection);

        if (filterparams?.dropdownFilter) {
            if(filterparams.dropdownFilter.customer){
                params['addedBy'] = filterparams.dropdownFilter.customer['value'];
            }
        }

        console.log('params => ', params);
        showLoader();
        productApi.getProducts(params, (message: string, resp: any) => {
            hideLoader();
            setProducts(resp.products);
            setTotalPage(Math.ceil(resp.totalRecord / limit));
            console.log(resp)
        }, (message: string) => {
            hideLoader();
            console.log(message);
        })
    }

    const getSingleProduct = (id: number) => {
        showLoader();
        productApi.getProducts({productID: id}, (message: string, resp: any) => {
            hideLoader();
            const data: any = resp.products && resp.products.length && resp.products[0] || null;
            if ( data && data['ownerSettings'] && data['ownerSettings'].length ) {
				setSelectedProduct(data)
				setIsFeesModalOpen(true);
			} else if ( !data ) {
				toast.error('Product not found!')
			} else if ( !data.hasOwnProperty('ownerSettings') || (data.hasOwnProperty('ownerSettings') && !data['ownerSettings'].length)) {
				toast.error('Owner settings not found!')
			}
        }, (message: string) => {
            hideLoader();
            toast.error(message)
            console.log(message);
        })
    }

    const deleteProduct = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            showLoader();
            productApi.deleteProducts({
                deleteProducts: [{ productID: id }]
            }, (message: string, resp: any) => {
                hideLoader();
                resolve(message)
            }, (message: string) => {
                hideLoader();
                reject(message);
            })
        })
    }

    const customfieldDeletehandler = (field: any) => {
        console.log(field.customFieldID)
        toast((t) => (
            <span>
                Are you sure?
                <br />
                You want to <b className="text-danger">delete</b> product{" "}
                <b>{field.productName}</b>
                <br />
                <hr className="mb-3 mt-3" />
                <div className="align-middle flex justify-end">
                    <ButtonPrimary className="bg-black m-1 sm:h-9" onClick={() => { toast.dismiss(t.id) }} >Cancel</ButtonPrimary>
                    <ButtonPrimary
                        className="bg-red-500 m-1 sm:h-9"
                        onClick={() => {
                            toast.dismiss(t.id);
                            toast.promise(
                                deleteProduct(field.productID), {
                                loading: "Deleting...",
                                success: (msg) => {
                                    getProducts();
                                    return <b>{field.productName} Deleted Successfully!</b>
                                },
                                error: (msg) => { return <b>{msg}</b> },
                            }, { duration: 2000 });
                        }}
                    >
                        Delete
                    </ButtonPrimary>
                </div>
            </span>
        ),
            { duration: 95000 }
        );
    }

    const getCustomerList = (searchText: string = ''): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            let params: any = {
                usePaging: true,
                pageSize: 5,
                pageNumber: 1,
                searchText 
            }
            userApi.customerList(params,
            (message: string, resp: any) => {
                let tempArr: any = resp?.customers.map((customer: any) => ({
                    label: `${customer['firstName']} ${customer['lastName']}`,
                    value: customer['customerID'],
                    email: customer['emailID'],
                    cryptoAddress: customer['cryptoAddress']
                }))
                return resolve(tempArr);
            },
            (message: string) => {
                toast.error(message)
                return resolve([]);
            })
        })
	}

    const onApplySort = (type: string) => {
        setSort(type);
        //setTimeout(() => {
        //  getProducts();
        //});
    }

    const formatCustomerOptionLabel = ({label, email, cryptoAddress}: any) => (
		<div>
			{
				label ? <div>{label}{ email ? ` (${email})` : '' }</div> : ''
			}
			{
				cryptoAddress ? <div>{cryptoAddress}</div> : ''
			}
		</div>
	)

    const onApplyFilter = (data: any) => {
        setCurrentPage(1)
        setFilterparams(data);
    }

    const onSearch = (data: any) => {
        setSearchKey(data);
    }

    const getStoreConfiguration = () => {
		userApi.getStoreConfiguration({}, (message: any, resp: any) => {
			if (resp.successful === true && resp.code === 200 && resp.storeConfiguration.length > 0) {
				if ( resp.storeConfiguration[0].subDomainName ) {
					const url = 'https://'+resp.storeConfiguration[0].subDomainName+'.'+(resp.storeConfiguration[0].domainName || 'nftwards.com');
					setStoreBaseUrl(url)
				}
			}
		}, (message: string) => {
		  	toast.error(message)
		});
	}

    const navigateDetails = (data: any) => {
		if ( storeBaseUrl ) {
			const url = storeBaseUrl + `/product/${data.productID}`
			window.open(url, '_blank')
		}
	}

    useEffect(() => {
        if (products) {
            if (currentPage == 1) {
                getProducts();
            } else {
                setCurrentPage(1);
            }
        }

    }, [sort])


    useEffect(() => {
        if (products) {
            if (currentPage == 1) {
                getProducts();
            } else {
                setCurrentPage(1);
            }
        }

    }, [searchKey]);

    useEffect(() => {
        getProducts();
        getCollections();
    }, [currentPage])

    useEffect(() => {
        //console.log("sao paulo", filterparams);
        if (filterparams && filterparams?.radioBtnFiltrations && typeof filterparams.radioBtnFiltrations.sortBy !== 'undefined') {
            //console.log("xxx", filterparams.radioBtnFiltrations.itemType, filterparams.radioBtnFiltrations.sortBy);
            getProducts();
        }
    }, [filterparams])

    useEffect(() => {
        getStoreConfiguration();
    }, [])


    return (
        <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
            <Helmet>
                <title>Products</title>
            </Helmet>
            <PageHeaderWithSearch title="Products" onSearch={setSearchKey} searchKey={searchKey} />


            <div className="container-full py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28 pl-10 pr-10">
                <main>
                    {/* FILTER */}
                    <div className={`flex flex-col relative ${className}`}>
                        <div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2">
                            <div></div>
                            <span className="flex flex-shrink-0 text-right items-center">
                                <ButtonSecondary
                                    className="w-auto !pr-16 ml-2"
                                    sizeClass="pl-4 py-2.5 sm:pl-6 border-primary-500 focus:ring-0 focus:ring-primary-200"
                                    onClick={() => {
                                        setIsOpen(!isOpen);
                                    }}
                                >
                                    <svg
                                        className={`w-4 h-4 sm:w-6 sm:h-6`}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeMiterlimit="10"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M19.87 17.12L18.87 16.12"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>

                                    <span className="block truncate ml-2.5">Filter</span>
                                    <span className="absolute top-1/2 -translate-y-1/2 right-5">
                                        <ChevronDownIcon
                                            className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpen ? "rotate-180" : ""
                                                }`}
                                            aria-hidden="true"
                                        />
                                    </span>
                                </ButtonSecondary>
                            </span>
                        </div>

                        <Transition
                            show={isOpen}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
                            {/*<TabFilters onApplySort={onApplySort} onSearch={onSearch} />*/}
                            <TabFilters name="Product Filters"
                                radioBtnFiltrations={[
                                    {
                                        key: 'pageSize',
                                        options: [
                                            { id: '10', name: '10 per page' },
                                            { id: '25', name: '25 per page' },
                                            { id: '50', name: '50 per page' },
                                            { id: '100', name: '100 per page' },
                                            { id: '200', name: '200 per page' }
                                        ]
                                    },
                                    {
                                        key: 'Collection',
                                        options: collections
                                    }, 
                                    {
                                        key: 'sortBy',
                                        options: sortBy
                                    },
                                ]}
                                dropdownFilter={[
									{
										key: 'customer',
										placeholder: 'Select Customer',
                                        isAsync: true,
										options: [],
										formatOptionLabel: formatCustomerOptionLabel,
										callback: getCustomerList
									}
								]} onApplyFilter={onApplyFilter}/* onApplySort={onApplySort} onSearch={onSearch} */ />
                        </Transition>
                    </div>


                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-8 gap-y-10  mt-8 lg:mt-10 custom-xs-col-2">
                        {products && products.length > 0 ? (
                            <React.Fragment>
                                {products.map((item: any, index: number) => {
                                    return (
                                        <div key={'cstfld-' + index} className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md`}>
                                            <div className="relative flex-shrink-0 ">
                                                <div>
                                                    <NcImage
                                                        containerClassName="flex aspect-w-11 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0 rounded-b-none"
                                                        src={item.productThumbUrl}
                                                        className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
                                                    />
                                                </div>
                                                <div className="absolute flex flex-row-reverse gap-2 justify-end right-2 sm:left-auto top-2">
                                                    <NftMoreDropdown
                                                        actions={[
                                                            {
                                                                id: "customer_view_item",
                                                                name: "View",
                                                                icon: "las la-eye",
                                                                callBack: () => navigateDetails(item)
                                                            },
                                                            user && user['store'] && user['store'].length && user['store'][0]['type'] === "Marketplace" ? {
                                                                id: "customer_edit_fees",
                                                                name: "Edit Fees",
                                                                icon: "las la-coins",
                                                                callBack: () => getSingleProduct(+item?.productID)
                                                            } : {
                                                                id: "",
                                                                name: "",
                                                                icon: "",
                                                                callBack: () => {}
                                                            },
                                                            {
                                                                id: "customer_product_delete",
                                                                name: "Delete",
                                                                icon: "las la-trash",
                                                                callBack: () => customfieldDeletehandler(item)
                                                            }
                                                        ]}
                                                        containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
                                                    />

                                                    {/* <span className="font-normal ml-2">
                                                        <ButtonCircle type="button" onClick={() => { navigateDetails(item) }} className="mr-2">
                                                            <ItemTypeViewIcon />
                                                        </ButtonCircle>
                                                    </span>
                                                    {
                                                        user && user['store'] && user['store'].length && user['store'][0]['type'] === "Marketplace" && (
                                                            <span className="font-normal ml-2">
                                                                <ButtonCircle type="button" onClick={() => getSingleProduct(+item?.productID)} className="mr-2">
                                                                    <BsCashCoin size={20} />
                                                                </ButtonCircle>
                                                            </span>
                                                        ) || ''
                                                    } */}
                                                </div>
                                                {item.isFeatured === 1 && (<span className={`featured-badge nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium relative text-green-800 bg-green-100`}>Featured</span>)}
                                                {/* <span onClick={() => { customfieldDeletehandler(item) }} className="ml-2 delete-btn"><ItemTypeDeleteIcon /></span> */}
                                            </div>

                                            <div className="p-4 py-5 space-y-3">
                                                <div className="flex justify-between">
                                                    <h3 className={`text-sm font-medium category-name`}>{item.collectionName}</h3>
                                                    <h3 className={`text-sm font-medium`}>{item.categoryName}{/*item.productTypeName*/}</h3>
                                                </div>

                                                <h2 className={`text-lg font-medium mb-3`}>{item.productName}</h2>

                                                {/* <h3 className={`text-sm font-medium`}>Slug: {item.slug}</h3>
                                                 <h3 className={`text-sm font-medium`}>Product type: {item.productTypeName}</h3>
                                                <h3 className={`text-sm font-medium`}>Collection: {item.collectionName}</h3>
                                                 */}

                                                <div className="flex justify-between pt-1.5 custom-product-price">                                                    
                                                {item.productPrice.length > 0 ? (                                                        
                                                        <PriceText price={item.productPrice[0]?.cryptoStartPrice} network={item.network} />
                                                    ) : (
                                                        <PriceText price={'NA'} />
                                                    )}
                                                    <div className="option">
                                                        <h4 className={`text-sm font-medium item-status ` + (item.isPublished === true ? 'bg-gray-500 text-white' : 'bg-gray-100 text-gray-400')}>{item.isPublished === true ? 'Published' : 'Not published'}</h4>
                                                    </div>
                                                </div>
                                                {/*item.productPrice.length > 0 && (
                                                    <h3 className={`text-sm font-medium`}>Price 100</h3>
                                                )*/}
                                                {/* <div className="option">
                                                    <h4 className={`text-sm font-medium`}>{item.isPublished === true ? 'Published' : 'Not published'}</h4>
                                                    <h4 className={`text-sm font-medium`}>Featured: {item.isFeatured === 1 ? 'Yes' : 'No'}</h4>
                                                    <h4 className={`text-sm font-medium`}>Reedemtion: {item.isReedemtion === 1 ? 'Yes' : 'No'}</h4>
                                                    <h4 className={`text-sm font-medium`}>Reedemable: {item.isReedamable === 1 ? 'Yes' : 'No'}</h4>
                                                </div> */}
                                            </div>
                                        </div>
                                    )
                                })}
                            </React.Fragment>
                        ) : ''}
                    </div>
                    {products?.length == 0 && (
                        <h2 className="font-medium text-2xl text-center w-full">No Result Found!</h2>
                    )}
                    {totalPage > 1 && (
                        <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                            <Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
                        </div>
                    )}

                </main>
            </div>
            <NcCustomModal contentExtraClass="max-w-3xl" modalTitle={'Update Fees'} modalStatus={isFeesModalOpen} closeModal={closeModalEditFees}>
				<EditFees itemType={ITEM_TYPE.PRODUCT} itemDetails={selectedProduct} onCloseModalEdit={closeModalEditFees} />
            </NcCustomModal>

        </div>
    );
}

export default PageProducts;