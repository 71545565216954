import { FC } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";

import DetailsCoupon from '../../components/Gallery/addUpdate'

export interface Props {
  className?: string;
}

const AddUpdateGallery: FC<Props> = ({ className = "" }) => {

  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/galleries')
  }

  return (
    <div className={`nc-PageUploadItem ${className}`} data-nc-id="PageUploadItem">
      <Helmet>
        <title>Gallery</title>
      </Helmet>
      <div className="container">
        <DetailsCoupon 
          handleCancel={handleRedirect}
          classNames='bg-neutral-100 container lg:pb-28 lg:pt-20 lg:space-y-28 py-16 rounded-2xl space-y-20'
        />
      </div>
    </div>
  );
};

export default AddUpdateGallery;
