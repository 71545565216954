import React, { FC, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { toast, Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";

import NcImage from "shared/NcImage/NcImage";
import CheckBoxNative from "shared/Checkbox/nativeCheckbox";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import DropZone from 'shared/DropZone/DropZone'
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import ItemTypeDeleteIcon from "../../components/ItemTypeDeleteIcon";
import NcCustomModal from "components/Modal/NcCustomModal";

import { ProductPreviewAcceptTypes } from "_common/_constant";


import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { ProductPreviewFormSchemaValidation } from "utils/form-validation";
import { ProductPreview_Form } from "_common/interfaces/FormProps";
import DisplayFile from "shared/DisplayNft/displayNftFile";
import InputFile from "shared/InputFile/InputFile";


export interface PreviewSettingsNFTProps {
    className?: string;
}

const PreviewSettings: FC<PreviewSettingsNFTProps> = ({ className = '' }) => {

    const { id: productId } = useParams();
    const navigate = useNavigate();
    const { getProducts, deletePreviewImage, assignPreviewProduct } = useProductApi();
    const { fileUpload } = useCommonApi();

    const { register, control, handleSubmit, getValues, watch, reset, resetField, setValue, formState: { errors } } = useForm<ProductPreview_Form>({
        defaultValues: {
            isShowPreviewInFrontend: false
        },
        resolver: yupResolver(ProductPreviewFormSchemaValidation)
    });

    const { showLoader, hideLoader } = useAppLoader();
    const [productDetails, setProductDetails] = useState<any>(null)
    const [previewList, setPreviewList] = useState<any>([])
    const [isPreviewOpen, setPreviewOpen] = useState(true)
    const [files, setFiles] = useState<any>([]);
    const [showModalPreview, setShowModalPreview] = useState(false)
    const [selectedFileToPreview, setSelectedFileToPreview] = useState<any>(null)

    useEffect(() => {
        fetchProductDetails();
    }, [])

    useEffect(() => {
        if (productDetails) {
            reset({
                isShowPreviewInFrontend: productDetails['isShowPreviewInFrontend']
            })
        }
    }, [productDetails])

    const fetchProductDetails = () => {
        if (productId) {
            showLoader()
            getProducts({ productID: productId },
                (message: string, resp: any) => {
                    const data = resp && resp.products && resp.products.length && resp.products[0] || null;
                    if (data) {
                        hideLoader()
                        const previewList = data.productPreview || [];
                        setPreviewList(previewList)
                        setProductDetails(data)
                    } else {
                        hideLoader()
                        toast.error('Product not found!')
                        navigate('/products')
                    }
                },
                (message: string, resp: any) => {
                    hideLoader()
                    console.log('error resp: ', resp)
                    toast.error(message)
                })
        } else {
            toast.error('Product ID not found!')
            navigate('/products')
        }
    }

    const deletePreviewFile = (item: any, index: number): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            const params = {
                ProductId: productId,
                ProducrPreviewID: item['producrPreviewID']
            }
            deletePreviewImage(params, (message: string, resp: any) => {
                return resolve(true)
            },
                (message: string, resp: any) => {
                    return reject(false)
                })
        })
    }

    const confirmationDelete = (item: any, index: number) => {
        toast((t) => (
            <span>
                Are you sure to delete?
                <br />
                Once you confirmed, item will be deleted from this assignmnent.
                <br />
                <hr className="mb-3 mt-3" />
                <div className="align-middle flex justify-end">
                    <ButtonPrimary className="bg-black m-1 sm:h-9" onClick={() => { toast.dismiss(t.id) }} >
                        Cancel
                    </ButtonPrimary>
                    <ButtonPrimary
                        className="bg-red-500 m-1 sm:h-9"
                        onClick={() => {
                            toast.dismiss(t.id);
                            toast.promise(
                                deletePreviewFile(item, index), {
                                loading: "Deleting...",
                                success: (msg) => {
                                    const list = JSON.parse(JSON.stringify(previewList))
                                    list.splice(index, 1)
                                    setPreviewList(list);
                                    return <b>Item deleted successfully!</b>
                                },
                                error: (msg) => { return <b>{msg}</b> },
                            }, { duration: 2000 });
                        }}
                    >
                        Delete
                    </ButtonPrimary>
                </div>
            </span>
        ),
            { duration: 95000 }
        );
    }

    const handleFileUpload = (file: any, index: number): Promise<any> => {
        console.log('handleFileUpload: file => ', file)
        return new Promise<any>((resolve, reject) => {
            console.log('before file: ', file)
            const formData: any = new FormData();
            formData.append("", file)
            formData.append("ItemId", productId);
            formData.append("ItemType", 2);
            formData.append("FileType", 'productpreview');

            fileUpload(formData,
                (message: string, resp: any) => {
                    if (resp['successful']) {
                        resolve({
                            name: file['name'],
                            uploadResp: resp['uploadedFiles'][0]
                        })
                    } else {
                        resolve(false)
                    }
                }, (message: string) => {
                    reject(false)
                })
        })
    }

    const onSubmit = (data: any) => {
        if (previewList.length < 1) {
            toast.error('Please upload files from Media!')
            return;
        }
        const params = {
            productID: productId,
            isShowPreviewInFrontend: data['isShowPreviewInFrontend'] || false,
            productPreview: previewList.map((o: any) => {
                return {
                    originalFileName: o.originalFileName,
                    renamedFileName: o.renamedFileName,
                    fileUrl: o.fileUrl
                }
            })
        }
        showLoader();
        assignPreviewProduct(params, (message: string, resp: any) => {
            hideLoader();
            toast.success(message)
            fetchProductDetails();
            setFiles([]);
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        });
    }

    const handleCancel = () => {
        navigate('/products')
    }

    const showModal = (item: any) => {
        setShowModalPreview(true)
        setSelectedFileToPreview(item);
    }
    const closeModal = () => {
        setShowModalPreview(false)
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className={`mb-8`}>
                <>{console.log('errors: ', errors)}</>
                <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5 mb-10">
                        <CheckBoxNative label="Show preview in frontend" {...register('isShowPreviewInFrontend')} />
                        {
                            errors && errors.isShowPreviewInFrontend && errors.isShowPreviewInFrontend.message &&
                            (<p className='text-red-400'>
                                {errors.isShowPreviewInFrontend.message}
                            </p>) || ''
                        }
                    </div>


                    <div className={"accordion-item-wrapper"}>
                        <div className={"accordion-title flex items-center justify-between"}>
                            <span className="text-lg font-bold">Gallery</span>
                            <ButtonSecondary
                                type="button"
                                className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                                sizeClass={"py-2.5"}
                                onClick={() => setPreviewOpen(!isPreviewOpen)}
                            >
                                <span>
                                    <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${isPreviewOpen ? "rotate-180" : ""}`} aria-hidden="true" />
                                </span>
                            </ButtonSecondary>
                        </div>
                        <Transition
                            show={isPreviewOpen}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className={"accordion-body"}>
                                {previewList.length > 0 ? (
                                    <>
                                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-8 gap-y-10  mt-8 lg:mt-10 custom-xs-col-2">
                                            {
                                                previewList.map((item: any, index: number) => (
                                                    <div key={'cstfld-' + index} className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ]`}>
                                                        <div className="relative flex-shrink-0 ">
                                                            <div onClick={() => showModal(item)} className="cursor-pointer">
                                                                <DisplayFile
                                                                    from={'external'}
                                                                    videoControl={false}
                                                                    link={item?.fileUrl}
                                                                    mimeType={item?.mimeType} className="h-48 w-full object-cover rounded-2xl overflow-hidden" />
                                                                {/* <NcImage
                                                    containerClassName="flex aspect-w-4 aspect-h-4 w-full h-0 rounded-3xl overflow-hidden z-0"
                                                    src={item.fileUrl}
                                                    className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
                                                /> */}
                                                            </div>
                                                            <span onClick={() => confirmationDelete(item, index)} className="ml-2 delete-btn"><ItemTypeDeleteIcon /></span>
                                                        </div>
                                                        <div className="p-4 py-5 space-y-3">
                                                            <h2 className={`text-lg font-medium mb-3 custom-product-name`}>{item?.originalFileName}</h2>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <div className="flex flex-col justify-center p-6">
                                        <div className="text-5xl text-center text-yellow-500"><i className="las la-box"></i></div>
                                        <div className="text-center"><strong>No Records Yet!</strong></div>
                                    </div>
                                )}
                            </div>
                        </Transition>
                    </div>

                    <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
                        <ButtonPrimary className="flex-1">Save</ButtonPrimary>
                        <ButtonSecondary type={"button"} className="flex-1" onClick={handleCancel}>Cancel</ButtonSecondary>
                    </div>

                </div>
            </form>


            <NcCustomModal contentExtraClass="max-w-3xl" modalTitle={selectedFileToPreview?.originalFileName} modalStatus={showModalPreview} closeModal={closeModal}>
                <DisplayFile link={selectedFileToPreview?.fileUrl} mimeType={'image/jpeg'} />
            </NcCustomModal>
        </div>
    );
};

export default PreviewSettings;