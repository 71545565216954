import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageHeaderWithSearch from "shared/PageHeaderWithSearch/PageHeaderWithSearch";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";
import TabFilters from "components/Filters/TabFilters";
import Pagination from "shared/Pagination/PaginationHandler";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import moment from "moment";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useProductApi } from "_common/hooks/api/productApiHook";
import PriceText from "components/PriceText";

export interface Props {
	className?: string;
}

const Activities: FC<Props> = ({ className = "" }) => {

	const limit: number = 12;
	const sortBy = [
		{ name: "Active Only", id: "name-active", direction: 1 },
		{ name: "InActive Only", id: "name-inactive", direction: 0 }
	];

	const [searchKey, setSearchKey] = useState<string>('');
	const [activityList, setActivityList] = useState<any>(null)
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState<number>(1);
	const [isOpen, setIsOpen] = useState(false);
	const [filter, setFilter] = useState<any>({});
	const [collections, setCollections] = useState<any>([]);
	const [filterCollections, setFilterCollections] = useState<any>([]);
	const [products, setProducts] = useState<any>([]);
	const [filterProducts, setFilterProducts] = useState<any>([]);
	const [datefilter, setDateFilter] = useState<any>({});

	const commonApi = useCommonApi()
	const collectionApi = useCollectionApi()
	const productApi = useProductApi()
	const { showLoader, hideLoader } = useAppLoader()

	const onSearch = (key: string) => {
		setSearchKey(key);
	}

	const onApplyFilter = (data: any) => {
		// console.log('Category Filter =>', data)
		setFilter(data)
	}

	const getCollections = () => {
		collectionApi.getCollections({}, (message: string, resp: any) => {
			setCollections(resp.collections);
		}, (message: string) => {
			console.log(message);
		})
	}

	const getProducts = () => {
		productApi.getProducts({}, (message: string, resp: any) => {
			setProducts(resp.products);
		}, (message: string) => {
			console.log(message);
		})
	}

	const getActivityList = () => {
		let params: any = {
			usePaging: true,
			pageSize: limit,
			pageNumber: currentPage,
		}

		if (searchKey) {
			params.searchText = searchKey
		}

		switch (filter?.radioBtnFiltrations?.Status) {
			case 'name-active':
				params.isActive = 1;
				break;
			case 'name-inactive':
				params.isActive = 0;
				break;
			default:
		}

		if (filter?.radioBtnFiltrations?.Collection) {
			params.collectionID = filter?.radioBtnFiltrations?.Collection
		}

		if (filter?.radioBtnFiltrations?.Product) {
			params.productID = filter?.radioBtnFiltrations?.Product
		}

		if (filter?.dateInputFilter?.StartDate) {
			params.startDate = filter?.dateInputFilter?.StartDate
		}

		if (filter?.dateInputFilter?.EndDate) {
			params.endDate = filter?.dateInputFilter?.EndDate
		}

		showLoader()
		commonApi.getActivities(params,
			(message: string, resp: any) => {
				// console.log({ message, resp })
				setActivityList(resp.activities)
				setTotalPage(Math.ceil(resp.totalRecord / limit))
				hideLoader()
			},
			(message: string) => {
				console.log(message)
				hideLoader()
			})
	}

	useEffect(() => {
		if (collections) {
			let options: any = [];
			collections.map((col: any, i: any) => { options.push({ name: col.collectionName, id: col.collectionID },) });
			setFilterCollections(options)
		}
	}, [collections])

	useEffect(() => {
		if (products) {
			let options: any = [];
			products.map((prod: any, i: any) => { options.push({ name: prod.productName, id: prod.productID },) });
			setFilterProducts(options)
		}
	}, [products])

	useEffect(() => {
		// console.log('on apply filter =>', filter);
		if (activityList) {
			if (currentPage == 1) {
				getActivityList();
			} else {
				setCurrentPage(1);
			}
		}
	}, [filter, searchKey])

	useEffect(() => {
		getActivityList();
	}, [currentPage])

	useEffect(() => {
		getProducts();
		getCollections();

	}, [])


	return (
		<div className={`nc-PageUploadItem`} data-nc-id="PageUploadItem">
			<Helmet>
				<title>Activity List</title>
			</Helmet>
			<PageHeaderWithSearch title="Activity List" onSearch={setSearchKey} searchKey={searchKey} />

			<div className="container-full pl-10 pr-10 py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
				<div className={`flex flex-col relative ${className}`}>
					<div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
						<div className="relative flex w-full text-sm md:text-base hiddenScrollbar items-center">

						</div>
						<span className="flex flex-shrink-0 text-right items-center">
							<ButtonSecondary
								className="w-auto !pr-16 ml-2 border-primary-500 focus:ring-0 focus:ring-primary-200"
								sizeClass="pl-4 py-2.5 sm:pl-6"
								onClick={() => {
									setIsOpen(!isOpen);
								}}
							>
								<svg
									className={`w-4 h-4 sm:w-6 sm:h-6`}
									viewBox="0 0 24 24"
									fill="none"
								>
									<path
										d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M19.87 17.12L18.87 16.12"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>

								<span className="block truncate ml-2.5">Filter</span>
								<span className="absolute top-1/2 -translate-y-1/2 right-5">
									<ChevronDownIcon
										className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpen ? "rotate-180" : ""
											}`}
										aria-hidden="true"
									/>
								</span>
							</ButtonSecondary>
						</span>
					</div>

					<Transition
						show={isOpen}
						enter="transition-opacity duration-150"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity duration-150"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
						<TabFilters name="Activity List Filters"
							radioBtnFiltrations={[
								{
									key: 'Collection',
									options: filterCollections
								}, {
									key: 'Product',
									options: filterProducts
								}, {
									key: 'Status',
									options: sortBy
								}
							]}
							dateInputFilter={[
								{
									key: 'StartDate'
								},
								{
									key: 'EndDate'
								}
							]}
							onApplyFilter={onApplyFilter}
						/>
					</Transition>
				</div>

				<div className="grid sm:grid-cols-1 gap-x-8 gap-y-3  mt-8 lg:mt-10">
					{activityList && activityList.length > 0 ? (
						<React.Fragment>
							{activityList.map((item: any, index: number) => {
								return (
									<div key={'cstfld-' + index} className={`relative rounded-xl hover:shadow-lg hover:bg-neutral-50 border border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800 dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200`}>

										<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-10 gap-x-5 gap-y-10 custom-xs-col-2 items-center">
											<div className="custom-fld-icon w-28">
												<NcImage
													containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0 rounded-3xl overflow-hidden z-0"
													src={item?.productFileUrl}
													className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
												/>
											</div>
											<div>
												<h2 className={`mb-1 flex items-center flex font-semibold`}>{item?.note}</h2>
											</div>
											<div>
												<h2 className={`font-semibold mb-1 flex items-center`}>
													<span className="font-normal">{item?.productName}</span>
												</h2>
											</div>
											<div>
												<h2 className={`font-semibold mb-1 flex items-center`}>
													<span className="font-normal">{item?.addedByName}</span>
												</h2>
											</div>
											<div>
												<h2 className={`font-semibold mb-1 flex flex-col text-center items-center`}>
													<img src={item?.collectionLogoUrl} alt="" className="rounded-full w-16 h-16 object-cover overflow-hidden" />
													<span className="font-normal mt-3">{item?.collectionName}</span>
												</h2>
											</div>
											<div>
												<h2 className={`font-semibold mb-1 flex items-center`}>
													<PriceText price={2} network={"ethereum"} className="w-full" />
												</h2>
											</div>
											<div>
												<h2 className={`font-semibold mb-1 flex items-center`}>
													<span className="font-normal">{item?.chainName}</span>
												</h2>
											</div>
											<div>
												<h2 className={`font-semibold mb-1 flex items-center`}>
													<span className="font-normal">From address</span>
												</h2>
											</div>
											<div>
												<h2 className={`font-semibold mb-1 flex items-center`}>
													<span className="font-normal">To address</span>
												</h2>
											</div>
											<div>
												<h2 className={`font-semibold mb-1 flex items-center text-sm text-gray-400`}>
													<span className="font-normal text-sm">{item?.addedDate && moment(item?.addedDate).format('DD-MM-YYY hh:mm A')}</span>
												</h2>
											</div>
										</div>
										<div className="option ml-3 absolute bottom-3 right-3">
											<h4 className={`text-sm font-medium item-status ` + (item.isActive == 1 ? 'bg-lime-500 text-white' : 'bg-red-300 text-white')}>{item.isActive == 1 ? "Active" : "InActive"}</h4>
										</div>
									</div>
								)
							})}
						</React.Fragment>
					) : (
						<h2 className="font-medium text-2xl text-center w-full">No Result Found!</h2>
					)}
				</div>
				<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
					<Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
				</div>
			</div>
		</div>
	);
};

export default Activities;
