import React, { InputHTMLAttributes, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import DisplayFile from "shared/DisplayNft/displayNftFile";
import NcImage from "shared/NcImage/NcImage";

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  uploadedMimeType?: string;
  onChangeCapture?:any;
  uploadedUrl?:any;
}

const InputFile = React.forwardRef<HTMLInputElement, SwitchProps>(({ uploadedUrl, uploadedMimeType = 'image/jpeg', className = "",onChangeCapture, children,  ...args }, ref) => {

  const [image, setImage] = useState<any>('');
  const [mimeType, setMimeType] = useState<string>(uploadedMimeType)


  const changeStoreFavIconHandler = (e: any) => {

    if (e.target.files.length > 0) {
		const file = e.target.files[0];
		console.log('file: ', file)

		const fileExtension = file['name'].split('.').pop();
		const type = file['type'] || (['glb', 'gltf'].includes(fileExtension) ? 'model/gltf-binary' : 'image/jpeg')

		setMimeType(type)
		const fileReader = new FileReader();
        fileReader.onload = (evt:any) => {
          const { result } = evt.target;
          if (result) {
            setImage(result);
			if(onChangeCapture){
				onChangeCapture(e);
			}
          } else {
			if(onChangeCapture){
				onChangeCapture(null);
			}
		  }
        }
    	fileReader.readAsDataURL(file);
      /*if (file.type.match(/image\/(png|jpg|jpeg|ico)/i)) {
        const fileReader = new FileReader();
        fileReader.onload = (evt:any) => {
          const { result } = evt.target;
          if (result) {
            setImage(result);
			if(onChangeCapture){
				onChangeCapture(e);
			}
          } else {
			if(onChangeCapture){
				onChangeCapture(null);
			}
		  }
        }
        fileReader.readAsDataURL(file);      
      } else {
        if(onChangeCapture){
			onChangeCapture(null);
			setImage('');
		}
      }*/
    } else {
		setImage(null);
	}
  }

const abcd = ()=>{
	console.log('Event catched')
}



  useEffect(() => {
	if(uploadedUrl){
		setImage(uploadedUrl);
	}
  },[uploadedUrl])


	return (
		<div className={`${className} `}>
			<input
				ref={ref}
				type="file"
				className={`block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100`}
				onChangeCapture={changeStoreFavIconHandler}								

				accept=".png,.jpg,.ico"
				{...args}
			/>
			{image && (
				<div className={"mt-3"}>
					{/* <NcImage src={image} className={'h-40'} /> */}
					<DisplayFile mimeType={mimeType} link={image} placeholder={''} className={'h-40'} />
				</div>
			)}
		</div>
      
    );
  }
);

export default InputFile;
