import { takeLatest, all, takeEvery } from 'redux-saga/effects'; // fork, take,  takeEvery, takeLeading
import { SAGA_ACTIONS } from '../../_config';

import * as userSaga from './userSaga';
import * as commonSaga from './commonSaga';
import * as collectionSaga from './collectionSaga';
import * as payoutGroupSaga from './payoutGroupSaga';
import * as productSaga from './productSaga';
import * as siteSettingSaga from './siteSettingSaga';
import * as themeSaga from './themeSaga';
import * as cmsSaga from './cmsSaga';
import * as emailTempltSaga from './emailTempltSaga';
import * as employeesSaga from './employeesSaga';
import * as couponSaga from './couponSaga';
import * as ordersSaga from './ordersSaga';
import * as preSaleSaga from './preSalesSaga';

import * as basketSaga from './basketSaga';
import * as metaGallerySaga from './metaGallerySaga';

import * as gallerySaga from './gallerySaga';

export function* sagas() {
  yield all([
    takeLatest(SAGA_ACTIONS.USER.LOGIN, userSaga.login),
    takeLatest(SAGA_ACTIONS.USER.LOGOUT, userSaga.logout),
    takeLatest(SAGA_ACTIONS.USER.REGISTER.STEP1, userSaga.registerStepOne),
    takeLatest(SAGA_ACTIONS.USER.REGISTER.STEP2, userSaga.registerStepTwo),
    takeLatest(SAGA_ACTIONS.USER.REGISTER.STEP3, userSaga.registerStepThree),
    takeLatest(SAGA_ACTIONS.USER.REGISTER.STEP4, userSaga.registerStepFour),
    takeLatest(SAGA_ACTIONS.USER.CONNECT_WALLET, userSaga.connectWallet),
    takeLatest(SAGA_ACTIONS.USER.GET_PROFILE, userSaga.getProfileDetails),
    takeLatest(SAGA_ACTIONS.USER.EDIT_PROFILE, userSaga.updateProfile),
    takeLatest(SAGA_ACTIONS.USER.CHANGE_PASSWORD, userSaga.changePassword),
    takeLatest(SAGA_ACTIONS.USER.CUSTOMER_ADD, userSaga.customerAdd),
    takeLatest(SAGA_ACTIONS.USER.CUSTOMER_LIST, userSaga.customerList),
    takeEvery(SAGA_ACTIONS.USER.DELETE_CUSTOMER, userSaga.deleteCustomer),
    takeEvery(SAGA_ACTIONS.USER.SIGNUP, userSaga.signup),
    takeEvery(SAGA_ACTIONS.USER.GET_USER_PROFILE, userSaga.getUserProfile),

    takeLatest(SAGA_ACTIONS.COMMON.GET_REPORT, commonSaga.getReport),
    takeLatest(SAGA_ACTIONS.COMMON.POST_REPORT, commonSaga.postReport),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_REPORT, commonSaga.deleteReport),
    takeLatest(SAGA_ACTIONS.COMMON.GET_REPORT_TYPE, commonSaga.getReportType),
    takeLatest(SAGA_ACTIONS.COMMON.UPDATE_MARKETPLACE_FEES, commonSaga.updateMarketplaceFees),
    takeEvery(SAGA_ACTIONS.COMMON.FILE_UPLOAD, commonSaga.fileUpload),
    takeLatest(SAGA_ACTIONS.COMMON.GET_BLOCKCHAIN_NETWORKS, commonSaga.getBlockchainNetworks),
    takeLatest(SAGA_ACTIONS.COMMON.GET_DATE_RANGE_TYPES, commonSaga.getDateRangeTypes),
    takeLatest(SAGA_ACTIONS.COMMON.SAVE_CONTRACT_TXN, commonSaga.saveContractTxn),
    takeLatest(SAGA_ACTIONS.COMMON.SAVE_CONTRACT_CREATE_UPDATE_TXN, commonSaga.saveContractDeployTxn),
    takeLatest(SAGA_ACTIONS.COMMON.ACTIVITY, commonSaga.getActivities),
    takeLatest(SAGA_ACTIONS.COMMON.GET_CONTACTUS, commonSaga.getContactUs),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_REPORT, commonSaga.deleteContactUs),
    takeLatest(SAGA_ACTIONS.COMMON.GET_COUNTRY, commonSaga.getCountries),
    takeLatest(SAGA_ACTIONS.COMMON.GET_LIKE_UNLIKE, commonSaga.getLikeUnlike),
    takeLatest(SAGA_ACTIONS.COMMON.POST_LIKE_UNLIKE, commonSaga.postLikeUnlike),
    takeLatest(SAGA_ACTIONS.COMMON.GET_FAVORITE, commonSaga.getFavorite),
    takeLatest(SAGA_ACTIONS.COMMON.POST_FAVORITE, commonSaga.postFavorite),
    takeLatest(SAGA_ACTIONS.COMMON.GET_FOLLOW_UNFOLLOW, commonSaga.getFollowunfollow),
    takeLatest(SAGA_ACTIONS.COMMON.POST_FOLLOW_UNFOLLOW, commonSaga.postFollowunfollow),
    takeLatest(SAGA_ACTIONS.COMMON.DASHBOARD_GET_COUNT, commonSaga.getDashboardCount),
    takeLatest(SAGA_ACTIONS.COMMON.DASHBOARD_GET_ORDER_REVENUE, commonSaga.getDashboardOrderRevenue),
    takeLatest(SAGA_ACTIONS.COMMON.DASHBOARD_GET_PRODUCT, commonSaga.getDashboardProducts),


    takeLatest(SAGA_ACTIONS.COLLECTION.GET_COLLECTION_TYPES, collectionSaga.getCollectionTypes),
    takeLatest(SAGA_ACTIONS.COLLECTION.ADD_UPDATE_DETAILS, collectionSaga.addUpdateDetails),
    takeEvery(SAGA_ACTIONS.COLLECTION.GET_COLLECTIONS, collectionSaga.getCollections),
    takeEvery(SAGA_ACTIONS.COLLECTION.DELETE_COLLECTION, collectionSaga.deleteCollection),
    takeLatest(SAGA_ACTIONS.COLLECTION.UPDATE_SOCIALS, collectionSaga.updateCollectionSocials),
    takeLatest(SAGA_ACTIONS.COLLECTION.UPDATE_PRESALE, collectionSaga.updateCollectionPresale),
    takeLatest(SAGA_ACTIONS.COLLECTION.UPDATE_GENESIS, collectionSaga.updateCollectionGenesis),
    takeLatest(SAGA_ACTIONS.COLLECTION.ASSIGN_PAYOUT_GROUP, collectionSaga.assignCollectionPayoutGroup),
    takeLatest(SAGA_ACTIONS.COLLECTION.FETCH_CONTRACT_ABI, collectionSaga.fetchCollectionAbi),
    takeLatest(SAGA_ACTIONS.COLLECTION.UPDATE_FEATURE, collectionSaga.updateCollectionFeature),
    takeLatest(SAGA_ACTIONS.COLLECTION.UPDATE_PUBLISH_STATUS, collectionSaga.updateCollectionPublishStatus),
    takeLatest(SAGA_ACTIONS.COLLECTION.UPDATE_BAN_STATUS, collectionSaga.updateCollectionBanStatus),
    takeLatest(SAGA_ACTIONS.COLLECTION.DEPLOY_CONTRACT, collectionSaga.deployCollectionThroughAPI),

    takeLatest(SAGA_ACTIONS.COUPON.LIST, couponSaga.getCoupons),
    takeLatest(SAGA_ACTIONS.COUPON.LIST_TYPE, couponSaga.getCouponTypes),
    takeLatest(SAGA_ACTIONS.COUPON.LIST_DISCOUNT_TYPE, couponSaga.getCouponDiscountTypes),
    takeLatest(SAGA_ACTIONS.COUPON.ADD, couponSaga.addCoupon),
    takeLatest(SAGA_ACTIONS.COUPON.EDIT, couponSaga.updateCoupon),
    takeLatest(SAGA_ACTIONS.COUPON.DELETE, couponSaga.deleteCoupon),

    takeLatest(SAGA_ACTIONS.PAYOUT_GROUP.GET_PAYOUT_GROUPS, payoutGroupSaga.getPayoutGroups),
    takeLatest(SAGA_ACTIONS.PAYOUT_GROUP.ADD_PAYOUT_GROUP, payoutGroupSaga.addPayoutGroups),
    takeLatest(SAGA_ACTIONS.PAYOUT_GROUP.UPDATE_PAYOUT_GROUP, payoutGroupSaga.updatePayoutGroups),
    takeLatest(SAGA_ACTIONS.PAYOUT_GROUP.DELETE_PAYOUT_GROUP, payoutGroupSaga.deletePayoutGroups),

    takeEvery(SAGA_ACTIONS.PRODUCT.GET_CATEGORIES, productSaga.getCategories),
    takeEvery(SAGA_ACTIONS.PRODUCT.ADD_UPDATE_CATEGORY, productSaga.addUpdateCategory),
    takeEvery(SAGA_ACTIONS.PRODUCT.DELETE_CATEGORY, productSaga.deleteCategory),
    takeLatest(SAGA_ACTIONS.PRODUCT.ASSIGN_REDEMPTION, productSaga.assignRedemptionProduct),
    takeLatest(SAGA_ACTIONS.PRODUCT.DELETE_REDEMPTION, productSaga.deleteRedemptionProduct),
    takeLatest(SAGA_ACTIONS.PRODUCT.LIST_REDEMPTION, productSaga.listRedemptionProduct),
    takeLatest(SAGA_ACTIONS.PRODUCT.DELETE_PREVIEW_IMAGE, productSaga.deleteProductPreviewImage),
    takeLatest(SAGA_ACTIONS.PRODUCT.ASSIGN_PREVIEW_PRODUCT, productSaga.assignPreviewProduct),
    takeLatest(SAGA_ACTIONS.PRODUCT.UPDATE_PRODUCT_PRICE, productSaga.updateProductPrice),
    takeLatest(SAGA_ACTIONS.PRODUCT.PRODUCT_GROUP_LIST, productSaga.getPayoutGroup),
    takeLatest(SAGA_ACTIONS.PRODUCT.PRODUCT_ADD, productSaga.addProduct),
    takeLatest(SAGA_ACTIONS.PRODUCT.PRODUCT_UPDATE, productSaga.updateProduct),
    takeEvery(SAGA_ACTIONS.PRODUCT.GET, productSaga.getProducts),
    takeEvery(SAGA_ACTIONS.PRODUCT.MAPPRODUCTPAYOUTGROUP, productSaga.mapProductPayout),
    takeEvery(SAGA_ACTIONS.PRODUCT.PUBLISH, productSaga.publishProducts),
    takeEvery(SAGA_ACTIONS.PRODUCT.DELETE, productSaga.deleteProducts),
    takeEvery(SAGA_ACTIONS.PRODUCT.FEATURE, productSaga.featuredProducts),
    takeEvery(SAGA_ACTIONS.PRODUCT.GET_NFT, productSaga.getMyNft),
    takeEvery(SAGA_ACTIONS.PRODUCT.GET_OWNER_BY_NFT, productSaga.getOwnerByNft),

    takeLatest(SAGA_ACTIONS.USER.LOGINSTEP1, userSaga.loginstep1),
    takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, userSaga.forgotpassword),
    takeLatest(SAGA_ACTIONS.USER.RESET_PASSWORD, userSaga.setforgottenpassword),
    takeEvery(SAGA_ACTIONS.STORE.CONFIG, userSaga.getStoreConfiguration),
    takeEvery(SAGA_ACTIONS.STORE.CONFIG_SAVE, userSaga.saveStoreConfiguration),
    takeEvery(SAGA_ACTIONS.CUSTOMFIELD.GET_TYPE, productSaga.getCustomFieldType),
    takeEvery(SAGA_ACTIONS.CUSTOMFIELD.SAVE, productSaga.saveCustomfield),
    takeEvery(SAGA_ACTIONS.CUSTOMFIELD.GET, productSaga.getCustomfields),
    takeEvery(SAGA_ACTIONS.CUSTOMFIELD.DELETE, productSaga.deleteCustomfield),

    takeLatest(SAGA_ACTIONS.PRODUCT.GET_OWNER_NFT, productSaga.getOwnerNFT),


    takeEvery(SAGA_ACTIONS.SITE_SETTINGS.GET_SITE_SETTINGS, siteSettingSaga.getSiteSettings),
    takeEvery(SAGA_ACTIONS.SITE_SETTINGS.POST_SITE_SETTINGS, siteSettingSaga.postSiteSettings),
    takeLatest(SAGA_ACTIONS.SITE_SETTINGS.ADD_CUSTODIAL_WALLET, siteSettingSaga.addCustodialWallet),
    takeLatest(SAGA_ACTIONS.SITE_SETTINGS.FETCH_CUSTODIAL_WALLET, siteSettingSaga.fetchCustodialWallet),

    takeEvery(SAGA_ACTIONS.THEMES.GET, themeSaga.getThemes),
    takeEvery(SAGA_ACTIONS.THEMES.POST, themeSaga.saveTheme),

    takeEvery(SAGA_ACTIONS.CMS.GET_LIST, cmsSaga.getCmsList),
    takeEvery(SAGA_ACTIONS.CMS.DELETE_CMS, cmsSaga.deleteCms),
    takeLatest(SAGA_ACTIONS.CMS.ADD_CMS, cmsSaga.addCms),
    takeLatest(SAGA_ACTIONS.CMS.EDIT_CMS, cmsSaga.editCms),

    takeEvery(SAGA_ACTIONS.EMAIL_TEMPLATES.GET, emailTempltSaga.getEmailTemplaits),
    takeLatest(SAGA_ACTIONS.EMAIL_TEMPLATES.EDIT, emailTempltSaga.updateEmailTemplate),
    takeLatest(SAGA_ACTIONS.EMAIL_TEMPLATES.STATUS_CHANGE, emailTempltSaga.changeStatus),

    takeEvery(SAGA_ACTIONS.EMPLOYEE.GET, employeesSaga.getEmployees),
    takeEvery(SAGA_ACTIONS.EMPLOYEE.DELETE, employeesSaga.deleteEmployee),
    takeLatest(SAGA_ACTIONS.EMPLOYEE.ADD, employeesSaga.addEmployee),
    takeLatest(SAGA_ACTIONS.EMPLOYEE.UPDATE, employeesSaga.updateEmployee),

    takeLatest(SAGA_ACTIONS.ORDERS.GET, ordersSaga.getOrdersList),

    takeLatest(SAGA_ACTIONS.PRE_SALE.GET, preSaleSaga.getPreSaleList),
    takeLatest(SAGA_ACTIONS.PRE_SALE.ADD, preSaleSaga.addPreSale),
    takeLatest(SAGA_ACTIONS.PRE_SALE.STATUS_CHANGE, preSaleSaga.statusChangePreSale),



    takeEvery(SAGA_ACTIONS.BASKET.GET_BASKET, basketSaga.getBasket),
    takeEvery(SAGA_ACTIONS.BASKET.ADD_BASKET, basketSaga.addBasket),
    takeEvery(SAGA_ACTIONS.BASKET.EDIT_BASKET, basketSaga.editBasket),
    takeEvery(SAGA_ACTIONS.BASKET.DELETE_BASKET, basketSaga.deleteBasket),
    takeEvery(SAGA_ACTIONS.META_GALLERY.ADD_META_GALLERY, metaGallerySaga.addMetaGallery),
    takeEvery(SAGA_ACTIONS.META_GALLERY.GET_META_GALLERY, metaGallerySaga.getMetaGallery),
    takeEvery(SAGA_ACTIONS.META_GALLERY.DELETE_META_GALLERY, metaGallerySaga.deleteMetaGallery),

    takeLatest(SAGA_ACTIONS.GALLERY.LIST, gallerySaga.getGallery),
    takeEvery(SAGA_ACTIONS.GALLERY.INDUSTRY_LIST, gallerySaga.getIndustryList),
    takeLatest(SAGA_ACTIONS.GALLERY.ADD, gallerySaga.addGallery),
    takeLatest(SAGA_ACTIONS.GALLERY.EDIT, gallerySaga.updateGallery),
    takeLatest(SAGA_ACTIONS.GALLERY.DELETE, gallerySaga.deleteGallery),


  ]);
}