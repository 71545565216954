import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function usePreSaleApi() {

  const callApi = useApiCall()

  const getPreSaleList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRE_SALE.GET, data, onSuccess, onError);
  }
  const addPreSale = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRE_SALE.ADD, data, onSuccess, onError);
  }
  const statusChangePreSale = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRE_SALE.STATUS_CHANGE, data, onSuccess, onError);
  }

  return {
    getPreSaleList,
    addPreSale,
    statusChangePreSale
  }
}