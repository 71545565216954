import React, { FC, useEffect, useState, useRef } from "react";
import Web3 from 'web3';

import useMetaMask from "../../_common/provider/metamask-provider";
import { METAMASK_KEYS, CHAIN_ADD_CONFIG } from '../../_common/_constant'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import { useUserCryptoDetails } from '../../_common/hooks/selectors/userSelector'
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import AddFund from './AddFund'
import NcImage from "shared/NcImage/NcImage";
import { getPriceIconUrl } from "_common/functions";
import NftMoreDropdown from "components/NftMoreDropdown";
import NcCustomModal from "components/Modal/NcCustomModal";

export interface NetworkBalanceProps {
  className?: string;
  address: string;
}

const NetworkBalance: FC<NetworkBalanceProps> = ({ className = "", address = "" }) => {
  const { account, getNetworkBalance } = useMetaMask()
  const { ethereum }: any = window;

  const [ networkBalances, setNetworkBalances ] = useState({})

  const [modalTitle, setModalTitle] = useState<string>('')
  const [modalContent, setModalContent] = useState<any>('')
  const [isShowWalletModal, setIsShowWalletModal] = useState(false);

  useEffect(() => {
    const intervalAr: any[] = [];

    handleFetchBalance();
    const interval = setInterval(() => {
      handleFetchBalance();
    }, 1000 * 60)
    intervalAr.push(interval)

    return () => {
      intervalAr.map(e => clearInterval(e))
    }
  }, [address])

  const handleAddFund = (symbol: string) => {
    setModalTitle('Add Fund')
    showWalletModal();
    setModalContent(<AddFund className="" cancel={closeWalletModal} currency={symbol} address={address} />)
  }

  const handleFetchBalance = () => {
    const web3 = new Web3(ethereum);
    Object.keys(CHAIN_ADD_CONFIG).map((chainId: any, key) => {
      getNetworkBalance(address, CHAIN_ADD_CONFIG[chainId as keyof typeof CHAIN_ADD_CONFIG]['rpcUrls'][0]).then((balance: string) => {
        const priceUrl = getPriceIconUrl(CHAIN_ADD_CONFIG[chainId as keyof typeof CHAIN_ADD_CONFIG]['nativeCurrency']['customCurrency']);
        setNetworkBalances((prevState) => ({
          ...prevState,
          [''+chainId]: {
            chainName: CHAIN_ADD_CONFIG[chainId as keyof typeof CHAIN_ADD_CONFIG]['chainName'],
            symbol: CHAIN_ADD_CONFIG[chainId as keyof typeof CHAIN_ADD_CONFIG]['nativeCurrency']['symbol'],
            balance: parseFloat(web3.utils.fromWei(balance, 'ether')).toFixed(4),
            priceUrl: priceUrl
          }
        }))
      }).catch((err: any) => {
        console.log('Network balance error: ', err)
      })
    })
  }

  const showWalletModal = () => {
		setIsShowWalletModal(true);
	}
	const closeWalletModal = () => {
		setIsShowWalletModal(false);
		setModalContent('')
	}

  return (
    <div className={`nc-PageConnectWallet ${className}`} data-nc-id="PageConnectWallet">
      <div className="container">
        <div className="my-6 sm:lg:my-16 lg:my-24 max-w-3xl mx-auto space-y-8 sm:space-y-10">
          <div className="max-w-2xl netbalance-wrapper">
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              {
                Object.keys(networkBalances).map((chainId: string, index: number) => (
                  <div className="border flex justify-between p-2 rounded-2xl items-center netbalance-row" key={index + new Date().getTime()}>
                    <div className="flex justify-between">
                      <NcImage
                        containerClassName="flex aspect-w-11 aspect-h-12 p-4 mr-2 rounded-3xl overflow-hidden z-0"
                        src={networkBalances[chainId as keyof typeof networkBalances]['priceUrl']}
                        className="object-contain w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
                      />
                      <div>
                        <strong>{networkBalances[chainId as keyof typeof networkBalances]['chainName']}</strong>
                        <p>{networkBalances[chainId as keyof typeof networkBalances]['symbol']}</p>
                      </div>
                    </div>
                    
                    <div className="flex gap-3">
                    <div className="flex flex-col justify-center">
                      {networkBalances[chainId as keyof typeof networkBalances]['balance']}
                    </div>
                    <NftMoreDropdown
                    actions={[
                      {
                        id: "add_fund",
                        name: `Add ${networkBalances[chainId as keyof typeof networkBalances]['symbol']}`,
                        icon: "las la-plus-circle",
                        callBack: () => handleAddFund(networkBalances[chainId as keyof typeof networkBalances]['symbol'])
                      },
                      // {
                      //   id: "Action",
                      //   name: "Action 2",
                      //   icon: "las la-trash-alt",
                      // },
                    ]}
                    containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
                  />
                  </div>
                  </div>
                ))
              }
            </span>
          </div>
        </div>
      </div>
      <NcCustomModal contentExtraClass="max-w-3xl" modalTitle={modalTitle} modalStatus={isShowWalletModal} closeModal={closeWalletModal}>
				{modalContent}
			</NcCustomModal>
    </div>
  );
};

export default NetworkBalance;
