import React from "react";
import { BrowserRouter, Router, Routes, Route } from 'react-router-dom';
import PrivateRoutes from '../_config/privateRoutes';
import OpenRoutes from '../_config/openRoutes';





import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import NftDetailPage from "containers/NftDetailPage/NftDetailPage";
import PayoutGroup from "containers/PayoutGroup/PayoutGroupList";
import PageCollection from "containers/PageCollection";
import Collection from "containers/Collection/CollectionList";
import AddUpdateCollection from "containers/Collection/addUpdateCollection";
import CollectionListByCustomer from "containers/CollectionByCustomer/CollectionList";
import AddUpdatePayoutGroup from "containers/PayoutGroup/AddUpdatePayoutGroup";
import PageSearch from "containers/PageSearch";
import PageUploadItem from "containers/PageUploadItem";
import PageConnectWallet from "containers/PageConnectWallet";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import CategoryList from "containers/Category/CategoryList";
import AddUpdateCategory from "containers/Category/addUpdateCategory";
import { URLS } from "_config";
import PageForgotpassword from "containers/PageForgotpassword/PageForgotpassword";
import PageSetforgottenpassword from "containers/PageSetforgottenpassword/PageSetforgottenpassword";
import PageStoreconfiguration from "containers/AccountSettings/StoreConfiguration/PageStoreconfiguration";
import PageCustomfieldmanage from "containers/ProductSettings/CustomFields/PageCustomfieldmanage";
import PageAddcustomfield from "containers/ProductSettings/CustomFields/PageAddcustomfield";
import PageProducts from "containers/ProductSettings/Products/PageProducts";
import ProductListByCustomer from "containers/ProductSettings/ProductByCustomer/PageProducts";
import PreSales from "containers/PreSales/PreSales";
import PageProductAdd from "containers/ProductSettings/Products/PageProductAdd";
import AddToPreSales from "containers/PreSales/AddToPreSales";
import Airdrops from "containers/Airdrops/Airdrops";
import AddToAirdrops from "containers/Airdrops/AddToAirdrops";
import AddToAirdropsTwo from "containers/Airdrops/AddToAirdropsTwo";
import PageTheme from "containers/Configuration/Theme/PageTheme";
import SiteSettings from "containers/SiteSettings/SiteSettings";
import Cms from "containers/CMS/Cms";
import AddToCms from "containers/CMS/AddToCms";
import EmailTemplate from "containers/EmailTemplate/EmailTemplate";
import UpdateEmailTemplate from "containers/EmailTemplate/UpdateEmailTemplate";
import Employees from "containers/Employees/Employees";
import AddToEmployees from "containers/Employees/AddToEmployees";
import CustomerList from "containers/Customer/CustomerList";
import DetailsCustomer from "containers/Customer/DetailsCustomer";
import DesignCreate from "containers/Generator/DesignCreate";
import CouponList from "containers/Coupon/CouponList";
import GalleryList from "containers/Gallery/GalleryList";
import AddUpdateGallery from "containers/Gallery/AddUpdateGallery";
import CouponAddUpdate from "containers/Coupon/AddUpdateCoupon";
import CustomerListMkt from "containers/CustomerListFEMkt/CustomerListMkt";
import AddUpdateCustomerMkt from "containers/CustomerListFEMkt/addUpdateCustomerMkt";
import ViewProfile from "containers/CustomerListFEMkt/ViewProfile";
import Orders from "containers/Orers/Orders";
import OrderDetails from "containers/Orers/OrderDetails";
import Activities from "containers/Activities/Activities";
import ReportedNfts from "containers/ReportedNFTs/ReportedNfts";
import ContactUsList from "containers/ContactUsList/ContactUsList";
import PageDashboard from "containers/PageHome/PageDashboard";
import ActualNftDetailsPage from "containers/NftDetailPage/ActualNftDetailsPage";
import ProductDetailPage from "containers/NftDetailPage/ProductDetailPage";
import Baskets from "containers/Basket/BasketList";
import AddUpdateBasket from "containers/Basket/AddUpdateBasket";

export const pages: Page[] = [
	//{ path: "/", component: PageHome2 },
	{ path: "/", component: PageDashboard },
	{ path: "/#", component: PageHome2 },
	{ path: "/baskets", component: Baskets },
	{ path: "/basket/create", component: AddUpdateBasket },
	{ path: "/basket/edit/:id", component: AddUpdateBasket },
	{ path: "/baskets", component: PageHome },
	{ path: "/home2", component: PageHome },
	{ path: "/home3", component: PageHome3 },
	{ path: "/home-header-2", component: PageHome },
	{ path: "/nft-detailt", component: NftDetailPage },
	{ path: "/page-collection", component: PageCollection },
	{ path: "/collection", component: Collection },
	{ path: "/collection/:id", component: AddUpdateCollection },
	{ path: "/customer-collections", component: CollectionListByCustomer },
	{ path: "/customer-products", component: ProductListByCustomer },
	{ path: "/page-search", component: PageSearch },
	{ path: "/page-author", component: AuthorPage },
	{ path: "/account", component: AccountPage },
	{ path: "/page-upload-item", component: PageUploadItem },
	{ path: "/connect-wallet", component: PageConnectWallet },
	{ path: "/blog", component: BlogPage },
	{ path: "/blog-single", component: BlogSingle },
	{ path: "/contact", component: PageContact },
	{ path: "/about", component: PageAbout },
	{ path: "/payout-groups", component: PayoutGroup },
	{ path: "/payout-groups/:id", component: AddUpdatePayoutGroup },
	{ path: "/pre-sales", component: PreSales },
	{ path: "/pre-sales/new", component: AddToPreSales },
	{ path: "/airdrops", component: Airdrops },
	{ path: "/airdrops/new", component: AddToAirdrops },
	{ path: "/airdrops/new-two", component: AddToAirdropsTwo },
	{ path: "/site-settings", component: SiteSettings },
	{ path: "/cms", component: Cms },
	{ path: "/cms/:type", component: AddToCms },
	{ path: "/email-template", component: EmailTemplate },
	{ path: "/email-template/:id", component: UpdateEmailTemplate },
	{ path: "/employees", component: Employees },
	{ path: "/employees/:type", component: AddToEmployees },
	/* { path: "/signup", component: PageSignUp }, */
	/*  { path: "/login", component: PageLogin }, */
	{ path: "/subscription", component: PageSubcription },
	{ path: "/customer-list", component: CustomerList },
	{ path: "/customer-list/:id", component: DetailsCustomer },
	{ path: "/design-create", component: DesignCreate },
	{ path: "/coupons", component: CouponList },
	{ path: "/coupons/add", component: CouponAddUpdate },
	{ path: "/coupons/edit/:id", component: CouponAddUpdate },
	{ path: "/customerlist-mkt", component: CustomerListMkt },
	{ path: "/customerlist-mkt/add", component: AddUpdateCustomerMkt },
	{ path: "/view-profile/:id", component: ViewProfile },
	{ path: "/orders", component: Orders },
	{ path: "/order-details/:id", component: OrderDetails },
	{ path: "/activities", component: Activities },
	{ path: "/reported-nfts", component: ReportedNfts },
	{ path: "/contactus-list", component: ContactUsList },
	{ path: "/nft/:id", component: ActualNftDetailsPage },
	{ path: "/product/:id", component: ProductDetailPage },
	{ path: "/galleries", component: GalleryList },
	{ path: "/galleries/add", component: AddUpdateGallery },
	{ path: "/galleries/edit/:id", component: AddUpdateGallery },
];

const MyRoutes = () => {
	return (		
		<BrowserRouter basename={process.env.NODE_ENV === "production" ? "" : ""} >
			<React.Fragment>
				<ScrollToTop />
				<SiteHeader />
				<Routes>

					<Route element={<OpenRoutes />} >
						<Route path={URLS.LOGIN} element={<PageLogin />} />
						<Route path={URLS.SIGNUP} element={<PageSignUp />} />
						<Route path={'/forgot-pass'} element={<PageForgotpassword />} />
						<Route path={'/reset-pass'} element={<PageSetforgottenpassword />} />
					</Route>

					<Route element={<Page404 />} />

					<Route element={<PrivateRoutes />} >						
						<Route path={URLS.CATEGORIES} element={<CategoryList />} />
						<Route path={URLS.ADD_UPDATE_CATEGORIES} element={<AddUpdateCategory />} />
						<Route path={'/store-configuration'} element={<PageStoreconfiguration />} />
						<Route path={'/products'} element={<PageProducts />} />
						<Route path={'/products/add'} element={<PageProductAdd />} />
						<Route path={'/products/edit/:id'} element={<PageProductAdd />} />
						<Route path={'/customfields'} element={<PageCustomfieldmanage />} />
						<Route path={'/customfields/add'} element={<PageAddcustomfield />} />
						<Route path={'/customfields/edit/:id'} element={<PageAddcustomfield />} />
						<Route path={'/theme'} element={<PageTheme />} />
						{pages.map(({ component, path }) => {
							const Component = component;
							return <Route key={path} element={<Component />} path={path} />;
						})} 
					</Route>
					
				</Routes>
				<Footer />
			</React.Fragment>
		</BrowserRouter>


	);
};

export default MyRoutes;
