import React, { FC } from "react";
import { getPriceIconUrl, getPriceFormat } from '_common/functions'
import NcImage from "shared/NcImage/NcImage";
import { MdVisibility } from "react-icons/md";

interface Props {
	className?: string;
	count: number;
}

const ViewCount: FC<Props> = ({	className = "",	count = 0}) => {


	return (
		<div className={`nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-gray-800 bg-gray-100 dark:bg-neutral-700  dark:text-neutral-100 relative ${className}`}>
			<div className="flex items-center text-2xl">
				<MdVisibility title={'View'} className="mr-2"/> 
				<span className="text-lg">{count}</span>
			</div>
		</div>
	);
};

export default ViewCount;
