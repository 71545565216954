import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import NcImage from "shared/NcImage/NcImage";
import CardNFT from "components/CardNFT";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import collectionBanner from "images/nfts/collectionBanner.png";
import { nftsImgs } from "contains/fakeData";
import NftMoreDropdown from "components/NftMoreDropdown";
import ButtonDropDownShare from "components/ButtonDropDownShare";
import TabFilters from "components/TabFilters";
import SectionSliderCollections from "components/SectionSliderCollections";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useNavigate, useParams } from "react-router";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Controller, useForm } from "react-hook-form";
import Select from "shared/Select/Select";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { toast } from "react-hot-toast";
import { URLS } from "_config";
import Checkbox from "shared/Checkbox/nativeCheckbox";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useSelector } from "react-redux";
import { StateExtended } from "_common/interfaces/StateExtended";
import InputFile from "shared/InputFile/InputFile";
import FormItem from "components/FormItem";
import Switch from "shared/Switch/Switch";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface Props {
  className?: string;
}

const PageStoreconfiguration: FC<Props> = ({ className = "" }) => {
  const user = Object.assign({}, JSON.parse(JSON.stringify(useSelector((state: StateExtended) => state.user))));
  const { register, handleSubmit, setValue, getValues, watch, formState: { errors }, control, reset } = useForm();
  const { showLoader, hideLoader } = useAppLoader();
  const userApi = useUserApi();
  const commonApi = useCommonApi();
  const navigate = useNavigate();


  const [storeID, setStoreID] = useState(null);
  const [subdomainFilled, setSubdomainFilled] = useState(false);
  const [storeData, setStoreData] = useState<any>(null);
  const [fieldDisable, setFieldDisable] = useState<any>(null);


  const alias:any = watch('subDomainName');
  const domain:any = watch('domainName');
  const customDomain:any = watch('customDomain');








  const uploadFile = (param: FormData) => {
    return new Promise<any>((resolve, reject) => {
      commonApi.fileUpload(param, (message: string, resp: any) => {
        resolve(resp)
      }, (message: string) => {
        reject(message)
      })
    });
  }

  const onSaveStoreSettings = (data: any) => {
    const settingparams = {
      storeName: data.storeName,
      subDomainName: data.subDomainName,
      domainName: data.domainName,
      customDomain: data.customDomain,
      description: data.description,
      isPublished: (data.isPublished ? 1 : 0),
      isFiatEnable: (data.isFiatEnable ? 1 : 0)
    }
    showLoader();
    userApi.saveStoreConfiguration(settingparams, (message: any, resp: any) => {
      hideLoader();
      if (resp.successful === true && resp.code === 200) {
        toast.success("Store configuration saved successfully.");
        console.log(data);
        console.log("Logged in user", user);
        let imgItemType = 4;
        if (user.user.store.length > 0 && user.user.store[0].type === 'Marketplace') {
          imgItemType = 5;
        } else if (user.user.store.length > 0 && user.user.store[0].type === 'Store') {
          imgItemType = 4;
        }

        const PromiseInArray = [];
        if (data.hasOwnProperty('storeLogoUrl') && data.storeLogoUrl.length > 0 && data.storeLogoUrl[0]) {
          let uploadparams: any = new FormData();
          uploadparams.append("", data.storeLogoUrl[0]);
          uploadparams.append("ItemId", storeID);
          uploadparams.append("ItemType", imgItemType);
          uploadparams.append("FileType", 'logo');
          PromiseInArray.push(uploadFile(uploadparams));
        }
        if (data.hasOwnProperty('storeFaviconUrl') && data.storeFaviconUrl.length > 0 && data.storeFaviconUrl[0]) {
          let nextuploadparams: any = new FormData();
          nextuploadparams.append("", data.storeFaviconUrl[0]);
          nextuploadparams.append("ItemId", storeID);
          nextuploadparams.append("ItemType", imgItemType);
          nextuploadparams.append("FileType", 'favicon');
          PromiseInArray.push(uploadFile(nextuploadparams));
        }
        if (data.hasOwnProperty('darkThemeLogoUrl') && data.darkThemeLogoUrl.length > 0 && data.darkThemeLogoUrl[0]) {
          let uploadparams: any = new FormData();
          uploadparams.append("", data.darkThemeLogoUrl[0]);
          uploadparams.append("ItemId", storeID);
          uploadparams.append("ItemType", imgItemType);
          uploadparams.append("FileType", 'darktheme');
          PromiseInArray.push(uploadFile(uploadparams));
        }
        if (PromiseInArray.length > 0) {
          showLoader();
          Promise.all(PromiseInArray).then((resp: any) => {
            hideLoader();
            let successCount = 0;
            let failCount = 0;
            if (resp.length > 0) {
              for (var i = 0; i < resp.length; i++) {
                if (resp[i].successful === true && resp[i].uploadedFiles.length > 0)
                  successCount++;
                else
                  failCount++
              }
            }
            if (successCount > 0)
              toast.success(`${successCount} File(s) uploaded successfully.`);
            if (failCount > 0)
              toast.error(`${failCount} File(s) failed to upload.`)
            getStoreConfiguration();
          }).catch((err: any) => {
            hideLoader();
            toast.error(`${err}`)
            getStoreConfiguration();
          });
        } else {
          getStoreConfiguration();
        }
      } else {
        toast.error(message);
      }
    }, (message: string) => {
      hideLoader();
      toast.error(message);
    });
  }

  const getStoreConfiguration = () => {
    showLoader();
    userApi.getStoreConfiguration({}, (message: any, resp: any) => {
      hideLoader();
      if (resp.successful === true && resp.code === 200 && resp.storeConfiguration.length > 0) {
        console.log("Get cconfiguration", resp.storeConfiguration);
        setStoreData(resp.storeConfiguration[0]);
        setStoreID(resp.storeConfiguration[0].storeID);
        const disableStates = {
          subdomain: resp.storeConfiguration[0].subDomainName,
          domain: resp.storeConfiguration[0].domainName,
          custom: resp.storeConfiguration[0].customDomain
        }
        setFieldDisable(disableStates);

        reset({
          storeName: resp.storeConfiguration[0].storeName,
          subDomainName: resp.storeConfiguration[0].subDomainName,
          domainName: resp.storeConfiguration[0].domainName, //(resp.storeConfiguration[0].domainName ? resp.storeConfiguration[0].domainName : 'nftwards.com'),
          customDomain: resp.storeConfiguration[0].customDomain,
          description: resp.storeConfiguration[0].description,
          isPublished: resp.storeConfiguration[0].isPublished == 1 ? true : false,
          isFiatEnable: resp.storeConfiguration[0].isFiatEnable == 1 ? true : false,
          industryTypeID: resp.storeConfiguration[0].industryTypeID
        });
        setSubdomainFilled((resp.storeConfiguration[0].subDomainName));
      }
    }, (message: string) => {
      hideLoader();
      toast.error(message);
    });
  }



  useEffect(() => {
    getStoreConfiguration();
  }, [])


  return (
    <div className={`nc-PageUploadItem ${className}`} data-nc-id="PageUploadItem">
      <Helmet>
        <title>Store Configuration</title>
      </Helmet>
      <div className="container">
        <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              Store Configuration
            </h2>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              You can set preferred display name, create your profile URL and manage other personal settings.
            </span>
          </div>
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSaveStoreSettings)}>
            <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">

              <FormItem label="Name">
                <Input
                  placeholder="Example Doe"
                  type="text"
                  className="mt-1"
                  {...register("storeName", { required: true, maxLength: 80 })}
                />
                {errors?.storeName ? <div className="invalid-feedback text-red-600">
                  {errors.storeName.type === "required" ? 'Storename shouldn\'t be blank' : null}
                </div> : null}
              </FormItem>

              
                  <div className={"grid sm:grid-cols-1 lg:grid-cols-2 gap-6"}>
                    <FormItem label="Domain Alias/Subdomain">
                      <Input
                        placeholder="Enter your domain alias/subdomain"
                        type="text"
                        className="mt-1"
                        disabled={fieldDisable?.subdomain}
                        {...register("subDomainName", { required: (customDomain?false:true), maxLength: 80 })}
                      />
                    </FormItem>
                    <FormItem label="Domain Name">
                      <Input
                        placeholder="Enter your domain"
                        type="text"
                        disabled={fieldDisable?.domain} 
                        className={"mt-1"}
                        defaultValue={'nftwardsv2.com'}
                        {...register("domainName", {
                          required: (customDomain?false:true)
                        })}
                      />
                    </FormItem>
                  </div>
                  {errors?.subDomainName ? <div className="invalid-feedback text-red-600">
                    {errors.subDomainName.type === "required" ? 'Domain alias/subdomain shouldn\'t be blank.' : null}
                    {errors?.domainName && errors.domainName.type === "required" ? 'Domain shouldn\'t be blank.' : null}
                  </div> : null}
                


              
                <div className={"grid sm:grid-cols-1 lg:grid-cols-2 gap-6"}>
                  <FormItem label="Custom Domain">
                    <Input
                      placeholder="Enter your custom domain "
                      type="text"
                      className="mt-1" 
                      disabled={fieldDisable?.custom} 
                      {...register("customDomain", { required: (alias && domain)?false:true, maxLength: 80 })}
                    />
                    {errors?.customDomain ? <div className="invalid-feedback text-red-600">
                      {errors.customDomain.type === "required" ? 'Domain name shouldn\'t be blank' : null}
                    </div> : null}
                  </FormItem>
                  <div></div>
                </div>
              

              <FormItem label="Description">
                <Textarea
                  className="mt-1"
                  rows={5}
                  style={{ resize: "none" }}
                  {...register("description", { required: false })}
                />
              </FormItem>
              <div className={"grid sm:grid-cols-1 lg:grid-cols-2 gap-6"}>
                <FormItem label={""}>
                  <Switch label="Published(Public)?" {...register("isPublished", { required: false })} />
                </FormItem>

                <FormItem label={""}>
                  <Switch label="Enable Fiat?" {...register("isFiatEnable", { required: false })} />
                </FormItem>
              </div>
              <div className={"grid grid-cols-5 sm:grid-cols-5 gap-5 sm:gap-2.5"}>
                <FormItem label="Light Theme Logo" desc={
                  <div>
                    File must be a image (*.png, *.jpg, *.jpeg).
                  </div>
                }>
                  <InputFile  {...register("storeLogoUrl", { required: false })} uploadedUrl={storeData?.storeLogoUrl} onCanPlayCapture={(e: any) => { if (e == null) { setValue('storeLogoUrl', ''); } }} />
                </FormItem>
                <div className="custom-vr-divider"></div>
                <FormItem label="Dark Theme Logo" desc={
                  <div>
                    File must be a image (*.png, *.jpg, *.jpeg).
                  </div>
                }>
                  <InputFile  {...register("darkThemeLogoUrl", { required: false })} uploadedUrl={storeData?.darkThemeLogoUrl} onCanPlayCapture={(e: any) => { if (e == null) { setValue('darkThemeLogoUrl', ''); } }} />
                </FormItem>
                <div className="custom-vr-divider"></div>
                <FormItem label="Favicon" desc={
                  <div>
                    File must be a icon (*.png, *.jpg, *.jpeg) And size must be 32px x 32px
                  </div>
                }>
                  <InputFile  {...register("storeFaviconUrl", { required: false })} uploadedUrl={storeData?.storeFaviconUrl} onCanPlayCapture={(e: any) => { if (e == null) { setValue('storeFaviconUrl', ''); } }} />
                </FormItem>
              </div>
              <div className="pt-2 text-center flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
                <ButtonPrimary type="submit" className="flex-1">Update Details</ButtonPrimary>
              </div>
            </div>
          </form>
        </div>
      </div>



      {/*
      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-20 lg:space-y-28">
        <main>
          <div className="grid grid-cols-2 gap-4">
            <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSaveStoreSettings)}>
              <label className="block">
                <Label>Name</Label>
                <Input
                  placeholder="Example Doe"
                  type="text"
                  className="mt-1"
                  {...register("storeName", { required: true, maxLength: 80 })}
                />
                {errors?.storeName ? <div className="invalid-feedback text-red-600">
                  {errors.storeName.type === "required" ? 'Storename shouldn\'t be blank' : null}
                </div> : null}
              </label>            
              <label className="block">
                <Label>Domain (One time setup)</Label>
                <div className={"flex items-center"}>
                  <Input
                    placeholder="Enter your domain alias/subdomain"
                    type="text"
                    className="mt-1"
                    disabled={subdomainFilled}                                        
                    {...register("subDomainName", { required: true, maxLength: 80 })}
                  />
                  <Input
                    placeholder="Enter your domain"
                    type="text"
                    disabled={subdomainFilled}                    
                    className={"mt-1 ml-2"}
                    defaultValue={'nftwardsv2.com'}
                    {...register("domainName", {
                      required: true
                    })}
                  />
                </div>
                
                {errors?.subDomainName ? <div className="invalid-feedback text-red-600">
                  {errors.subDomainName.type === "required" ? 'Domain alias/subdomain shouldn\'t be blank.' : null}
                  {errors?.domainName && errors.domainName.type === "required" ? 'Domain shouldn\'t be blank.' : null}
                </div> : null}
              </label> 
              <label className="block">
                <Label>Description</Label>
                <Textarea 
                  className="mt-1" 
                  rows={5} 
                  style={{resize: "none"}}
                  {...register("description", { required: false })} 
                />                
              </label> 
              <div className="block">
                <Checkbox label="Published(Public)?" {...register("isPublished", { required: false })}  />
              </div>                                     
              <div className={"grid grid-cols-2 gap-4"}>

                <label className="block">
                  <Label>Store Logo</Label>
                  <InputFile  {...register("storeLogoUrl", { required: false })} uploadedUrl={storeData?.storeLogoUrl} onCanPlayCapture={(e: any) => { if (e == null) { setValue('storeLogoUrl', ''); } }} />
                </label>
              
                <label className="block">
                  <Label>Store Favicon (32px x 32px)</Label>
                  <InputFile  {...register("storeFaviconUrl", { required: false })} uploadedUrl={storeData?.storeFaviconUrl} onCanPlayCapture={(e: any) => { if (e == null) { setValue('storeFaviconUrl', ''); } }} />
                </label>
              </div>
              <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
                  <ButtonPrimary type="submit" className="flex-1">Update Details</ButtonPrimary>                  
              </div>
              <div>
                <ButtonPrimary type="submit"></ButtonPrimary>
              </div>
            </form>            
          </div>
        </main>
      </div>
      */}
    </div>
  );
};

export default PageStoreconfiguration;
