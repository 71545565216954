import React from "react";
import { AiFillMacCommand, AiOutlineApartment, AiOutlineFundProjectionScreen, AiOutlineNotification } from "react-icons/ai";
import moment from "moment";
interface Props {
	product: any
}
const Details = ({ product }: Props) => {


	return (
		<div className="w-full pdx-2 sm:px-0">
			{product.productProperty && product.productProperty.length > 0 ?
				<div>
					<div className="text-lg font-bold py-2 mb-2 flex gap-2 border-b border-neutral-200 dark:border-neutral-700">
						<AiFillMacCommand className="text-2xl mt-1" /> <span className="flex uppercase">Properties</span>
					</div>
					<div className="flex gap-2 flex-wrap">
						{product.productProperty.map((prop: any, index: any) => (
							<div key={index} className={`relative py-4 ${index % 2 === 1 ? "bg-neutradl-100" : ""}`} >
								<div className="bg-blue-100 flex flex-col items-center p-2.5 rounded-md text-blue-800">
									<span className="font-medium">{prop?.propertyName}</span>
									<span className="text-xs mt-1">{prop?.propertyValue}</span>
								</div>
							</div>
						))}
					</div>
				</div>
				:
				product.nftProperty && product.nftProperty.length > 0 ?
					<div>
						<div className="text-lg font-bold py-2 mb-2 flex gap-2 border-b border-neutral-200 dark:border-neutral-700">
							<AiFillMacCommand className="text-2xl mt-1" /> <span className="flex uppercase">Properties</span>
						</div>
						<div className="flex gap-2 flex-wrap">
							{product.nftProperty.map((prop: any, index: any) => (
								<div key={index} className={`relative py-4 ${index % 2 === 1 ? "bg-neutradl-100" : ""}`} >
									<div className="bg-blue-100 flex flex-col items-center p-2.5 rounded-md text-blue-800">
										<span className="font-medium">{prop?.propertyName}</span>
										<span className="text-xs mt-1">{prop?.propertyValue}</span>
									</div>
								</div>
							))}
						</div>
					</div>
					: null
			}
			{product.productLevel && product.productLevel.length > 0 ?
				<div>
					<div className="text-lg font-bold py-2 mb-2 flex gap-2 border-b border-neutral-200 dark:border-neutral-700">
						<AiOutlineApartment className="text-2xl mt-1" /> <span className="flex uppercase">Levels</span>
					</div>
					<div >
						{product.productLevel.map((prop: any, index: any) => (
							<div key={index} className={`my-2`} >
								<div className="flex justify-between items-center p-2.5">
									<span className="font-medium">{prop?.levelName}</span>
									<span className="text-xs mt-1">{prop?.minValue} of {prop?.maxValue}</span>
								</div>
								<div className="bg-green-100 border border-green-500 rounded-full overflow-hidden">
									<div className="w-full bg-green-500 py-2" style={{
										width: `${(100 / prop?.maxValue) * prop?.minValue}%`
									}}></div>
								</div>
							</div>
						))}
					</div>
				</div>
				:
				product.nftLevel && product.nftLevel.length > 0 ?
					<div>
						<div className="text-lg font-bold py-2 mb-2 flex gap-2 border-b border-neutral-200 dark:border-neutral-700">
							<AiOutlineApartment className="text-2xl mt-1" /> <span className="flex uppercase">Levels</span>
						</div>
						<div >
							{product.nftLevel.map((prop: any, index: any) => (
								<div key={index} className={`my-2`} >
									<div className="flex justify-between items-center p-2.5">
										<span className="font-medium">{prop?.levelName}</span>
										<span className="text-xs mt-1">{prop?.minValue} of {prop?.maxValue}</span>
									</div>
									<div className="bg-green-100 border border-green-500 rounded-full overflow-hidden">
										<div className="w-full bg-green-500 py-2" style={{
											width: `${(100 / prop?.maxValue) * prop?.minValue}%`
										}}></div>
									</div>
								</div>
							))}
						</div>
					</div>
					:
					null
			}
			{product.productStat && product.productStat.length > 0 ?
				<div>
					<div className="text-lg font-bold py-2 mb-2 flex gap-2 border-b border-neutral-200 dark:border-neutral-700">
						<AiOutlineFundProjectionScreen className="text-2xl mt-1" /> <span className="flex uppercase">Stats</span>
					</div>
					<div className="grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-x-12 gap-y-12 mt-8 lg:mt-10 custom-xs-col-2">
						{product.productStat.map((prop: any, index: any) => (
							<div key={index} className={`my-2 flex items-center`} >
								<p className="text-5xl py-4 px-4">{prop.minValue}</p>
								<div className="flex flex-col p-2.5">
									<span className="font-medium">{prop.statName}</span>
									<span className="text-xs mt-1">Out of {prop.maxValue}</span>
								</div>

							</div>
						))}
					</div>
				</div>
				:
				product.nftStat && product.nftStat.length > 0 ?
					<div>
						<div className="text-lg font-bold py-2 mb-2 flex gap-2 border-b border-neutral-200 dark:border-neutral-700">
							<AiOutlineFundProjectionScreen className="text-2xl mt-1" /> <span className="flex uppercase">Stats</span>
						</div>
						<div className="grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-x-12 gap-y-12 mt-8 lg:mt-10 custom-xs-col-2">
							{product.nftStat.map((prop: any, index: any) => (
								<div key={index} className={`my-2 flex items-center`} >
									<p className="text-5xl py-4 px-4">{prop.minValue}</p>
									<div className="flex flex-col p-2.5">
										<span className="font-medium">{prop.statName}</span>
										<span className="text-xs mt-1">Out of {prop.maxValue}</span>
									</div>

								</div>
							))}
						</div>
					</div>
					:
					null
			}
			{product.productBoosts && product.productBoosts.length > 0 ?
				<div>
					<div className="text-lg font-bold py-2 mb-2 flex gap-2 border-b border-neutral-200 dark:border-neutral-700">
						<AiOutlineNotification className="text-2xl mt-1" /> <span className="flex uppercase">Boosts</span>
					</div>
					<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-12 gap-y-12 mt-8 lg:mt-10 custom-xs-col-2">
						{product.productBoosts.map((prop: any, index: any) => (
							<div key={index} className={`my-2 flex flex-col `} >

								<div className="relative">
									<svg viewBox="0 0 36 36" className="circular-chart orange">
										<path className="circle-bg" d="M18 2.0845 
											a 15.9155 15.9155 0 0 1 0 31.831
											a 15.9155 15.9155 0 0 1 0 -31.831">
										</path>
										<path className="circle" strokeDasharray={(prop.parcentageValue ? prop.parcentageValue : ((100 / prop.maxValue) * prop.minValue)) + ", 100"} d="M18 2.0845
											a 15.9155 15.9155 0 0 1 0 31.831
											a 15.9155 15.9155 0 0 1 0 -31.831">
										</path>
										<path className="bg-center" d="M18 5 
											a 10 10 0 0 1 0 26
											a 10 10 0 0 1 0 -26">
										</path>
									</svg>
									<AiOutlineNotification className="text-2xl center-icon" />
								</div>
								<div className="flex flex-col items-center justify-center text-center">
									<span className="font-medium">{prop.name}</span>
									<span className="font-medium text-green-500">{prop.parcentageValue > 0 ? prop.parcentageValue + '% Boost' : `+${prop.minValue} Boost`}</span>
								</div>
							</div>
						))}
					</div>
				</div>
				:
				product.nftBoosts && product.nftBoosts.length > 0 ?
					<div>
						<div className="text-lg font-bold py-2 mb-2 flex gap-2 border-b border-neutral-200 dark:border-neutral-700">
							<AiOutlineNotification className="text-2xl mt-1" /> <span className="flex uppercase">Boosts</span>
						</div>
						<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-12 gap-y-12 mt-8 lg:mt-10 custom-xs-col-2">
							{product.nftBoosts.map((prop: any, index: any) => (
								<div key={index} className={`my-2 flex flex-col `} >

									<div className="relative">
										<svg viewBox="0 0 36 36" className="circular-chart orange">
											<path className="circle-bg" d="M18 2.0845 
											a 15.9155 15.9155 0 0 1 0 31.831
											a 15.9155 15.9155 0 0 1 0 -31.831">
											</path>
											<path className="circle" strokeDasharray={(prop.parcentageValue ? prop.parcentageValue : ((100 / prop.maxValue) * prop.minValue)) + ", 100"} d="M18 2.0845
											a 15.9155 15.9155 0 0 1 0 31.831
											a 15.9155 15.9155 0 0 1 0 -31.831">
											</path>
											<path className="bg-center" d="M18 5 
											a 10 10 0 0 1 0 26
											a 10 10 0 0 1 0 -26">
											</path>
										</svg>
										<AiOutlineNotification className="text-2xl center-icon" />
									</div>
									<div className="flex flex-col items-center justify-center text-center">
										<span className="font-medium">{prop.name}</span>
										<span className="font-medium text-green-500">{prop.parcentageValue > 0 ? prop.parcentageValue + '% Boost' : `+${prop.minValue} Boost`}</span>
									</div>
								</div>
							))}
						</div>
					</div>
					:
					null
			}
			{product.productDates && product.productDates.length > 0 ?
				<div>
					<div className="text-lg font-bold py-2 mb-2 flex gap-2 border-b border-neutral-200 dark:border-neutral-700">
						<AiOutlineNotification className="text-2xl mt-1" /> <span className="flex uppercase">Dates</span>
					</div>
					<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-12 gap-y-12 mt-2 lg:mt-2 custom-xs-col-2">


						{product.productDates.map((prop: any, index: any) => (
							<div key={index} className={`relative py-4 ${index % 2 === 1 ? "bg-neutradl-100" : ""}`} >
								<div className="bg-blue-100 flex flex-col items-center p-2.5 rounded-md text-blue-800">
									<span className="font-medium">{prop.name}</span>
									<span className="text-xs mt-1">{moment(prop.dateValue).format('DD MMM, YYYY')}</span>
								</div>
							</div>
						))}
					</div>
				</div>
				:
				product.nftDates && product.nftDates.length > 0 ?
					<div>
						<div className="text-lg font-bold py-2 mb-2 flex gap-2 border-b border-neutral-200 dark:border-neutral-700">
							<AiOutlineNotification className="text-2xl mt-1" /> <span className="flex uppercase">Dates</span>
						</div>
						<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-12 gap-y-12 mt-2 lg:mt-2 custom-xs-col-2">


							{product.nftDates.map((prop: any, index: any) => (
								<div key={index} className={`relative py-4 ${index % 2 === 1 ? "bg-neutradl-100" : ""}`} >
									<div className="bg-blue-100 flex flex-col items-center p-2.5 rounded-md text-blue-800">
										<span className="font-medium">{prop.name}</span>
										<span className="text-xs mt-1">{moment(prop.dateValue).format('DD MMM, YYYY')}</span>
									</div>
								</div>
							))}
						</div>
					</div>
					:
					null
			}




		</div>
	);
};

export default Details;
