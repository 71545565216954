import { combineReducers } from 'redux';

import loaderReducer from './common/loaderReducer'
import commonReducer from './common/commonReducer'
import userReducer from './user/userReducer'
import mediaRunning from '../mediaRunning/mediaRunning'
import collectionReducer from './collection/collectionReducer'
import siteSettingReducer from './siteSettings/siteSettingReducer';

const rootReducer = combineReducers({
  loader: loaderReducer,
  common: commonReducer,
  user: userReducer,
  collection: collectionReducer,
  siteSettings: siteSettingReducer,
  mediaRunning: mediaRunning
});

export default rootReducer