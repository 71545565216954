import { call } from 'redux-saga/effects';
import { API_URL } from '../../_config'
import { CallApi } from './api/callApi';

const serialize = (obj:any) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}
const asUrlParams = (obj:any)=>{
    return '?'+serialize(obj);
}

export function* getThemes(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.THEMES.GET, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* saveTheme(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.THEMES.POST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
