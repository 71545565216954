import FormItem from "components/FormItem";
import React, { FC, useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import { yupResolver } from '@hookform/resolvers/yup';

import { FeesEdit_Form } from '../../_common/interfaces/FormProps'
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { EditFeesFormSchemaValidation } from '../../utils/form-validation'

export interface EditFeesProps {
  itemType: number;
  itemDetails: any | null;
  onCloseModalEdit: () => void;
}

const EditFees: FC<EditFeesProps> = ({ itemType, itemDetails, onCloseModalEdit }) => {

  const { customerList } = useUserApi()
  const { updateMarketPlaceFees } = useCommonApi();
  const { showLoader, hideLoader } = useAppLoader()

  const { register, control, getValues, setValue, watch, setError, clearErrors, handleSubmit, reset, formState: { errors } } = useForm<FeesEdit_Form>({
        defaultValues: {
            ownerFee: [{
                feesTypeID: 0,
                feesType: '',
                blockChainNetworkID: 0,
                walletAddress: '',
                value: 0
            }]
        },
        resolver: yupResolver(EditFeesFormSchemaValidation)
    });

    const { fields: ownerFields, append, remove } = useFieldArray({
        control,
        name: "ownerFee"
    });

    const feesSubmitted = (data: any) => {
        const params = {
            itemType: +itemType,
            itemID: +itemType === 9 ? +itemDetails['customerID'] : +itemType === 2 ? +itemDetails['productID'] : +itemDetails['collectionID'],
            ...data
        }
        updateMarketPlaceFees(params,
            (message: string, resp: any) => {
                hideLoader()
                if ( resp.successful ) {
                    toast.success(resp.message)
                    onCloseModalEdit()
                } else {
                    toast.error(resp.message)
                }
            },
            (message: string) => {
                console.log(message)
                toast.error(message)
                hideLoader()
            })
    }

    useEffect(() => {
        if ( itemDetails ) {
            const ownerFees = itemDetails['ownerSettings'] && itemDetails['ownerSettings'].map((e: any) => ({
                feesTypeID: e['feesTypeID'],
                feesType: e['feesType'],
                blockChainNetworkID: e['blockChainNetworkID'],
                walletAddress: e['walletAddress'],
                value: e['value']
            })) || [{
                feesTypeID: 0,
                feesType: '',
                blockChainNetworkID: 0,
                walletAddress: '',
                value: 0
            }]
            reset({'ownerFee': ownerFees})
        }
    }, [itemDetails])

    return (
        <div>
            {/* <div>
                <h4><b>Created by</b> John Doe(ema@ds.cin)</h4>
                <div className="flex justify-between">
                    <h4><b>Creator Address</b> ODX..E</h4>
                    <h4><b>Joined On</b> 22th DEC,2022, 11.55 AM</h4>
                </div>
            </div> */}
            <form onSubmit={handleSubmit(feesSubmitted)}>
                <div className="relative rounded-md shadow-sm">
                    <div>
                    {
                        ownerFields.map((item: any, index: number) => (
                            <div key={index} className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5 mt-4">
                                <FormItem label={item.feesType} isRequired>                        
                                    <Controller
                                    name={`ownerFee.${index}.value`}
                                    control={control}
                                    render={({ field }) => <Input
                                        {...field}
                                        placeholder={''}
                                        autoComplete={'off'}
                                        required={true}
                                    />}
                                />
                                {
                                    errors && errors.ownerFee && errors.ownerFee[index] && errors.ownerFee[index]?.value && errors.ownerFee[index]?.value?.message ?  
                                    <p className='text-red-400'>{errors.ownerFee[index]?.value?.message}</p> : ''
                                }
                                </FormItem>
                                <FormItem label="Wallet Address" isRequired>
                                    <Controller
                                    name={`ownerFee.${index}.walletAddress`}
                                    control={control}
                                    render={({ field }) => <Input
                                        {...field}
                                        placeholder={''}
                                        autoComplete={'off'}
                                        required={true}
                                    />}
                                />
                                {
                                    errors && errors.ownerFee && errors.ownerFee[index] && errors.ownerFee[index]?.walletAddress && errors.ownerFee[index]?.walletAddress?.message ?  
                                    <p className='text-red-400'>{errors.ownerFee[index]?.walletAddress?.message}</p> : ''
                                }
                                </FormItem>
                            </div>
                        ))
                    }
                    </div>          
                </div>
                <div className="mt-4 space-x-3">
                <ButtonPrimary type="submit">Submit</ButtonPrimary>
                <ButtonSecondary type="button" onClick={onCloseModalEdit}>Cancel</ButtonSecondary>
                </div>
            </form>
        </div>
    );
};

export default EditFees;
