import FormItem from 'components/FormItem';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import { useSiteSettingsSelector } from '_common/hooks/selectors/siteSettingsSelector'
import { useSiteSettingsApi } from '_common/hooks/api/siteSettingsApiHook';
import { useAppLoader } from '_common/hooks/common/appLoaderHook';
import toast from 'react-hot-toast';
import Textarea from 'shared/Textarea/Textarea';

const SocialMediaLink = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const siteSettingsApi = useSiteSettingsApi()
  const siteSettingsList = useSiteSettingsSelector()
  const { showLoader, hideLoader } = useAppLoader()

  const [tabFields, setTabFields] = useState<any>()


  const { register, control, handleSubmit, reset, setValue, formState: { errors }, watch } = useForm()

  const getTabFiels = () => {
    siteSettingsList.map((item: any, index: number) => {
      if (item?.siteGroupName == tab) {
        setTabFields(item)
      }
    })
  }

  const onSubmit = (data: any) => {
    // console.log({ data })
    let params: any = []

    for (const key in data) {
      let tempParams: any = {}
      tabFields?.keyValue.forEach((element: any) => {
        if (element?.appKey == key) {
          tempParams["appKey"] = element?.appKey
          tempParams["appValue"] = data[key]
          tempParams["typeID"] = element?.typeID
          params.push(tempParams)
        }
      });
    }

    // console.log({ params })
    showLoader()
    siteSettingsApi.postSiteSettings({
      "siteSettings": params
    },
      (message: string, resp: any) => {
        console.log({ resp, message })
        toast.success(message)
        getTabFiels()
        // handleCancel()
        hideLoader()
      }, (message: string) => {
        console.log({ message })
        toast.error(message)
        hideLoader()
      })
  }

  const handleCancel = () => {
    reset({
      FacebookSocial: '',
      TwitterSocial: '',
      DiscordSocial: '',
      InstagramSocial: '',
      TelegramSocial: '',
      TiktokSocial: '',
      SnapchatSocial: '',
      WebsiteSocial: '',
      EmailSocial: '',
      FaxSocial: '',
      MobileSocial: '',
      PhoneSocial: '',
      AddressSocial: ''
    })
  }

  useEffect(() => {
    if (tab && siteSettingsList) {
      getTabFiels()
    }
  }, [tab, siteSettingsList])

  useEffect(() => {
    console.log('tabFields: ', tabFields)
    if (tabFields && Object.keys(tabFields).length ) {
      const data = tabFields['keyValue'].reduce((a: any, c: any) => {
        a = {
          ...a,
          [c['appKey']]: c['appValue']
        }
        return a;
      }, {})
      console.log('data: ', data)
      reset(data)
    }
    
    /*reset({
      FacebookSocial: tabFields?.keyValue[2]?.appValue,
      TwitterSocial: tabFields?.keyValue[7]?.appValue,
      DiscordSocial: tabFields?.keyValue[0]?.appValue,
      InstagramSocial: tabFields?.keyValue[3]?.appValue,
      TelegramSocial: tabFields?.keyValue[5]?.appValue,
      TiktokSocial: tabFields?.keyValue[6]?.appValue,
      SnapchatSocial: tabFields?.keyValue[4]?.appValue,
      WebsiteSocial: tabFields?.keyValue[8]?.appValue,
      EmailSocial: tabFields?.keyValue[1]?.appValue,
      FaxSocial: tabFields?.keyValue[1]?.appValue,
      MobileSocial: tabFields?.keyValue[1]?.appValue,
      PhoneSocial: tabFields?.keyValue[1]?.appValue,
      AddressSocial: tabFields?.keyValue[1]?.appValue,
    })*/
  }, [tabFields])

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5">
          <FormItem label="Facebook">
            <Controller
              name={`FacebookSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Twitter">
            <Controller
              name={`TwitterSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
            {
              errors && errors.TwitterSocial && errors.TwitterSocial?.type == "required" ?
                <p className='text-red-400'>Required</p> : ''
            }
          </FormItem>
          <FormItem label="Discord">
            <Controller
              name={`DiscordSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Instagram">
            <Controller
              name={`InstagramSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Telegram">
            <Controller
              name={`TelegramSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Tiktok">
            <Controller
              name={`TiktokSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Snapchat">
            <Controller
              name={`SnapchatSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Website">
            <Controller
              name={`WebsiteSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Email">
            <Controller
              name={`EmailSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Phone">
            <Controller
              name={`PhoneSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                type={'number'}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Mobile">
            <Controller
              name={`MobileSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                type={'number'}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Fax">
            <Controller
              name={`FaxSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Address">
            <Textarea className="" rows={3} {...register(`AddressSocial`)} placeholder="Address" />
          </FormItem>
        </div>

        <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 m-8">
          <ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
          <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Reset</ButtonSecondary>
        </div>
      </form>
    </React.Fragment>
  )
}

export default SocialMediaLink