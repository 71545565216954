import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi } from './api/callApi';
const API_BASE_URL = process.env.REACT_APP_API_URL;

const serialize = (obj:any) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}
const asUrlParams = (obj:any)=>{
    return '?'+serialize(obj);
}

export function* getCoupons(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COUPON.LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data  ) {
            /*yield put({
                type: ACTIONS.COLLECTION.GET_COLLECTION_TYPES,
                payload: {
                    types:  resp.data.collectionTypes
                }
            })*/
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* getCouponTypes(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COUPON.LIST_TYPE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data  ) {
            /*yield put({
                type: ACTIONS.COLLECTION.GET_COLLECTION_TYPES,
                payload: {
                    types:  resp.data.collectionTypes
                }
            })*/
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCouponDiscountTypes(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COUPON.LIST_DISCOUNT_TYPE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data  ) {
            /*yield put({
                type: ACTIONS.COLLECTION.GET_COLLECTION_TYPES,
                payload: {
                    types:  resp.data.collectionTypes
                }
            })*/
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addCoupon(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COUPON.ADD, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data  ) {
            /*yield put({
                type: ACTIONS.COLLECTION.GET_COLLECTION_TYPES,
                payload: {
                    types:  resp.data.collectionTypes
                }
            })*/
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* updateCoupon(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.PUT, API_URL.COUPON.EDIT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data  ) {
            /*yield put({
                type: ACTIONS.COLLECTION.GET_COLLECTION_TYPES,
                payload: {
                    types:  resp.data.collectionTypes
                }
            })*/
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteCoupon(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.COUPON.DELETE + asUrlParams(data), data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data  ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
