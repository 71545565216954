import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import FormItem from "components/FormItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import InputFile from 'shared/InputFile/InputFile';
import Textarea from "shared/Textarea/Textarea";
import CheckBoxNative from "shared/Checkbox/nativeCheckbox";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { Editor } from "@tinymce/tinymce-react";
import toast from "react-hot-toast";
import { useEmailTempltApi } from "_common/hooks/api/emailTempltApi";


const UpdateEmailTemplate = () => {

    const emailTmpltApi = useEmailTempltApi()
    const navigate = useNavigate()
    const { id } = useParams()
    const { showLoader, hideLoader } = useAppLoader()

    const editorRef: any = useRef('')
    const [emailTmpltDetails, setEmailTmpltDetails] = useState<any>(null)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm();

    const getEmailTemplaits = () => {
        showLoader()
        emailTmpltApi.getEmailTemplaits({ emailTemplateID: id },
            (message: string, resp: any) => {
                console.log({ message, resp })
                setEmailTmpltDetails(resp?.emailTemplate && resp?.emailTemplate[0] || null)
                if (resp.emailTemplate && resp.emailTemplate.length > 0) {
                    reset({
                        title: resp.emailTemplate[0].title,
                        slug: resp.emailTemplate[0].slug,
                        subject: resp.emailTemplate[0].subject
                    })
                }
                hideLoader()
            },
            (message: string) => {
                console.log(message)
                hideLoader()
            })
    }

    const onEditorChange = (e: any) => {
        // console.log({e})
        if (editorRef.current) {
            let editorContent = editorRef?.current.getContent()
            if (editorContent == '') {
                setErrorMessage("Story text is required")
            }
            else {
                setErrorMessage('')
            }
        }
    }

    const onSubmit = (data: any) => {
        console.log({ data })
        let params: any = {
            title: data.title,
            slug: data.slug,
            subject: data.subject
        }
        params["emailTemplateID"] = id
        if (editorRef.current) {
            let editorContent = editorRef?.current.getContent()
            if (editorContent == '') {
                setErrorMessage("Story text is required")
            }
            else {
                params["contents"] = editorContent
            }
        }
        console.log({ params })
        showLoader()
        emailTmpltApi.updateEmailTemplate(params,
            (message: string, resp: any) => {
                console.log({ message, resp })
                toast.success(message)
                hideLoader()
                handleCancel()
            },
            (message: string) => {
                toast.error(message)
                hideLoader()
            })
    }

    const handleCancel = () => {
        navigate(`/email-template`)
    }

    useEffect(() => {
        if (id) {
            getEmailTemplaits()
        }
    }, [id])

    return (
        <React.Fragment>
            <div className="container">
                <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
                    {/* HEADING */}
                    <div className="max-w-2xl">
                        <h2 className="text-3xl sm:text-4xl">
                            Update <span className="font-semibold">{ emailTmpltDetails && emailTmpltDetails['title'] || '' }</span>
                        </h2>
                    </div>
                    <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                            <FormItem label="Title" isRequired className='m-2' >
                                <Controller
                                    name={`title`}
                                    control={control}
                                    render={({ field }) => <Input
                                        {...field}
                                        placeholder={''}
                                        autoComplete={'off'}
                                        required={true}
                                        disabled={true}
                                    />}
                                />
                                {
                                    errors && errors.title && errors.title?.type == "required" ?
                                        <p className='text-red-400'>Required</p> : ''
                                }
                            </FormItem>
                            <FormItem label="Slug" isRequired className='m-2' >
                                <Controller
                                    name={`slug`}
                                    control={control}
                                    render={({ field }) => <Input
                                        {...field}
                                        placeholder={''}
                                        autoComplete={'off'}
                                        required={true}
                                        disabled={true}
                                    />}
                                />
                                {
                                    errors && errors.slug && errors.slug?.type == "required" ?
                                        <p className='text-red-400'>Required</p> : ''
                                }
                            </FormItem>
                        </div>

                        <div className="mt-10 md:mt-5 space-y-5 sm:space-y-6 md:sm:space-y-8">
                            <FormItem label="Subject" isRequired className='m-2' >
                                <Controller
                                    name={`subject`}
                                    control={control}
                                    render={({ field }) => <Input
                                        {...field}
                                        placeholder={''}
                                        autoComplete={'off'}
                                        required={true}
                                    />}
                                />
                                {
                                    errors && errors.subject && errors.subject?.type == "required" ?
                                        <p className='text-red-400'>Required</p> : ''
                                }
                            </FormItem>
                        </div>

                        <div className="mt-10 md:mt-8 space-y-5 sm:space-y-6 md:sm:space-y-8">
                            <FormItem label="Content" isRequired className='m-2' >

                                <Editor
                                    onInit={(evt: any, editor: any) => {
                                        editorRef.current = editor
                                        // if(createPetition && createPetition.editor)
                                        // {
                                        //   editorRef.current.setContent(createPetition.editor)
                                        // }
                                    }}
                                    initialValue={emailTmpltDetails ? emailTmpltDetails?.contents : null}
                                    onEditorChange={onEditorChange}

                                    init={{
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar: 'undo redo | formatselect | ' +
                                            'bold italic underline | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                                {
                                    errorMessage && errorMessage.length ?
                                        <p className='text-red-400'>{errorMessage}</p> : ''
                                }
                            </FormItem>
                        </div>

                        <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 mt-6">
                            <ButtonPrimary className="flex-1" type="submit">Update</ButtonPrimary>
                            <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Cancel</ButtonSecondary>
                        </div>

                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UpdateEmailTemplate