import React, { FC, useEffect, useState, useRef } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Web3 from 'web3';
import { toast } from "react-hot-toast";

import NcModal from "shared/NcModal/NcModal";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";

import useMetaMask from "../../_common/provider/metamask-provider";
import { METAMASK_KEYS, CHAIN_ADD_CONFIG } from '../../_common/_constant'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import { useUserCryptoDetails } from '../../_common/hooks/selectors/userSelector'
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";

import {abi, address} from '../../_common/_chainLinkToken'

export interface ContractLinkBalanceProps {
  className?: string;
  changeChainId: number;
  cancel: Function;
  type: string;
  item?: any
}

const LinkBalance: FC<ContractLinkBalanceProps> = ({ className = "", changeChainId, cancel, type, item = null }) => {
  const { connect, disconnect, isLoading, isActive, chainId, account, addNetwork, switchNetwork, isConnected, authenticateWallet } = useMetaMask()
  const { ethereum }: any = window;
  const inputRef: any | null = useRef(null)
  
  const { connectWallet } = useUserApi()
  const { getCollections } = useCollectionApi();
  const cryptoDetails = useUserCryptoDetails()

  const [tokenAddress, setTokenAddress] = useState(address)
  const [bal, setBal] = useState<string>('');
  const [collectionDetails, setCollectionDetails] = useState<any>({})
  const [amount, setAmount] = useState<number>(0)
  const [errMsg, setErrMsg] = useState<string>('')
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const { showLoader, hideLoader } = useAppLoader();
  const [tokenAbi, setTokenAbi] = useState<any>(abi)

  useEffect(() => {
    if ( item && Object.keys(item) ) {
      getSingleCollection();
    }
  }, [item]);
  useEffect(() => {
    getDetails();
  }, [collectionDetails]);

  const getSingleCollection = () => {
    showLoader();
    getCollections({ collectionID: item['collectionID'] }, (message: string, resp: any) => {
      hideLoader();
			if (resp && resp.collections && resp.collections.length) {
        setCollectionDetails(resp.collections[0]);
			} else {
        toast.error('Collection not found!')
        cancel();
			}
		}, (message: string, resp: any) => {
      hideLoader();
      toast.error(message)
		})
  }

  const getDetails = async () => {
    if ( collectionDetails && collectionDetails['contractAddress'] && collectionDetails['contractAddress']['address'] && collectionDetails['contractAddress']['abi'] ) {
      const contractAddress = collectionDetails['contractAddress']['address'];

      const web3 = new Web3(ethereum);

      const contract = new web3.eth.Contract(tokenAbi, tokenAddress);
      contract.methods.balanceOf(contractAddress).call()
      .then((balance: any) => {
        setBal(parseFloat(web3.utils.fromWei(balance, 'ether')).toFixed(4));
      })
      .catch((err: any) => {
        console.log('err: ', err)
      });
    }
  }; 

  const submitHandler = () => {
    if ( isNaN(amount) || ( !isNaN(amount) && amount <= 0 ) ) {
      setErrMsg('Invalid amount!')
    } else {
      console.log('amount: ', amount)
      setErrMsg('')
      _sendLink();
    }
  } 

  const _sendLink = async () => {

    const web3: any = new Web3(ethereum);
    const toAddress = collectionDetails['contractAddress'] && collectionDetails['contractAddress']['address'] || '';
    const fromAddress = cryptoDetails[0]?.walletAddress || ''

    // Use BigNumber
    // const decimals = web3.utils.toBN(18);
    // const _amount = web3.utils.toBN(amount);

    if ( toAddress && fromAddress ) {
      setDisableButton(true)
      const contract = new web3.eth.Contract(tokenAbi, tokenAddress);
      const _amount = web3.utils.toWei(String(amount), 'ether')
      contract.methods.transfer(toAddress, _amount).send({from: fromAddress})
      .on('transactionHash', function(hash: string){
        console.log('transactionHash: ', hash);
      }).on('receipt', (receipt: any) => {
        toast.success('Transaction successful');
        getDetails();
        setDisableButton(false)
        if ( inputRef ) {
          inputRef.current.value = ''
        }
      })
      .on('error', (error: any) => {
        toast.error('Some error occurred, please try again');
        setDisableButton(false)
      });
    } else {
      toast.error('Contract address, collectin abi and user wallet address required');
    }
  };


  return (
    <div className={`nc-PageConnectWallet ${className}`} data-nc-id="PageConnectWallet">
      <div className="container">
        <div className="my-6 sm:lg:my-16 lg:my-24 max-w-3xl mx-auto space-y-8 sm:space-y-10">
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              { type === 'viewBalance' ? 'View Balance' : 'Send Link' }
            </h2>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              <h6>Current Balance: <strong>{bal}</strong></h6>
              <h6>Chain: <strong>{collectionDetails['chainLabel']}</strong></h6>
            </span>
            <div className="mt-4">
              {
                  type != 'viewBalance' && (
                    <label className="block">
                        <Label className="block mb-2">Amount <span className="text-red-600">*</span></Label>
                        <Input placeholder={`Amount`} type="number" className="mt-1" onChange={(e: any) => setAmount(e.target.value)} ref={inputRef} />
                        {
                          errMsg && (<p className='text-red-400'>{errMsg}</p>) || ''
                        }
                    </label>
                  ) || ''
              }
            </div>
          </div>
          <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
            {
              type != 'viewBalance' && (<ButtonPrimary disabled={disableButton} className="flex-1" type="button" onClick={() => submitHandler()}>Submit</ButtonPrimary>) || ''
            }            
            <ButtonSecondary className="flex-1" type="button" onClick={() => cancel()}>Cancel</ButtonSecondary>
          </div>

          {
            disableButton && (<div className="pt-2 flex justify-center">
              <p className="">Please wait, until transaction is completed ..</p>
            </div>) || ''
          }
        </div>
      </div>
    </div>
  );
};

export default LinkBalance;
