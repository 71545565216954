import React from "react";
import {AiFillEye} from "react-icons/ai";

export interface ItemTypeViewIconProps {
  className?: string;
}

const ItemTypeViewIcon: React.FC<ItemTypeViewIconProps> = ({ className = "w-8 h-8 md:w-10 md:h-10", }) => {
  return (
    <div
      className={`flex items-center justify-center rounded-full text-white ${className}`}

    >
      <AiFillEye/>

    </div>
  );
};

export default ItemTypeViewIcon;
