import React, { FC, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from 'react-select'
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonDanger from "shared/Button/ButtonDanger";
import FormItem from "components/FormItem";
import { BsFillTrashFill } from "react-icons/bs";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Radio from "shared/Radio/nativeRadio";
import DatePicker from "react-datepicker";
import { DATE_FORMAT } from "_common/_constant";
import DateInput from "shared/Date/DateInput";
import ItemTypeDeleteIcon from "components/ItemTypeDeleteIcon";


interface AddressQuantityArrayValues {
    type: any,
    collectionDate: any,
    operator: any,
    formValue: string,
}

interface AddressQuantityFormValues {
    addressQuantity: AddressQuantityArrayValues[],
    productType: any,
    name: string,
    customer: any,
}

const AddToAirdropsTwo = () => {

    const navigate = useNavigate()
    const { register, control, handleSubmit, reset, formState: { errors }, watch } = useForm<AddressQuantityFormValues>({
        defaultValues: {
            productType: null,
            name: '',
            customer: null,
            addressQuantity: [
                {
                    type: null,
                    collectionDate: null,
                    operator: null,
                    formValue: '',
                }
            ],
        },
    });

    const CustomerChange = watch('customer')

    const {
        fields: addressQuantityFields,
        append: addressQuantityAppend,
        remove: addressQuantityRemove
    } = useFieldArray({ control, name: "addressQuantity" })

    const onSubmit = (data: any) => {
        console.log({ data })
    }

    const handleCancel = () => {
        navigate(`/airdrops?tab=Airdrops+List`)
    }

    const addNewaddressQuantity = () => {
        addressQuantityAppend({
            type: null,
            collectionDate: null,
            operator: null,
            formValue: '',
        })
    }

    return (
        <React.Fragment>
            <div className="container">
                <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
                    {/* HEADING */}
                    <div className="max-w-2xl">
                        <h2 className="text-3xl sm:text-4xl font-semibold">
                            Create Airdrops Lists
                        </h2>
                    </div>
                    <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                        <FormItem label="Type">
                            <div className="grid grid-cols-2 gap-4">
                                <Radio label="Static" type="radio" value="1" {...register(`productType`, { required: true })} />
                                <Radio label="Dynamic" type="radio" value="0" {...register(`productType`, { required: true })} />
                            </div>
                        </FormItem>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                        <FormItem label="Name">
                            <Input placeholder={"Enter name"} {...register('name', { required: true })} />
                        </FormItem>
                        <FormItem label="Customer">
                            <Controller
                                name={"customer"}
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, onChange, onBlur } }) => (
                                    <Select
                                        className={`mt-1 rounded-2xl`}
                                        options={[{ value: 1, label: 'Collection #01' }, { value: 2, label: 'Collection #02' }]}
                                        placeholder={"Select Customer"}
                                        onChange={onChange}
                                        value={value}
                                        defaultValue={value}
                                    />
                                )}
                            />
                        </FormItem>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        {CustomerChange?.value ?
                            <div>
                                <div className="flex items-center justify-between mb-4">
                                    <h4 className="text-lg font-bold">Add</h4>
                                    <div className="sm:text-sm flex items-center text-blue-800 cursor-pointer" onClick={addNewaddressQuantity}><AiOutlinePlus className="mr-2" /> Add New </div>
                                </div>

                                {addressQuantityFields.map((field: any, index: number) => (
                                    <div className="flex items-end mb-4" key={field.id}>
                                        <FormItem label="Type" className="mr-4 w-72">
                                            <Controller
                                                name={`addressQuantity.${index}.type`}
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange, onBlur } }) => (
                                                    <Select
                                                        className={`mt-1 rounded-2xl`}
                                                        options={[{ value: 1, label: 'Collection' }, { value: 2, label: 'Register Date' }]}
                                                        placeholder={"Select Type"}
                                                        onChange={onChange}
                                                        value={value}
                                                        defaultValue={value}
                                                    />
                                                )}
                                            />
                                        </FormItem>
                                        <FormItem label="Collection / Date" className="mr-4 w-48 custom-items-self-base">
                                            {watch(`addressQuantity.${index}.type`)?.value == 1 ?
                                                <Controller
                                                    // name={`collectionDate`}
                                                    name={`addressQuantity.${index}.collectionDate`}
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange, onBlur } }) => (
                                                        <Select
                                                            className={`mt-1 rounded-2xl`}
                                                            options={[{ value: 1, label: 'Collection' }, { value: 2, label: 'Register Date' }]}
                                                            placeholder={"Collections"}
                                                            onChange={onChange}
                                                            value={value}
                                                            defaultValue={value}
                                                        />
                                                    )}
                                                />
                                                : "NA"
                                            }
                                        </FormItem>
                                        <FormItem label="Operator" className="mr-4 w-44">
                                            <Controller
                                                name={`addressQuantity.${index}.operator`}
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange, onBlur } }) => (
                                                    <Select
                                                        className={`mt-1 rounded-2xl`}
                                                        options={[{ value: 1, label: 'NFT' }, { value: 2, label: 'TOKEN' }]}
                                                        placeholder={"Operators"}
                                                        onChange={onChange}
                                                        value={value}
                                                        defaultValue={value}
                                                    />
                                                )}
                                            />
                                        </FormItem>
                                        <FormItem label="Value" className="w-32">
                                            {watch(`addressQuantity.${index}.type`)?.value == 1 ?
                                                <Controller
                                                    name={`addressQuantity.${index}.formValue`}
                                                    control={control}
                                                    render={({ field }) => <Input
                                                        {...field}
                                                        placeholder={'Value'}
                                                        autoComplete={'off'}
                                                    />}
                                                />
                                                :
                                                <Controller
                                                    name={`addressQuantity.${index}.formValue`}
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { onChange, onBlur, value, name, ref }, }) => (
                                                        <DateInput
                                                            name={name}
                                                            onChange={onChange}
                                                            value={value}
                                                            onBlur={onBlur}
                                                            placeholder="Date"
                                                            showTimeSelect={true}
                                                            dateFormat="MM-dd-yyyy hh:mm a"
                                                        />
                                                    )}
                                                />
                                            }
                                        </FormItem>
                                        <div className="relative flex-shrink-0 flex mb-1">
                                            {addressQuantityFields.length > 1 ? <span onClick={() => {
                                                addressQuantityRemove(index)
                                            }} className="ml-2 delete-btn relative pt-7"><ItemTypeDeleteIcon /></span>
                                                : null
                                            }
                                        </div>
                                    </div>
                                ))}


                                {/* <table>
                                    <thead>
                                        <tr>
                                            <th className="text-left">Type</th>
                                            <th className="text-left">Collection / Date</th>
                                            <th className="text-left">Operator</th>
                                            <th className="text-left">Value</th>
                                            <th className="text-left"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {addressQuantityFields.map((field: any, index: number) => (
                                            <tr key={field.id} className="m-4">
                                                <td>
                                                    <label className="block">
                                                        <Controller
                                                            name={`addressQuantity.${index}.type`}
                                                            control={control}
                                                            rules={{ required: true }}
                                                            render={({ field: { value, onChange, onBlur } }) => (
                                                                <Select
                                                                    className={`mt-1 rounded-2xl`}
                                                                    options={[{ value: 1, label: 'Collection' }, { value: 2, label: 'Register Date' }]}
                                                                    placeholder={"Select Type"}
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    defaultValue={value}
                                                                />
                                                            )}
                                                        />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="block">
                                                        {watch(`addressQuantity.${index}.type`)?.value == 1 ?
                                                            <Controller
                                                                // name={`collectionDate`}
                                                                name={`addressQuantity.${index}.collectionDate`}
                                                                control={control}
                                                                rules={{ required: true }}
                                                                render={({ field: { value, onChange, onBlur } }) => (
                                                                    <Select
                                                                        className={`mt-1 rounded-2xl`}
                                                                        options={[{ value: 1, label: 'Collection' }, { value: 2, label: 'Register Date' }]}
                                                                        placeholder={"Select Collection"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    />
                                                                )}
                                                            />
                                                            : "NA"
                                                        }
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="block">
                                                        <Controller
                                                            name={`addressQuantity.${index}.operator`}
                                                            control={control}
                                                            rules={{ required: true }}
                                                            render={({ field: { value, onChange, onBlur } }) => (
                                                                <Select
                                                                    className={`mt-1 rounded-2xl`}
                                                                    options={[{ value: 1, label: 'NFT' }, { value: 2, label: 'TOKEN' }]}
                                                                    placeholder={"Select Operator"}
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    defaultValue={value}
                                                                />
                                                            )}
                                                        />
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="block">
                                                        {watch(`addressQuantity.${index}.type`)?.value == 1 ?
                                                            <Controller
                                                                name={`addressQuantity.${index}.formValue`}
                                                                control={control}
                                                                render={({ field }) => <Input
                                                                    {...field}
                                                                    placeholder={'Value'}
                                                                    autoComplete={'off'}
                                                                />}
                                                            />
                                                            :
                                                            <Controller
                                                                name={`addressQuantity.${index}.formValue`}
                                                                control={control}
                                                                rules={{ required: true }}
                                                                render={({ field: { onChange, onBlur, value, name, ref }, }) => (
                                                                    <DateInput
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Date"
                                                                        showTimeSelect={true}
                                                                        dateFormat="MM-dd-yyyy hh:mm a"
                                                                    />
                                                                )}
                                                            />
                                                        }
                                                    </label>
                                                </td>
                                                <td>
                                                    {addressQuantityFields.length > 1 ? <div className="col-sm-2"><ButtonDanger className="ml-3" type="button" onClick={() => addressQuantityRemove(index)}><BsFillTrashFill /></ButtonDanger></div> : null}
                                                    <div className="col-sm-2">
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table> */}

                            </div>
                            : null
                        }

                        <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3">
                            <ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
                            <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Cancel</ButtonSecondary>
                        </div>

                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddToAirdropsTwo