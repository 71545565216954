
import { InjectedConnector } from "@web3-react/injected-connector";
import { BscConnector } from '@binance-chain/bsc-connector';
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

export interface CollectionSocialAttributes {
  socialKey: string;
  socialKeyValue: string;
  label: string;
  placeholder: string,
  group: string;
  fieldType: string;
  displayOrder: number;
}

export const SOCIALS_ARRAY: CollectionSocialAttributes[] = [
  {
    socialKey: 'Facebook',
    socialKeyValue: '',
    label: 'Facebook',
    placeholder: 'facebook url',
    group: 'SocialMedia',
    fieldType: 'text',
    displayOrder: 1,
  },
  {
    socialKey: 'Twitter',
    socialKeyValue: '',
    label: 'Twitter',
    placeholder: 'twitter url',
    group: 'SocialMedia',
    fieldType: 'text',
    displayOrder: 2,
  },
  {
    socialKey: 'Instagram',
    socialKeyValue: '',
    label: 'Instagram',
    placeholder: 'instagram url',
    group: 'SocialMedia',
    fieldType: 'text',
    displayOrder: 3,
  },
  {
    socialKey: 'Telegram',
    socialKeyValue: '',
    label: 'Telegram',
    placeholder: 'telegram url',
    group: 'SocialMedia',
    fieldType: 'text',
    displayOrder: 4,
  },
  {
    socialKey: 'Discord',
    socialKeyValue: '',
    label: 'Discord',
    placeholder: 'discord url',
    group: 'SocialMedia',
    fieldType: 'text',
    displayOrder: 5,
  },
];

export const REG_EXP = {
  VALID_URL: /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
  Valid_Metamask_Wallet_Address: /^0x[a-fA-F0-9]{40}$/g,
}

export const DATE_FORMAT = 'yyyy/MM/dd';

export const AUCTION_TYPES = [{
  label: 'English Auction(Top bid wins auction)',
  value: 1
}, {
  label: 'Dutch Auction(Sell at a declining price)',
  value: 2
}]

export const ProductPreviewAcceptTypes = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'image/gif': ['.gif'],
  'image/svg': ['.svg'],
  'image/mp4': ['.mp4'],
  'image/webm': ['.webm'],
  'image/mp3': ['.mp3'],
  'image/wav': ['.wav'],
  'image/ogg': ['.ogg'],
  'image/glb': ['.glb'],
  'image/gltf': ['.gltf']
}

export const ProductFileAcceptTypes = [
  'image/svg+xml',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'video/webm',
  'video/mp4',
  'video/ogg',
  'video/webm',
  'audio/webm',
  'audio/mpeg',
  'audio/wav',
  'audio/ogg',
  'application/octet-stream',
  'model/gltf-binary',
  'model/gltf+json',
]

export const ProductPlaceholderAcceptTypes = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/vnd.microsoft.icon',
  'image/svg',
  'image/svg+xml',
  'image/webp',
]

export const INFURA_KEY = 'ad027482b9d04724b41c81f4ae1ecce2';
export const METAMASK_KEYS = {
  CONNECT_METAMASK: 'CONNECT_METAMASK',
  WALLET_CONNECT: 'WALLET_CONNECT',
  COINBASE_WALLET: 'COINBASE_WALLET'
}

export const FILE_PROTOTYPE = {
  COLLECTION: {
    LOGO: {
      SIZE: 1024 * 1024 * 20, // 20MB,
      FILE_TYPE: ["image/svg+xml", "image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"]
    },
    BANNER: {
      SIZE: 1024 * 1024 * 20, // 20MB,
      FILE_TYPE: ["image/svg+xml", "image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"]
    },
    FEATURE: {
      SIZE: 1024 * 1024 * 20, // 20MB,
      FILE_TYPE: ["image/svg+xml", "image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"]
    }
  }
}

const supportedChainIds = [1, 3, 4, 5, 42, 80001, 137, 56, 97, 43114, 43113, 11155111];
const injectedConnector = new InjectedConnector({
  supportedChainIds
});
const bscConnector = new BscConnector({ supportedChainIds });
const walletconnect = new WalletConnectConnector({
  // rpcUrl: `https://mainnet.infura.io/v3/`,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  infuraId: INFURA_KEY,
  supportedChainIds: supportedChainIds,
  /*chainId: 56,
  rpc: {
    1:"https://mainnet.infura.io/v3/d45b891571014af08050aa7e8976b32a",
    3: 'https://ropsten.infura.io',
    4: 'https://rinkeby.infura.io',
    5:"https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    42: '',
    80001: "https://rpc-mumbai.maticvigil.com/",
    137: "https://polygon-rpc.com/",
    56: "https://bsc-dataseed1.binance.org/",
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    43114: 'https://avalanche-mainnet.infura.io',
    43113: '',
    11155111: 'https://sepolia.infura.io/v3/'
  },*/
  /*qrcodeModalOptions: {
    desktopLinks: ['ledger', 'tokenary', 'wallet', 'wallet 3', 'secuX', 'ambire', 'wallet3', 'apolloX', 'zerion', 'sequence', 'punkWallet', 'kryptoGO', /<star> 'nft', <star>/ 'riceWallet', 'vision', 'keyring' ],
    mobileLinks: [ "rainbow", /<star> "metamask", <star>/ "argent", "trust", "imtoken", "pillar", ],
  }*/
  // rpc: { 1: RPC_URLS[1] }
});
const coinbaseWallet = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  appName: "NFTWards",
  supportedChainIds
});
export const ConnectionConfig = {
  [METAMASK_KEYS.CONNECT_METAMASK]: injectedConnector,
  [METAMASK_KEYS.WALLET_CONNECT]: walletconnect,
  [METAMASK_KEYS.COINBASE_WALLET]: coinbaseWallet
}

export const CHAIN_ADD_CONFIG = {
  137: {
      chainId: '0x'+Number(137).toString(16),
      chainName:'Polygon',
      rpcUrls:['https://polygon-rpc.com'],
      blockExplorerUrls:['https://polygonscan.com/'],  
      nativeCurrency: { 
        symbol:'MATIC',   
        decimals: 18,
        customCurrency: 'polygon'
      }     
  },
  80001: {
      chainId: '0x'+Number(80001).toString(16),
      chainName: 'Mumbai',
      nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18,
          customCurrency: 'polygon'
      },
      rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
      blockExplorerUrls: ['https://mumbai.polygonscan.com/']
  },
  56: {
      chainId: '0x'+Number(56).toString(16),
      chainName: 'Smart Chain',
      nativeCurrency: {
          name: 'BNB',
          symbol: 'BNB',
          decimals: 18,
          customCurrency: 'binance'
      },
      rpcUrls: ['https://bsc-dataseed.binance.org/'],
      blockExplorerUrls: ['https://bscscan.com']
  },
  97: {
      chainId: '0x'+Number(97).toString(16),
      chainName: 'Smart Chain - Testnet',
      nativeCurrency: {
          name: 'BNB',
          symbol: 'BNB',
          decimals: 18,
          customCurrency: 'binance'
      },
      rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
      blockExplorerUrls: ['https://testnet.bscscan.com']
  },
  1: {
      chainId: '0x'+Number(1).toString(16),
      chainName: 'Ethereum Mainnet',
      nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
          customCurrency: 'ethereum'
      },
      rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
      blockExplorerUrls: ['https://etherscan.io']
  },
  43113: {
      chainId: '0x'+Number(43113).toString(16),
      chainName: 'Avalanche Testnet',
      nativeCurrency: {
          name: 'AVAX',
          symbol: 'AVAX',
          decimals: 18,
          customCurrency: 'avalanche'
      },
      rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
      blockExplorerUrls: ['https://testnet.snowtrace.io']
  },
  5: {
      chainId: '0x'+Number(5).toString(16),
      chainName: 'Goerli test network',
      nativeCurrency: {
          name: 'GoerliETH',
          symbol: 'GoerliETH',
          decimals: 18,
          customCurrency: 'ethereum'
      },
      rpcUrls: ['https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
      blockExplorerUrls: ['https://goerli.etherscan.io']
  },
  11155111: {
      chainId: '0x'+Number(11155111).toString(16),
      chainName: 'Sepolia test network',
      nativeCurrency: {
          name: 'SepoliaETH',
          symbol: 'SepoliaETH',
          decimals: 18,
          customCurrency: 'ethereum'
      },
      rpcUrls: ['https://rpc.sepolia.org'],
      blockExplorerUrls: ['https://sepolia.etherscan.io']
  },
}

/*
https://rpc.sepolia.dev
https://rpc.sepolia.online
https://www.sepoliarpc.space
https://rpc.sepolia.org (status: https://status.sepolia.org)
https://rpc-sepolia.rockx.com
https://rpc.bordel.wtf/sepolia
*/

export const ITEM_TYPE = {
  COLLECTION: 1,
  PRODUCT: 2,
  CATEGORY: 3,
  STORE: 4,
  MARKETPLACE: 5,
  CMS: 6,
  EMPLOYEE: 7,
  ADMIN_USER: 8,
  CUSTOMER: 9,
  SITE_SETTINGS: 10
}

export const USER_TYPE = {
  ADMIN: 1,
  EMPLOYEE: 2,
  CUSTOMER: 3
}

export const CONTRACT_DEPLOYED_STATUS = {
  PENDING: [0, 1],
  INITIATE: [2],
  IN_PROCESS: [3],
  DEPLOYED: [4],
  FAILED: [5]
}

export const NETWORK_URLS: any = {
  "1": 'https://etherscan.io',
  "3": 'https://ropsten.etherscan.io',
  "4": 'https://rinkeby.etherscan.io',
  "5": 'https://goerli.etherscan.io',
  "42": 'https://kovan.etherscan.io',
  "11155111": "https://sepolia.etherscan.io",

  "137": 'https://polygonscan.com',
  "80001": 'https://mumbai.polygonscan.com',

  "56": 'https://bscscan.com',
  "97": 'https://testnet.bscscan.com',

  "43114": 'https://snowtrace.io',
  "43113": 'https://testnet.snowtrace.io',
}