import FormItem from 'components/FormItem';
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import InputFile from 'shared/InputFile/InputFile';
import Textarea from 'shared/Textarea/Textarea';
import { useSiteSettingsSelector } from '_common/hooks/selectors/siteSettingsSelector'
import Switch from "shared/Switch/Switch";
import { Editor } from '@tinymce/tinymce-react';
import { useSiteSettingsApi } from '_common/hooks/api/siteSettingsApiHook';
import { useCommonApi } from '_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '_common/hooks/common/appLoaderHook';
import toast from 'react-hot-toast';

const HomeSettings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const siteSettingsApi = useSiteSettingsApi()
  const commonApi = useCommonApi()
  const siteSettingsList = useSiteSettingsSelector()
  const { showLoader, hideLoader } = useAppLoader()

  const editorRef: any = useRef('');
  const [tabFields, setTabFields] = useState<any>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [uploadedUrl, setUploadedUrl] = useState<string>('')


  const { register, control, handleSubmit, reset, setValue, formState: { errors }, watch } = useForm()

  const getTabFiels = () => {
    siteSettingsList.map((item: any, index: number) => {
      if (item?.siteGroupName == tab) {
        setTabFields(item)
      }
    })
  }

  const onEditorChange = (e: any) => {
    // console.log({e})
    if (editorRef.current) {
      let editorContent = editorRef?.current.getContent()
      if (editorContent == '') {
        setErrorMessage("Story text is required")
      }
      else {
        setErrorMessage('')
      }
    }
  }

  const mapCategoryImage = (id: any, file: any, type: string) => {
    console.log({ id, file, type })
    return new Promise((resolve: any, reject: any) => {
      let data: any = new FormData();
      data.append("", file);
      data.append("ItemId", id);
      data.append("ItemType", 10);
      data.append("FileType", type);

      commonApi.fileUpload(data,
        (message: string, resp: any) => {
          console.log('f complete')
          resolve(message)
        }, (message: string) => {
          console.log('f failed')
          reject(message)
        })
    })

  }

  const onSubmit = (data: any) => {
    console.log({ data })
    let params: any = []

    for (const key in data) {
      let tempParams: any = {}
      tabFields?.keyValue.forEach((element: any) => {
        if (element?.appKey == key && key != "HeroImagesHome" && element?.appKey != "HeroImagesHome") {
          tempParams["appKey"] = element?.appKey
          tempParams["appValue"] = data[key]
          tempParams["typeID"] = element?.typeID
          params.push(tempParams)
        }
      });
    }

    if (editorRef.current) {
      let tempParams: any = {}
      let editorContent = editorRef?.current.getContent()
      if (editorContent == '') {
        setErrorMessage("Story text is required")
      }
      else {
        tempParams["appKey"] = "HowItWorksHome"
        tempParams["appValue"] = editorContent
        tempParams["typeID"] = 2
        params.push(tempParams)
      }
    }

    // console.log({ params })
    showLoader()
    siteSettingsApi.postSiteSettings({
      "siteSettings": params
    }, (message: string, resp: any) => {
      // console.log({ resp, message })
      if (data.HeroImagesHome[0] != undefined) {
        mapCategoryImage(resp.returnID, data.HeroImagesHome[0], "heroimageshome").then((msg: any) => {
          toast.success(message);
          getTabFiels()
          // handleCancel()
          hideLoader();
        }).catch((msg: any) => {
          toast.error(message);
          getTabFiels()
          hideLoader();
        })
      } else {
        toast.success(message);
        getTabFiels();
        // handleCancel()
        hideLoader();
      }
    }, (message: string) => {
      console.log({ message })
      hideLoader()
    })
  }

  const handleCancel = () => {
    reset({
      HeroImagesHome: '',
      StoreTaglineHome: '',
      StoreTagline2Home: '',
      StoreSubtitleHome: '',
      isStoreFeaturedProductsHome: false,
      StoreFeaturedEventTextHome: '',
      isShowFeaturedCollectionsHome: false,
      StoreFeaturedCollectionTextHome: '',
      isShowRecentCollectionsHome: false,
      StoreTrendingEventTextHome: '',
      isShowBestsellerCollectionsHome: false,
      StoreBestSellerTextHome: '',
      isShowBottomHome: false,
      StoreBottomTextHome: '',
      isShowCopyrightHome: false,
      StoreCopyrightTextHome: '',
      StoreBrowsByCategoriesTextHome: ''
    })
    setUploadedUrl('')
    editorRef?.current.setContent('')
  }

  useEffect(() => {
    if (tab && siteSettingsList) {
      getTabFiels()
    }
  }, [tab, siteSettingsList])

  useEffect(() => {
    // console.log({ tabFields })
    reset({
      // HeroImagesHome: tabFields?.keyValue[0].appValue,
      StoreTaglineHome: tabFields?.keyValue[16]?.appValue,
      StoreTagline2Home: tabFields?.keyValue[15]?.appValue,
      StoreSubtitleHome: tabFields?.keyValue[14]?.appValue,
      isStoreFeaturedProductsHome: tabFields?.keyValue[7]?.appValue == 'true' ? true : false,
      StoreFeaturedEventTextHome: tabFields?.keyValue[13]?.appValue,
      isShowFeaturedCollectionsHome: tabFields?.keyValue[5]?.appValue == 'true' ? true : false,
      StoreFeaturedCollectionTextHome: tabFields?.keyValue[12]?.appValue,
      isShowRecentCollectionsHome: tabFields?.keyValue[6]?.appValue == 'true' ? true : false,
      StoreTrendingEventTextHome: tabFields?.keyValue[17]?.appValue,
      isShowBestsellerCollectionsHome: tabFields?.keyValue[2]?.appValue == 'true' ? true : false,
      StoreBestSellerTextHome: tabFields?.keyValue[8]?.appValue,
      isShowBottomHome: tabFields?.keyValue[3]?.appValue == 'true' ? true : false,
      StoreBottomTextHome: tabFields?.keyValue[9]?.appValue,
      isShowCopyrightHome: tabFields?.keyValue[4]?.appValue == 'true' ? true : false,
      StoreCopyrightTextHome: tabFields?.keyValue[11]?.appValue,
      StoreBrowsByCategoriesTextHome: tabFields?.keyValue[10]?.appValue
    })
    setUploadedUrl(tabFields?.keyValue[0]?.appValue)
    // if (editorRef.current) {
    //   editorRef?.current.setContent(tabFields?.keyValue[1]?.appValue)
    // }
  }, [tabFields])

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5 mb-5">
          <FormItem label="Hero Image">
            <InputFile
              {...register(`HeroImagesHome`, { required: false })}
              onCanPlayCapture={(e: any) => {
                if (e == null) {
                  setValue(`HeroImagesHome`, '');
                }
              }}
              uploadedUrl={uploadedUrl}
            />
          </FormItem>
          <FormItem label="Tagline">
            <Controller
              name={`StoreTaglineHome`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
              />}
            />
            {/* {
              errors && errors.StoreTaglineHome && errors.StoreTaglineHome?.type == "required" ?
                <p className='text-red-400'>Required</p> : ''
            } */}
          </FormItem>
          <FormItem label="Tagline 2">
            <Controller
              name={`StoreTagline2Home`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
              />}
            />
            {/* {
              errors && errors.StoreTagline2Home && errors.StoreTagline2Home?.type == "required" ?
                <p className='text-red-400'>Required</p> : ''
            } */}
          </FormItem>
          <FormItem label="Subtitle">
            <Controller
              name={`StoreSubtitleHome`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
              />}
            />
            {/* {
              errors && errors.StoreSubtitleHome && errors.StoreSubtitleHome?.type == "required" ?
                <p className='text-red-400'>Required</p> : ''
            } */}
          </FormItem>
        </div>

        <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8 mb-5">
          <FormItem label="How it Works">
            <Editor
              onInit={(evt: any, editor: any) => {
                editorRef.current = editor
                // if(createPetition && createPetition.editor)
                // {
                //   editorRef.current.setContent(createPetition.editor)
                // }
              }}
              // name={name}
              // textareaName={name}
              onEditorChange={onEditorChange}
              // rows={5}
              // onBlur={onBlur}
              initialValue={tabFields?.keyValue[1]?.appValue}
              // inputRef={ref}
              // type="text"
              // error={errors.editor1}

              init={{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | ' +
                  'bold italic underline | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
            />
          </FormItem>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5 mb-5">


          <div>
          <FormItem label="Show Featured Products" className='mb-4 mt-4'>
            <Switch label=""
              {...register(`isStoreFeaturedProductsHome`)}
            />
          </FormItem>

          <FormItem label="Featured Products Text">
            <Controller
              name={`StoreFeaturedEventTextHome`}
              control={control}
              render={({ field }) => <Textarea
                {...field}
                rows={5}
                {...register(`StoreFeaturedEventTextHome`, { required: watch('isStoreFeaturedProductsHome') })}
              />}
            />
            {
              errors && errors.StoreFeaturedEventTextHome && errors.StoreFeaturedEventTextHome?.type == "required" ?
                <p className='text-red-400'>Required</p> : ''
            }
          </FormItem>
          </div>
          <div>
          <FormItem label="Show Featured Collections" className='mb-4 mt-4'>

            <Switch label=""
              {...register(`isShowFeaturedCollectionsHome`, { required: false })}
            />
          </FormItem>
          <FormItem label="Featured Collections Text">
            <Controller
              name={`StoreFeaturedCollectionTextHome`}
              control={control}
              render={({ field }) => <Textarea
                {...field}
                rows={5}
                {...register(`StoreFeaturedCollectionTextHome`, { required: watch('isShowFeaturedCollectionsHome') })}
              />}
            />
            {
              errors && errors.StoreFeaturedCollectionTextHome && errors.StoreFeaturedCollectionTextHome?.type == "required" ?
                <p className='text-red-400'>Required</p> : ''
            }
          </FormItem>
          </div>


          <div>
          <FormItem label="Show Recent Collections" className='mb-4 mt-4'>

            <Switch label=""
              {...register(`isShowRecentCollectionsHome`, { required: false })}
            />
          </FormItem>
          <FormItem label="Recent Collections Text">
            <Controller
              name={`StoreTrendingEventTextHome`}
              control={control}
              render={({ field }) => <Textarea
                {...field}
                rows={5}
                {...register(`StoreTrendingEventTextHome`, { required: watch('isShowRecentCollectionsHome') })}
              />}
            />
            {
              errors && errors.StoreTrendingEventTextHome && errors.StoreTrendingEventTextHome?.type == "required" ?
                <p className='text-red-400'>Required</p> : ''
            }
          </FormItem>
          </div>

          <div>
          <FormItem label="Show Bestseller Collections" className='mb-4 mt-4'>
            <Switch label=""
              {...register(`isShowBestsellerCollectionsHome`, { required: false })}
            />
          </FormItem>
          <FormItem label="Bestseller Collections Text">
            <Controller
              name={`StoreBestSellerTextHome`}
              control={control}
              render={({ field }) => <Textarea
                {...field}
                rows={5}
                {...register(`StoreBestSellerTextHome`, { required: watch('isShowBestsellerCollectionsHome') })}
              />}
            />
            {
              errors && errors.StoreBestSellerTextHome && errors.StoreBestSellerTextHome?.type == "required" ?
                <p className='text-red-400'>Required</p> : ''
            }
          </FormItem>
          </div>

          <div>
          <FormItem label="Show Bottom" className='mb-4 mt-4'>
            <Switch label=""
              {...register(`isShowBottomHome`, { required: false })}
            />
          </FormItem>
          <FormItem label="Bottom Text">
            <Controller
              name={`StoreBottomTextHome`}
              control={control}
              render={({ field }) => <Textarea
                {...field}
                rows={5}
                {...register(`StoreBottomTextHome`, { required: watch('isShowBottomHome') })}
              />}
            />
            {
              errors && errors.StoreBottomTextHome && errors.StoreBottomTextHome?.type == "required" ?
                <p className='text-red-400'>Required</p> : ''
            }
          </FormItem>
          </div>

          <div>
          <FormItem label="Show Copyright" className='mb-4 mt-4'>
            <Switch label=""
              {...register(`isShowCopyrightHome`, { required: false })}
            />
          </FormItem>
          <FormItem label="Copyright Text">
            <Controller
              name={`StoreCopyrightTextHome`}
              control={control}
              render={({ field }) => <Textarea
                {...field}
                rows={5}
                {...register(`StoreCopyrightTextHome`, { required: watch('isShowCopyrightHome') })}
              />}
            />
            {
              errors && errors.StoreCopyrightTextHome && errors.StoreCopyrightTextHome?.type == "required" ?
                <p className='text-red-400'>Required</p> : ''
            }
          </FormItem>
          </div>

        </div>

        <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8 mb-5">
          <FormItem label="Browse by Categories text">
            <Controller
              name={`StoreBrowsByCategoriesTextHome`}
              control={control}
              render={({ field }) => <Textarea
                {...field}
                rows={5}
                {...register(`StoreBrowsByCategoriesTextHome`, { required: false })}
              />}
            />
            {
              errors && errors.StoreBrowsByCategoriesTextHome && errors.StoreBrowsByCategoriesTextHome?.type == "required" ?
                <p className='text-red-400'>Required</p> : ''
            }
          </FormItem>
        </div>

        <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 m-8 mb-5">
          <ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
          <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Reset</ButtonSecondary>
        </div>
      </form>
    </React.Fragment>
  )
}

export default HomeSettings