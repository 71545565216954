import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import CheckBoxNative from "shared/Checkbox/nativeCheckbox";
import InputFile from "shared/InputFile/InputFile";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useUserApi } from "_common/hooks/api/UserApiHook";

export interface PageSignUpProps {
  className?: string;
}

const storeTypesOption = [{
  label: "Store",
  value: 1
}, {
  label: "Market Place",
  value: 2
}]

const subsriptionOption = [{
  label: "0",
  value: 0
}, {
  label: "1",
  value: 1
}]

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {

  const { register, control, handleSubmit, reset, getValues, setFocus, setValue, formState: { errors } } = useForm({});

  const commonApi = useCommonApi()
  const userApi = useUserApi()

  const [countryList, setCountryList] = useState<any>(null)

  const getCountries = () => {
    commonApi.getCountries({},
      (message: string, resp: any) => {
        // console.log({ message, resp })
        if (resp.countries.length > 0) {
          setCountryList(resp.countries.map((data: any) => {
            data.label = data.countryName;
            data.value = data.countryID;
            return data;
          }))
        }
      }, (message: string) => {
        console.log({ message })
      })
  }

  const handleKeyPress = (event: any) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

  const onSubmit = (data: any) => {
    console.log({ data })
    data["apiKey"] = "85B1FD63-08C6-41AC-BEC3-F7EEB598C62A"
    data["storeType"] = data.storeType.value
    data["subscriptionID"] = data.subscriptionID.value
    data["industryTypeID"] = data.industryTypeID.value
    userApi.signup(data,
      (message: string, resp: any) => {
        console.log({ resp })
      }, (message: string) => {
        console.log({ message })
      })
  }

  // useEffect(() => {
  //   getCountries()
  // }, [])

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-4xl mx-auto space-y-8 sm:space-y-10 my-12 sm:lg:my-16 lg:my-24">
          {/* FORM */}
          <form onSubmit={handleSubmit(onSubmit)} >
            <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    First Name
                  </span>
                  <Input
                    type="text"
                    placeholder=""
                    className="mt-1"
                    {...register("firstName", { required: true })}
                  />
                  {
                    errors && errors.firstName && errors.firstName?.type == "required" ?
                      <p className='text-red-400'>Required</p> : ''
                  }
                </label>

                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Last Name
                  </span>
                  <Input
                    type="text"
                    placeholder=""
                    className="mt-1"
                    {...register("lastName", { required: true })}
                  />
                  {
                    errors && errors.lastName && errors.lastName?.type == "required" ?
                      <p className='text-red-400'>Required</p> : ''
                  }
                </label>

                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Email
                  </span>
                  <Input
                    type="email"
                    placeholder=""
                    className="mt-1"
                    {...register("emailId", { required: true })}
                  />
                  {errors?.emailID ? <p className='text-red-400'>
                    {errors.emailID.type === "required" ? 'Required' : null}
                  </p> : null}
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Password
                  </span>
                  <Input type="password" className="mt-1" {...register("password", { required: true })} />
                  {errors?.password ? <p className='text-red-400'>
                    {errors.password.type === "required" ? 'Required' : null}
                  </p> : null}
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Phone
                  </span>
                  <Input type="text" className="mt-1" {...register("phoneNo", { required: true })} onKeyPress={(event: any) => handleKeyPress(event)} />
                  {errors?.phoneNo ? <p className='text-red-400'>
                    {errors.phoneNo.type === "required" ? 'Required' : null}
                  </p> : null}
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Mobile
                  </span>
                  <Input type="text" className="mt-1" {...register("mobile", { required: true })} onKeyPress={(event: any) => handleKeyPress(event)} />
                  {errors?.mobile ? <p className='text-red-400'>
                    {errors.mobile.type === "required" ? 'Required' : null}
                  </p> : null}
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Country
                  </span>
                  {/* <Controller
                    name={`countryID`}
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Select
                        className={`mt-1`}
                        options={countryList ? countryList : []}
                        placeholder={""}
                        onChange={onChange}
                        value={value}
                        defaultValue={value}
                        required={true}
                      />
                    )}
                  /> */}
                  <Input type="number" min="0" className="mt-1" {...register("countryID", { required: true })} />
                  {errors?.countryID ? <p className='text-red-400'>
                    {errors.countryID.type === "required" ? 'Required' : null}
                  </p> : null}
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    State
                  </span>
                  <Input type="number" min="0" className="mt-1" {...register("state", { required: true })} />
                  {errors?.state ? <p className='text-red-400'>
                    {errors.state.type === "required" ? 'Required' : null}
                  </p> : null}
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    City
                  </span>
                  <Input type="text" className="mt-1" {...register("city", { required: true })} />
                  {errors?.city ? <p className='text-red-400'>
                    {errors.city.type === "required" ? 'Required' : null}
                  </p> : null}
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Zip Code
                  </span>
                  <Input type="text" className="mt-1" {...register("zipCode", { required: true })} />
                  {errors?.zipCode ? <p className='text-red-400'>
                    {errors.zipCode.type === "required" ? 'Required' : null}
                  </p> : null}
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Address
                  </span>
                  <Input type="text" className="mt-1" {...register("address", { required: true })} />
                  {errors?.address ? <p className='text-red-400'>
                    {errors.address.type === "required" ? 'Required' : null}
                  </p> : null}
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Store Type
                  </span>
                  <Controller
                    name={"storeType"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Select
                        className={`mt-1`}
                        options={storeTypesOption ? storeTypesOption : []}
                        placeholder={""}
                        onChange={onChange}
                        value={value}
                        defaultValue={value}
                      />
                    )}
                  />
                  {errors?.storeType ? <p className='text-red-400'>
                    {errors.storeType.type === "required" ? 'Required' : null}
                  </p> : null}
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Subscription ID
                  </span>
                  <Controller
                    name={"subscriptionID"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Select
                        className={`mt-1`}
                        options={subsriptionOption ? subsriptionOption : []}
                        placeholder={""}
                        onChange={onChange}
                        value={value}
                        defaultValue={value}
                      />
                    )}
                  />
                  {errors?.subscriptionID ? <p className='text-red-400'>
                    {errors.subscriptionID.type === "required" ? 'Required' : null}
                  </p> : null}
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Store Name
                  </span>
                  <Input type="text" className="mt-1" {...register("storeName", { required: true })} />
                  {errors?.storeName ? <p className='text-red-400'>
                    {errors.storeName.type === "required" ? 'Required' : null}
                  </p> : null}
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Industry Type
                  </span>
                  <Controller
                    name={"industryTypeID"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Select
                        className={`mt-1`}
                        options={subsriptionOption ? subsriptionOption : []}
                        placeholder={""}
                        onChange={onChange}
                        value={value}
                        defaultValue={value}
                        required={true}
                      />
                    )}
                  />
                  {errors?.industryTypeID ? <p className='text-red-400'>
                    {errors.industryTypeID.type === "required" ? 'Required' : null}
                  </p> : null}
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Domain Name
                  </span>
                  <Input type="text" className="mt-1" {...register("domainName", { required: true })} />
                  {errors?.domainName ? <p className='text-red-400'>
                    {errors.domainName.type === "required" ? 'Required' : null}
                  </p> : null}
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Description
                  </span>
                  <Input type="text" className="mt-1" {...register("description", { required: true })} />
                  {errors?.description ? <p className='text-red-400'>
                    {errors.description.type === "required" ? 'Required' : null}
                  </p> : null}
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    For Trial
                  </span>
                  <CheckBoxNative className="mt-1.5" {...register('isTrial')} />
                </label>

                {/* <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Store Logo
                  </span>
                  <InputFile
                    {...register("storeLogoUrl", { required: false })} onCanPlayCapture={(e: any) => { if (e == null) { setValue('storeLogoUrl', ''); } }}
                  />
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Store Favicon
                  </span>
                  <InputFile
                    {...register("storeFaviconUrl", { required: false })} onCanPlayCapture={(e: any) => { if (e == null) { setValue('storeFaviconUrl', ''); } }}
                  />
                </label>

                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Profile Picture
                  </span>
                  <InputFile
                    {...register("profilePictureUrl", { required: false })} onCanPlayCapture={(e: any) => { if (e == null) { setValue('profilePictureUrl', ''); } }}
                  />
                </label> */}
              </div>

              <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3">
                <ButtonPrimary className="flex-1" type="submit">Continue</ButtonPrimary>
              </div>
            </div>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login" className="text-green-600">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
