import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import NcImage from "shared/NcImage/NcImage";
import CardNFT from "components/CardNFT";
import Pagination from "shared/Pagination/PaginationHandler";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import collectionBanner from "images/nfts/collectionBanner.png";
import { nftsImgs } from "contains/fakeData";
import NftMoreDropdown from "components/NftMoreDropdown";
import ButtonDropDownShare from "components/ButtonDropDownShare";
//import TabFilters from "components/Customfield/TabFilters";
import TabFilters from "components/Filters/TabFilters";
import SectionSliderCollections from "components/SectionSliderCollections";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useNavigate, useParams } from "react-router";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Controller, useForm } from "react-hook-form";
import Select from "shared/Select/Select";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { toast } from "react-hot-toast";
import { URLS } from "_config";
import Checkbox from "shared/Checkbox/Checkbox";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { Link } from "react-router-dom";
import ItemTypeEditIcon from "../../../components/ItemTypeEditIcon";
import ItemCustomFieldImageIcon from "../../../components/Customfield/ItemCustomFieldImageIcon";
import ItemTypeDeleteIcon from "../../../components/ItemTypeDeleteIcon";
import ButtonCircle from "shared/Button/ButtonCircle";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";
import PageHeaderWithSearch from "shared/PageHeaderWithSearch/PageHeaderWithSearch";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface Props {
  className?: string;
}

const PageCustomfieldmanage: FC<Props> = ({ className = "" }) => {
  const productApi = useProductApi();
  const { register, control, handleSubmit, reset, getValues, watch, formState: { errors } } = useForm({});
  const [customfields, setCustomfields] = useState<any>(null);
  // for pagination
  const limit: number = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [sort, setSort] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');
  const { showLoader, hideLoader } = useAppLoader();
  const [isOpen, setIsOpen] = React.useState(false);
  const [filterparams, setFilterparams] = React.useState<any>({});

  const sortBy = [
    { name: "Name ASC", id: "name" },
    { name: "Name DESC", id: "name:desc" }
  ];

  const ItemTypeOptions: Array<any> = [{ id: "1", name: 'Collection' }, { id: "2", name: 'Product' }, { id: "3", name: 'Category' }];

  const getCustomFields = () => {
    let params: any = {
      usePaging: true,
      pageSize: limit,
      pageNumber: currentPage,
      searchtext: searchKey,
      itemType: (filterparams?.radioBtnFiltrations?.usedFor) ? filterparams?.radioBtnFiltrations?.usedFor : ''
    }
    console.log(filterparams?.radioBtnFiltrations?.sortBy);
    switch (filterparams?.radioBtnFiltrations?.sortBy) {
      case 'name':
        params.sortingBy = 'customFieldName';
        params.sortingOrder = 'asc';
        break;
      case 'name:desc':
        params.sortingBy = 'customFieldName';
        params.sortingOrder = 'desc';
        break;
      default:
    }
    console.log('params => ', params);
    showLoader();
    productApi.getCustomfields(params, (message: string, resp: any) => {
      hideLoader();
      setCustomfields(resp.customFielddm);
      setTotalPage(Math.ceil(resp.totalRecord / limit));
      console.log(resp)
    }, (message: string) => {
      hideLoader();
      console.log(message);
    })
  }

  const deleteCustomField = (id: any) => {
    return new Promise((resolve: any, reject: any) => {
      showLoader();
      productApi.deleteCustomfield({
        customFieldID: id
      }, (message: string, resp: any) => {
        hideLoader();
        resolve(message)
      }, (message: string) => {
        hideLoader();
        reject(message);
      })
    })
  }

  const customfieldDeletehandler = (field: any) => {
    console.log(field.customFieldID)
    toast((t) => (
      <span>
        Are you sure?
        <br />
        You want to <b className="text-danger">delete</b> Custom field{" "}
        <b>{field.customFieldName}</b>
        <br />
        <hr className="mb-3 mt-3" />
        <div className="align-middle flex justify-end">
          <ButtonPrimary className="bg-black m-1 sm:h-9" onClick={() => { toast.dismiss(t.id) }} >Cancel</ButtonPrimary>
          <ButtonPrimary
            className="bg-red-500 m-1 sm:h-9"
            onClick={() => {
              toast.dismiss(t.id);
              toast.promise(
                deleteCustomField(field.customFieldID), {
                loading: "Deleting...",
                success: (msg) => {
                  getCustomFields();
                  return <b>{field.customFieldName} Deleted Successfully!</b>
                },
                error: (msg) => { return <b>{msg}</b> },
              }, { duration: 2000 });
            }}
          >
            Delete
          </ButtonPrimary>
        </div>
      </span>
    ),
      { duration: 95000 }
    );
  }

  const onApplySort = (type: string) => {
    setSort(type);
    //setTimeout(() => {
    //  getCustomFields();
    //});
  }

  const onSearch = (data: any) => {
    console.log("Search data", data);
    setSearchKey(data);
  }

  useEffect(() => {
    if (customfields) {
      if (currentPage == 1) {
        getCustomFields();
      } else {
        setCurrentPage(1);
      }
    }

  }, [sort])


  useEffect(() => {
    if (customfields) {
      if (currentPage == 1) {
        getCustomFields();
      } else {
        setCurrentPage(1);
      }
    }

  }, [searchKey]);



  useEffect(() => {
    getCustomFields();
  }, [currentPage])

  const onApplyFilter = (data: any) => {
    setFilterparams(data);
  }

  useEffect(() => {
    //console.log("sao paulo", filterparams);
    if (filterparams && filterparams?.radioBtnFiltrations && typeof filterparams.radioBtnFiltrations.usedFor !== 'undefined' && typeof filterparams.radioBtnFiltrations.sortBy !== 'undefined') {
      //console.log("xxx", filterparams.radioBtnFiltrations.itemType, filterparams.radioBtnFiltrations.sortBy);
      getCustomFields();
    }
  }, [filterparams])


  return (
    <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
      <Helmet>
        <title>Custom Fields</title>
      </Helmet>
      <PageHeaderWithSearch title="Custom Fields" onSearch={setSearchKey} searchKey={searchKey} />

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>

          {/* FILTER */}
          <div className={`flex flex-col relative ${className}`}>
            <div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
              <div className="relative flex w-full text-sm md:text-base hiddenScrollbar items-center">

              </div>
              <span className="flex flex-shrink-0 text-right items-center">
                <Link className="sm:space-x-2" to="/customfields/add">
                  <ButtonPrimary>Add New</ButtonPrimary>
                </Link>
                <ButtonSecondary
                  className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium pl-4 py-2.5 sm:pl-6 border-primary-500 focus:ring-0 focus:ring-primary-200  ttnc-ButtonSecondary border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 w-auto !pr-16 ml-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0"
                  sizeClass="pl-4 py-2.5 sm:pl-6"
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <svg
                    className={`w-4 h-4 sm:w-6 sm:h-6`}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19.87 17.12L18.87 16.12"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="block truncate ml-2.5">Filter</span>
                  <span className="absolute top-1/2 -translate-y-1/2 right-5">
                    <ChevronDownIcon
                      className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpen ? "rotate-180" : ""
                        }`}
                      aria-hidden="true"
                    />
                  </span>
                </ButtonSecondary>
              </span>
            </div>

            <Transition
              show={isOpen}
              enter="transition-opacity duration-150"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
              {/*<TabFilters onApplySort={onApplySort} onSearch={onSearch} />*/}
              <TabFilters name="Customfields Filters"
                radioBtnFiltrations={[
                  {
                    key: 'sortBy',
                    options: sortBy
                  },
                  {
                    key: 'usedFor',
                    options: [{ id: "1", name: 'Collection' }, { id: "2", name: 'Product' }, { id: "3", name: 'Category' }]
                  }
                ]} onApplyFilter={onApplyFilter}/* onApplySort={onApplySort} onSearch={onSearch} */ />
            </Transition>
          </div>

          <div className="grid sm:grid-cols-2 gap-x-8 gap-y-8  mt-8 lg:mt-10">
            {customfields && customfields.length > 0 ? (
              <React.Fragment>
                {customfields.map((item: any, index: number) => {
                  return (
                    <div key={'cstfld-' + index} className={`relative rounded-xl hover:shadow-lg hover:bg-neutral-50 border border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800 dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200`}>


                      <div className="flex items-center w-full">
                        <div className="w-20 custom-fld-icon mr-4 custom-width-mobile">
                          <ItemCustomFieldImageIcon />
                        </div>
                        <div className="w-full custom-cat-wrap">
                          <div className="flex justify-between">
                            <h3 className={`text-sm font-medium category-name`}></h3>
                            <h3 className={`text-sm font-medium`}>{''}</h3>
                          </div>
                          <h2 className={`text-lg font-semibold text-2xl mb-1 flex items-center`}>{item.customFieldName} - <span className="font-normal text-gray-400 text-sm ml-2">{item.itemTypeName}</span></h2>
                          <div className="flex justify-between capitalize">
                            {item.customFieldTypeName}
                            {/* <div className="option">
                              <h4 className={`text-sm font-medium item-status ` + (item.isActive === 1 ? 'bg-lime-500 text-white' : 'bg-orange-300 text-white')}>{item.isActive === 1 ? 'Published' : 'Not published'}</h4>
                            </div> */}
                          </div>
                        </div>
                        {/* <div className="relative flex-shrink-0 flex">
                          <span onClick={() => { customfieldDeletehandler(item) }} className="ml-2 delete-btn relative"><ItemTypeDeleteIcon /></span>
                        </div> */}

                        <div className="absolute left-auto top-3 right-3 flex flex-row-reverse justify-end gap-2">
                          <NftMoreDropdown
                            actions={[
                              {
                                id: "customer_delete",
                                name: "Delete",
                                icon: "las la-trash",
                                callBack: () => customfieldDeletehandler(item)
                              },
                            ]}
                            containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
                          />
                        </div>
                      </div>


                    </div>
                  )
                })}
              </React.Fragment>
            ) : (
              <div className="noproductfoundgraphics">
                No Customfield added yet.
              </div>
            )}
          </div>

          {totalPage > 1 && (
            <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
              <Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
            </div>
          )}
        </main>

      </div>
    </div>
  );
}

export default PageCustomfieldmanage;