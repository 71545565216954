import React from "react";
// import {FaEdit} from "react-icons/fa";
import {FiEdit3} from "react-icons/fi";

export interface ItemTypeImageIconProps {
  className?: string;
}

const ItemTypeImageIcon: React.FC<ItemTypeImageIconProps> = ({
  className = "w-8 h-8 md:w-10 md:h-10",
}) => {
  return (
    <div
      className={`bg-black/50 flex items-center justify-center rounded-full text-white ${className}`}

    >
      <FiEdit3/>

    </div>
  );
};

export default ItemTypeImageIcon;
