import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
  BlankReq,
  LoginReq,
} from '../../interfaces/ApiReqRes'


export function useUserApi() {

  const callApi = useApiCall()

  const login = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
  }

  const logout = (data: BlankReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGOUT, data, onSuccess, onError);
  }

  const registerStepOne = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTER.STEP1, data, onSuccess, onError);
  }

  const registerStepTwo = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTER.STEP2, data, onSuccess, onError);
  }

  const registerStepThree = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTER.STEP3, data, onSuccess, onError);
  }

  const registerStepFour = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTER.STEP4, data, onSuccess, onError);
  }

  const loginstep1 = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGINSTEP1, data, onSuccess, onError);
  }

  const forgotpassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.FORGOT_PASSWORD, data, onSuccess, onError);
  }

  const setforgottenpassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.RESET_PASSWORD, data, onSuccess, onError);
  }

  const getStoreConfiguration = (data: BlankReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.STORE.CONFIG, data, onSuccess, onError);
  }

  const saveStoreConfiguration = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.STORE.CONFIG_SAVE, data, onSuccess, onError);
  }

  const getProfileDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.GET_PROFILE, data, onSuccess, onError);
  }

  const updateProfile = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.EDIT_PROFILE, data, onSuccess, onError);
  }

  const changePassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.CHANGE_PASSWORD, data, onSuccess, onError);
  }

  const connectWallet = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.CONNECT_WALLET, data, onSuccess, onError);
  }

  const customerAdd = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.CUSTOMER_ADD, data, onSuccess, onError);
  }

  const customerList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.CUSTOMER_LIST, data, onSuccess, onError);
  }

  const deleteCustomer = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DELETE_CUSTOMER, data, onSuccess, onError);
  }

  const signup = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SIGNUP, data, onSuccess, onError);
  }

  const getUserProfile = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.GET_USER_PROFILE, data, onSuccess, onError);
  }

  return {
    login: login,
    logout: logout,
    registerStepOne: registerStepOne,
    registerStepTwo: registerStepTwo,
    registerStepThree: registerStepThree,
    registerStepFour: registerStepFour,
    loginstep1: loginstep1,
    forgotpassword: forgotpassword,
    setforgottenpassword: setforgottenpassword,
    getStoreConfiguration: getStoreConfiguration,
    saveStoreConfiguration: saveStoreConfiguration,
    getProfileDetails: getProfileDetails,
    updateProfile: updateProfile,
    changePassword: changePassword,
    connectWallet,
    customerAdd,
    customerList,
    deleteCustomer: deleteCustomer,
    signup: signup,
    getUserProfile: getUserProfile,
  }
}