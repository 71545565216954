import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi } from './api/callApi';
const API_BASE_URL = process.env.REACT_APP_API_URL;


export function* getSiteSettings(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SITE_SETTINGS.GET_SITE_SETTINGS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            yield put({
                type: ACTIONS.SITE_SETTINGS.GET_SITE_SETTINGS,
                payload: {
                    types: resp.data.siteSettings
                }
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* postSiteSettings(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SITE_SETTINGS.POST_SITE_SETTINGS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addCustodialWallet(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SITE_SETTINGS.ADD_CUSTODIAL_WALLET, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* fetchCustodialWallet(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SITE_SETTINGS.FETCH_CUSTODIAL_WALLET, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}