import React, { InputHTMLAttributes, useState } from "react";

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  fontClass?: string;

}

const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(({
  className = "",
  fontClass = "",
  children,
  label="",
  type = "checkbox",
  ...args
}, ref) => {
    return (
     
      <div className={`switch flex w-full relative ${className} `}>
        <input
        ref={ref}
        type={type}
        className={``}
        {...args}
      />
      <label  className={``}>
          <div className={``}></div>
      </label>
      <p className={`nc-Label text-base font-medium text-neutral-900 dark:text-neutral-200 ${fontClass} `}>{label}</p>
      </div>
      
    );
  }
);

export default Switch;
