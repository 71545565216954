import React from "react";
import ReactDOM from "react-dom/client";
import { Web3ReactProvider } from '@web3-react/core'

//
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "rc-slider/assets/index.css";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "app/store";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from 'react-hot-toast';  
import AppLoader from './_common/pageLoader/appLoader';
import { ACTIONS, STORAGE } from "_config";
import { MetaMaskProvider, getLibrary } from './_common/provider/metamask-provider'
import { useSearchParams } from "react-router-dom";

const urlParams = new URLSearchParams(window.location.search);
const autoData:any= urlParams.get('auto');
if(autoData !== null){
	let data:any = autoData.replace(/\|/g,'Q')
	sessionStorage.setItem(STORAGE,atob(data));
	window.location.href = '/login'
}




const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

function render() {
  root.render(
	<Web3ReactProvider getLibrary={getLibrary}>
        <MetaMaskProvider>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<AppLoader />
					<Toaster position="top-center" />
					<App />
				</PersistGate>
			</Provider>
		</MetaMaskProvider>
	</Web3ReactProvider>
  
  );
}



const user = sessionStorage.getItem(STORAGE);

if (user) {
	try {
		store.dispatch({
			type: ACTIONS.USER.LOGIN,
			payload: {
				user: JSON.parse(user),
				token: null
			}
		});
		render();
	} catch (e) {
		sessionStorage.removeItem(STORAGE);
		render();
	}
} else {
	render();
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
