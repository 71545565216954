import { type } from 'os';
import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { User } from '../../../_common/interfaces/models/user';
import { ACTIONS } from '../../../_config'

export interface CollectionReducer {
  types: any[] | null;
}

const initialState: CollectionReducer = {
  types: null
};

const collectionReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.COLLECTION.GET_COLLECTION_TYPES:
      return {
        ...state,
        types: action.payload.types
      }
    default:
      return state;
  }
};

export default collectionReducer;
