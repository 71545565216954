import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi } from './api/callApi';
const API_BASE_URL = process.env.REACT_APP_API_URL;

const serialize = (obj:any) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}
const asUrlParams = (obj:any)=>{
    return '?'+serialize(obj);
}

export function* getPayoutGroups(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.PAYOUT_GROUP.GET_PAYOUT_GROUPS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data  ) {
            /*yield put({
                type: ACTIONS.COLLECTION.GET_COLLECTION_TYPES,
                payload: {
                    types:  resp.data.collectionTypes
                }
            })*/
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addPayoutGroups(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.PAYOUT_GROUP.ADD_PAYOUT_GROUP, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data  ) {
            /*yield put({
                type: ACTIONS.COLLECTION.GET_COLLECTION_TYPES,
                payload: {
                    types:  resp.data.collectionTypes
                }
            })*/
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* updatePayoutGroups(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.PUT, API_URL.PAYOUT_GROUP.UPDATE_PAYOUT_GROUP, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data  ) {
            /*yield put({
                type: ACTIONS.COLLECTION.GET_COLLECTION_TYPES,
                payload: {
                    types:  resp.data.collectionTypes
                }
            })*/
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deletePayoutGroups(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.PAYOUT_GROUP.DELETE_PAYOUT_GROUP + asUrlParams(data), data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data  ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
