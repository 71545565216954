import React, { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from 'react-router';
import { useForm, Controller } from "react-hook-form";
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment'

import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import DateInput from "shared/Date/DateInput"
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Switch from "shared/Switch/Switch";

import { Collection_Genesis_Form } from '../../_common/interfaces/FormProps'
import { CollectionGenesisFormSchemaValidation } from '../../utils/form-validation'
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import InputPrice from "components/InputPrice";

import { useCollectionApi } from '../../_common/hooks/api/collectionApiHook'
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface CollectionGenesisProps {
    className?: string;
}

const Genesis: FC<CollectionGenesisProps> = ({ className = "" }) => {

    const { id: collectionId } = useParams();
    const navigate = useNavigate()
    const { getCollections, updateGenesis } = useCollectionApi()
    const { showLoader, hideLoader } = useAppLoader();

    const [collectionDetails, setCollectionDetails] = useState<any>(null)
    const { register, control, getValues, setValue, watch, setError, clearErrors, handleSubmit, reset, formState: { errors } } = useForm<Collection_Genesis_Form>({
        defaultValues: {
            isGenesisEnable: false,
            saleStartDate: new Date(),
            saleEndDate: new Date(),
            cryptoPrice: 0,
            genesisTotalSupply: 1,
            description: ''
        },
        resolver: yupResolver(CollectionGenesisFormSchemaValidation)
    });

    useEffect(() => {
        fetchCollection()
    }, [])

    useEffect(() => {
        if (collectionDetails) {
            const genesis = collectionDetails && collectionDetails['genesis'] || [];
            const collectionGenesis = genesis[0] || null;
            // console.log('start date: ', collectionGenesis['saleStartDate'], ' local: ', moment(collectionGenesis['saleStartDate']).local().format('YYYY-MM-DD HH:mm:ss'), ' date: ', moment(collectionGenesis['saleStartDate']).local().toDate())

            reset({
                isGenesisEnable: collectionDetails['isEnableGenesis'] || false,
                saleStartDate: collectionGenesis && collectionGenesis['saleStartDate'] ? moment.utc(collectionGenesis['saleStartDate']).local().toDate() : new Date(),
                saleEndDate: collectionGenesis && collectionGenesis['saleEndDate'] ? moment.utc(collectionGenesis['saleEndDate']).local().toDate() : new Date(),
                cryptoPrice: collectionGenesis && collectionGenesis['cryptoPrice'] || 0,
                genesisTotalSupply: collectionGenesis && collectionGenesis['genesisTotalSupply'] || 1,
                description: collectionGenesis && collectionGenesis['description'] || ''
            })
        } else {

        }
    }, [collectionDetails]);

    const fetchCollection = () => {
        if (collectionId) {
            getCollections({ collectionID: collectionId },
                (message: string, resp: any) => {
                    const data = resp && resp.collections && resp.collections.length && resp.collections[0] || null;
                    if (data) {
                        setCollectionDetails(data)
                    } else {
                        toast.error('Collection not found!')
                        navigate('/collection')
                    }
                },
                (message: string, resp: any) => {
                    console.log('error resp: ', resp)
                    toast.error(message)
                })
        } else {
            toast.error('Collection ID not found!')
            navigate('/collection')
        }
    }

    const genesisSubmitted = (data: any) => {
        if (collectionId) {
            showLoader()
            const params = {
                collectionID: collectionId,
                isGenesisEnable: data['isGenesisEnable'] || false,
                saleStartDate: moment(data['saleStartDate']).utc().format("YYYY-MM-DD HH:mm:ss a"),
                saleEndDate: moment(data['saleEndDate']).utc().format("YYYY-MM-DD HH:mm:ss a"),
                cryptoCurrency: collectionDetails && collectionDetails['blockchainNetwork'] || "ETH",
                cryptoPrice: data['cryptoPrice'],
                genesisTotalSupply: data['genesisTotalSupply'],
                description: data['description']
            }

            updateGenesis(params,
                (message: string, resp: any) => {
                    hideLoader()
                    toast.success(message)
                },
                (message: string, resp: any) => {
                    console.log('error resp: ', resp)
                    hideLoader()
                    toast.error(message)
                })
        } else {
            toast.error('Collection ID not found!')
        }
    }

    const handleCancel = () => {
        navigate('/collection')
    }

    return (

        <form onSubmit={handleSubmit(genesisSubmitted)}>
            <div className="grid grid-cols-1 sm:grid-cols-1 gap-5 sm:gap-5">
                <label className="block mb-3 mt-6">
                    <Switch label="Enable Genesis"  {...register("isGenesisEnable")} />
                </label>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5">
                <label className="block">
                    <Label className="block mb-2">Start Date<span className="text-red-600">*</span></Label>
                    <Controller
                        control={control}
                        name="saleStartDate"
                        render={({ field: { onChange, onBlur, value, name, ref }, }) => (
                            <DateInput
                                name={name}
                                onChange={onChange}
                                value={value}
                                onBlur={onBlur}
                                placeholder="Start Date"
                                showTimeSelect={true}
                                dateFormat="MM-dd-yyyy hh:mm a"
                            />
                        )}
                    />
                    {
                        errors && errors.saleStartDate && errors.saleStartDate.message &&
                        (<p className='text-red-400'>
                            {errors.saleStartDate.message}
                        </p>)
                    }
                </label>

                <label className="block">
                        <Label className="block mb-2">End Date<span className="text-red-600">*</span></Label>
                        <Controller
                            control={control}
                            name="saleEndDate"
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                            }) => (
                                <DateInput
                                    name={name}
                                    onChange={onChange}
                                    value={value}
                                    onBlur={onBlur}
                                    placeholder="End Date"
                                    showTimeSelect={true}
                                    dateFormat="MM-dd-yyyy hh:mm a"
                                />
                            )}
                        />
                        {
                            errors && errors.saleEndDate && errors.saleEndDate.message &&
                            (<p className='text-red-400'>
                                {errors.saleEndDate.message}
                            </p>)
                        }
                </label>
                <label className="block">
                    <Label className="block mb-2">Price<span className="text-red-600">*</span></Label>
                    <InputPrice network={collectionDetails?.blockchainNetwork} placeholder={"Price"} className={`pricetb_` + collectionDetails?.blockchainNetwork} {...register('cryptoPrice')} />
                    {/* <Controller
                        name={'cryptoPrice'}
                        control={control}
                        render={({ field }) => <Input
                            {...field}
                            type={'number'}
                            placeholder="Price"
                            onWheel={(e) => e.currentTarget.blur()}
                        />}
                    /> */}
                    {
                        errors && errors.cryptoPrice && errors.cryptoPrice.message &&
                        (<p className='text-red-400'>
                            {errors.cryptoPrice.message}
                        </p>)
                    }
                </label>
                <label className="block">
                    <Label className="block mb-2">Quanity<span className="text-red-600">*</span></Label>
                    <Controller
                        name={'genesisTotalSupply'}
                        control={control}
                        render={({ field }) => <Input
                            {...field}
                            type={'number'}
                            placeholder="Total supply"
                            onWheel={(e) => e.currentTarget.blur()}
                        />}
                    />
                    {
                        errors && errors.genesisTotalSupply && errors.genesisTotalSupply.message &&
                        (<p className='text-red-400'>
                            {errors.genesisTotalSupply.message}
                        </p>)
                    }
                </label>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-1 gap-5 sm:gap-5 mt-5">
                <label className="block">
                    <Label className="block mb-2">Description</Label>
                    <Controller
                        name={'description'}
                        control={control}
                        render={({ field }) => <Textarea {...field} />}
                    />
                    {
                        errors && errors.description && errors.description.message &&
                        (<p className='text-red-400'>
                            {errors.description.message}
                        </p>)
                    }
                </label>
            </div>
       

            <div className="w-full border-b-2 mb-5 mt-5 border-neutral-100 dark:border-neutral-700 mt-10 mb-10"></div>
            {/* <div className={`text-center`}>
                <ButtonPrimary type="submit">Save</ButtonPrimary>
            </div> */}
            <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
                <ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
                <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Cancel</ButtonSecondary>
            </div>

        </form>

    );
};

export default Genesis;
