import FormItem from 'components/FormItem'
import Label from 'components/Label/Label'
import React, { useEffect, useState, Fragment } from 'react'
import { Helmet } from "react-helmet"
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import ButtonSecondary from 'shared/Button/ButtonSecondary'
import { Tab } from "@headlessui/react";
import NcImage from 'shared/NcImage/NcImage'
import TabFilters from "components/Filters/TabFilters";
import { useUserApi } from '_common/hooks/api/UserApiHook'
import { useCollectionApi } from '../../_common/hooks/api/collectionApiHook'
import { useProductApi } from '../../_common/hooks/api/productApiHook'
import { useAppLoader } from '_common/hooks/common/appLoaderHook'
import Pagination from "shared/Pagination/PaginationHandler";
import { TfiWallet } from 'react-icons/tfi'
import toast from "react-hot-toast";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";

const lists = [
	{ name: "Most Recent" }
];

const sortBy = [
	{ name: "Name (ASC)", id: "name-asc" },
	{ name: "Name (DESC)", id: "name-desc" }
];

const ViewProfile = () => {

    const limit = 12;
    const { id } = useParams()
    const userApi = useUserApi()
    const { showLoader, hideLoader } = useAppLoader()
    const { getCollections } = useCollectionApi();
    const { getProducts, getMyNft } = useProductApi();

	const [isOpen, setIsOpen] = useState(false);
	const [filter, setFilter] = useState<any>({});
    const [collectionList, setCollectionList] = useState<any[]>([])
    const [productList, setProductList] = useState<any[]>([])
	const [nftList, setNftList] = useState<any[]>([])
    const [currentCollectionPage, setCurrentCollectionPage] = useState<number>(1);
	const [totalCollectionPage, setTotalCollectionPage] = useState<number>(1);
    const [currentProductPage, setCurrentProductPage] = useState<number>(1);
	const [totalProductPage, setTotalProductPage] = useState<number>(1);
	const [currentNftPage, setCurrentNftPage] = useState<number>(1);
	const [totalNftPage, setTotalNftPage] = useState<number>(1);
    const [customerDetails, setCustomerDetails] = useState<any>(null)

    const getCustomerDetails = () => {
        showLoader()
        userApi.customerList({ customerID: id },
            (message: string, resp: any) => {
                console.log({ message, resp })
                setCustomerDetails(resp?.customers[0])
                hideLoader()
            },
            (message: string) => {
                console.log(message)
                hideLoader()
            })
    }

    const fetchCollections = () => {
		let params: any = {
			usePaging: true,
            addedBy: id, 
            isCreatedByCustomer: 1,
			pageSize: limit,
			pageNumber: currentCollectionPage
		}

		if(filter?.radioBtnFiltrations){
			switch (filter.radioBtnFiltrations.sortBy) {
				case 'name-asc':
					params.sortingBy = 'collectionName';
					params.sortingOrder = 'asc';
					break;
				case 'name-desc':
					params.sortingBy = 'collectionName';
					params.sortingOrder = 'desc';
					break;
				default:
			}
		}

		showLoader();
		getCollections(params, (message: string, resp: any) => {
			hideLoader();
			setCollectionList(resp.collections);
			setTotalCollectionPage(Math.ceil(resp.totalRecord / limit));
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

    const fetchProducts = () => {
		let params: any = {
			usePaging: true,
            addedBy: id,
            isCreatedByCustomer: 1,
			pageSize: limit,
			pageNumber: currentProductPage
		}

		if(filter?.radioBtnFiltrations){
			switch (filter.radioBtnFiltrations.sortBy) {
				case 'name-asc':
					params.sortingBy = 'productName';
					params.sortingOrder = 'asc';
					break;
				case 'name-desc':
					params.sortingBy = 'productName';
					params.sortingOrder = 'desc';
					break;
				default:
			}
		}

		showLoader();
		getProducts(params, (message: string, resp: any) => {
			hideLoader()
			setProductList(resp.products);
			setTotalProductPage(Math.ceil(resp.totalRecord / limit));
		}, (message: string) => {
			hideLoader()
			console.log(message);
		})
	}

	const fetchNfts = () => {
		let params: any = {
			usePaging: true,
            createdBy: id,
            isCreatedByCustomer: 1,
			pageSize: limit,
			pageNumber: currentNftPage
		}

		if(filter?.radioBtnFiltrations){
			switch (filter.radioBtnFiltrations.sortBy) {
				case 'name-asc':
					params.sortingBy = 'name';
					params.sortingOrder = 'asc';
					break;
				case 'name-desc':
					params.sortingBy = 'name';
					params.sortingOrder = 'desc';
					break;
				default:
			}
		}

		showLoader();
		getMyNft(params, (message: string, resp: any) => {
			hideLoader()
			setNftList(resp.nfTs);
			setTotalNftPage(Math.ceil(resp.totalRecord / limit));
		}, (message: string) => {
			hideLoader()
		})
	}

	useEffect(() => {
		if ( productList ) {
			fetchProducts()
		}
		if ( nftList ) {
			fetchNfts()
		}
		if ( collectionList ) {
			fetchCollections()
		}
	}, [filter])

    useEffect(() => {
		if (currentProductPage > 1) {
			fetchProducts()
		}
	}, [currentProductPage]);

	useEffect(() => {
		if (currentNftPage > 1) {
			fetchNfts()
		}
	}, [currentNftPage]);

    useEffect(() => {
		if (currentCollectionPage > 1) {
			fetchCollections()
		}
	}, [currentCollectionPage]);

    useEffect(() => {
        if (id) {
            getCustomerDetails()
            fetchProducts()
			fetchNfts();
            fetchCollections()
        }
    }, [id])

	const copyText = (data: string) => {
		navigator.clipboard.writeText(data)
		toast.success('Copied')
	}

    return (
        <React.Fragment>
            <div className={`nc-AccountPage`} data-nc-id="AccountPage">
                <Helmet>
                    <title>Profile</title>
                </Helmet>
                <div className="w-full">
					<div className="relative w-full h-40 md:h-60 2xl:h-72">
						<NcImage containerClassName="absolute inset-0" src={customerDetails && customerDetails?.profileImageUrl} className="object-cover w-full h-full" />
					</div>
					<div className="container -mt-10 lg:-mt-16">
						<div className="relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row cstm-sm-flex-col">
							<div className="w-32 lg:w-44 flex-shrink-0 mt-12 sm:mt-0">
								<NcImage src={customerDetails && customerDetails?.profileImageUrl} containerClassName="aspect-w-1 aspect-h-1 rounded-3xl overflow-hidden" />
							</div>
							<div className="pt-5 md:pt-1 md:ml-6 xl:ml-14 flex-grow">
								<div className="max-w-screen-sm ">
									<h2 className="inline-flex items-center text-2xl sm:text-3xl lg:text-4xl font-semibold">
										<span>{customerDetails && customerDetails.firstName && customerDetails?.lastName ? `${customerDetails.firstName} ${customerDetails.lastName}` : 'NA' }</span>
										{/* <VerifyIcon
											className="ml-2"
											iconClass="w-6 h-6 sm:w-7 sm:h-7 xl:w-8 xl:h-8"
										/> */}
									</h2>
									{
										customerDetails && customerDetails.cryptoAddress && (
											<div className="flex items-center text-sm font-medium space-x-2.5 mt-2.5 text-green-600 cursor-pointer">
												<TfiWallet />
												<span className="text-neutral-700 dark:text-neutral-300">
													{customerDetails && customerDetails?.cryptoAddress}
												</span>
												<div onClick={() => copyText(customerDetails.cryptoAddress)}>
													<svg width="20" height="21" viewBox="0 0 20 21" fill="none">
														<path
														d="M18.05 9.19992L17.2333 12.6833C16.5333 15.6916 15.15 16.9083 12.55 16.6583C12.1333 16.6249 11.6833 16.5499 11.2 16.4333L9.79999 16.0999C6.32499 15.2749 5.24999 13.5583 6.06665 10.0749L6.88332 6.58326C7.04999 5.87492 7.24999 5.25826 7.49999 4.74992C8.47499 2.73326 10.1333 2.19159 12.9167 2.84993L14.3083 3.17493C17.8 3.99159 18.8667 5.71659 18.05 9.19992Z"
														stroke="currentColor"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
														/>
														<path
														d="M12.5498 16.6583C12.0331 17.0083 11.3831 17.3 10.5915 17.5583L9.2748 17.9917C5.96646 19.0583 4.2248 18.1667 3.1498 14.8583L2.08313 11.5667C1.01646 8.25833 1.8998 6.50833 5.20813 5.44167L6.5248 5.00833C6.86646 4.9 7.19146 4.80833 7.4998 4.75C7.2498 5.25833 7.0498 5.875 6.88313 6.58333L6.06646 10.075C5.2498 13.5583 6.3248 15.275 9.7998 16.1L11.1998 16.4333C11.6831 16.55 12.1331 16.625 12.5498 16.6583Z"
														stroke="currentColor"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
														/>
													</svg>
												</div>
											</div>
										) || ''
									}
									<span className="block mt-4 text-sm text-neutral-500 dark:text-neutral-400">
										{customerDetails && customerDetails?.description}
									</span>
								</div>
								{/* <div className="mt-5">
									{customerDetails && customerDetails?.socialURL && customerDetails?.socialURL.length > 0 ?
										<SocialsList itemClass="block w-7 h-7" socialsList={customerDetails && customerDetails?.socialURL} />
										: null
									}
								</div> */}
							</div>
							<div className="absolute md:static left-5 top-4 sm:left-auto sm:top-5 sm:right-5 flex flex-row-reverse justify-end gap-2">

								{/* <NftMoreDropdown
									type_id={1}
									actions={[
										{
											id: "report",
											name: "Report abuse",
											icon: "las la-flag",
										},
									]}
									containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
								/>

								<NcShareDropDown
									className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer mx-2"
									panelMenusClass="origin-top-right !-right-5 !w-40 sm:!w-52"
								/>

								<FollowButton
									isFollowing={false}
									fontSize="text-sm md:text-base font-medium"
									sizeClass="px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8"
								/> */}

								{/* {isAuth && ( */}
								{/* <LikeButton
									className="px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8"
									id={id}
									type={1}
									isAuth={isAuth}
								/> */}
								{/* )} */}

							</div>
						</div>
					</div>
				</div>

				<div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
					<main>
						<Tab.Group>
							<div className="flex flex-col lg:flex-row justify-between ">
								<Tab.List className="flex space-x-0 sm:space-x-2 overflow-x-auto ">
									<Tab as={Fragment}>
										{({selected}) => (
										<button
											className={`flex-shrink-0 block font-medium px-4 py-2 text-sm sm:px-6 sm:py-2.5 capitalize rounded-full focus:outline-none ${
											selected
												? "bg-neutral-900 dark:bg-neutral-100 text-neutral-50 dark:text-neutral-900"
												: "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100/70 dark:hover:bg-neutral-800"
											} `}
										>
											Collection
										</button>
										)}
									</Tab>
									<Tab as={Fragment}>
										{({ selected }) => (
										<button
											className={`flex-shrink-0 block font-medium px-4 py-2 text-sm sm:px-6 sm:py-2.5 capitalize rounded-full focus:outline-none ${
											selected
												? "bg-neutral-900 dark:bg-neutral-100 text-neutral-50 dark:text-neutral-900"
												: "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100/70 dark:hover:bg-neutral-800"
											} `}
										>
											Product
										</button>
										)}
									</Tab>
									<Tab as={Fragment}>
										{({ selected }) => (
										<button
											className={`flex-shrink-0 block font-medium px-4 py-2 text-sm sm:px-6 sm:py-2.5 capitalize rounded-full focus:outline-none ${
											selected
												? "bg-neutral-900 dark:bg-neutral-100 text-neutral-50 dark:text-neutral-900"
												: "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100/70 dark:hover:bg-neutral-800"
											} `}
										>
											NFT
										</button>
										)}
									</Tab>
								</Tab.List>
								<div className="mt-5 lg:mt-0 flex items-end justify-end">
									{/* <ArchiveFilterListBox options={lists} /> */}
									<span className="flex flex-shrink-0 text-right items-center">
										<ButtonSecondary
											className="w-auto !pr-16 ml-2 border-primary-500 focus:ring-0 focus:ring-primary-200"
											sizeClass="pl-4 py-2.5 sm:pl-6"
											onClick={() => {
												setIsOpen(!isOpen);
											}}
										>
											<svg
												className={`w-4 h-4 sm:w-6 sm:h-6`}
												viewBox="0 0 24 24"
												fill="none"
											>
												<path
													d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
													stroke="currentColor"
													strokeWidth="1.5"
													strokeMiterlimit="10"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
													stroke="currentColor"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M19.87 17.12L18.87 16.12"
													stroke="currentColor"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>

											<span className="block truncate ml-2.5">Filter</span>
											<span className="absolute top-1/2 -translate-y-1/2 right-5">
												<ChevronDownIcon
													className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpen ? "rotate-180" : ""}`} aria-hidden="true"
												/>
											</span>
										</ButtonSecondary>
									</span>
								</div>
							</div>
							<div>
								<Transition
									show={isOpen}
									enter="transition-opacity duration-150"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="transition-opacity duration-150"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
									<TabFilters name="Customer List Filters"
										radioBtnFiltrations={[
											{
												key: 'sortBy',
												options: sortBy
											}
										]} onApplyFilter={setFilter} />
								</Transition>
							</div>
							<Tab.Panels>
								<Tab.Panel className="">
									{/* LOOP ITEMS */}
									<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
										{collectionList?.map((collection: any, i: any) => (
											<div className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md`}>
												<div className="relative flex-shrink-0 ">
													<div>
														<NcImage
															containerClassName="flex aspect-w-11 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0 bg-gray-500 rounded-b-none"
															src={collection.logoUrl}
															className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
														/>
													</div>
												</div>
								
												<div className="p-4 py-5 space-y-3">
													<div className="flex justify-between">
														<h3 className={`text-sm font-medium category-name`}>{collection.collectionType}</h3>
														<h3 className={`text-sm font-medium`}>{collection.chainLabel}</h3>
													</div>
													<h2 className={`text-lg font-medium mb-3`}>{collection.collectionName}</h2>
												</div>
											</div>
										))}
									</div>
									{collectionList?.length == 0 && (
										<h2 className="font-medium text-2xl text-center w-full">No Result Found!</h2>
									)}

									{/* PAGINATION */}
									{currentCollectionPage < totalCollectionPage && collectionList?.length != 0 && (
										<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
											<Pagination currentPage={currentCollectionPage} totalPage={totalCollectionPage} setCurrentPage={setCurrentCollectionPage} />
										</div>
									)}
								</Tab.Panel>
								<Tab.Panel className="">
									{/* LOOP ITEMS */}
									<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
										{productList?.map((product: any, i: any) => (
											<div key={i} className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md`}>
												<div className="relative flex-shrink-0 ">
													<div>
														<NcImage
															containerClassName="flex aspect-w-11 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0 rounded-b-none"
															src={product.productThumbUrl}
															className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
														/>
													</div>
													{product.isFeatured === 1 && (<span className={`featured-badge nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium relative text-green-800 bg-green-100`}>Featured</span>)}
												</div>

												<div className="p-4 py-5 space-y-3">
													<div className="flex justify-between">
														<h3 className={`text-sm font-medium category-name`}>{product.collectionName}</h3>
														<h3 className={`text-sm font-medium`}>{product.categoryName}</h3>
													</div>
													<h2 className={`text-lg font-medium mb-3`}>{product.productName}</h2>
												</div>
											</div>
										))}
									</div>
									{productList?.length == 0 && (
										<h2 className="font-medium text-2xl text-center w-full">No Result Found!</h2>
									)}

									{/* PAGINATION */}
									{currentProductPage < totalProductPage && productList?.length && (
										<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
											<Pagination currentPage={currentProductPage} totalPage={totalProductPage} setCurrentPage={setCurrentProductPage} />
										</div>
									)}
								</Tab.Panel>
								<Tab.Panel className="">
									{/* LOOP ITEMS */}
									<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
										{nftList?.map((nft: any, i: any) => (
											<div key={i} className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md`}>
												<div className="relative flex-shrink-0 ">
													<div>
														<NcImage
															containerClassName="flex aspect-w-11 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0 rounded-b-none"
															src={nft.nftThumbUrl}
															className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
														/>
													</div>
													{nft.isFeatured === 1 && (<span className={`featured-badge nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium relative text-green-800 bg-green-100`}>Featured</span>)}
												</div>

												<div className="p-4 py-5 space-y-3">
													<div className="flex justify-between">
														<h3 className={`text-sm font-medium category-name`}>{nft.collectionName}</h3>
														<h3 className={`text-sm font-medium`}>{nft.categoryName}</h3>
													</div>
													<h2 className={`text-lg font-medium mb-3`}>{nft.name}</h2>
												</div>
											</div>
										))}
									</div>
									{nftList?.length == 0 && (
										<h2 className="font-medium text-2xl text-center w-full">No Result Found!</h2>
									)}

									{/* PAGINATION */}
									{currentNftPage < totalNftPage && nftList?.length && (
										<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
											<Pagination currentPage={currentNftPage} totalPage={totalNftPage} setCurrentPage={setCurrentNftPage} />
										</div>
									)}
								</Tab.Panel>
							</Tab.Panels>
						</Tab.Group>
					</main>
				</div>
            </div>
        </React.Fragment>
    )
}

export default ViewProfile