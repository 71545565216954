import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from 'react-select'
import { yupResolver } from '@hookform/resolvers/yup';
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import FormItem from "components/FormItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import ItemTypeDeleteIcon from "components/ItemTypeDeleteIcon";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { usePreSaleApi } from "_common/hooks/api/preSaleApi";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import toast from 'react-hot-toast';
import { PreSaleAddFormSchemaValidation } from '../../utils/form-validation'
import { AddressQuantityFormValues } from '../../_common/interfaces/FormProps'

const AddToPreSales = () => {

    const navigate = useNavigate()
    const { addPreSale } = usePreSaleApi();
    const { getCollections } = useCollectionApi();
    const { showLoader, hideLoader } = useAppLoader();
    const [collectionList, setCollectionList] = useState([]);

    const { register, control, handleSubmit, reset, formState: { errors }, watch } = useForm<AddressQuantityFormValues>({
        defaultValues: {
            collection: null,
            preSaleRegistartions: [{
                cryptoAddress: '',
                approvedQty: 0
            }],
        },
        resolver: yupResolver(PreSaleAddFormSchemaValidation)
    });

    const CollectionChange = watch('collection')

    const { fields: preSaleRegistrationFields, append: preSaleRegistrationAppend, remove: preSaleRegistrationRemove } = useFieldArray({ control, name: "preSaleRegistartions" })

    const onSubmit = (data: any) => { 
        console.log('data: ', data)
        const patams = {
            preSaleRegistartions: data['preSaleRegistartions'],
            collectionID: +data['collection']['value']
        }

        showLoader();
        addPreSale(patams,
        (message: string, resp: any) => {
            hideLoader()
            toast.success(message)
            navigate(-1)
        },
        (message: string, resp: any) => {
            hideLoader()
            toast.error(message)
        })
    }

    const handleCancel = () => {
        navigate(`/pre-sales`)
    }

    const listCollection = () => {
        showLoader()
        getCollections({isEnabledPresale: 1}, (message: string, resp: any) => {
            hideLoader();
            const data = resp.collections && resp.collections.map((e: any) => ({
                label: e['collectionName'],
                value: +e['collectionID']
            })) || []
            setCollectionList(data);
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        })
    }

    const addNewaddressQuantity = () => {
        preSaleRegistrationAppend({ cryptoAddress: "", approvedQty: 0 })
    }

    useEffect(() => {
        listCollection();
    }, [])

    return (
        <React.Fragment>
            <div className="container">
                <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
                    {/* HEADING */}
                    <div className="max-w-2xl">
                        <h2 className="text-3xl sm:text-4xl font-semibold">
                            {/* { payoutGroupData && payoutGroupData['payoutGroupID'] ? 'Update Payout Group' : 'Create Payout Group' } */}
                            Add To Pre-sales List
                        </h2>
                        {/* <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                You can set preferred display name, create your profile URL and
                manage other personal settings.
                </span> */}
                    </div>
                    <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5 mb-4">
                            <FormItem label="Collection" isRequired={true}>
                                <Controller
                                    name={"collection"}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange, onBlur } }) => (
                                        <Select
                                            className={`mt-1 rounded-2xl`}
                                            options={collectionList}
                                            placeholder={"Select collections"}
                                            onChange={onChange}
                                            value={value}
                                            defaultValue={value}
                                        />
                                    )}
                                />
                            </FormItem>
                        </div>
                        
                        {CollectionChange?.value ?
                            <div className="mt-4 pt-4">
                                <div className="flex items-center justify-between mb-4">
                                    <h4 className="text-lg font-bold">Add Addresses</h4>
                                    <div className="sm:text-sm flex items-center text-blue-800 cursor-pointer" onClick={addNewaddressQuantity}><AiOutlinePlus className="mr-2" /> Add New </div>
                                </div>

                                {preSaleRegistrationFields.map((field: any, index: number) => (
                                    <div className="flex items-end mb-4" key={field.id}>
                                        <FormItem label="Wallet address" isRequired={true} className="mr-4 w-2/3">
                                            <Controller
                                                name={`preSaleRegistartions.${index}.cryptoAddress`}
                                                control={control}
                                                render={({ field }) => <Input
                                                    {...field}
                                                    placeholder={'Enter wallet address'}
                                                    autoComplete={'off'}
                                                />}
                                            />
                                            {
                                                errors && errors.preSaleRegistartions && errors.preSaleRegistartions[index] && errors.preSaleRegistartions[index]?.cryptoAddress && errors.preSaleRegistartions[index]?.cryptoAddress?.message ?
                                                    <p className='text-red-400'>{errors.preSaleRegistartions[index]?.cryptoAddress?.message}</p> : ''
                                            }
                                        </FormItem>
                                        <FormItem label="Quantity" isRequired={true} className="mr-4">                        
                                            <Controller
                                            name={`preSaleRegistartions.${index}.approvedQty`}
                                            control={control}
                                            render={({ field }) => <Input
                                                {...field}
                                                placeholder={'Enter quantity'}
                                                autoComplete={'off'}
                                                required={true}
                                            />}
                                        />
                                        {
                                            errors && errors.preSaleRegistartions && errors.preSaleRegistartions[index] && errors.preSaleRegistartions[index]?.approvedQty && errors.preSaleRegistartions[index]?.approvedQty?.message ?
                                                <p className='text-red-400'>{errors.preSaleRegistartions[index]?.approvedQty?.message}</p> : ''
                                        }
                                        </FormItem>
                                        
                                        <div className="relative flex-shrink-0 flex mb-1">
                                            {preSaleRegistrationFields.length > 1 ? <span onClick={() => {
                                                preSaleRegistrationRemove(index)
                                            }} className="ml-2 delete-btn relative pt-7"><ItemTypeDeleteIcon /></span>
                                                : null
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                            : null
                        }

                        <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 mt-10">
                            <ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
                            <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Cancel</ButtonSecondary>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddToPreSales