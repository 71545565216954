import React, { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from 'react-router';
import { useForm, Controller } from "react-hook-form";
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select'
import { AiOutlinePlus, AiOutlineClose } from 'react-icons/ai';

import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

import { Collection_PayoutGroup_Form } from '../../_common/interfaces/FormProps'
import { CollectionPayoutGroupFormSchemaValidation } from '../../utils/form-validation'
import { useAppLoader } from "_common/hooks/common/appLoaderHook";

import { useCollectionApi } from '../../_common/hooks/api/collectionApiHook'
import { usePayoutGroupApi } from '../../_common/hooks/api/payoutGroupApiHook'

import DetailsPayoutGroup from '../PayoutGroup/addUpdate'
import NcCustomModal from "components/Modal/NcCustomModal";

export interface CollectionPayoutGroupProps {
    className?: string;
}

const PayoutGroup: FC<CollectionPayoutGroupProps> = ({ className = "" }) => {

    const { id: collectionId } = useParams();
    const navigate = useNavigate()
    const { getCollections, assignPayoutGroup } = useCollectionApi()
    const { getPayoutGroups } = usePayoutGroupApi()
    const { showLoader, hideLoader } = useAppLoader();

    const [collectionDetails, setCollectionDetails] = useState(null)
    const [payoutGroupList, setPayoutGroupList] = useState([])
    const [showPayoutGroupModal, setShowPayoutGroupModal] = useState(false)

    const { register, control, getValues, setValue, watch, setError, clearErrors, handleSubmit, reset, formState: { errors } } = useForm<Collection_PayoutGroup_Form>({
        defaultValues: {
            payoutGroup: null
        },
        resolver: yupResolver(CollectionPayoutGroupFormSchemaValidation)
    });

    useEffect(() => {
        fetchCollection()
        fetchPayoutGroup()
    }, [])

    useEffect(() => {
        if (collectionDetails && payoutGroupList.length) {

            // const payoutGroups = collectionDetails && collectionDetails['payoutGroup'] || [];
            // const payoutGroup = payoutGroups[0] || null;
            const payoutGroup = collectionDetails && collectionDetails['payoutGroup'] || null

            const selectedPayoutGroup = payoutGroupList.filter((e: any) => payoutGroup && e['value'] == payoutGroup['payoutGroupID'])

            reset({
                payoutGroup: selectedPayoutGroup.length && selectedPayoutGroup[0] || null
            })
        } else {

        }
    }, [collectionDetails, payoutGroupList]);

    const fetchCollection = () => {
        if (collectionId) {
            getCollections({ collectionID: collectionId },
                (message: string, resp: any) => {
                    const data = resp && resp.collections && resp.collections.length && resp.collections[0] || null;
                    if (data) {
                        setCollectionDetails(data)
                    } else {
                        toast.error('Collection not found!')
                        navigate('/collection')
                    }
                },
                (message: string, resp: any) => {
                    console.log('error resp: ', resp)
                    toast.error(message)
                })
        } else {
            toast.error('Collection ID not found!')
            navigate('/collection')
        }
    }
    const fetchPayoutGroup = () => {
        if (collectionId) {
            getPayoutGroups({ collectionID: collectionId },
                (message: string, resp: any) => {
                    const data = resp.payoutGrpdm && resp.payoutGrpdm.map((e: any) => ({
                        label: e['payoutGroupName'],
                        value: e['payoutGroupID']
                    })) || []
                    setPayoutGroupList(data || [])
                },
                (message: string, resp: any) => {
                    console.log('error resp: ', resp)
                    toast.error(message)
                })
        } else {
            toast.error('Collection ID not found!')
            navigate('/collection')
        }
    }

    const payoutGroupAssigned = (data: any) => {
        if (collectionId) {
            showLoader()
            const params = {
                collectionID: collectionId,
                payoutGroupID: data && data.payoutGroup && +data.payoutGroup.value || null,
            }

            assignPayoutGroup(params,
                (message: string, resp: any) => {
                    hideLoader()
                    toast.success(message)
                },
                (message: string, resp: any) => {
                    console.log('error resp: ', resp)
                    hideLoader()
                    toast.error(message)
                })
        } else {
            toast.error('Collection ID not found!')
        }
    }

    const openPayoutGroupModal = () => {
        setShowPayoutGroupModal(true)
    }
    const closePayoutGroupModal = () => {
        setShowPayoutGroupModal(false)
        fetchCollection()
        fetchPayoutGroup()
    }

    const handleCancel = () => {
        navigate('/collection')
    }

    return (
        <>
            <form className="" onSubmit={handleSubmit(payoutGroupAssigned)}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5">
                    <label className="block">
                        <Label>Payout Group <span className="text-red-600">*</span></Label>
                        <Controller
                            name={"payoutGroup"}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Select
                                    className={`mt-1`}
                                    classNamePrefix="select"
                                    options={payoutGroupList ? payoutGroupList : []}
                                    placeholder={"Select .."}
                                    onChange={onChange}
                                    value={value}
                                    defaultValue={value}
                                />
                            )}
                        />
                        {
                            errors && errors.payoutGroup && errors.payoutGroup.message &&
                            (<p className='text-red-400'>
                                {errors.payoutGroup.message}
                            </p>)
                        }
                    </label>
                    <div className="row mt-6">
                        <ButtonPrimary type="button" onClick={openPayoutGroupModal}> <AiOutlinePlus /> &nbsp; Add New</ButtonPrimary>
                    </div>
                </div>

                <div className="w-full border-b-2 mb-5 mt-5 border-neutral-100 dark:border-neutral-700 mt-10 mb-10"></div>
                {/* <div className="text-center">
                    <ButtonPrimary type="submit">Save</ButtonPrimary>
                </div> */}
                <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3">
                    <ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
                    <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Cancel</ButtonSecondary>
                </div>
            </form>

            <NcCustomModal contentExtraClass="max-w-3xl" modalTitle="Add Payout Group" modalStatus={showPayoutGroupModal} closeModal={closePayoutGroupModal}>
            <DetailsPayoutGroup
                showPageTitle={false}
                collectionId={collectionId && +collectionId || null}
                enableLoader={showLoader}
                disableLoader={hideLoader}
                handleCancel={closePayoutGroupModal}
                readOnlyCollection={true}
                payoutGroupData={{}}
                classNames='bg-neutral-100 dark:bg-neutral-800/20 container lg:space-y-28 py-5 px-8 rounded-2xl space-y-20'
            />
            </NcCustomModal>
        </>

    );
};

export default PayoutGroup;
