import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function useEmailTempltApi() {

  const callApi = useApiCall()

  const getEmailTemplaits = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EMAIL_TEMPLATES.GET, data, onSuccess, onError);
  }

  const updateEmailTemplate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EMAIL_TEMPLATES.EDIT, data, onSuccess, onError);
  }

  const changeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EMAIL_TEMPLATES.STATUS_CHANGE, data, onSuccess, onError);
  }

  return {
    getEmailTemplaits: getEmailTemplaits,
    updateEmailTemplate: updateEmailTemplate,
    changeStatus: changeStatus,
  }
}