import React, { FC, Fragment, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Input from "shared/Input/Input";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { FiSearch } from "react-icons/fi";



export interface Props {
  data: any;
  state: any;
  onUpdate: (str: any) => void;
  label?: string;
  placeholder?: string
}

const TextBoxFilterMobile: FC<Props> = ({ data, onUpdate, state = "", label = "Search ..", placeholder = 'Type any' }) => {

  const [searchStates, setSearchStates] = useState<any>(state);

  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  /*useEffect(() => {
    onUpdate({ [data.key]: searchStates })
  }, [searchStates]);*/

  useEffect(() => {
    setSearchStates(state)
  }, [state])

  return (
    <div className="py-7">
      <h3 className="text-xl font-medium">{label}</h3>
      <div className="mt-6 relative ">
        <div className="relative flex flex-col space-y-5 cstm-filter-text-mb">
          <Input placeholder={placeholder} autoComplete={'off'} onChange={(e) => setSearchStates(e.target.value)} value={searchStates} />
          <span className="absolute cursor-pointer right-2 top-0 z-10" onClick={() => onUpdate({ [data.key]: searchStates })}><FiSearch /></span>
        </div>
      </div>
    </div>
  )
}

export default TextBoxFilterMobile;