import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';

import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";

import NcImage from "shared/NcImage/NcImage";
import productdummyimage from 'images/nfts/4.jpg';
import { FaRegUser } from "react-icons/fa";

import graph1 from 'images/graph-1.png';
import graph2 from 'images/graph-2.png';
import graph3 from 'images/graph-3.png';
import graph4 from 'images/graph-4.png';

import ethem from 'images/priceIcons/ethem-icon.png';
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import { AiFillEye } from "react-icons/ai";
import toast from "react-hot-toast";
import moment from "moment";

interface Props {
  product: any,
  storeBaseUrl: string
}

function ProductDashboard({ product, storeBaseUrl }: Props) {

  const navigateDetails = (data: any) => {
    if (storeBaseUrl) {
      const url = storeBaseUrl + `/product/${product.productID}`
      window.open(url, '_blank')
    }
  }

  function getTimeInDaysAgoFormat(date: any) {
    const momentDate = moment(date);
    const now = moment();
    const hoursAgo = now.diff(momentDate, 'hours');
    
    if (hoursAgo < 24) {
      return hoursAgo + " hours ago";
    } else {
      const daysAgo = now.diff(momentDate, 'days');
      if (daysAgo >= 1 && daysAgo <= 7) {
        return daysAgo + " days ago";
      } else {
        return moment.utc(date).local().format("Do MMM, YYYY, hh:mm A");
      }
    }
  }

  return (
    <div className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md`}>
      <div className="relative flex-shrink-0 ">
        <div className="relative">
          <NcImage
            containerClassName="flex aspect-w-8 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0"
            src={product['productThumbUrl']}
            className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
          />
          <span className="absolute bg-neutral-900/40 flex gap-1 items-center pb-1 pl-3 pr-3 pt-1 right-3 rounded-2xl top-3 text-gray-100"><AiFillEye />{product['viewCount']}</span>
        </div>
      </div>
      <div className="p-4 py-5 space-y-3">
        <h2 onClick={navigateDetails} className={`cursor-pointer text-lg font-medium mb-3`}>{product['productName']}</h2>
        <h3 className="font-medium mt-1 text-lg text-primary-500 text-sm">{product['collectionName']}</h3>
        {/* <h4 className="font-bold tex-lg">#Art</h4> */}
        {/* 27th Jan, 2023, 10:35 */}
        <p className="text-gray-400">Last seen : {getTimeInDaysAgoFormat(product['lastViewOn'])}</p>
      </div>
    </div>
  );
}

export default ProductDashboard;
