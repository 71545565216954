import { type } from 'os';
import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { User } from '../../../_common/interfaces/models/user';
import { ACTIONS } from '../../../_config'

export interface CommonReducer {
  blockchainNetworks: any[] | null;
  dateRangeTypes: any[]
}

const initialState: CommonReducer = {
  blockchainNetworks: null,
  dateRangeTypes: []
};

const commonReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.COMMON.GET_BLOCKCHAIN_NETWORKS:
      return {
        ...state,
        blockchainNetworks: action.payload.blockchainNetworks
      };
    case ACTIONS.COMMON.GET_DATE_RANGE_TYPES:
      return {
        ...state,
        dateRangeTypes: action.payload.dateRangeTypes
      };
    default:
      return state;
  }
};

export default commonReducer;
