import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router";
import toast from 'react-hot-toast';

import NcImage from "shared/NcImage/NcImage";
import collectionBanner from "images/nfts/collectionBanner.png";
import DetailsPayoutGroup from '../../components/PayoutGroup/addUpdate'
import { usePayoutGroupApi } from '../../_common/hooks/api/payoutGroupApiHook'
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import Heading from "components/Heading/Heading";

export interface Props {
  className?: string;
}

const AddUpdatePayoutGroup: FC<Props> = ({ className = "" }) => {

  const {id: payoutGroupId} = useParams();
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const { getPayoutGroups } = usePayoutGroupApi()

  const [ payoutGroupDetails, setPayoutGroupDetails ] = useState<any>({})

  const handleRedirect = () => {
    navigate('/payout-groups')
  }

  useEffect(() => {
    fetchPayoutGroup()
  }, [])

    const fetchPayoutGroup = () => {
        if ( payoutGroupId && payoutGroupId != 'new' ) {
            getPayoutGroups({payoutGroupID: payoutGroupId}, 
            (message: string, resp: any) => {
                if ( resp.payoutGrpdm && resp.payoutGrpdm.length ) {
                    setPayoutGroupDetails(resp.payoutGrpdm[0])
                } else {
                    toast.error('Payout group not found!')
                    navigate('/payout-groups')
                }
            },
            (message: string, resp: any) => {
                console.log('error resp: ', resp)
                toast.error(message)
                navigate('/payout-groups')
            })
        } else {
            
        }
    }

    const renderHeading2 = () => {
		return (
			<div>
				<Heading
					className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50"
					fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
					isCenter
					desc=""
				>
					Top List Creators.
				</Heading>
			</div>
		);
	};


  return (
    <div className={`nc-PageUploadItem ${className}`} data-nc-id="PageUploadItem">
      <Helmet>
        <title>Payout Group</title>
      </Helmet>
      <div className="container">
        <DetailsPayoutGroup 
          collectionId={payoutGroupDetails && payoutGroupDetails['collectionID'] || null}
          enableLoader={showLoader}
          disableLoader={hideLoader}
          handleCancel={handleRedirect}
          payoutGroupData={payoutGroupDetails}
          readOnlyCollection={false}
          classNames='bg-neutral-100 container lg:pb-28 lg:pt-20 lg:space-y-28 py-16 rounded-2xl space-y-20'
        />
      </div>
    </div>
  );
};

export default AddUpdatePayoutGroup;
