import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import FormItem from "components/FormItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import InputFile from 'shared/InputFile/InputFile';
import Textarea from "shared/Textarea/Textarea";
import CheckBoxNative from "shared/Checkbox/nativeCheckbox";
import { useCmsApi } from "_common/hooks/api/cmsApi";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { Editor } from "@tinymce/tinymce-react";
import toast from "react-hot-toast";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";


const DesignCreate = () => {

    

    return (
        <React.Fragment>
            <div className="container">
                <div className="my-12 sm:lg:my-16 lg:my-24 mx-auto space-y-8 sm:space-y-10">
                    {/* HEADING */}
                    <div className="max-w-2xl">
                        <h2 className="text-3xl sm:text-4xl font-semibold">
                            Cute Monster
                        </h2>
                    </div>
                    <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 relative text-center lg:text-left">
                        <div className="max-w-full xl:max-w-lg space-y-5 lg:space-y-7 border-r sm-border-0 pr-5">

                        </div>
                        <div className="flex-grow">
                            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-8 gap-y-10  mt-8 lg:mt-10 custom-xs-col-2">
                                <div className="nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ]">
                                    <div className="relative flex-shrink-0 ">
                                        <div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default DesignCreate