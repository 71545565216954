import React, { FC, Fragment, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { ChevronDownIcon } from "@heroicons/react/outline";

export interface Props {
  data: any;
  state: any;
  onUpdate: (str: any) => void;
  label?: string;
  placeholder?: string;
  callback: Function
}

const DropDownFilter: FC<Props> = ({ data, onUpdate, state = "", label = "Sort order", callback, placeholder = 'Select any' }) => {

  const { options, isAsync = false } = data;
  const [sortOrderStates, setSortOrderStates] = useState<any>(state);

  const defaultFormat = (str: string) => (
    <div>
      {str}
    </div>
  )

  const formatOptionLabel = (obj: any) => {
    if ( data.formatOptionLabel ) {
      return data.formatOptionLabel(obj)
    } else {
      return defaultFormat(obj.label)
    }
  }

  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const loadOptions = ( inputValue: string, callbackFn: (options: any[]) => void) => {
    setTimeout(async() => {
      const data = await callback(inputValue)
      callbackFn(data);
    }, 1000);
  };

  useEffect(() => {
    onUpdate({ [data.key]: sortOrderStates })
  }, [sortOrderStates]);

  useEffect(() => {
    setSortOrderStates(state)
  }, [state])

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex items-center justify-center px-4 py-2 text-sm border rounded-full focus:outline-none 
              ${open ? "!border-primary-500 " : ""}
                ${sortOrderStates && Object.keys(sortOrderStates).length
                ? "!border-primary-500 bg-primary-50 text-primary-900"
                : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
              }
                `}
          >
            <svg className="w-4 h-4" viewBox="0 0 20 20" fill="none">
              <path
                d="M11.5166 5.70834L14.0499 8.24168"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.5166 14.2917V5.70834"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.48327 14.2917L5.94995 11.7583"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.48315 5.70834V14.2917"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39763 14.6025 1.66667 10.0001 1.66667C5.39771 1.66667 1.66675 5.39763 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span className="ml-2">
              {
                sortOrderStates && Object.keys(sortOrderStates).length && sortOrderStates?.label || label
              }
            </span>
            {!sortOrderStates.length ? (
              <ChevronDownIcon className="w-4 h-4 ml-3" />
            ) : (
              <span onClick={() => setSortOrderStates("")}>
                {renderXClear()}
              </span>
            )}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-40 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
              <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                <div className="relative flex flex-col px-5 py-6 space-y-5">
                  {
                    isAsync && (
                      <AsyncSelect 
                        className={`mt-1`}
                        cacheOptions 
                        loadOptions={loadOptions} 
                        placeholder={placeholder}
                        formatOptionLabel={formatOptionLabel}
                        onChange={setSortOrderStates}
                        defaultOptions />
                    ) || (
                      <Select
                        className={`mt-1`}
                        options={options}
                        placeholder={placeholder}
                        formatOptionLabel={formatOptionLabel}
                        onChange={setSortOrderStates}
                        defaultValue={sortOrderStates}
                      />
                    )
                  }
                </div>
                <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                  <ButtonThird
                    onClick={() => {
                      close();
                      setSortOrderStates("");
                    }}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    Clear
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={close}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    Close
                  </ButtonPrimary>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default DropDownFilter;