import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

const otherPageChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home Demo 1",
  },
  {
    id: ncNanoId(),
    href: "/home2",
    name: "Home Demo 2",
  },
  {
    id: ncNanoId(),
    href: "/home3",
    name: "Home Demo 3",
  },
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Collection page",
  },
  {
    id: ncNanoId(),
    href: "/page-search",
    name: "Search page",
  },
  {
    id: ncNanoId(),
    href: "/page-author",
    name: "Author Profile",
  },
  {
    id: ncNanoId(),
    href: "/nft-detailt",
    name: "NFT detailt",
  },
  {
    id: ncNanoId(),
    href: "/account",
    name: "Account settings",
  },
  {
    id: ncNanoId(),
    href: "/page-upload-item",
    name: "Upload Item",
  },
  {
    id: ncNanoId(),
    href: "/connect-wallet",
    name: "Connect Wallet",
  },

  {
    id: ncNanoId(),
    href: "/about",
    name: "Other Pages",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/about",
        name: "About",
      },
      {
        id: ncNanoId(),
        href: "/contact",
        name: "Contact us",
      },
      {
        id: ncNanoId(),
        href: "/login",
        name: "Login",
      },
      {
        id: ncNanoId(),
        href: "/signup",
        name: "Signup",
      },
      {
        id: ncNanoId(),
        href: "/subscription",
        name: "Subscription",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog Page",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/blog",
        name: "Blog Page",
      },
      {
        id: ncNanoId(),
        href: "/blog-single",
        name: "Blog Single",
      },
    ],
  },
];

const AccountSettingsMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/store-configuration",
    name: "Store Configuration",
  },/* {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Profile Information",
  },*/ {
    id: ncNanoId(),
    href: "/employees",
    name: "Employees",
  }, {
    id: 'mktcst-1',
    href: "/customerlist-mkt",
    name: "Customer List",
  }, {
    id: 'mktcst-2',
    href: "/customer-collections",
    name: "Collections By Customer",
  }, {
    id: 'mktcst-3',
    href: "/customer-products",
    name: "Products By Customer",
  }, {
    id: ncNanoId(),
    href: "/orders",
    name: "Orders",
  }, /*{
    id: ncNanoId(),
    href: "/page-collection",
    name: "Customer List (Store)",
  },*/ {
    id: 'storecst-1',
    href: "/customer-list",
    name: "Customer List",
  }, {
    id: ncNanoId(),
    href: "/activities",
    name: "Activities",
  }, {
    id: ncNanoId(),
    href: "/reported-nfts",
    name: "Reported NFTs",
  }, {
    id: ncNanoId(),
    href: "/contactus-list",
    name: "Contact-Us List",
  }
];

const ProductSettingsMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/categories",
    name: "Categories",
  }, {
    id: ncNanoId(),
    href: "/collection",
    name: "Collections",
  }, {
    id: ncNanoId(),
    href: "/payout-groups",
    name: "Payout Group",
  }, {
    id: ncNanoId(),
    href: "/products",
    name: "Products",
  },/* {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Generator",
  },*/ {
    id: ncNanoId(),
    href: "/pre-sales",
    name: "Pre-Sales",
  }, {
    id: ncNanoId(),
    href: "/airdrops",
    name: "Airdrops",
  }, {
    id: ncNanoId(),
    href: "/customfields",
    name: "Custom Fields",
  },
  {
    id: ncNanoId(),
    href: "/coupons",
    name: "Coupons",
  }
];

const ConfigurationMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/theme",
    name: "Theme",
  }, {
    id: ncNanoId(),
    href: "/site-settings",
    name: "Site Settings",
  }, {
    id: ncNanoId(),
    href: "/cms",
    name: "CMS",
  }, {
    id: ncNanoId(),
    href: "/email-template",
    name: "Email Templates",
  }, {
    id: ncNanoId(),
    href: "/galleries",
    name: "Gallery",
  },{
    id: ncNanoId(),
    href: "/baskets",
    name: "Baskets",
  },/* {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Payment Providers",
  }, {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Contact Us List",
  }, {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Email Request List",
  },*/ {
    id: ncNanoId(),
    href: "/connect-wallet",
    name: "Crypto Wallet",
  }/*,

  
  {
    id: ncNanoId(),
    href: "/",
    name: "Home Demo 1",
  },
  {
    id: ncNanoId(),
    href: "/home2",
    name: "Home Demo 2",
  },
  {
    id: ncNanoId(),
    href: "/home3",
    name: "Home Demo 3",
  },
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Collection page",
  },
  {
    id: ncNanoId(),
    href: "/page-search",
    name: "Search page",
  },
  {
    id: ncNanoId(),
    href: "/page-author",
    name: "Author Profile",
  },
  {
    id: ncNanoId(),
    href: "/nft-detailt",
    name: "NFT detailt",
  },
  {
    id: ncNanoId(),
    href: "/account",
    name: "Account settings",
  },
  {
    id: ncNanoId(),
    href: "/page-upload-item",
    name: "Upload Item",
  },
  {
    id: ncNanoId(),
    href: "/connect-wallet",
    name: "Connect Wallet",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "Other Pages",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/about",
        name: "About",
      },
      {
        id: ncNanoId(),
        href: "/contact",
        name: "Contact us",
      },
      {
        id: ncNanoId(),
        href: "/login",
        name: "Login",
      },
      {
        id: ncNanoId(),
        href: "/signup",
        name: "Signup",
      },
      {
        id: ncNanoId(),
        href: "/subscription",
        name: "Subscription",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog Page",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/blog",
        name: "Blog Page",
      },
      {
        id: ncNanoId(),
        href: "/blog-single",
        name: "Blog Single",
      },
    ],
  }
  */
];

export const NAVIGATION_DEMO_2: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Account Settings",
    type: "dropdown",
    children: AccountSettingsMenus,
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Product Settings",
    type: "dropdown",
    children: ProductSettingsMenus,
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Configuration",
    type: "dropdown",
    children: ConfigurationMenus,
  }/*,  
  {
    id: ncNanoId(),
    href: "/page-upload-item",
    name: "Go To Store",
  },*/
];
