import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { AiOutlineSetting } from "react-icons/ai";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useUserSelector } from "_common/hooks/selectors/userSelector";
import UpdateProfile from "components/AccountsProfile/UpdateProfile";
import UpdatePassword from "components/AccountsProfile/UpdatePassword";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {

  const [dropSection, setDropSection] = useState<boolean>(true)
  const [dropSectionTwo, setDropSectionTwo] = useState<boolean>(false)
  const [userDetaits, setUserDetails] = useState<any>(null)

  const user: any = useUserSelector()
  const userApi = useUserApi()
  const { showLoader, hideLoader } = useAppLoader()

  const getUserDetails = () => {
    showLoader()
    userApi.getProfileDetails({ "requestData.userID": user?.userID },
      (message: string, resp: any) => {
        console.log(message, resp)
        setUserDetails(resp?.users[0])
        hideLoader()
      }, (message: string) => {
        console.log(message)
        hideLoader()
      })
  }

  useEffect(() => {
    // if (user) {
      getUserDetails()
    // }
  }, [user?.userID])

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Edit Profile</title>
      </Helmet>
      <div className="container">
        <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              Profile settings
            </h2>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              You can set preferred display name, create your profile URL and
              manage other personal settings.
            </span>
          </div>
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>

          <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
            <div className={"accordion-item-wrapper mb-4 mt-3"}>
              <div className={"accordion-title flex items-center justify-between"}>
                <div className="text-lg font-bold flex gap-2"><AiOutlineSetting className="text-2xl mt-1" /> <span className="flex flex-col"><p>Update Profile</p></span></div>
                <ButtonSecondary
                  className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                  sizeClass={"py-2.5"}
                  type="button"
                  onClick={() => { setDropSection(!dropSection) }}
                >
                  <span>
                    <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${dropSection ? "rotate-180" : ""}`} aria-hidden="true" />
                  </span>
                </ButtonSecondary>
              </div>
              <Transition
                show={dropSection}
                enter="transition-opacity duration-150"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className={"accordion-body"}>
                  <UpdateProfile user={user} getUserDetails={getUserDetails} userDetaits={userDetaits} />
                </div>
              </Transition>
            </div>
          </div>

          <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
            <div className={"accordion-item-wrapper mb-4 mt-3"}>
              <div className={"accordion-title flex items-center justify-between"}>
                <div className="text-lg font-bold flex gap-2"><AiOutlineSetting className="text-2xl mt-1" /> <span className="flex flex-col"><p>Change Password</p></span></div>
                <ButtonSecondary
                  className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                  sizeClass={"py-2.5"}
                  type="button"
                  onClick={() => { setDropSectionTwo(!dropSectionTwo) }}
                >
                  <span>
                    <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${dropSectionTwo ? "rotate-180" : ""}`} aria-hidden="true" />
                  </span>
                </ButtonSecondary>
              </div>
              <Transition
                show={dropSectionTwo}
                enter="transition-opacity duration-150"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className={"accordion-body"}>
                  <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-5 sm:space-y-6 md:sm:space-y-7">
                    <UpdatePassword />
                  </div>
                </div>
              </Transition>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default AccountPage;
