import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import NcImage from "shared/NcImage/NcImage";
import CardNFT from "components/CardNFT";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import collectionBanner from "images/nfts/collectionBanner.png";
import { nftsImgs } from "contains/fakeData";
import NftMoreDropdown from "components/NftMoreDropdown";
import ButtonDropDownShare from "components/ButtonDropDownShare";
import TabFilters from "components/TabFilters";
import SectionSliderCollections from "components/SectionSliderCollections";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useNavigate, useParams } from "react-router";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Controller, useForm } from "react-hook-form";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { toast } from "react-hot-toast";
import { URLS } from "_config";
import Checkbox from "shared/Checkbox/Checkbox";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import FormItem from "components/FormItem";
import Select from 'react-select';
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface Props {
    className?: string;
}

const PageAddcustomfield: FC<Props> = ({ className = "" }) => {

    const productApi = useProductApi();
    const { register, handleSubmit, setValue, formState: { errors }, control, reset } = useForm();
    const { showLoader, hideLoader } = useAppLoader();
    const userApi = useUserApi();
    const navigate = useNavigate();    
    const [fieldtypes, setFieldtypes] = useState([]);
    const {id} = useParams();

    const onSaveCustomFields = (data: any) => {
        console.log("Form data", data);
        const inputparams = {
            "customFields": [
                {
                    "customFieldID": 0,
                    "customFieldName": data.customFieldName,
                    "customFieldTypeID": data.customFieldTypeID?.value
                }
            ],
            "itemType": data.itemType?.value
        };
        showLoader();
        productApi.saveCustomfield(inputparams, (message: any, resp: any) => {
          hideLoader();
          if (resp.successful === true && resp.code === 200) {
            toast.success("Customfield added successfully.");
            navigate('/customfields');
          } else {
            toast.error(resp.message);
          }
        }, (message: string) => {
          hideLoader();
          toast.error(message); 
        });  
    }

    const getCustomFieldType = () => {
        showLoader();
        productApi.getCustomFieldType({}, (message: any, resp: any) => {
            hideLoader();
            if (resp && resp.successful === true &&
                resp.code === 200 &&
                resp.hasOwnProperty('customFieldTypedm') &&
                Array.isArray(resp.customFieldTypedm) &&
                resp.customFieldTypedm.length > 0
            ) {
                resp.customFieldTypedm = resp.customFieldTypedm.map((o:any) => {
                    return {
                        value: o.customFieldTypeID,
                        label: o.customFieldTypeName
                    }
                })
                setFieldtypes(resp.customFieldTypedm);
            }
        }, (message: string) => {
            hideLoader();
        });
    }

    const getCustomFieldByID = () => {
        let params: any = {
            usePaging: false,
            customFieldID: id
        }
        productApi.getCustomfields(params, (message: string, resp: any) => {            
            console.log(resp);
            if (resp.code === 200 && 
                resp.successful === true && 
                resp.hasOwnProperty('customFielddm') && 
                Array.isArray(resp.customFielddm) && 
                resp.customFielddm.length > 0
            ) {
                const data = resp.customFielddm[0];
                reset({
                    customFieldName: data.customFieldName,
                    customFieldTypeID: data.customFieldTypeID,
                    itemType: data.itemType
                })
            }
        }, (message: string) => {
            toast.error(message);
        })
    };

    useEffect(() => {
        getCustomFieldType();
        if (id) {
            getCustomFieldByID()
        }
        console.log("Selected Custom field", id);
    }, [])


    return (
        <div className={`nc-PageUploadItem ${className}`} data-nc-id="PageUploadItem">
            <Helmet>
                <title>Add Custom Field</title>
            </Helmet>
            <div className="container">
                <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
                    
                    <div className="max-w-2xl">
                        <h2 className="text-3xl sm:text-4xl font-semibold">
                        Create New Custom Field
                        </h2>
                        <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                        You can set preferred display name, create your profile URL and
                        manage other personal settings.
                        </span>
                    </div>
                    <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
                    <form method="POST" onSubmit={handleSubmit(onSaveCustomFields)}>
                        <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
                            <FormItem label="Field Name">
                                <Input
                                    placeholder="Enter name of the custom field."
                                    type="text"
                                    className="mt-1"
                                    {...register("customFieldName", { required: true, maxLength: 80 })}
                                />
                                {errors?.customFieldName ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                                {errors.customFieldName.type === "required" ? 'Custom field name shouldn\'t be blank' : null}
                                </div> : null}
                            </FormItem>
                            <FormItem label="Field Type">
                                <Controller
                                    name={"customFieldTypeID"}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange, onBlur } }) => (
                                        <Select
                                            className={`mt-1 rounded-2xl`}
                                            options={fieldtypes}
                                            placeholder={"Select Field type"}
                                            onChange={onChange}
                                            value={value}
                                            defaultValue={value}
                                        />
                                    )}
                                />
                                {errors?.customFieldTypeID ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                                {errors.customFieldTypeID.type === "required" ? 'Custom field type shouldn\'t be blank' : null}
                                </div> : null}
                            </FormItem>
                            <FormItem label="Field use for">
                                <Controller
                                    name={"itemType"}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange, onBlur } }) => (
                                        <Select
                                            className={`mt-1 rounded-2xl`}
                                            options={[
                                                {value: 1, label: 'Collection'}, 
                                                {value: 2, label: 'Product'},
                                                {value: 3, label: 'Category'}
                                            ]}
                                            placeholder={"Select Field used for"}
                                            onChange={onChange}
                                            value={value}
                                            defaultValue={value}
                                        />
                                    )}
                                />
                                {errors?.itemType ? <div className="invalid-feedback text-red-600 text-sm">
                                {errors.itemType.type === "required" ? 'Please select where you want to use it.' : null}
                                </div> : null}
                            </FormItem> 
                            <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
                                <ButtonPrimary className="flex-1">Submit</ButtonPrimary>
                                <ButtonSecondary type={"button"} className="flex-1" onClick={() => {
                                    navigate('/customfields');
                                }}>Cancel</ButtonSecondary>
                            </div>                       
                        </div>
                    </form>                    
                </div>
            </div>
            
            {/*
            <div className="container py-16 lg:pb-28 lg:pt-20 space-y-20 lg:space-y-28">
                <main>
                    <div className="grid grid-cols-2 gap-4">
                        <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSaveCustomFields)}>
                            <label className="block">
                                <Label>Field Name</Label>
                                <Input
                                    placeholder="Example Doe"
                                    type="text"
                                    className="mt-1"
                                    {...register("customFieldName", { required: true, maxLength: 80 })}
                                />
                                {errors?.customFieldName ? <div className="invalid-feedback text-red-600">
                                    {errors.customFieldName.type === "required" ? 'Custom field name shouldn\'t be blank' : null}
                                </div> : null}
                            </label>
                            <div className="grid grid-cols-2 gap-4">
                                <label className="block">
                                    <Label>Field Type</Label>
                                    <select
                                        className="block mt-3 w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                                        {...register("customFieldTypeID", { required: true })}
                                    >
                                        <option value={''}>Select field type</option>
                                        {fieldtypes.map((item: any, index: any) => {
                                            return (
                                                <option key={'cstmfld' + index} value={item.customFieldTypeID}>{item.customFieldTypeName}</option>
                                            )
                                        })}
                                    </select>
                                    {errors?.customFieldTypeID ? <div className="invalid-feedback text-red-600">
                                    {errors.customFieldTypeID.type === "required" ? 'Custom field type shouldn\'t be blank' : null}
                                    </div> : null}
                                </label>
                                <label className="block">
                                    <Label>Field use for</Label>
                                    <select
                                        className="block mt-3 w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                                        {...register("itemType", { required: true })}
                                    >
                                        <option value={''}>Select item</option>
                                        <option value={1}>Collection</option>
                                        <option value={2}>Product</option>
                                        <option value={3}>Category</option>
                                    </select>
                                    {errors?.itemType ? <div className="invalid-feedback text-red-600">
                                    {errors.itemType.type === "required" ? 'Please select where you want to use it.' : null}
                                    </div> : null}
                                </label>
                            </div>
                            <div>
                                <ButtonPrimary type="submit">Save</ButtonPrimary>
                            </div>
                        </form>                       
                    </div>
                </main>
            </div>
            */}
        </div>
    );
};

export default PageAddcustomfield;
