import React, { FC, Fragment, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Radio from "shared/Radio/Radio";
import { ChevronDownIcon } from "@heroicons/react/outline";



export interface Props {
  data: any;
  state: any;
  onUpdate: (str: any) => void;
  label?: string;
  name:string;
}

const SortFilter: FC<Props> = ({data, onUpdate, state="" ,label="Sort order",name }) => {

    const {options} = data;
    const [sortOrderStates, setSortOrderStates] = useState<string>(state);

  

    useEffect(() => {
        if(onUpdate)(onUpdate(JSON.parse(`{"${data.key}": "${sortOrderStates}"}`)));
    },[sortOrderStates]);
    
    useEffect(() => {
        setSortOrderStates(state)
    },[state])
    
    return (
      <div className="py-7">
      <h3 className="text-xl font-medium">{label}</h3>
      <div className="mt-6 relative ">
        <div className="relative flex flex-col space-y-5">
          {options.map((item:any,i:any) => (
            <Radio
              id={item.id}
              key={item.id}
              name={name}
              label={item.name}
              defaultChecked={sortOrderStates === item.id}
              onChange={setSortOrderStates}
            />
          ))}
        </div>
      </div>
    </div>
    )
}

export default SortFilter;