import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { APP_URL, URLS } from "_config";

export interface PageForgotpasswordProps {
    className?: string;
}



const PageForgotpassword: FC<PageForgotpasswordProps> = ({ className = "" }) => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    
    const [stores, setStores] = useState<any>([]);
    const [marketplaces, setMarketplaces] = useState<any>([]);
    const [loginModel, setLoginModel] = useState<any>(null);
    const { showLoader, hideLoader } = useAppLoader();

    const userApi = useUserApi();

    const onForgotPasswordSubmit = (data: any) => {
        const inputparams = Object.assign({}, JSON.parse(JSON.stringify(data)));
        inputparams['recoveryURL'] = APP_URL + URLS.RESET_PASSWORD;        
        console.log("Forgotpassword", inputparams);
        showLoader();
        userApi.forgotpassword(inputparams, (message: any, resp: any) => {
            hideLoader();
            console.log("Api response", resp);            
            if (resp.code === 200 && resp.successful === true) {  
                reset({
                    emailId: ''
                })
                toast.success("Reset password mail sent to your email id " + inputparams.emailId + ". Please check your mail inbox & spam too.", {
                    duration: 10000
                });
            } else {
                toast.error(resp.message);
            }            
        }, (message: string) => {
            hideLoader();
            console.log(message);
        });        
    };   

    useEffect(() => {

    }, [])


    return (
        <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
            <Helmet>
                <title>Forgot Password</title>
            </Helmet>
            <div className="container mb-24 lg:mb-32">
                <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Forgot Password
                </h2>
                <div className="max-w-md mx-auto space-y-6">
                    {/* FORM */}                    
                    <form className="grid grid-cols-1 gap-6" method="post" onSubmit={handleSubmit(onForgotPasswordSubmit)} autoComplete={'off'}>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                                Email address
                            </span>
                            <Input
                                type="email"
                                placeholder="Enter your login email"
                                className={"mt-1" + (errors.emailId ? " is-invalid" : "")}
                                {...register("emailId", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                            />
                            {errors?.emailId ? <div className="invalid-feedback text-red-600">
                                {errors.emailId.type === "required" ? 'Email shouldn\'t be blank.' : null}
                                {errors.emailId.type === "pattern" ? 'Invalid email address.' : null}
                            </div> : null}
                        </label>                        
                        <ButtonPrimary type="submit">Submit</ButtonPrimary>
                    </form>   
                    {/* ==== */}
                    <span className="block text-center text-neutral-700 dark:text-neutral-300">
                        <Link to="/login" className="text-green-600">
                            Back to Login
                        </Link>
                    </span>                 
                </div>
            </div>
        </div>
    );
};

export default PageForgotpassword;
