//import { getSubdomain } from './functions';
export const API_ROOT_URL = process.env.REACT_APP_API_ROOT_URL;
export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const API_NODE_BASE_URL = process.env.REACT_APP_API_NODE_URL;
export const API_NODE_API_KEY = process.env.REACT_APP_NODE_API_KEY;
export const CONTRACT_META_BASE_PATH = process.env.REACT_APP_NODE_URL;

export const CONTRACT_DEPLOY_NEW_TOKEN_URL = process.env.REACT_APP_ContractDeployNewTokenURL;
export const CONTRACT_DEPLOY_NEW_CONTRACT_URL = process.env.REACT_APP_ContractDeployNewContractURL;
export const CONTRACT_DEPLOY_ORDER_API_BASE_URL = process.env.REACT_APP_ContractDeployOrderAPIBaseUrl;
export const CONTRACT_DEPLOY_REDEEM_API_BASE_URL = process.env.REACT_APP_ContractDeployRedeemAPIBaseUrl;
export const CONTRACT_DEPLOY_PRESALE_API_BASE_URL = process.env.REACT_APP_ContractDeployPresaleAPIBaseUrl;

export const STORAGE = 'nftwardv2';
export const APP_URL = window.location.origin;

export const URLS = {
  HOME: '/',
  WELCOME: '/welcome',
  LOGIN: '/login',
  SIGNUP: '/signup',
  CATEGORIES: '/categories',
  ADD_UPDATE_CATEGORIES: '/category/:id',
  DASHBOARD: '/dashboard',
  TRAVELS: '/travels',
  TRAVEL_DETAILS: '/travels/:id',
  PRIVACY_POLICY: '/privacy-policy',
  NEW_TRAVEL: '/new-travel',
  ADD_RESIDENCE: '/add-residence',
  STUDENT_REGISTRATION: '/student-registration',
  ADD_ROOM: '/add-room',
  RESIDENCE_SUMMERY: '/residence-summery',
  DESCRIBE_ROOMS: '/describe-rooms',
  MORE_ABOUT_PROPERTY: '/more-about-property',
  UPLOAD_PROPERTY_IMAGES: '/upload-property-images',
  UPLOAD_PROPERTY_DOCUMENTS: '/upload-property-documents',
  CONNECT: '/connect',
  CONNECTED: '/connected',
  CONNECT_REQUEST: '/connect-request',
  CONNECTED_PROFILE: '/connected/:id',
  PROFILE: '/profile',
  CASES: '/cases',
  CASE_DETAILS: '/case/:id',
  ADVISORY: '/advisory',
  NOTIFICATIONS: '/notifications',
  NOTIFICATION_DETAILS: '/notification/:id',
  CREATE_CASE: '/create-case/:id',
  SUCCESS_PAGE: '/success',
  CANCEL_PAGE: '/cancel',
  NOTIFY_PAGE: '/notify',
  MAKE_PAYMENT: '/make-payment/:id',
  FORGOT_PASSWORD: '/forgot-pass',
  RESET_PASSWORD: '/reset-pass',
  REGISTRATION_SUCCESSFUL: '/registration-successful',
  UPDATE_PROFILE: '/update-profile',
  OPEN_CASES: '/open-cases',
  OPEN_CASE_DETAILS: '/open-cases-details/:id',
}

export const API_URL = {
  COMMON: {
    FILE_UPLOAD: `${API_BASE_URL}/FileUpload`,
    GET_BLOCKCHAIN_NETWORKS: `${API_BASE_URL}/BlockChainNetwork`,
    GET_DATE_RANGE_TYPES: `${API_BASE_URL}/DateRange`,
    SAVE_CONTRACT_TXN: `${API_BASE_URL}/Transaction/SaveHash`,
    SAVE_CONTRACT_CREATE_UPDATE_TXN: `${API_BASE_URL}/Transaction/CreateUpdateTransactionHash`,
    DELETE_REPORT: `${API_BASE_URL}/Report`,
    POST_REPORT: `${API_BASE_URL}/Report`,
    GET_REPORT: `${API_BASE_URL}/Report`,
    GET_REPORT_TYPE: `${API_BASE_URL}/ReportType`,
    UPDATE_MARKETPLACE_FEES: `${API_BASE_URL}/Fees/MarketplaceFees`,
    ACTIVITY: `${API_BASE_URL}/Activity`,
    GET_CONTACTUS: `${API_BASE_URL}/ContactUs`,
    DELETE_CONTACTUS: `${API_BASE_URL}/ContactUs`,
    GET_COUNTRY: `${API_BASE_URL}/Country`,
    LIKE_UNLIKE: `${API_BASE_URL}/LikeUnlike`,
    FAVORITE: `${API_BASE_URL}/Favourite`,
    FOLLOW_UNFOLLOW: `${API_BASE_URL}/FollowUnfollow`,
    DASHBOARD_GET_COUNT: `${API_BASE_URL}/Dashboard/GetCount`,
    DASHBOARD_GET_ORDER_REVENUE: `${API_BASE_URL}/Dashboard/GetOrderRevenue`,
    DASHBOARD_GET_PRODUCT: `${API_BASE_URL}/Dashboard/GetProduct`,
  },
  BASKET: {
    API_BASKET: `${API_BASE_URL}/GalleryBasket`,
  },
  META_GALLERY: {
    API_META_GALLERY: `${API_BASE_URL}/MetaGallery`,
  },
  USER: {
    LOGIN: `${API_BASE_URL}/APIAuthenticate`,
    REFRESH_TOKEN: `${API_BASE_URL}/Refreshtoken`,
    ME: `${API_BASE_URL}details`,
    SOCIAL_LOGIN: `${API_BASE_URL}customer/social-login`,
    REGISTER: {
      STEP1: `https://prod-164.westeurope.logic.azure.com:443/workflows/df6a78bd31de47208a7f4508af78b82b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=hyO70plLDoVzEw2FNzT-0BY4sMOos98KLBid9lFbVM0`,
      STEP2: `https://prod-208.westeurope.logic.azure.com:443/workflows/381f431a20264ca2bc70a630074c5e08/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=hC9Rfs0ZfTkmiP2HTXrmc_W_VWpsuzctNAdY74f6T_g`,
      STEP3: `https://prod-208.westeurope.logic.azure.com:443/workflows/381f431a20264ca2bc70a630074c5e08/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=hC9Rfs0ZfTkmiP2HTXrmc_W_VWpsuzctNAdY74f6T_g`,
      STEP4: `https://prod-208.westeurope.logic.azure.com:443/workflows/381f431a20264ca2bc70a630074c5e08/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=hC9Rfs0ZfTkmiP2HTXrmc_W_VWpsuzctNAdY74f6T_g`,
    },
    FORGOT_PASSWORD: `${API_BASE_URL}/Admin/ForgotPassword`,
    RESET_PASSWORD: `${API_BASE_URL}/Admin/ValidateForgotPassword`,
    CONNECT_WALLET: `${API_BASE_URL}/ConnectWallet`,
    EDIT_PROFILE: `${API_BASE_URL}/Admin`,
    GET_PROFILE: `${API_BASE_URL}/Admin`,
    CHANGE_PASSWORD: `${API_BASE_URL}/Admin/ChangePasswod`,
    CUSTOMER_ADD: `${API_BASE_URL}/Customer`,
    CUSTOMER_LIST: `${API_BASE_URL}/Customer/GetCustomer`,
    DELETE_CUSTOMER: `${API_BASE_URL}/Customer/GetCustomer`,
    SIGNUP: `${API_BASE_URL}/SignUp`,
    GET_USER_PROFILE: `${API_BASE_URL}/Customer/GetCustomer`,
  },
  PRODUCT: {
    GET_CATEGORIES: `${API_BASE_URL}/Category`,
    ADD_UPDATE_CATEGORY: `${API_BASE_URL}/Category`,
    DELETE_CATEGORY: `${API_BASE_URL}/Category`,
    GET: `${API_BASE_URL}/Product`,
    GET_OWNER_NFT: `${API_BASE_URL}/nft/GetNFTByOwner`,
    MAPPRODUCTPAYOUTGROUP: `${API_BASE_URL}/product/payoutgroup`,
    PUBLISH: `${API_BASE_URL}/product/publish`,
    FEATURE: `${API_BASE_URL}/product/feature`,
    DELETE: `${API_BASE_URL}/Product`,
    DELETE_REDEMPTION: `${API_BASE_URL}/Product/redeem/delete`,
    ASSIGN_REDEMPTION: `${API_BASE_URL}/Product/redeem`,
    LIST_REDEMPTION: `${API_BASE_URL}/Product/redeem`,
    DELETE_PREVIEW_IMAGE: `${API_BASE_URL}/FileUpload/ProductPreviewFileDelete`,
    ASSIGN_PREVIEW_PRODUCT: `${API_BASE_URL}/product/preview`,
    UPDATE_PRODUCT_PRICE: `${API_BASE_URL}/product/price`,
    PRODUCT_GROUP_LIST: `${API_BASE_URL}/ProductGroup`,
    PRODUCT_ADD: `${API_BASE_URL}/Product`,
    PRODUCT_UPDATE: `${API_BASE_URL}/Product`,
    GET_NFT: `${API_BASE_URL}/NFT`,
    GET_OWNER_BY_NFT: `${API_BASE_URL}/NFT/GetOwnerByNFT`,
  },
  PAYOUT_GROUP: {
    GET_PAYOUT_GROUPS: `${API_BASE_URL}/PayoutGroup`,
    ADD_PAYOUT_GROUP: `${API_BASE_URL}/PayoutGroup`,
    UPDATE_PAYOUT_GROUP: `${API_BASE_URL}/PayoutGroup`,
    DELETE_PAYOUT_GROUP: `${API_BASE_URL}/PayoutGroup`,
  },
  COLLECTION: {
    GET_COLLECTION_TYPES: `${API_BASE_URL}/collection/collectiontype`,
    GET_COLLECTIONS: `${API_BASE_URL}/Collection`,
    ADD_UPDATE_DETAILS: `${API_BASE_URL}/Collection`,
    DELETE_COLLECTION: `${API_BASE_URL}/Collection`,
    UPDATE_SOCIALS: `${API_BASE_URL}/collection/social`,
    UPDATE_PRESALE: `${API_BASE_URL}/collection/presale`,
    UPDATE_GENESIS: `${API_BASE_URL}/collection/genesis`,
    ASSIGN_PAYOUT_GROUP: `${API_BASE_URL}/collection/payoutgroup`,
    FETCH_CONTRACT_ABI: `${API_NODE_BASE_URL}/collection/initiate-contract`,
    DEPLOY_CONTRACT: `${API_BASE_URL}/collection/DeployContract`,
    UPDATE_FEATURE: `${API_BASE_URL}/collection/feature`,
    UPDATE_PUBLISH_STATUS: `${API_BASE_URL}/collection/publish`,
    UPDATE_BAN_STATUS: `${API_BASE_URL}/collection/ban`,
  },
  TRAVELS: {
    GET_TRAVELS: `https://prod-24.westeurope.logic.azure.com:443/workflows/5e9d284482974edd818a23cb2670ca79/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=HZuu2lK2zqNLz4W8CtHZ8oA6ooK2yRr57pI6FzutwVY&travel=5aa3aacf-7666-ed11-9561-000d3a4bbc07`,
    STEP1: `https://prod-197.westeurope.logic.azure.com:443/workflows/4769ad4312c0450c94cccb9379813637/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=2KUV2syX2FAy2_nNyMfZeSsHtr1sisPifokcpLBp8Ho`,
    STEP2: `https://prod-208.westeurope.logic.azure.com:443/workflows/381f431a20264ca2bc70a630074c5e08/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=hC9Rfs0ZfTkmiP2HTXrmc_W_VWpsuzctNAdY74f6T_g`,
    STEP3: `https://prod-197.westeurope.logic.azure.com:443/workflows/4769ad4312c0450c94cccb9379813637/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=2KUV2syX2FAy2_nNyMfZeSsHtr1sisPifokcpLBp8Ho`,
    STEP4: `https://prod-197.westeurope.logic.azure.com:443/workflows/4769ad4312c0450c94cccb9379813637/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=2KUV2syX2FAy2_nNyMfZeSsHtr1sisPifokcpLBp8Ho`,
  },
  STORE: {
    CONFIG: `${API_BASE_URL}/StoreConfiguration`,
  },
  CUSTOMFIELD: {
    GET_TYPE: `${API_BASE_URL}/CustomFieldType`,
    SAVE: `${API_BASE_URL}/CustomField`,
    GET: `${API_BASE_URL}/CustomField`,
    DELETE: `${API_BASE_URL}/CustomField`
  },
  SITE_SETTINGS: {
    GET_SITE_SETTINGS: `${API_BASE_URL}/SiteSettings`,
    POST_SITE_SETTINGS: `${API_BASE_URL}/SiteSettings`,
    ADD_CUSTODIAL_WALLET: `${API_BASE_URL}/CustodialWallet`,
    FETCH_CUSTODIAL_WALLET: `${API_BASE_URL}/CustodialWallet`,
  },
  THEMES: {
    GET: `${API_BASE_URL}/Theam`,
    POST: `${API_BASE_URL}/Theam`
  },
  CMS: {
    GET_LIST: `${API_BASE_URL}/CMS`,
    DELETE_CMS: `${API_BASE_URL}/CMS`,
    ADD_CMS: `${API_BASE_URL}/CMS`,
    EDIT_CMS: `${API_BASE_URL}/CMS`,
  },
  EMAIL_TEMPLATES: {
    GET: `${API_BASE_URL}/EmailTemplate`,
    EDIT: `${API_BASE_URL}/EmailTemplate`,
    STATUS_CHANGE: `${API_BASE_URL}/EmailTemplate/StatusChange`,
  },
  EMPLOYEE: {
    GET: `${API_BASE_URL}/Employee`,
    DELETE: `${API_BASE_URL}/Employee`,
    ADD: `${API_BASE_URL}/Employee`,
    UPDATE: `${API_BASE_URL}/Employee`,
  },
  COUPON: {
    LIST_TYPE: `${API_BASE_URL}/coupon/Coupontype`,
    LIST_DISCOUNT_TYPE: `${API_BASE_URL}/coupon/CouponDiscountType`,
    LIST: `${API_BASE_URL}/coupon`,
    ADD: `${API_BASE_URL}/coupon`,
    EDIT: `${API_BASE_URL}/coupon`,
    DELETE: `${API_BASE_URL}/coupon`,
  },
  GALLERY: {
    INDUSTRY_LIST: `${API_BASE_URL}/GalleryIndustry`,
    LIST: `${API_BASE_URL}/MetaGallery`,
    ADD: `${API_BASE_URL}/MetaGallery`,
    EDIT: `${API_BASE_URL}/MetaGallery`,
    DELETE: `${API_BASE_URL}/MetaGallery`,
  },
  ORDERS: {
    GET: `${API_BASE_URL}/Order`
  },
  PRE_SALE: {
    GET: `${API_BASE_URL}/PreSaleRegistration`,
    ADD: `${API_BASE_URL}/presaleregistartion/adminpresaleregistartion`,
    STATUS_CHANGE: `${API_BASE_URL}/presaleregistartion/presaleregistartionstatuschange`
  }
}

export const ACTIONS = {
  LOADER: {
    SET_FP_STATUS: 'ACTIONS/LOADER/SET_FP_STATUS',
  },
  COMMON: {
    FILE_UPLOAD: 'ACTIONS/COMMON/FILE_UPLOAD',
    GET_BLOCKCHAIN_NETWORKS: 'ACTIONS/COMMON/GET_BLOCKCHAIN_NETWORKS',
    GET_DATE_RANGE_TYPES: 'ACTIONS/COMMON/GET_DATE_RANGE_TYPES',
    SAVE_CONTRACT_TXN: 'ACTIONS/COMMON/SAVE_CONTRACT_TXN',
    SAVE_CONTRACT_CREATE_UPDATE_TXN: 'ACTIONS/COMMON/SAVE_CONTRACT_CREATE_UPDATE_TXN',
    DELETE_REPORT: 'ACTIONS/COMMON/DELETE_REPORT',
    POST_REPORT: 'ACTIONS/COMMON/POST_REPORT',
    GET_REPORT: 'ACTIONS/COMMON/GET_REPORT',
    GET_REPORT_TYPE: 'ACTIONS/COMMON/GET_REPORT_TYPE',
    UPDATE_MARKETPLACE_FEES: 'ACTIONS/COMMON/UPDATE_MARKETPLACE_FEES',
    ACTIVITY: 'ACTIONS/COMMON/ACTIVITY',
    GET_CONTACTUS: 'ACTIONS/COMMON/GET_CONTACTUS',
    DELETE_CONTACTUS: 'ACTIONS/COMMON/DELETE_CONTACTUS',
    GET_COUNTRY: 'ACTIONS/COMMON/GET_COUNTRY',
    POST_LIKE_UNLIKE: 'ACTIONS/COMMON/POST_LIKE_UNLIKE',
    GET_LIKE_UNLIKE: 'ACTIONS/COMMON/GET_LIKE_UNLIKE',
    POST_FAVORITE: 'ACTIONS/COMMON/POST_FAVORITE',
    GET_FAVORITE: 'ACTIONS/COMMON/GET_FAVORITE',
    POST_FOLLOW_UNFOLLOW: 'ACTIONS/COMMON/POST_FOLLOW_UNFOLLOW',
    GET_FOLLOW_UNFOLLOW: 'ACTIONS/COMMON/GET_FOLLOW_UNFOLLOW',
    DASHBOARD_GET_COUNT: 'ACTIONS/COMMON/DASHBOARD_GET_COUNT',
    DASHBOARD_GET_ORDER_REVENUE: 'ACTIONS/COMMON/DASHBOARD_GET_ORDER_REVENUE',
    DASHBOARD_GET_PRODUCT: 'ACTIONS/COMMON/DASHBOARD_GET_PRODUCT',
  },
  USER: {
    LOGIN: 'ACTIONS/USER/LOGIN',
    LOGOUT: 'ACTIONS/USER/LOGOUT',
    PRIVACY_POLICY: 'ACTIONS/USER/PRIVACY_POLICY',
    ME: 'ACTIONS/USER/ME',
    REGISTER: {
      STEP1: 'ACTIONS/USER/REGISTER/STEP1',
      STEP2: 'ACTIONS/USER/REGISTER/STEP2',
      STEP3: 'ACTIONS/USER/REGISTER/STEP3',
      STEP4: 'ACTIONS/USER/REGISTER/STEP4',
    },
    FORGOT_PASSWORD: 'ACTIONS/USER/FORGOT_PASSWORD',
    RESET_PASSWORD: 'ACTIONS/USER/RESET_PASSWORD',
    EDIT_PROFILE: 'ACTIONS/USER/EDIT_PROFILE',
    CONNECT_WALLET: 'ACTIONS/USER/CONNECT_WALLET',
    UPDATE_CUSTODIAL: 'ACTIONS/USER/UPDATE_CUSTODIAL',
    GET_PROFILE: 'ACTIONS/USER/GET_PROFILE',
    CHANGE_PASSWORD: 'ACTIONS/USER/CHANGE_PASSWORD',
    CUSTOMER_ADD: 'ACTIONS/USER/CUSTOMER_ADD',
    CUSTOMER_LIST: 'ACTIONS/USER/CUSTOMER_LIST',
    DELETE_CUSTOMER: 'ACTIONS/USER/DELETE_CUSTOMER',
    SIGNUP: 'ACTIONS/USER/SIGNUP',
    GET_USER_PROFILE: 'ACTIONS/USER/GET_USER_PROFILE',
  },
  PRODUCT: {
    GET_CATEGORIES: 'ACTIONS/PRODUCT/GET_CASES',
    ADD_UPDATE_CATEGORY: 'ACTIONS/PRODUCT/ADD_UPDATE_CATEGORY',
    DELETE_CATEGORY: 'ACTIONS/PRODUCT/DELETE_CATEGORY',
    GET: 'ACTIONS/PRODUCT/PRODUCT',
    GET_OWNER_NFT: 'ACTIONS/PRODUCT/GET_OWNER_NFT',
    MAPPRODUCTPAYOUTGROUP: 'SAGA_ACTIONS/PRODUCT/MAPPRODUCTPAYOUTGROUP',
    PUBLISH: 'ACTIONS/PRODUCT/PUBLISH',
    DELETE: 'ACTIONS/PRODUCT/DELETE',
    DELETE_REDEMPTION: 'ACTIONS/PRODUCT/DELETE_REDEMPTION',
    DELETE_PREVIEW_IMAGE: 'ACTIONS/PRODUCT/DELETE_PREVIEW_IMAGE',
    ASSIGN_REDEMPTION: 'ACTIONS/PRODUCT/ASSIGN_REDEMPTION',
    LIST_REDEMPTION: 'ACTIONS/PRODUCT/LIST_REDEMPTION',
    ASSIGN_PREVIEW_PRODUCT: 'ACTIONS/PRODUCT/ASSIGN_PREVIEW_PRODUCT',
    UPDATE_PRODUCT_PRICE: 'ACTIONS/PRODUCT/UPDATE_PRODUCT_PRICE',
    FEATURE: 'ACTIONS/PRODUCT/FEATURE',
    PRODUCT_GROUP_LIST: 'ACTIONS/PRODUCT/PRODUCT_GROUP_LIST',
    PRODUCT_ADD: 'ACTIONS/PRODUCT/PRODUCT_ADD',
    PRODUCT_UPDATE: 'ACTIONS/PRODUCT/PRODUCT_UPDATE',
    GET_NFT: `ACTIONS/PRODUCT/NFT`,
    GET_OWNER_BY_NFT: `ACTIONS/PRODUCT/NFT/GET_OWNER_BY_NFT`,
  },
  COLLECTION: {
    GET_COLLECTION_TYPES: 'ACTIONS/COLLECTION/GET_COLLECTION_TYPES',
    ADD_UPDATE_DETAILS: 'ACTIONS/COLLECTION/ADD_UPDATE_DETAILS',
    GET_COLLECTIONS: 'ACTIONS/COLLECTION/GET_COLLECTIONS',
    DELETE_COLLECTION: 'ACTIONS/COLLECTION/DELETE_COLLECTION',
    UPDATE_SOCIALS: 'ACTIONS/COLLECTION/UPDATE_SOCIALS',
    UPDATE_PRESALE: 'ACTIONS/COLLECTION/UPDATE_PRESALE',
    UPDATE_GENESIS: 'ACTIONS/COLLECTION/UPDATE_GENESIS',
    ASSIGN_PAYOUT_GROUP: 'ACTIONS/COLLECTION/ASSIGN_PAYOUT_GROUP',
    FETCH_CONTRACT_ABI: 'ACTIONS/COLLECTION/FETCH_CONTRACT_ABI',
    DEPLOY_CONTRACT: 'ACTIONS/COLLECTION/DEPLOY_CONTRACT',
    UPDATE_FEATURE: 'ACTIONS/COLLECTION/UPDATE_FEATURE',
    UPDATE_PUBLISH_STATUS: 'ACTIONS/COLLECTION/UPDATE_PUBLISH_STATUS',
    UPDATE_BAN_STATUS: 'ACTIONS/COLLECTION/UPDATE_BAN_STATUS',
  },
  PAYOUT_GROUP: {
    GET_PAYOUT_GROUPS: 'ACTIONS/PAYOUT_GROUP/GET_PAYOUT_GROUPS',
    ADD_PAYOUT_GROUP: 'ACTIONS/PAYOUT_GROUP/ADD_PAYOUT_GROUP',
    UPDATE_PAYOUT_GROUP: 'ACTIONS/PAYOUT_GROUP/UPDATE_PAYOUT_GROUP',
    DELETE_PAYOUT_GROUP: 'ACTIONS/PAYOUT_GROUP/DELETE_PAYOUT_GROUP'
  },
  TRAVELS: {
    GET_TRAVELS: 'ACTIONS/TRAVELS/GET_TRAVELS',
    STEP1: 'ACTIONS/TRAVELS/STEP1',
    STEP2: 'ACTIONS/TRAVELS/STEP2',
    STEP3: 'ACTIONS/TRAVELS/STEP3',
    STEP4: 'ACTIONS/TRAVELS/STEP4',
  },
  STORE: {
    CONFIG: 'ACTIONS/STORE/CONFIGURATION',
    CONFIG_SAVE: 'ACTIONS/STORE/CONFIGURATION_SAVE',
  },
  CUSTOMFIELD: {
    GET_TYPE: 'ACTIONS/CUSTOMFIELD/GET_TYPE',
    GET: 'ACTIONS/CUSTOMFIELD/GET',
    SAVE: 'ACTIONS/CUSTOMFIELD/SAVE',
    DELETE: 'ACTIONS/CUSTOMFIELD/DELETE'
  },
  SITE_SETTINGS: {
    GET_SITE_SETTINGS: 'ACTIONS/SITE_SETTINGS/GET_SITE_SETTINGS',
    POST_SITE_SETTINGS: 'ACTIONS/SITE_SETTINGS/POST_SITE_SETTINGS',
    ADD_CUSTODIAL_WALLET: 'ACTIONS/SITE_SETTINGS/ADD_CUSTODIAL_WALLET',
    FETCH_CUSTODIAL_WALLET: 'ACTIONS/SITE_SETTINGS/FETCH_CUSTODIAL_WALLET',
  },
  THEMES: {
    GET: 'ACTIONS/THEMES/GET',
    POST: 'ACTIONS/THEMES/POST'
  },
  CMS: {
    GET_LIST: 'ACTIONS/CMS/GET_LIST',
    DELETE_CMS: 'ACTIONS/CMS/DELETE_CMS',
    ADD_CMS: 'ACTIONS/CMS/ADD_CMS',
    EDIT_CMS: 'ACTIONS/CMS/EDIT_CMS',
  },
  EMAIL_TEMPLATES: {
    GET: 'ACTIONS/EMAIL_TEMPLATES/GET',
    EDIT: 'ACTIONS/EMAIL_TEMPLATES/EDIT',
    STATUS_CHANGE: 'ACTIONS/EMAIL_TEMPLATES/STATUS_CHANGE',
  },
  EMPLOYEE: {
    GET: 'ACTIONS/EMPLOYEE/GET',
    DELETE: 'ACTIONS/EMPLOYEE/DELETE',
    ADD: 'ACTIONS/EMPLOYEE/ADD',
    UPDATE: 'ACTIONS/EMPLOYEE/UPDATE',
  },
  COUPON: {
    LIST_TYPE: `ACTIONS/COUPON/LIST_TYPE`,
    LIST_DISCOUNT_TYPE: `ACTIONS/COUPON/LIST_DISCOUNT_TYPE`,
    LIST: 'ACTIONS/COUPON/LIST',
    ADD: 'ACTIONS/COUPON/ADD',
    EDIT: 'ACTIONS/COUPON/EDIT',
    DELETE: 'ACTIONS/COUPON/DELETE',
  },
  GALLERY: {
    INDUSTRY_LIST: `ACTIONS/GALLERY/INDUSTRY_LIST`,
    LIST: 'ACTIONS/GALLERY/LIST',
    ADD: 'ACTIONS/GALLERY/ADD',
    EDIT: 'ACTIONS/GALLERY/EDIT',
    DELETE: 'ACTIONS/GALLERY/DELETE',
  },
  ORDERS: {
    GET: 'ACTIONS/ORDERS/GET'
  },
  PRE_SALE: {
    GET: 'ACTIONS/PRE_SALE/GET',
    ADD: 'ACTIONS/PRE_SALE/ADD',
    STATUS_CHANGE: 'ACTIONS/PRE_SALE/STATUS_CHANGE',
  }
}

export const SAGA_ACTIONS = {
  COMMON: {
    FILE_UPLOAD: 'SAGA_ACTIONS/COMMON/FILE_UPLOAD',
    GET_BLOCKCHAIN_NETWORKS: 'SAGA_ACTIONS/COMMON/GET_BLOCKCHAIN_NETWORKS',
    GET_DATE_RANGE_TYPES: 'SAGA_ACTIONS/COMMON/GET_DATE_RANGE_TYPES',
    SAVE_CONTRACT_TXN: 'SAGA_ACTIONS/COMMON/SAVE_CONTRACT_TXN',
    SAVE_CONTRACT_CREATE_UPDATE_TXN: 'SAGA_ACTIONS/COMMON/SAVE_CONTRACT_CREATE_UPDATE_TXN',
    DELETE_REPORT: 'SAGA_ACTIONS/COMMON/DELETE_REPORT',
    POST_REPORT: 'SAGA_ACTIONS/COMMON/POST_REPORT',
    GET_REPORT: 'SAGA_ACTIONS/COMMON/GET_REPORT',
    GET_REPORT_TYPE: 'SAGA_ACTIONS/COMMON/GET_REPORT_TYPE',
    UPDATE_MARKETPLACE_FEES: 'SAGA_ACTIONS/COMMON/UPDATE_MARKETPLACE_FEES',
    ACTIVITY: 'SAGA_ACTIONS/COMMON/ACTIVITY',
    GET_CONTACTUS: 'SAGA_ACTIONS/COMMON/GET_CONTACTUS',
    DELETE_CONTACTUS: 'SAGA_ACTIONS/COMMON/DELETE_CONTACTUS',
    GET_COUNTRY: 'SAGA_ACTIONS/COMMON/GET_COUNTRY',
    POST_LIKE_UNLIKE: 'SAGA_ACTIONS/COMMON/POST_LIKE_UNLIKE',
    GET_LIKE_UNLIKE: 'SAGA_ACTIONS/COMMON/GET_LIKE_UNLIKE',
    POST_FAVORITE: 'SAGA_ACTIONS/COMMON/POST_FAVORITE',
    GET_FAVORITE: 'SAGA_ACTIONS/COMMON/GET_FAVORITE',
    POST_FOLLOW_UNFOLLOW: 'SAGA_ACTIONS/COMMON/POST_FOLLOW_UNFOLLOW',
    GET_FOLLOW_UNFOLLOW: 'SAGA_ACTIONS/COMMON/GET_FOLLOW_UNFOLLOW',
    DASHBOARD_GET_COUNT: 'SAGA_ACTIONS/COMMON/DASHBOARD_GET_COUNT',
    DASHBOARD_GET_ORDER_REVENUE: 'SAGA_ACTIONS/COMMON/DASHBOARD_GET_ORDER_REVENUE',
    DASHBOARD_GET_PRODUCT: 'SAGA_ACTIONS/COMMON/DASHBOARD_GET_PRODUCT',
  },
  BASKET:{
    GET_BASKET: 'SAGA_ACTIONS/BASKET/GET_BASKET',
    ADD_BASKET: 'SAGA_ACTIONS/BASKET/ADD_BASKET',
    EDIT_BASKET: 'SAGA_ACTIONS/BASKET/EDIT_BASKET',
    DELETE_BASKET: 'SAGA_ACTIONS/BASKET/DELETE_BASKET',
  },
  META_GALLERY:{
    GET_META_GALLERY: 'SAGA_ACTIONS/META_GALLERY/GET_META_GALLERY',
    ADD_META_GALLERY: 'SAGA_ACTIONS/META_GALLERY/ADD_META_GALLERY',
    DELETE_META_GALLERY: 'SAGA_ACTIONS/META_GALLERY/DELETE_META_GALLERY',
  },
  USER: {
    LOGIN: 'SAGA_ACTIONS/USER/LOGIN',
    LOGINSTEP1: 'SAGA_ACTIONS/USER/LOGINSTEP1',
    LOGOUT: 'SAGA_ACTIONS/USER/LOGOUT',
    PRIVACY_POLICY: 'SAGA_ACTIONS/USER/PRIVACY_POLICY',
    ME: 'SAGA_ACTIONS/USER/ME',
    REGISTER: {
      STEP1: 'SAGA_ACTIONS/USER/REGISTER/STEP1',
      STEP2: 'SAGA_ACTIONS/USER/REGISTER/STEP2',
      STEP3: 'SAGA_ACTIONS/USER/REGISTER/STEP3',
      STEP4: 'SAGA_ACTIONS/USER/REGISTER/STEP4',
    },
    FORGOT_PASSWORD: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD',
    RESET_PASSWORD: 'SAGA_ACTIONS/USER/RESET_PASSWORD',
    EDIT_PROFILE: 'SAGA_ACTIONS/USER/EDIT_PROFILE',
    CONNECT_WALLET: 'SAGA_ACTIONS/USER/CONNECT_WALLET',
    GET_PROFILE: 'SAGA_ACTIONS/USER/GET_PROFILE',
    CHANGE_PASSWORD: 'SAGA_ACTIONS/USER/CHANGE_PASSWORD',
    CUSTOMER_ADD: 'SAGA_ACTIONS/USER/CUSTOMER_ADD',
    CUSTOMER_LIST: 'SAGA_ACTIONS/USER/CUSTOMER_LIST',
    DELETE_CUSTOMER: 'SAGA_ACTIONS/USER/DELETE_CUSTOMER',
    SIGNUP: 'SAGA_ACTIONS/USER/SIGNUP',
    GET_USER_PROFILE: 'SAGA_ACTIONS/USER/GET_USER_PROFILE',
  },
  PRODUCT: {
    GET_CATEGORIES: 'SAGA_ACTIONS/PRODUCT/GET_CASES',
    ADD_UPDATE_CATEGORY: 'SAGA_ACTIONS/PRODUCT/ADD_UPDATE_CATEGORY',
    DELETE_CATEGORY: 'SAGA_ACTIONS/PRODUCT/DELETE_CATEGORY',
    GET: 'SAGA_ACTIONS/PRODUCT/PRODUCT',
    GET_OWNER_NFT: 'SAGA_ACTIONS/PRODUCT/GET_OWNER_NFT',
    MAPPRODUCTPAYOUTGROUP: 'SAGA_ACTIONS/PRODUCT/MAPPRODUCTPAYOUTGROUP',
    PUBLISH: 'SAGA_ACTIONS/PRODUCT/PUBLISH',
    DELETE: 'SAGA_ACTIONS/PRODUCT/DELETE',
    DELETE_REDEMPTION: 'SAGA_ACTIONS/PRODUCT/DELETE_REDEMPTION',
    DELETE_PREVIEW_IMAGE: 'SAGA_ACTIONS/PRODUCT/DELETE_PREVIEW_IMAGE',
    ASSIGN_PREVIEW_PRODUCT: 'SAGA_ACTIONS/PRODUCT/ASSIGN_PREVIEW_PRODUCT',
    LIST_REDEMPTION: 'SAGA_ACTIONS/PRODUCT/LIST_REDEMPTION',
    UPDATE_PRODUCT_PRICE: 'SAGA_ACTIONS/PRODUCT/UPDATE_PRODUCT_PRICE',
    ASSIGN_REDEMPTION: 'SAGA_ACTIONS/PRODUCT/ASSIGN_REDEMPTION',
    FEATURE: 'SAGA_ACTIONS/PRODUCT/FEATURE',
    PRODUCT_GROUP_LIST: 'SAGA_ACTIONS/PRODUCT/PRODUCT_GROUP_LIST',
    PRODUCT_ADD: 'SAGA_ACTIONS/PRODUCT/PRODUCT_ADD',
    PRODUCT_UPDATE: 'SAGA_ACTIONS/PRODUCT/PRODUCT_UPDATE',
    GET_NFT: `SAGA_ACTIONS/PRODUCT/NFT`,
    GET_OWNER_BY_NFT: `SAGA_ACTIONS/PRODUCT/NFT/GET_OWNER_BY_NFT`,
  },
  CUSTOMFIELD: {
    GET_TYPE: 'SAGA_ACTIONS/CUSTOMFIELD/GET_TYPE',
    SAVE: 'SAGA_ACTIONS/CUSTOMFIELD/SAVE',
    GET: 'SAGA_ACTIONS/CUSTOMFIELD/GET',
    DELETE: 'SAGA_ACTIONS/CUSTOMFIELD/DELETE'
  },
  COLLECTION: {
    GET_COLLECTION_TYPES: 'SAGA_ACTIONS/COLLECTION/GET_COLLECTION_TYPES',
    ADD_UPDATE_DETAILS: 'SAGA_ACTIONS/COLLECTION/ADD_UPDATE_DETAILS',
    GET_COLLECTIONS: 'SAGA_ACTIONS/COLLECTION/GET_COLLECTIONS',
    DELETE_COLLECTION: 'SAGA_ACTIONS/COLLECTION/DELETE_COLLECTION',
    UPDATE_SOCIALS: 'SAGA_ACTIONS/COLLECTION/UPDATE_SOCIALS',
    UPDATE_PRESALE: 'SAGA_ACTIONS/COLLECTION/UPDATE_PRESALE',
    UPDATE_GENESIS: 'SAGA_ACTIONS/COLLECTION/UPDATE_GENESIS',
    ASSIGN_PAYOUT_GROUP: 'SAGA_ACTIONS/COLLECTION/ASSIGN_PAYOUT_GROUP',
    FETCH_CONTRACT_ABI: 'SAGA_ACTIONS/COLLECTION/FETCH_CONTRACT_ABI',
    DEPLOY_CONTRACT: 'SAGA_ACTIONS/COLLECTION/DEPLOY_CONTRACT',
    UPDATE_FEATURE: 'SAGA_ACTIONS/COLLECTION/UPDATE_FEATURE',
    UPDATE_PUBLISH_STATUS: 'SAGA_ACTIONS/COLLECTION/UPDATE_PUBLISH_STATUS',
    UPDATE_BAN_STATUS: 'SAGA_ACTIONS/COLLECTION/UPDATE_BAN_STATUS',
  },
  PAYOUT_GROUP: {
    GET_PAYOUT_GROUPS: 'SAGA_ACTIONS/PAYOUT_GROUP/GET_PAYOUT_GROUPS',
    ADD_PAYOUT_GROUP: 'SAGA_ACTIONS/PAYOUT_GROUP/ADD_PAYOUT_GROUP',
    UPDATE_PAYOUT_GROUP: 'SAGA_ACTIONS/PAYOUT_GROUP/UPDATE_PAYOUT_GROUP',
    DELETE_PAYOUT_GROUP: 'SAGA_ACTIONS/PAYOUT_GROUP/DELETE_PAYOUT_GROUP'
  },
  TRAVELS: {
    GET_TRAVELS: 'ACTIONS/TRAVELS/GET_TRAVELS',
    STEP1: 'ACTIONS/TRAVELS/STEP1',
    STEP2: 'ACTIONS/TRAVELS/STEP2',
    STEP3: 'ACTIONS/TRAVELS/STEP3',
    STEP4: 'ACTIONS/TRAVELS/STEP4',
  },
  STORE: {
    CONFIG: 'SAGA_ACTIONS/STORE/CONFIGURATION',
    CONFIG_SAVE: 'SAGA_ACTIONS/STORE/CONFIGURATION_SAVE',
  },
  SITE_SETTINGS: {
    GET_SITE_SETTINGS: 'SAGA_ACTIONS/SITE_SETTINGS/GET_SITE_SETTINGS',
    POST_SITE_SETTINGS: 'SAGA_ACTIONS/SITE_SETTINGS/POST_SITE_SETTINGS',
    ADD_CUSTODIAL_WALLET: 'SAGA_ACTIONS/SITE_SETTINGS/ADD_CUSTODIAL_WALLET',
    FETCH_CUSTODIAL_WALLET: 'SAGA_ACTIONS/SITE_SETTINGS/FETCH_CUSTODIAL_WALLET',
  },
  THEMES: {
    GET: 'SAGA_ACTIONS/THEMES/GET',
    POST: 'SAGA_ACTIONS/THEMES/POST'
  },
  CMS: {
    GET_LIST: 'SAGA_ACTIONS/CMS/GET_LIST',
    DELETE_CMS: 'SAGA_ACTIONS/CMS/DELETE_CMS',
    ADD_CMS: 'SAGA_ACTIONS/CMS/ADD_CMS',
    EDIT_CMS: 'SAGA_ACTIONS/CMS/EDIT_CMS',
  },
  EMAIL_TEMPLATES: {
    GET: 'SAGA_ACTIONS/EMAIL_TEMPLATES/GET',
    EDIT: 'SAGA_ACTIONS/EMAIL_TEMPLATES/EDIT',
    STATUS_CHANGE: 'SAGA_ACTIONS/EMAIL_TEMPLATES/STATUS_CHANGE',
  },
  EMPLOYEE: {
    GET: 'SAGA_ACTIONS/EMPLOYEE/GET',
    DELETE: 'SAGA_ACTIONS/EMPLOYEE/DELETE',
    ADD: 'SAGA_ACTIONS/EMPLOYEE/ADD',
    UPDATE: 'SAGA_ACTIONS/EMPLOYEE/UPDATE',
  },
  COUPON: {
    LIST_TYPE: `SAGA_ACTIONS/COUPON/LIST_TYPE`,
    LIST_DISCOUNT_TYPE: `SAGA_ACTIONS/COUPON/LIST_DISCOUNT_TYPE`,
    LIST: 'SAGA_ACTIONS/COUPON/LIST',
    ADD: 'SAGA_ACTIONS/COUPON/ADD',
    EDIT: 'SAGA_ACTIONS/COUPON/EDIT',
    DELETE: 'SAGA_ACTIONS/COUPON/DELETE',
  },
  GALLERY: {
    INDUSTRY_LIST: `SAGA_ACTIONS/GALLERY/INDUSTRY_LIST`,
    LIST: 'SAGA_ACTIONS/GALLERY/LIST',
    ADD: 'SAGA_ACTIONS/GALLERY/ADD',
    EDIT: 'SAGA_ACTIONS/GALLERY/EDIT',
    DELETE: 'SAGA_ACTIONS/GALLERY/DELETE',
  },
  ORDERS: {
    GET: 'SAGA_ACTIONS/ORDERS/GET'
  },
  PRE_SALE: {
    GET: 'SAGA_ACTIONS/PRE_SALE/GET',
    ADD: 'SAGA_ACTIONS/PRE_SALE/ADD',
    STATUS_CHANGE: 'SAGA_ACTIONS/PRE_SALE/STATUS_CHANGE',
  }
}