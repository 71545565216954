import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function useBasketApi() {

  const callApi = useApiCall();

  const getBasket = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.BASKET.GET_BASKET, data, onSuccess, onError);
  }

  const addBasket = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.BASKET.ADD_BASKET, data, onSuccess, onError);
  }
  const editBasket = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.BASKET.EDIT_BASKET, data, onSuccess, onError);
  }

  const deleteBasket = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.BASKET.DELETE_BASKET, data, onSuccess, onError);
  }

  
  return {
    getBasket,
    addBasket,
    editBasket,
    deleteBasket
  }
}