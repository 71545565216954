import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router";
import toast from 'react-hot-toast';

import DetailsCustomerMkt from '../../components/CustomerMkt/addUpdate'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import { useAppLoader } from "_common/hooks/common/appLoaderHook";

export interface Props {
  className?: string;
}

const AddUpdateCustomerMkt: FC<Props> = ({ className = "" }) => {

	const {id: customerId} = useParams();
	const navigate = useNavigate();
	const { showLoader, hideLoader } = useAppLoader();
	const { customerList } = useUserApi()

	const [ customerDetails, setCustomerDetails ] = useState<any>({})

	const handleRedirect = () => {
		navigate('/customerlist-mkt')
	}

	useEffect(() => {
		if ( customerId ) {
			getCustomerDetails();
		}
	}, [])

	const getCustomerDetails = () => {
		showLoader();
		customerList({customerID: customerId}, (message: string, resp: any) => {
			hideLoader();
			if ( resp['customers'] && resp['customers'][0] ) {
				setCustomerDetails(resp['customers'][0]);
			} else {
				toast.error('Customer not found!')
				handleRedirect();
			}
		}, (message: string, resp: any) => {
			hideLoader();
			toast.error(message)
			handleRedirect()
		})
	}


  return (
    <div className={`nc-PageUploadItem ${className}`} data-nc-id="PageUploadItem">
      <Helmet>
        <title>Customer</title>
      </Helmet>
      <div className="container">
        <DetailsCustomerMkt 
          handleCancel={handleRedirect}
          customerData={customerDetails}
          classNames='bg-neutral-100 container lg:pb-28 lg:pt-20 lg:space-y-28 py-16 rounded-2xl space-y-20'
        />
      </div>
    </div>
  );
};

export default AddUpdateCustomerMkt;
