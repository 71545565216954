import FormItem from 'components/FormItem';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import { useSiteSettingsSelector } from '_common/hooks/selectors/siteSettingsSelector'
import { useSiteSettingsApi } from '_common/hooks/api/siteSettingsApiHook';
import { useAppLoader } from '_common/hooks/common/appLoaderHook';
import toast from 'react-hot-toast';
import { Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { AiOutlineSetting } from 'react-icons/ai';
import Switch from 'shared/Switch/Switch';
import { BiSitemap } from 'react-icons/bi';
import { IoPricetagsOutline } from 'react-icons/io5';

const Seo = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const siteSettingsApi = useSiteSettingsApi()
  const siteSettingsList = useSiteSettingsSelector()
  const { showLoader, hideLoader } = useAppLoader()

  const [tabFields, setTabFields] = useState<any>()
  const [dropSection, setDropSection] = useState<boolean>(false)
  const [dropSectionTwo, setDropSectionTwo] = useState<boolean>(false)

  const { register, control, handleSubmit, reset, setValue, formState: { errors }, watch } = useForm()

  const getTabFiels = () => {
    siteSettingsList.map((item: any, index: number) => {
      if (item?.siteGroupName == tab) {
        setTabFields(item)
      }
    })
  }

  const onSubmit = (data: any) => {
    // console.log({ data })
    let params: any = []

    for (const key in data) {
      let tempParams: any = {}
      tabFields?.childSiteGroupDetails.forEach((element: any) => {
        element?.keyValue.forEach((item: any) => {
          if (item?.appKey == key) {
            tempParams["appKey"] = item?.appKey
            tempParams["appValue"] = data[key]
            tempParams["typeID"] = item?.typeID
            params.push(tempParams)
          }
        })
      });
    }

    // console.log({ params })
    showLoader()
    siteSettingsApi.postSiteSettings({
      "siteSettings": params
    },
      (message: string, resp: any) => {
        // console.log({ resp, message })
        toast.success(message)
        // handleCancel()
        getTabFiels()
        hideLoader()
      }, (message: string) => {
        console.log({ message })
        toast.error(message)
        hideLoader()
      })
  }

  const handleCancel = () => {
    reset({
      IsShowSitemapHtml: false,
      IsShowSitemapXml: false,
      SitemapHtml: '',
      SitemapXml: '',
      TitleMetatag: '',
      DescriptionMetatag: '',
      KeywordMetatag: '',
    })
  }

  useEffect(() => {
    if (tab && siteSettingsList) {
      getTabFiels()
    }
  }, [tab, siteSettingsList])

  useEffect(() => {
    reset({
      IsShowSitemapHtml: tabFields?.childSiteGroupDetails[0]?.keyValue[0]?.appValue == 'true' ? true : false,
      IsShowSitemapXml: tabFields?.childSiteGroupDetails[0]?.keyValue[1]?.appValue == 'true' ? true : false,
      SitemapHtml: tabFields?.childSiteGroupDetails[0]?.keyValue[2]?.appValue,
      SitemapXml: tabFields?.childSiteGroupDetails[0]?.keyValue[3]?.appValue,
      DescriptionMetatag: tabFields?.childSiteGroupDetails[1]?.keyValue[0]?.appValue,
      KeywordMetatag: tabFields?.childSiteGroupDetails[1]?.keyValue[1]?.appValue,
      TitleMetatag: tabFields?.childSiteGroupDetails[1]?.keyValue[2]?.appValue,
    })
  }, [tabFields])

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
          <div className={"accordion-item-wrapper mb-4 mt-3"}>
            <div className={"accordion-title flex items-center justify-between"}>
              <div className="text-lg font-bold flex gap-2"><BiSitemap className="text-2xl mt-1" /> <span className="flex flex-col"><p>Site maps</p></span></div>
              <ButtonSecondary
                className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                sizeClass={"py-2.5"}
                type="button"
                onClick={() => { setDropSection(!dropSection) }}
              >
                <span>
                  <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${dropSection ? "rotate-180" : ""}`} aria-hidden="true" />
                </span>
              </ButtonSecondary>
            </div>
            <Transition
              show={dropSection}
              enter="transition-opacity duration-150"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className={"accordion-body"}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5 mb-4 mt-4">
                  <FormItem label="Enable sitemap.html">
                    <Switch label=""
                      {...register(`IsShowSitemapHtml`, { required: false })}
                    />
                  </FormItem>
                  <FormItem label="">
                    <Controller
                      name={`SitemapHtml`}
                      control={control}
                      render={({ field }) => <Input
                        {...field}
                        placeholder={"sitemap.html"}
                        autoComplete={'off'}
                        disabled={!watch('IsShowSitemapHtml')}
                      />}
                    />
                  </FormItem>

                  <FormItem label="Enable sitemap.xml">
                    <Switch label=""
                      {...register(`IsShowSitemapXml`, { required: false })}
                    />
                  </FormItem>
                  <FormItem label="">
                    <Controller
                      name={`SitemapXml`}
                      control={control}
                      render={({ field }) => <Input
                        {...field}
                        placeholder={"sitemap.xml"}
                        autoComplete={'off'}
                        disabled={!watch('IsShowSitemapXml')}
                      />}
                    />
                  </FormItem>
                </div>
              </div>
            </Transition>
          </div>
        </div>

        <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
          <div className={"accordion-item-wrapper mb-4 mt-3"}>
            <div className={"accordion-title flex items-center justify-between"}>
              <div className="text-lg font-bold flex gap-2"><IoPricetagsOutline className="text-2xl mt-1" /> <span className="flex flex-col"><p>Meta tags</p></span></div>
              <ButtonSecondary
                className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                sizeClass={"py-2.5"}
                type="button"
                onClick={() => { setDropSectionTwo(!dropSectionTwo) }}
              >
                <span>
                  <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${dropSectionTwo ? "rotate-180" : ""}`} aria-hidden="true" />
                </span>
              </ButtonSecondary>
            </div>
            <Transition
              show={dropSectionTwo}
              enter="transition-opacity duration-150"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className={"accordion-body"}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5 mb-4 mt-4">
                  <FormItem label="Title">
                    <Controller
                      name={`TitleMetatag`}
                      control={control}
                      render={({ field }) => <Input
                        {...field}
                        placeholder={''}
                        autoComplete={'off'}
                        required={true}
                      />}
                    />
                    {
                      errors && errors.TitleMetatag && errors.TitleMetatag?.type == "required" ?
                        <p className='text-red-400'>Required</p> : ''
                    }
                  </FormItem>
                  <FormItem label="Description">
                    <Controller
                      name={`DescriptionMetatag`}
                      control={control}
                      render={({ field }) => <Input
                        {...field}
                        placeholder={''}
                        autoComplete={'off'}
                        required={true}
                      />}
                    />
                    {
                      errors && errors.DescriptionMetatag && errors.DescriptionMetatag?.type == "required" ?
                        <p className='text-red-400'>Required</p> : ''
                    }
                  </FormItem>
                  <FormItem label="Keywords">
                    <Controller
                      name={`KeywordMetatag`}
                      control={control}
                      render={({ field }) => <Input
                        {...field}
                        placeholder={''}
                        autoComplete={'off'}
                        required={true}
                      />}
                    />
                    {
                      errors && errors.KeywordMetatag && errors.KeywordMetatag?.type == "required" ?
                        <p className='text-red-400'>Required</p> : ''
                    }
                  </FormItem>
                </div>
              </div>
            </Transition>
          </div>
        </div>

        <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 m-8">
          <ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
          <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Reset</ButtonSecondary>
        </div>
      </form>
    </React.Fragment>
  )
}

export default Seo