import React, { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import Pagination from "shared/Pagination/PaginationHandler";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NftMoreDropdown from "components/NftMoreDropdown";
import TabFilters from "components/Filters/TabFilters";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useNavigate } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { toast } from "react-hot-toast";
import Checkbox from "shared/Checkbox/nativeCheckbox";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { usePayoutGroupApi } from "_common/hooks/api/payoutGroupApiHook";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import PageHeaderWithSearch from "shared/PageHeaderWithSearch/PageHeaderWithSearch";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useUserSelector } from '../../../_common/hooks/selectors/userSelector'
import EditFees from "components/FeesUpdate/feesUpdate";
import PriceText from "components/PriceText";
import NcCustomModal from "components/Modal/NcCustomModal";
import { ITEM_TYPE } from "_common/_constant";

export interface Props {
    className?: string;
}

const PageProducts: FC<Props> = ({ className = "" }) => {

    const { register, control, handleSubmit, reset, getValues, watch, formState: { errors } } = useForm({});

    const productApi = useProductApi();
    const payoutGroupApi = usePayoutGroupApi();
    const collectionApi = useCollectionApi();
    const navigate = useNavigate()

    const user: any = useUserSelector();
    const [products, setProducts] = useState<any>(null);
    // for pagination
    const limit: number = 10;
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [sort, setSort] = useState<string>('');
    const [searchKey, setSearchKey] = useState<string>('');
    const [payoutgourps, setPayoutgroups] = useState<any>(null);
    const [collections, setCollections] = useState<any>(null);
    const { showLoader, hideLoader } = useAppLoader();
    const [isOpen, setIsOpen] = React.useState(false);
    const [isFeesModalOpen, setIsFeesModalOpen] = useState(false);
    const [filterparams, setFilterparams] = React.useState<any>({});
    const [selectedProduct, setSelectedProduct] = useState<any | null>(null);

    const sortBy = [
        { name: "Collection Name ASC", id: "name" },
        { name: "Collection Name DESC", id: "name:desc" }
    ];

    const checkAll: any = useRef();

    const selectoperations = [
        { value: 'published', label: 'Published' },
        { value: 'unpublished', label: 'Un Published' },
        { value: 'delete', label: 'Delete' },
        { value: 'payout', label: 'Payout' },
        { value: 'feature', label: 'Feature' },
    ];

    const ProductOperationChange = watch('ProductOperation');
    const pselectedCheckbox = watch('selectedItems');
    const onChangeProductOperationField = () => {
        console.log("payoutgourps", ProductOperationChange);
        //console.log("Selected Productsx", getValues('selectedItems'));
        let params: any = {
            usePaging: false
        }
        showLoader();
        payoutGroupApi.getPayoutGroups(params, (message: string, resp: any) => {
            hideLoader();
            console.log(resp);
            if (resp.successful === true) {
                if (resp.payoutGrpdm.length > 0) {
                    resp.payoutGrpdm = resp.payoutGrpdm.map((o: any) => {
                        return {
                            value: o.payoutGroupID,
                            label: o.payoutGroupName
                        };
                    });
                    setPayoutgroups(resp.payoutGrpdm);
                }
            } else {
                toast.error(message);
            }
        }, (message: string) => {
            hideLoader();
            toast.error(message);
            console.log(message);
        });
    };

    const onSubmitListHeaderAction = () => {
        const productsx = getValues('selectedItems');
        if (ProductOperationChange?.value === 'payout' && Array.isArray(productsx) && productsx.length > 0) {
            //console.log(products, getValues('SelectedPayout'));
            const payoutgroup = getValues('SelectedPayout');
            const params = {
                products: productsx.map((o: any) => {
                    return {
                        productID: o
                    }
                }),
                payoutGroupID: payoutgroup?.value
            };
            showLoader();
            productApi.mapProductPayout(params, (message: string, resp: any) => {
                hideLoader();
                if (resp && resp.successful === true) {
                    toast.success(resp.message);
                    reset({
                        selectedItems: false,
                        ProductOperation: ''
                    });
                    getProducts();
                } else {
                    toast.error(message);
                }
                console.log(resp);
            }, (message: string) => {
                hideLoader();
                toast.error(message);
            })
        } else if (ProductOperationChange?.value === 'published' || ProductOperationChange?.value === 'unpublished') {
            const params = {
                products: productsx.map((o: any) => {
                    return {
                        productID: o
                    }
                }),
                isPublished: ProductOperationChange?.value === 'published' ? 1 : 0
            };
            showLoader();
            productApi.publishProducts(params, (message: string, resp: any) => {
                hideLoader();
                if (resp && resp.successful === true) {
                    toast.success(resp.message);
                    reset({
                        selectedItems: false,
                        ProductOperation: ''
                    });
                    getProducts();
                } else {
                    toast.error(message);
                }
                console.log(resp);
            }, (message: string) => {
                hideLoader();
                toast.error(message);
            })
        } else if (ProductOperationChange?.value === 'delete') {
            const params = {
                deleteProducts: productsx.map((o: any) => {
                    return {
                        productID: o
                    }
                })
            };
            showLoader();
            productApi.deleteProducts(params, (message: string, resp: any) => {
                hideLoader();
                if (resp && resp.successful === true) {
                    toast.success(resp.message);
                    reset({
                        selectedItems: false,
                        ProductOperation: ''
                    });
                    getProducts();
                } else {
                    toast.error(message);
                }
                console.log(resp);
            }, (message: string) => {
                hideLoader();
                toast.error(message);
            })
        } else if (ProductOperationChange?.value === 'feature') {
            const params = {
                products: productsx.map((o: any) => {
                    return {
                        productID: o
                    }
                }),
                isFeatured: 1
            };
            showLoader();
            productApi.featuredProducts(params, (message: string, resp: any) => {
                hideLoader();
                if (resp && resp.successful === true) {
                    toast.success(resp.message);
                    reset({
                        selectedItems: false,
                        ProductOperation: ''
                    });
                    getProducts();
                } else {
                    toast.error(message);
                }
                console.log(resp);
            }, (message: string) => {
                hideLoader();
                toast.error(message);
            })
        } else {
            console.log(getValues('selectedItems'));
        }
    }

    const closeModalEditFees = () => setIsFeesModalOpen(false);

    const getCollections = () => {
        let params: any = {
            usePaging: false,
            sortingBy: 'collectionName',
            sortingOrder: 'asc'
        }
        console.log('params => ', params);
        collectionApi.getCollections(params, (message: string, resp: any) => {
            const data = resp.collections.map((o: any) => {
                return {
                    id: '' + o.collectionID,
                    name: o.collectionName
                }
            });
            setCollections(data);
        }, (message: string) => {
            console.log(message);
        })
    }

    const getProducts = () => {
        let params: any = {
            usePaging: true,
            pageSize: limit,
            pageNumber: currentPage,
            searchText: searchKey
        }
        console.log(sort);
        switch (filterparams?.radioBtnFiltrations?.sortBy) {
            case 'name':
                params.sortingBy = 'collectionname';
                params.sortingOrder = 'asc';
                break;
            case 'name:desc':
                params.sortingBy = 'collectionname';
                params.sortingOrder = 'desc';
                break;
            default:
        }
        if (filterparams?.radioBtnFiltrations?.pageSize)
            params.pageSize = Math.abs(filterparams?.radioBtnFiltrations?.pageSize);
        if (filterparams?.radioBtnFiltrations?.Collection)
            params.collectionID = Math.abs(filterparams?.radioBtnFiltrations?.Collection);

        console.log('params => ', params);
        showLoader();
        productApi.getProducts(params, (message: string, resp: any) => {
            hideLoader();
            setProducts(resp.products);
            setTotalPage(Math.ceil(resp.totalRecord / limit));
            console.log(resp)
        }, (message: string) => {
            hideLoader();
            console.log(message);
        })
    }

    const getSingleProduct = (id: number) => {
        showLoader();
        productApi.getProducts({ productID: id }, (message: string, resp: any) => {
            hideLoader();
            const data: any = resp.products && resp.products.length && resp.products[0] || null;
            if (data && data['ownerSettings'] && data['ownerSettings'].length) {
                setSelectedProduct(data)
                setIsFeesModalOpen(true);
            } else if (!data) {
                toast.error('Product not found!')
            } else if (!data.hasOwnProperty('ownerSettings') || (data.hasOwnProperty('ownerSettings') && !data['ownerSettings'].length)) {
                toast.error('Owner settings not found!')
            }
        }, (message: string) => {
            hideLoader();
            toast.error(message)
            console.log(message);
        })
    }

    const deleteProduct = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            showLoader();
            productApi.deleteProducts({
                deleteProducts: [{ productID: id }]
            }, (message: string, resp: any) => {
                hideLoader();
                resolve(message)
            }, (message: string) => {
                hideLoader();
                reject(message);
            })
        })
    }

    const customfieldDeletehandler = (field: any) => {
        console.log(field.customFieldID)
        toast((t) => (
            <span>
                Are you sure?
                <br />
                You want to <b className="text-danger">delete</b> product{" "}
                <b>{field.productName}</b>
                <br />
                <hr className="mb-3 mt-3" />
                <div className="align-middle flex justify-end">
                    <ButtonPrimary className="bg-black m-1 sm:h-9" onClick={() => { toast.dismiss(t.id) }} >Cancel</ButtonPrimary>
                    <ButtonPrimary
                        className="bg-red-500 m-1 sm:h-9"
                        onClick={() => {
                            toast.dismiss(t.id);
                            toast.promise(
                                deleteProduct(field.productID), {
                                loading: "Deleting...",
                                success: (msg) => {
                                    getProducts();
                                    return <b>{field.productName} Deleted Successfully!</b>
                                },
                                error: (msg) => { return <b>{msg}</b> },
                            }, { duration: 2000 });
                        }}
                    >
                        Delete
                    </ButtonPrimary>
                </div>
            </span>
        ),
            { duration: 95000 }
        );
    }

    const onApplySort = (type: string) => {
        setSort(type);
        //setTimeout(() => {
        //  getProducts();
        //});
    }

    const onApplyFilter = (data: any) => {
        setCurrentPage(1);
        setFilterparams(data);
    }

    const onSearch = (data: any) => {
        setSearchKey(data);
    }

    useEffect(() => {
        if (products) {
            if (currentPage == 1) {
                getProducts();
            } else {
                setCurrentPage(1);
            }
        }

    }, [sort])


    useEffect(() => {
        if (products) {
            if (currentPage == 1) {
                getProducts();
            } else {
                setCurrentPage(1);
            }
        }

    }, [searchKey]);

    useEffect(() => {
        getProducts();
        getCollections();
    }, [currentPage])

    useEffect(() => {
        //console.log("sao paulo", filterparams);
        if (filterparams && filterparams?.radioBtnFiltrations && typeof filterparams.radioBtnFiltrations.sortBy !== 'undefined') {
            //console.log("xxx", filterparams.radioBtnFiltrations.itemType, filterparams.radioBtnFiltrations.sortBy);
            getProducts();
        }
    }, [filterparams])


    return (
        <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
            <Helmet>
                <title>Products</title>
            </Helmet>
            <PageHeaderWithSearch title="Products" onSearch={setSearchKey} searchKey={searchKey} />


            <div className="container-full py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28 pl-10 pr-10">
                <main>
                    {/* FILTER */}
                    <div className={`flex flex-col relative ${className}`}>
                        <div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2">
                            <div className="relative flex w-full text-sm md:text-base hiddenScrollbar items-center custom-flex-col">

                                {products && products.length > 0 && (
                                    <div className="checkalloption ml-3 ">
                                        <label>
                                            <Checkbox label="Check All" ref={checkAll} onChangeCapture={(e: any) => {
                                                if (e.target.checked) {
                                                    setIsOpen(false)
                                                    reset({
                                                        selectedItems: products.map((o: any) => ('' + o.productID))
                                                    })
                                                    console.log("selected item", getValues('selectedItems'))
                                                } else {
                                                    reset({
                                                        selectedItems: false
                                                    })
                                                }
                                            }} />
                                        </label>
                                    </div>
                                )}


                                {/* {watch('selectedItems') && Array.isArray(watch('selectedItems')) && watch('selectedItems').length > 0 && (
                                    
                                )} */}
                            </div>

                            <span className="flex flex-shrink-0 text-right items-center">
                                <Link className="sm:space-x-2" to="/products/add">
                                    <ButtonPrimary>Add New</ButtonPrimary>
                                </Link>
                                <ButtonSecondary
                                    className="w-auto !pr-16 ml-2"
                                    sizeClass="pl-4 py-2.5 sm:pl-6 border-primary-500 focus:ring-0 focus:ring-primary-200"
                                    onClick={() => {
                                        if (checkAll?.current) {
                                            checkAll.current.checked = false;
                                            reset({
                                                selectedItems: false
                                            })
                                        }
                                        setIsOpen(!isOpen);
                                    }}
                                >
                                    <svg
                                        className={`w-4 h-4 sm:w-6 sm:h-6`}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeMiterlimit="10"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M19.87 17.12L18.87 16.12"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>

                                    <span className="block truncate ml-2.5">Filter</span>
                                    <span className="absolute top-1/2 -translate-y-1/2 right-5">
                                        <ChevronDownIcon
                                            className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpen || pselectedCheckbox?.length > 0 ? "rotate-180" : ""
                                                }`}
                                            aria-hidden="true"
                                        />
                                    </span>
                                </ButtonSecondary>
                            </span>
                        </div>
                        {/* watch && watch('selectedItems') && Array.isArray(watch('selectedItems')) && watch('selectedItems').length > 0 */}
                        <Transition
                            show={(pselectedCheckbox?.length > 0 && !isOpen) ? true : false}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
                            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-8 gap-y-10  mt-8 lg:mt-10 custom-xs-col-2">
                                <Controller
                                    name={"ProductOperation"}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange, onBlur } }) => (
                                        <Select
                                            className={` ml-3 mt-1 rounded-2xl`}
                                            options={selectoperations ? selectoperations : []}
                                            placeholder={"Select Option"}
                                            onChange={(data: any) => {
                                                onChange(data);
                                                if (data.value === 'payout')
                                                    onChangeProductOperationField()
                                            }}
                                            value={value}
                                            defaultValue={value}
                                        />
                                    )}
                                />
                                {ProductOperationChange?.value === 'payout' && (
                                    <Controller
                                        name={"SelectedPayout"}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange, onBlur } }) => (
                                            <Select
                                                className={`ml-3 mt-1 rounded-2xl`}
                                                options={payoutgourps ? payoutgourps : []}
                                                placeholder={"Payout group"}
                                                onChange={onChange}
                                                value={value}
                                                defaultValue={value}
                                            />
                                        )}
                                    />
                                )}
                                <ButtonPrimary onClick={onSubmitListHeaderAction}>Submit</ButtonPrimary>
                            </div>
                        </Transition>

                        <Transition
                            show={isOpen}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
                            {/*<TabFilters onApplySort={onApplySort} onSearch={onSearch} />*/}
                            <TabFilters name="Product Filters"
                                radioBtnFiltrations={[
                                    {
                                        key: 'pageSize',
                                        options: [
                                            { id: '10', name: '10 per page' },
                                            { id: '25', name: '25 per page' },
                                            { id: '50', name: '50 per page' },
                                            { id: '100', name: '100 per page' },
                                            { id: '200', name: '200 per page' }
                                        ]
                                    },
                                    {
                                        key: 'Collection',
                                        options: collections
                                    },
                                    {
                                        key: 'sortBy',
                                        options: sortBy
                                    },

                                ]} onApplyFilter={onApplyFilter}/* onApplySort={onApplySort} onSearch={onSearch} */ />
                        </Transition>
                    </div>


                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-8 gap-y-10  mt-8 lg:mt-10 custom-xs-col-2">
                        {products && products.length > 0 ? (
                            <React.Fragment>
                                {products.map((item: any, index: number) => {
                                    return (
                                        <div key={'cstfld-' + index} className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md`}>
                                            <div className="relative flex-shrink-0 ">
                                                <div>
                                                    <NcImage
                                                        containerClassName="flex aspect-w-11 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0 rounded-b-none"
                                                        src={item.productThumbUrl}
                                                        className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
                                                    />
                                                </div>
                                                <div className="absolute top-3 left-0 w-full justify-between !h-9 flex ">
                                                    <span className="ml-3">
                                                        <Checkbox value={item.productID} {...register('selectedItems')} onChangeCapture={(e: any) => {
                                                            if (e.target.checked)
                                                                setIsOpen(false)
                                                        }} />
                                                    </span>

                                                    <div className="absolute flex flex-row-reverse gap-2 justify-end right-2 sm:left-auto top-0">
                                                        <NftMoreDropdown
                                                            actions={[
                                                                {
                                                                    id: "customer_fees_update",
                                                                    name: "Edit",
                                                                    icon: "las la-pen",
                                                                    callBack: () => navigate(`/products/edit/${item.productID}`)
                                                                },
                                                                {
                                                                    id: "customer_delete",
                                                                    name: "Delete",
                                                                    icon: "las la-trash",
                                                                    callBack: () => customfieldDeletehandler(item)
                                                                },
                                                            ]}
                                                            containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="featured-badge">
                                                    {item.isFeatured === 1 && (<span className={`nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium relative text-green-800 bg-green-100 mr-1`}>Featured</span>)}
                                                    {item.isDeleted === 1 && (<span className={`nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium relative text-white bg-red-500 mr-1`}>Deleted</span>)}
                                                    {item.isActive === 0 && (<span className={`nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium relative text-white bg-gray-500 mr-1`}>In-Active</span>)}
                                                </div>
                                            </div>

                                            <div className="p-4 py-5 space-y-3">
                                                <div className="flex justify-between">
                                                    <h3 className={`text-sm font-medium category-name`}>{item.collectionName}</h3>
                                                    <h3 className={`text-sm font-medium`}>{item.categoryName}</h3>
                                                </div>

                                                <h2 className={`text-lg font-medium mb-3`}>{item.productName}</h2>

                                                <div className="flex justify-between pt-1.5 custom-product-price">
                                                    {item.productPrice.length > 0 ? (
                                                        <PriceText price={item.productPrice[0]?.cryptoStartPrice} network={item.network} />
                                                    ) : (
                                                        <PriceText price={'NA'} />
                                                    )}
                                                    <div className="option">
                                                        <h4 className={`text-sm font-medium item-status text-center ` + (item.isPublished === true ? 'bg-gray-500 text-white' : 'bg-gray-100 text-gray-400')}>{item.isPublished === true ? 'Published' : 'Not published'}</h4>
                                                        {item.isEdition === 1 ? (<span className="text-xs font-medium block mt-2">Edition: enable</span>) : (<span className="text-xs font-medium block mt-2">Edition: disable</span>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </React.Fragment>
                        ) : (
                            <div className="noproductfoundgraphics">
                                No product found.
                            </div>
                        )}
                    </div>
                    {totalPage > 1 && (
                        <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                            <Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
                        </div>
                    )}

                </main>
            </div>
            <NcCustomModal contentExtraClass="max-w-3xl" modalTitle={'Update Fees'} modalStatus={isFeesModalOpen} closeModal={closeModalEditFees}>
                <EditFees itemType={ITEM_TYPE.PRODUCT} itemDetails={selectedProduct} onCloseModalEdit={closeModalEditFees} />
            </NcCustomModal>

        </div>
    );
}

export default PageProducts;