import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import Select from 'react-select';

export interface PageLoginProps {
  className?: string;
}



const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const { register, control, handleSubmit, formState: { errors } } = useForm();
  const [stores, setStores] = useState<any>([]);
  const [marketplaces, setMarketplaces] = useState<any>([]);
  const [loginModel, setLoginModel] = useState<any>(null);
  const { showLoader, hideLoader } = useAppLoader();

  const userApi = useUserApi();

  const onLoginFirstStepSubmit = (data: any) => {
    const inputparams = Object.assign({}, JSON.parse(JSON.stringify(data)));
    //inputparams['apiKey'] = process.env.REACT_APP_API_KEY;
    inputparams['usertype'] = '';
    setLoginModel(inputparams);
    showLoader();
    userApi.loginstep1(inputparams, (message: any, resp: any) => {
      hideLoader();
      console.log("Api response", resp);
      if (resp.authenticateDataModel.length > 0) {
        if (resp.authenticateDataModel[0].marketplace.length > 0 && resp.authenticateDataModel[0].store.length < 1) {
          resp.authenticateDataModel[0].marketplace = resp.authenticateDataModel[0].marketplace.map((o: any) => {
            return {
              value: o.marketplaceID,
              label: o.marketplaceName
            }
          });
          setMarketplaces(resp.authenticateDataModel[0].marketplace);
        } else if (resp.authenticateDataModel[0].store.length > 0 && resp.authenticateDataModel[0].marketplace.length < 1) {
          resp.authenticateDataModel[0].store = resp.authenticateDataModel[0].store.map((o: any) => {
            return {
              value: o.storeID,
              label: o.storeName
            }
          });
          setStores(resp.authenticateDataModel[0].store)
        } else if (resp.authenticateDataModel[0].store.length > 0 && resp.authenticateDataModel[0].marketplace.length > 0) {
          resp.authenticateDataModel[0].store = resp.authenticateDataModel[0].store.map((o: any) => {
            return {
              value: o.storeID,
              label: o.storeName
            }
          });
          setStores(resp.authenticateDataModel[0].store)
        } else {
          toast.error("Store/Marketplace not found for this user. Please try again later.");
        }
      } else {
        toast.error("Failed to authenticate.");
      }
    }, (message: string) => {
      hideLoader();
      toast.error(message);
      //console.log(message);
    });
  };


  const onSelectStoreSubmit = (data: any) => {
    const inputparams = Object.assign({}, JSON.parse(JSON.stringify(data)));
    inputparams['storeID'] = data.storeID?.value;
    console.log("xxxx", inputparams);
    if (data.storeID?.value) {
      const checksm = data.storeID.value.split('-');
      if (checksm[1] === 'Store') {
        inputparams['storeID'] = checksm[0];
        inputparams['marketplaceID'] = '';
      } else {
        inputparams['marketplaceID'] = checksm[0];
        inputparams['storeID'] = '';
      }
    }
    //console.log(inputparams);
    for (var iKey in loginModel) {
      inputparams[iKey] = loginModel[iKey];
    }
    console.log("Final params", inputparams);
    showLoader();
    userApi.login(inputparams, (message: string, resp: any) => {
      hideLoader();
      if (resp.code === 200 && resp.successful === true && resp.authenticateDataModel.length > 0 && resp.authenticateDataModel[0].responseToken) {
        toast.success(resp.message);
      } else {
        //toast.error(resp?.message || "Failed to authenticate, please check your login information and try again.");
        toast.error('Either username or password is wrong. You are not authorized to log in.');
      }
    }, (message: string) => {
      hideLoader();
      //toast.error(message);
      toast.error('Either username or password is wrong. You are not authorized to log in.');
      console.log(message);
    })
  };

  useEffect(() => {

  }, [])


  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          {(stores?.length || marketplaces?.length) ? (
            <React.Fragment>
              <form className="grid grid-cols-1 gap-6" method="post" onSubmit={handleSubmit(onSelectStoreSubmit)} autoComplete={'off'}>
                {(marketplaces?.length > 0) ? (
                  <React.Fragment>
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Select Store / Market-Place
                      </span>
                      <Controller
                        name={"marketplaceID"}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <Select
                            className={` ml-3 mt-1 rounded-2xl`}
                            options={marketplaces}
                            placeholder={"Select Store / Market-Place"}
                            onChange={onChange}
                            value={value}
                            defaultValue={value}
                          />
                        )}
                      />
                      {/*
                        <select className={ "block mt-3 w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900" + (errors.marketplaceID? " is-invalid": "") } {...register("marketplaceID", { required: true })}>
                          <option value={''}>Select Marketplace</option>
                          {marketplaces.map((item:any, index:any) => {
                            return (
                              <option key={'mktopt' + index} value={item.marketplaceID}>{item.marketplaceName}</option>    
                            )
                          })}
                        </select>
                        s*/}
                      {errors?.marketplaceID ? <div className="invalid-feedback text-red-600">
                        {errors.marketplaceID.type === "required" ? 'Marketplace shouldn\'t be blank.' : null}
                      </div> : null}
                    </label>
                    <ButtonPrimary type="submit">Submit</ButtonPrimary>
                  </React.Fragment>
                ) : false}
                {(stores?.length > 0) ? (
                  <React.Fragment>
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Select Store / Market-Place
                      </span><br />
                      <Controller
                        name={"storeID"}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <Select
                            className={` mt-1 rounded-2xl`}
                            options={stores}
                            placeholder={"Select Store / Market-Place"}
                            onChange={onChange}
                            value={value}
                            defaultValue={value}
                          />
                        )}
                      />
                      {/*
                        <select className="block mt-3 w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900" {...register("storeID", { required: true })}>
                          <option value={''}>Select Store</option>
                          {stores.map((item:any, index:any) => {
                            return (
                              <option key={'stropt' + index} value={item.storeID}>{item.storeName}</option>    
                            )
                          })}
                        </select>   
                        */}
                      {errors?.storeID ? <div className="invalid-feedback text-red-600">
                        {errors.storeID.type === "required" ? 'Store shouldn\'t be blank.' : null}
                      </div> : null}
                    </label>
                    <ButtonPrimary type="submit">Submit</ButtonPrimary>
                  </React.Fragment>
                ) : false}
              </form>
            </React.Fragment>
          ) : (
            <form className="grid grid-cols-1 gap-6" method="post" onSubmit={handleSubmit(onLoginFirstStepSubmit)} autoComplete={'off'}>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Email address
                </span>
                <Input
                  type="email"
                  placeholder="Enter your login email"
                  className={"mt-1" + (errors.username ? " is-invalid" : "")}
                  {...register("username", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                />
                {errors?.username ? <div className="invalid-feedback text-red-600">
                  {errors.username.type === "required" ? 'Email shouldn\'t be blank.' : null}
                  {errors.username.type === "pattern" ? 'Invalid email address.' : null}
                </div> : null}
              </label>
              <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  Password
                  <Link to="/forgot-pass" className="text-sm text-blue-800">
                    Forgot password?
                  </Link>
                </span>
                <Input type="password" className="mt-1" autoComplete="new-password" placeholder="Enter your login password"
                  {...register("password", { required: true })}
                />
                {errors?.password ? <div className="invalid-feedback text-red-600">
                  {errors.password.type === "required" ? 'Password shouldn\'t be blank' : null}
                </div> : null}
              </label>
              <ButtonPrimary type="submit">Continue</ButtonPrimary>
            </form>
          )}


          {/* ==== */}
          {/*
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup" className="text-blue-800">
              Create an account
            </Link>
          </span>
          */}
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
