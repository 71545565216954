import FormItem from 'components/FormItem';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustodialWalletFormSchemaValidation } from "utils/form-validation";
import { CustodialWalletFormValues } from "_common/interfaces/FormProps";
import CheckBoxNative from "shared/Checkbox/nativeCheckbox";

import { useSiteSettingsSelector } from '_common/hooks/selectors/siteSettingsSelector'
import { useUserSelector } from "_common/hooks/selectors/userSelector";
import { useSiteSettingsApi } from '_common/hooks/api/siteSettingsApiHook';
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { useAppLoader } from '_common/hooks/common/appLoaderHook';
import toast from 'react-hot-toast';

const CustodialWallet = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const { addCustodialWallet, fetchCustodialWallet } = useSiteSettingsApi()
  const { getProfileDetails } = useUserApi()
  const siteSettingsList = useSiteSettingsSelector()
  const user: any = useUserSelector()
  const { showLoader, hideLoader } = useAppLoader()

  const [tabFields, setTabFields] = useState<any>()

  const { register, control, handleSubmit, reset, setValue, formState: { errors }, watch } = useForm<CustodialWalletFormValues>({
    defaultValues: {
      enableCustodialWallet: false,
      apiKeyForCustodialWallet: ''
    },
    resolver: yupResolver(CustodialWalletFormSchemaValidation)
  })

  const fethcCustodial = () => {
    showLoader()
      fetchCustodialWallet({}, (message: string, resp: any) => {
        hideLoader();
        reset({
          enableCustodialWallet: resp['enableCustodialWallet'] || false,
          apiKeyForCustodialWallet: resp['apiKeyForCustodialWallet'] || ''
        })
    }, (message: string, resp: any) => {
        hideLoader();
        toast.error(message)
    })
  }

  const onSubmit = (data: any) => {
    showLoader()
    addCustodialWallet(data, (message: string, resp: any) => {
        hideLoader();
        if ( resp.successful ) {
          toast.success(message)
          getUserDetails()
        } else {
          toast.error(message)
        }
    }, (message: string, resp: any) => {
        hideLoader();
        toast.error(message)
    })
  }

  const getUserDetails = () => {
    getProfileDetails({ "requestData.userID": user?.userID },
      (message: string, resp: any) => {
      }, (message: string) => {
        console.log(message)
      })
  }

  const handleCancel = () => {
    reset({
      enableCustodialWallet: false,
      apiKeyForCustodialWallet: ''
    })
  }

  useEffect(() => {
    fethcCustodial()
  }, [])

  useEffect(() => {
    /*reset({
      MaxReportPerDaybyUserSpam: tabFields?.keyValue[0]?.appValue,
      MaxReporttoBanCollectionSpam: tabFields?.keyValue[1]?.appValue,
      MaxReporttoBanItemSpam: tabFields?.keyValue[2]?.appValue,
      MaxReporttoBlurItemSpam: tabFields?.keyValue[3]?.appValue
    })*/
  }, [tabFields])

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5">
            <FormItem>
                <CheckBoxNative label="Enable Custodial Wallet using Tatum" {...register('enableCustodialWallet')} />
                {
                    errors && errors.enableCustodialWallet && errors.enableCustodialWallet.message &&
                    (<p className='text-red-400'>
                        {errors.enableCustodialWallet.message}
                    </p>) || ''
                }
            </FormItem>
            
            <FormItem label="Tatum API Key">
              <Controller
                name={`apiKeyForCustodialWallet`}
                control={control}
                render={({ field }) => <Input
                  {...field}
                  type="password"
                  placeholder={''}
                  autoComplete={'off'}
                />}
              />
              {
                  errors && errors.apiKeyForCustodialWallet && errors.apiKeyForCustodialWallet.message &&
                  (<p className='text-red-400'>
                      {errors.apiKeyForCustodialWallet.message}
                  </p>) || ''
              }
            </FormItem>
        </div>

        <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 m-8">
          <ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
          <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Reset</ButtonSecondary>
        </div>
      </form>
    </React.Fragment>
  )
}

export default CustodialWallet