import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import NcModal from "shared/NcModal/NcModal";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import QrCodeImg from "images/qr-code.png";
import metamaskImg from "images/metamask.webp";
import walletconnectImg from "images/walletconnect.webp";
import walletlinkImg from "images/walletlink.webp";
import fortmaticImg from "images/fortmatic.webp";

import ConnectWallet from '../components/Crypto/ConnectWallet'

export interface PageConnectWalletProps {
  className?: string;
}

const plans = [
  {
    name: "Metamask",
    img: metamaskImg,
  },
  {
    name: "Walletconnect",
    img: walletconnectImg,
  },
  {
    name: "Walletlink",
    img: walletlinkImg,
  },
  {
    name: "Fortmatic",
    img: fortmaticImg,
  },
];
const PageConnectWallet: FC<PageConnectWalletProps> = ({ className = "" }) => {

  return (
    <div
      className={`nc-PageConnectWallet ${className}`}
      data-nc-id="PageConnectWallet"
    >
      <Helmet>
        <title>Connect Wallet</title>
      </Helmet>

      <ConnectWallet />
    </div>
  );
};

export default PageConnectWallet;
