import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';

import { useUserApi } from "_common/hooks/api/UserApiHook";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import ProductDashboard from './ProductDashboard'

import NcImage from "shared/NcImage/NcImage";
import productdummyimage from 'images/nfts/4.jpg';
import { FaRegUser } from "react-icons/fa";

import graph1 from 'images/graph-1.png';
import graph2 from 'images/graph-2.png';
import graph3 from 'images/graph-3.png';
import graph4 from 'images/graph-4.png';

import ethem from 'images/priceIcons/ethem-icon.png';
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import { AiFillEye } from "react-icons/ai";
import toast from "react-hot-toast";

const CustomerBirChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Customer Collections, Products and NFTs',
    },
  },
};
const MyBirChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'My Collections, Products and NFTs',
    },
  },
};
const PieChartOptions = {
  maintainAspectRatio: false
}

function PageDashboard() {

  const productApi = useProductApi();
  const { dashboardGetCount, dashboardGetOrderRevenue, dashboardGetProduct } = useCommonApi();
  const { showLoader, hideLoader } = useAppLoader();
  const userApi = useUserApi()

  const [ dashboardCounts, setDashboardCounts ] = useState<any>({})
  const [ orderRevenues, setOrderRevenues ] = useState<any>({})
  const [ orderPieData, setOrderPieData ] = useState<any>(null)
  const [ customerBarData, setCustomerBarData ] = useState<any>(null)
  const [ myBarData, setMyBarData ] = useState<any>(null)
  const [products, setProducts] = useState<any[]>([])
  const [storeBaseUrl, setStoreBaseUrl] = useState<string>('')


  const getCases = () => {
    showLoader();
    productApi.getCategories({},
      (message: string, resp: any) => {
        hideLoader()
        console.log({ resp })
      }, (message: string) => {
        hideLoader()
        console.log(message);
      })
  }

  const getDashboardCountReport = () => {
    showLoader();
    dashboardGetCount({}, (message: string, resp: any) => {
      hideLoader()
      setDashboardCounts(resp)
      initCountChart(resp)
    }, (message: string) => {
      hideLoader()
      toast.error(message);
    })
  }

  const getOrderRevenueReport = () => {
    showLoader();
    dashboardGetOrderRevenue({}, (message: string, resp: any) => {
      hideLoader()
      setOrderRevenues(resp)

      setOrderPieData({
        labels: ['Total Order Amount', 'Total Crypto Amount', 'Total Coupon Amount', 'Total Card Amount'],
        datasets: [
          {
            label: 'Total #',
            data: [resp['totalOrderAmount'], resp['totalCryptoOrderAmount'], resp['totalCouponAmount'], resp['totalCardAmount']],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(79, 255, 235, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(79, 255, 235, 1)'
            ],
            borderWidth: 1,
          },
        ]
      })
    }, (message: string) => {
      hideLoader()
      toast.error(message);
    })
  }

  const getProducts = () => {
    showLoader();
    dashboardGetProduct({}, (message: string, resp: any) => {
      hideLoader()
      setProducts(resp['products'].slice(0,4))
    }, (message: string) => {
      hideLoader()
      toast.error(message);
    })
  }

  const initCountChart = (counts: any) => {
    const labels = counts['customerCollectionCount'] && counts['customerCollectionCount']['chainCount'].length && counts['customerCollectionCount']['chainCount'].map((e: any) => e['chainName']);
    const customerCollectionCount = counts['customerCollectionCount'] && counts['customerCollectionCount']['chainCount'] && counts['customerCollectionCount']['chainCount'].reduce((a: any, c: any) => {
      if ( a[c['chainName']] ) {
        a[c['chainName']] += c['collectionCount']
      } else {
        a = {
          ...a,
          [c['chainName']]: +c['collectionCount']
        }
      }
      return a;
    }, {})
    const customerProductCount = counts['customerProductCount'] && counts['customerProductCount']['chainCount'] && counts['customerProductCount']['chainCount'].reduce((a: any, c: any) => {
      if ( a[c['chainName']] ) {
        a[c['chainName']] += c['productCount']
      } else {
        a = {
          ...a,
          [c['chainName']]: +c['productCount']
        }
      }
      return a;
    }, {})
    const customerNFTCount = counts['customerNFTCount'] && counts['customerNFTCount']['chainCount'] && counts['customerNFTCount']['chainCount'].reduce((a: any, c: any) => {
      if ( a[c['chainName']] ) {
        a[c['chainName']] += c['nftCount']
      } else {
        a = {
          ...a,
          [c['chainName']]: +c['nftCount']
        }
      }
      return a;
    }, {})

    setCustomerBarData({
      labels,
      datasets: [{
        label: 'Colletion Count',
        data: labels.map((label: string) => customerCollectionCount[label]),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }, {
        label: 'Product Count',
        data: labels.map((label: string) => customerProductCount[label]),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      }, {
        label: 'NFT Count',
        data: labels.map((label: string) => customerNFTCount[label]),
        backgroundColor: 'rgba(255, 225, 132, 0.5)',
      }]
    })

    const myCollectionCount = counts['myCollectionCount'] && counts['myCollectionCount']['chainCount'] && counts['myCollectionCount']['chainCount'].reduce((a: any, c: any) => {
      if ( a[c['chainName']] ) {
        a[c['chainName']] += c['collectionCount']
      } else {
        a = {
          ...a,
          [c['chainName']]: +c['collectionCount']
        }
      }
      return a;
    }, {})
    const myProductCount = counts['myProductCount'] && counts['myProductCount']['chainCount'] && counts['myProductCount']['chainCount'].reduce((a: any, c: any) => {
      if ( a[c['chainName']] ) {
        a[c['chainName']] += c['productCount']
      } else {
        a = {
          ...a,
          [c['chainName']]: +c['productCount']
        }
      }
      return a;
    }, {})
    const myNFTCount = counts['myNFTCount'] && counts['myNFTCount']['chainCount'] && counts['myNFTCount']['chainCount'].reduce((a: any, c: any) => {
      if ( a[c['chainName']] ) {
        a[c['chainName']] += c['nftCount']
      } else {
        a = {
          ...a,
          [c['chainName']]: +c['nftCount']
        }
      }
      return a;
    }, {})

    setMyBarData({
      labels,
      datasets: [{
        label: 'Colletion Count',
        data: labels.map((label: string) => myCollectionCount[label]),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }, {
        label: 'Product Count',
        data: labels.map((label: string) => myProductCount[label]),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      }, {
        label: 'NFT Count',
        data: labels.map((label: string) => myNFTCount[label]),
        backgroundColor: 'rgba(255, 225, 132, 0.5)',
      }]
    })

  }

  const getStoreConfiguration = () => {
		userApi.getStoreConfiguration({}, (message: any, resp: any) => {
			if (resp.successful === true && resp.code === 200 && resp.storeConfiguration.length > 0) {
				if ( resp.storeConfiguration[0].subDomainName ) {
					const url = 'https://'+resp.storeConfiguration[0].subDomainName+'.'+(resp.storeConfiguration[0].domainName || 'nftwards.com');
					setStoreBaseUrl(url)
				}
			}
		}, (message: string) => {
		  	toast.error(message)
		});
	}

  useEffect(() => {
    //getCases();
    getDashboardCountReport();
    getOrderRevenueReport();
    getProducts();
    getStoreConfiguration();

    ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);
  }, [])



  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className={`p-3`}>
        <div className="container p-0 pt-5">
           <h1 className="text-3xl sm:text-4xl font-semibold mb-5">Dashboard</h1>
        </div>
        <div className="container bg-gray-100 dark:bg-black/20 p-5 rounded-2xl mb-4 dashboard-top-blocks">
          <h2 className="font-semibold text-2xl text-gray-900 dark:text-neutral-100">Tasks Performance</h2>
          <div className="grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-8  mt-4 lg:mt-4">
              <div className="shadow-lg rounded-3xl bg-neutral-50 dark:bg-neutral-800">
                <h3 className="font-semibold text-2xl text-gray-900 dark:text-gray-200 p-4 flex align-top">
                  <span className="dark:bg-primary-500 bg-indigo-100 block flex justify-center p-3 rounded-2xl text-center w-12 mr-3 h-12"><FaRegUser /></span>
                  <span className="flex flex-col">My <span className="block">Collections</span></span>
                </h3>
                <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-2"></div>
                <div className="p-5 pt-3 pb-3">
                  <p className="text-gray-500">Total</p>
                  <p className="font-bold text-3xl">{dashboardCounts?.myCollectionCount?.totalCollectionByMeCount}</p>
                </div>
                <div className="flex justify-between pl-5 pr-5">
                  <p className="mb-2"><span className="text-gray-500">Active</span><span className="font-bold text-3xl block">{dashboardCounts?.myCollectionCount?.activeCollectionByMeCount}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Inactive</span><span className="font-bold text-3xl block">{dashboardCounts?.myCollectionCount?.inActieCollectionByMeCount}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Banned</span><span className="font-bold text-3xl block">{dashboardCounts?.myCollectionCount?.bannedCollectionCountByMe}</span></p>
                </div>
                <div className="p-5 pt-3">
                  <p className="mb-2"><span className="text-gray-500">Reported</span><span className="font-bold text-3xl block">{dashboardCounts?.myCollectionCount?.totalReportedCollectionByMe}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Non-Reported</span><span className="font-bold text-3xl block">{dashboardCounts?.myCollectionCount?.totalNonReportedCollectionByMe}</span></p>
                </div>
              </div>
              <div className="shadow-lg rounded-3xl bg-neutral-50 dark:bg-neutral-800">
                <h3 className="font-semibold text-2xl text-gray-900 dark:text-gray-200 p-4 flex align-top">
                  <span className="dark:bg-primary-500 bg-indigo-100 block flex justify-center p-3 rounded-2xl text-center w-12 mr-3 h-12"><FaRegUser /></span>
                  <span className="flex flex-col">My <span className="block">Products</span></span>
                </h3>
                <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-2"></div>
                <div className="p-5 pt-3 pb-3">
                  <p className="text-gray-500">Total</p>
                  <p className="font-bold text-3xl">{dashboardCounts?.myProductCount?.totalProductByMeCount}</p>
                </div>
                <div className="flex justify-between pl-5 pr-5">
                  <p className="mb-2"><span className="text-gray-500">Active</span><span className="font-bold text-3xl block">{dashboardCounts?.myProductCount?.activeProductByMeCount}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Inactive</span><span className="font-bold text-3xl block">{dashboardCounts?.myProductCount?.inActieProductByMeCount}</span></p>
                  {/* <p className="mb-2"><span className="text-gray-500">Banned</span><span className="font-bold text-3xl block">{dashboardCounts?.myProductCount?.bannedCollectionCountByMe}</span></p> */}
                </div>
                <div className="p-5 pt-3">
                  <p className="mb-2"><span className="text-gray-500">Reported</span><span className="font-bold text-3xl block">{dashboardCounts?.myProductCount?.totalReportedProductByMe}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Non-Reported</span><span className="font-bold text-3xl block">{dashboardCounts?.myProductCount?.totalNonReportedProductByMe}</span></p>
                </div>
              </div>
              <div className="shadow-lg rounded-3xl bg-neutral-50 dark:bg-neutral-800">
                <h3 className="font-semibold text-2xl text-gray-900 dark:text-gray-200 p-4 flex align-top">
                  <span className="dark:bg-primary-500 bg-indigo-100 block flex justify-center p-3 rounded-2xl text-center w-12 mr-3 h-12"><FaRegUser /></span>
                  <span className="flex flex-col">My <span className="block">NFTs</span></span>
                </h3>
                <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-2"></div>
                <div className="p-5 pt-3 pb-3">
                  <p className="text-gray-500">Total</p>
                  <p className="font-bold text-3xl">{dashboardCounts?.myNFTCount?.totalNFTByMeCount}</p>
                </div>
                <div className="flex justify-between pl-5 pr-5">
                  <p className="mb-2"><span className="text-gray-500">Active</span><span className="font-bold text-3xl block">{dashboardCounts?.myNFTCount?.activeNFTByMeCount}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Inactive</span><span className="font-bold text-3xl block">{dashboardCounts?.myNFTCount?.inActieNFTByMeCount}</span></p>
                  {/* <p className="mb-2"><span className="text-gray-500">Banned</span><span className="font-bold text-3xl block">{dashboardCounts?.myNFTCount?.bannedCollectionCountByMe}</span></p> */}
                </div>
                <div className="p-5 pt-3">
                  <p className="mb-2"><span className="text-gray-500">Reported</span><span className="font-bold text-3xl block">{dashboardCounts?.myNFTCount?.totalReportedNFTByMe}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Non-Reported</span><span className="font-bold text-3xl block">{dashboardCounts?.myNFTCount?.totalNonReportedNFTByMe}</span></p>
                </div>
              </div>
              <div className="shadow-lg rounded-3xl bg-neutral-50 dark:bg-neutral-800">
                <h3 className="font-semibold text-2xl text-gray-900 dark:text-gray-200 p-4 flex align-top">
                  <span className="dark:bg-primary-500 bg-indigo-100 block flex justify-center p-3 rounded-2xl text-center w-12 mr-3 h-12"><FaRegUser /></span>
                  <span className="flex flex-col">All <span className="block">Orders</span></span>
                </h3>
                <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-2"></div>
                <div className="p-5 pt-3 pb-3">
                  <p className="text-gray-500">Total</p>
                  <p className="font-bold text-3xl">{orderRevenues?.totalOrderCount}</p>
                </div>
                <div className="flex justify-between pl-5 pr-5">
                  <p className="mb-2"><span className="text-gray-500">Coupon</span><span className="font-bold text-3xl block">{orderRevenues?.totalCouponOrderCount}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Card</span><span className="font-bold text-3xl block">{orderRevenues?.totalCardOrderCount}</span></p>
                  {/* <p className="mb-2"><span className="text-gray-500">Banned</span><span className="font-bold text-3xl block">{orderRevenues?.customerCount?.totalCustomerCount}</span></p> */}
                </div>
                {/* <div className="p-5 pt-3">
                  <p className="mb-2"><span className="text-gray-500">Reported Customer</span><span className="font-bold text-3xl block">{orderRevenues?.customerCount?.totalReportedCustomer}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Non-Reported Customer</span><span className="font-bold text-3xl block">{orderRevenues?.customerCount?.totalNonReportedCustomer}</span></p>
                </div> */}
              </div>
              <div className="shadow-lg rounded-3xl bg-neutral-50 dark:bg-neutral-800">
                <h3 className="font-semibold text-2xl text-gray-900 dark:text-gray-200 p-4 flex align-top">
                  <span className="dark:bg-primary-500 bg-indigo-100 block flex justify-center p-3 rounded-2xl text-center w-12 mr-3 h-12"><FaRegUser /></span>
                  <span className="flex flex-col">All <span className="block">Customers</span></span>
                </h3>
                <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-2"></div>
                <div className="p-5 pt-3 pb-3">
                  <p className="text-gray-500">Total</p>
                  <p className="font-bold text-3xl">{dashboardCounts?.customerCount?.totalCustomerCount}</p>
                </div>
                <div className="flex justify-between pl-5 pr-5">
                  <p className="mb-2"><span className="text-gray-500">Active</span><span className="font-bold text-3xl block">{dashboardCounts?.customerCount?.activeCustomerCount}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Inactive</span><span className="font-bold text-3xl block">{dashboardCounts?.customerCount?.inActieCustomerCount}</span></p>
                  {/* <p className="mb-2"><span className="text-gray-500">Banned</span><span className="font-bold text-3xl block">{dashboardCounts?.customerCount?.totalCustomerCount}</span></p> */}
                </div>
                <div className="p-5 pt-3">
                  <p className="mb-2"><span className="text-gray-500">Reported</span><span className="font-bold text-3xl block">{dashboardCounts?.customerCount?.totalReportedCustomer}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Non-Reported</span><span className="font-bold text-3xl block">{dashboardCounts?.customerCount?.totalNonReportedCustomer}</span></p>
                </div>
              </div>
              <div className="shadow-lg rounded-3xl bg-neutral-50 dark:bg-neutral-800">
                <h3 className="font-semibold text-2xl text-gray-900 dark:text-gray-200 p-4 flex align-top">
                  <span className="dark:bg-primary-500 bg-indigo-100 block flex justify-center p-3 rounded-2xl text-center w-12 mr-3 h-12"><FaRegUser /></span>
                  <span className="flex flex-col">Customer <span className="block">Collections</span></span>
                </h3>
                <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-2"></div>
                <div className="p-5 pt-3 pb-3">
                  <p className="text-gray-500">Total</p>
                  <p className="font-bold text-3xl">{dashboardCounts?.customerCollectionCount?.totalCollectionByCustomerCount}</p>
                </div>
                <div className="flex justify-between pl-5 pr-5">
                  <p className="mb-2"><span className="text-gray-500">Active</span><span className="font-bold text-3xl block">{dashboardCounts?.customerCollectionCount?.activeCollectionByCustomerCount}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Inactive</span><span className="font-bold text-3xl block">{dashboardCounts?.customerCollectionCount?.inActieCollectionByCustomerCount}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Banned</span><span className="font-bold text-3xl block">{dashboardCounts?.customerCollectionCount?.bannedCollectionCountByCustomer}</span></p>
                </div>
                <div className="p-5 pt-3">
                  <p className="mb-2"><span className="text-gray-500">Reported</span><span className="font-bold text-3xl block">{dashboardCounts?.customerCollectionCount?.totalReportedCollectionByCustomer}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Non-Reported</span><span className="font-bold text-3xl block">{dashboardCounts?.customerCollectionCount?.totalNonReportedCollectionByCustomer}</span></p>
                </div>
              </div>
              <div className="shadow-lg rounded-3xl bg-neutral-50 dark:bg-neutral-800">
                <h3 className="font-semibold text-2xl text-gray-900 dark:text-gray-200 p-4 flex align-top">
                  <span className="dark:bg-primary-500 bg-indigo-100 block flex justify-center p-3 rounded-2xl text-center w-12 mr-3 h-12"><FaRegUser /></span>
                  <span className="flex flex-col">Customer <span className="block">Products</span></span>
                </h3>
                <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-2"></div>
                <div className="p-5 pt-3 pb-3">
                  <p className="text-gray-500">Total</p>
                  <p className="font-bold text-3xl">{dashboardCounts?.customerProductCount?.totalProductByCustomerCount}</p>
                </div>
                <div className="flex justify-between pl-5 pr-5">
                  <p className="mb-2"><span className="text-gray-500">Active</span><span className="font-bold text-3xl block">{dashboardCounts?.customerProductCount?.activeProductByCustomerCount}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Inactive</span><span className="font-bold text-3xl block">{dashboardCounts?.customerProductCount?.inActieProductByCustomerCount}</span></p>
                  {/* <p className="mb-2"><span className="text-gray-500">Banned</span><span className="font-bold text-3xl block">{dashboardCounts?.customerProductCount?.bannedCollectionCountByCustomer}</span></p> */}
                </div>
                <div className="p-5 pt-3">
                  <p className="mb-2"><span className="text-gray-500">Reported</span><span className="font-bold text-3xl block">{dashboardCounts?.customerProductCount?.totalReportedProductByCustomer}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Non-Reported</span><span className="font-bold text-3xl block">{dashboardCounts?.customerProductCount?.totalNonReportedProductByCustomer}</span></p>
                </div>
              </div>
              <div className="shadow-lg rounded-3xl bg-neutral-50 dark:bg-neutral-800">
                <h3 className="font-semibold text-2xl text-gray-900 dark:text-gray-200 p-4 flex align-top">
                  <span className="dark:bg-primary-500 bg-indigo-100 block flex justify-center p-3 rounded-2xl text-center w-12 mr-3 h-12"><FaRegUser /></span>
                  <span className="flex flex-col">Customer <span className="block">NFTs</span></span>
                </h3>
                <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-2"></div>
                <div className="p-5 pt-3 pb-3">
                  <p className="text-gray-500">Total</p>
                  <p className="font-bold text-3xl">{dashboardCounts?.customerNFTCount?.totalNFTByCustomerCount}</p>
                </div>
                <div className="flex justify-between pl-5 pr-5">
                  <p className="mb-2"><span className="text-gray-500">Active</span><span className="font-bold text-3xl block">{dashboardCounts?.customerNFTCount?.activeNFTByCustomerCount}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Inactive</span><span className="font-bold text-3xl block">{dashboardCounts?.customerNFTCount?.inActieNFTByCustomerCount}</span></p>
                  {/* <p className="mb-2"><span className="text-gray-500">Banned</span><span className="font-bold text-3xl block">{dashboardCounts?.customerNFTCount?.bannedCollectionCountByCustomer']}</span></p> */}
                </div>
                <div className="p-5 pt-3">
                  <p className="mb-2"><span className="text-gray-500">Reported</span><span className="font-bold text-3xl block">{dashboardCounts?.customerNFTCount?.totalReportedNFTByCustomer}</span></p>
                  <p className="mb-2"><span className="text-gray-500">Non-Reported</span><span className="font-bold text-3xl block">{dashboardCounts?.customerNFTCount?.totalNonReportedNFTByCustomer}</span></p>
                </div>
              </div>
          </div>
          <div className="grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-x-8 gap-y-8  mt-4 lg:mt-4">
            <div className="shadow-lg rounded-3xl bg-neutral-50 dark:bg-neutral-800">
                <h3 className="font-semibold text-2xl text-gray-900 dark:text-gray-200 p-4 flex align-top">
                  <span className="dark:bg-primary-500 bg-indigo-100 block flex justify-center p-3 rounded-2xl text-center w-12 mr-3 h-12"><FaRegUser /></span>
                  <span className="flex flex-col">All <span className="block">Order</span></span>
                </h3>
                <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-2"></div>
                <div className="p-5">
                  { orderPieData && <Pie width={300} height={300} options={PieChartOptions} data={orderPieData} /> || ''}
                </div>
              </div>
              <div className="shadow-lg rounded-3xl bg-neutral-50 dark:bg-neutral-800">
                <h3 className="font-semibold text-2xl text-gray-900 dark:text-gray-200 p-4 flex align-top">
                  <span className="dark:bg-primary-500 bg-indigo-100 block flex justify-center p-3 rounded-2xl text-center w-12 mr-3 h-12"><FaRegUser /></span>
                  <span className="flex flex-col">All <span className="block">Customer</span></span>
                </h3>
                <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-2"></div>
                <div className="p-5"><img src={graph1} className="w-full" /></div>
              </div>

              <div className="shadow-lg rounded-3xl bg-neutral-50 dark:bg-neutral-800">
                <h3 className="font-semibold text-2xl text-gray-900 dark:text-gray-200 p-4 flex align-top">
                  <span className="dark:bg-primary-500 bg-indigo-100 block flex justify-center p-3 rounded-2xl text-center w-12 mr-3 h-12"><FaRegUser /></span>
                  <span className="flex flex-col">My <span className="block">details</span></span>
                </h3>
                <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-2"></div>
                <div className="p-5">
                  { myBarData && <Bar options={MyBirChartOptions} data={myBarData} /> || ''}
                </div>
              </div>
              <div className="shadow-lg rounded-3xl bg-neutral-50 dark:bg-neutral-800">
                <h3 className="font-semibold text-2xl text-gray-900 dark:text-gray-200 p-4 flex align-top">
                  <span className="dark:bg-primary-500 bg-indigo-100 block flex justify-center p-3 rounded-2xl text-center w-12 mr-3 h-12"><FaRegUser /></span>
                  <span className="flex flex-col">All <span className="block">Customers</span></span>
                </h3>
                <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-2"></div>
                <div className="p-5">
                  { customerBarData && <Bar options={CustomerBirChartOptions} data={customerBarData} /> || ''}
                </div>
              </div>
              
          </div>
          {/* <div className="grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-x-8 gap-y-8  mt-4 lg:mt-4">
            <div className="shadow-lg rounded-3xl bg-neutral-50 dark:bg-neutral-800">
                <h3 className="font-semibold text-2xl text-gray-900 dark:text-gray-200 p-4 flex align-top">
                  <span className="dark:bg-primary-500 bg-indigo-100 block flex justify-center p-3 rounded-2xl text-center w-12 mr-3 h-12"><FaRegUser /></span>
                  <span className="flex flex-col">All <span className="block">Customer</span></span>
                </h3>
                <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-2"></div>
                <div className="p-5"><img src={graph1} className="w-full" /></div>
              </div>
              <div className="shadow-lg rounded-3xl bg-neutral-50 dark:bg-neutral-800">
                <h3 className="font-semibold text-2xl text-gray-900 dark:text-gray-200 p-4 flex align-top">
                  <span className="dark:bg-primary-500 bg-indigo-100 block flex justify-center p-3 rounded-2xl text-center w-12 mr-3 h-12"><FaRegUser /></span>
                  <span className="flex flex-col">All <span className="block">Customer</span></span>
                </h3>
                <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-2"></div>
                <div className="p-5"><img src={graph2} className="w-full" /></div>
              </div>
              <div className="shadow-lg rounded-3xl bg-neutral-50 dark:bg-neutral-800">
                <h3 className="font-semibold text-2xl text-gray-900 dark:text-gray-200 p-4 flex align-top">
                  <span className="dark:bg-primary-500 bg-indigo-100 block flex justify-center p-3 rounded-2xl text-center w-12 mr-3 h-12"><FaRegUser /></span>
                  <span className="flex flex-col">All <span className="block">Customer</span></span>
                </h3>
                <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-2"></div>
                <div className="p-5"><img src={graph3} className="w-full" /></div>
                
              </div>
              <div className="shadow-lg rounded-3xl bg-neutral-50 dark:bg-neutral-800">
                <h3 className="font-semibold text-2xl text-gray-900 dark:text-gray-200 p-4 flex align-top">
                  <span className="dark:bg-primary-500 bg-indigo-100 block flex justify-center p-3 rounded-2xl text-center w-12 mr-3 h-12"><FaRegUser /></span>
                  <span className="flex flex-col">All <span className="block">Customer</span></span>
                </h3>
                <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-2"></div>
                <div className="p-5"><img src={graph4} className="w-full" /></div>
              </div>
          </div> */}
        </div>
        {
          products.length && (
            <div className="container bg-gray-100 dark:bg-black/20 p-5 rounded-2xl mb-4">
              <div className="flex justify-between">
                <h2 className="font-semibold text-2xl text-gray-900 dark:text-neutral-100">Most Viewed Products</h2>
                <div className="flex gap-3 text-4xl text-gray-100">
                  <BsArrowLeftCircle className="opacity-25" />
                  <BsArrowRightCircle />
                </div>
              </div>
              <div className="grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-8  mt-4 lg:mt-4 most-viewed-panel">
                  {
                    products.map((product: any, index: number) => (<ProductDashboard key={index} product={product} storeBaseUrl={storeBaseUrl} />))
                  }
              </div>
            </div>
          ) || ''
        }
        {/* <div className="container bg-gray-100 dark:bg-black/20 p-5 rounded-2xl mb-4">
          <div className="lg:flex justify-between">
            <h2 className="font-semibold text-2xl text-gray-900 mb-3 dark:text-neutral-100">Revenue today</h2>
            <h3 className="bg-neutral-100 font-semibold mb-0 p-3 rounded-2xl text-gray-900 text-lg">Total Sales: <span className="font-bold text-purple-800">550</span></h3>
          </div>
          <div className="grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-8  mt-4 lg:mt-4 mb-3">
            <div className="bg-neutral-50 p-2 pl-4 pr-4 rounded-2xl shadow-md dark:bg-neutral-800 flex items-center justify-between">
              <span className="align-middle bg-neutral-100 flex h-14 justify-center p-2 rounded-2xl w-14 mr-3"><img src={ethem} /></span>
              <p className="dark:text-gray-200 font-semibold text-gray-800 text-right">Total Order Amount in ETM <span className="block text-2xl">5.25</span></p>
            </div>
            <div className="bg-neutral-50 p-2 pl-4 pr-4 rounded-2xl shadow-md dark:bg-neutral-800 flex items-center justify-between">
              <span className="align-middle bg-neutral-100 flex h-14 justify-center p-2 rounded-2xl w-14 mr-3"><img src={ethem} /></span>
              <p className="dark:text-gray-200 font-semibold text-gray-800 text-right">Total Order Amount in ETM <span className="block text-2xl">5.25</span></p>
            </div>
            <div className="bg-neutral-50 p-2 pl-4 pr-4 rounded-2xl shadow-md dark:bg-neutral-800 flex items-center justify-between">
              <span className="align-middle bg-neutral-100 flex h-14 justify-center p-2 rounded-2xl w-14 mr-3"><img src={ethem} /></span>
              <p className="dark:text-gray-200 font-semibold text-gray-800 text-right">Total Order Amount in ETM <span className="block text-2xl">5.25</span></p>
            </div>
            <div className="bg-neutral-50 p-2 pl-4 pr-4 rounded-2xl shadow-md dark:bg-neutral-800 flex items-center justify-between">
              <span className="align-middle bg-neutral-100 flex h-14 justify-center p-2 rounded-2xl w-14 mr-3"><img src={ethem} /></span>
              <p className="dark:text-gray-200 font-semibold text-gray-800 text-right">Total Order Amount in ETM <span className="block text-2xl">5.25</span></p>
            </div>
          </div>
          <div className="grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-8  mt-4 lg:mt-4 mb-3">
            <div className="bg-neutral-50 p-2 pl-4 pr-4 rounded-2xl shadow-md dark:bg-neutral-800 flex items-center justify-between">
              <span className="align-middle bg-neutral-100 flex h-14 justify-center p-2 rounded-2xl w-14 mr-3"><img src={ethem} /></span>
              <p className="dark:text-gray-200 font-semibold text-gray-800 text-right">Total Order Amount in ETM <span className="block text-2xl">5.25</span></p>
            </div>
            <div className="bg-neutral-50 p-2 pl-4 pr-4 rounded-2xl shadow-md dark:bg-neutral-800 flex items-center justify-between">
              <span className="align-middle bg-neutral-100 flex h-14 justify-center p-2 rounded-2xl w-14 mr-3"><img src={ethem} /></span>
              <p className="dark:text-gray-200 font-semibold text-gray-800 text-right">Total Order Amount in ETM <span className="block text-2xl">5.25</span></p>
            </div>
            <div className="bg-neutral-50 p-2 pl-4 pr-4 rounded-2xl shadow-md dark:bg-neutral-800 flex items-center justify-between">
              <span className="align-middle bg-neutral-100 flex h-14 justify-center p-2 rounded-2xl w-14 mr-3"><img src={ethem} /></span>
              <p className="dark:text-gray-200 font-semibold text-gray-800 text-right">Total Order Amount in ETM <span className="block text-2xl">5.25</span></p>
            </div>
            <div className="bg-neutral-50 p-2 pl-4 pr-4 rounded-2xl shadow-md dark:bg-neutral-800 flex items-center justify-between">
              <span className="align-middle bg-neutral-100 flex h-14 justify-center p-2 rounded-2xl w-14 mr-3"><img src={ethem} /></span>
              <p className="dark:text-gray-200 font-semibold text-gray-800 text-right">Total Order Amount in ETM <span className="block text-2xl">5.25</span></p>
            </div>
          </div>
          <div className="grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-8  mt-10 lg:mt-10 mb-3">
            <div className="bg-neutral-50 p-2 pl-4 pr-4 rounded-2xl shadow-md dark:bg-neutral-800 flex items-center justify-between">
              <span className="align-middle bg-neutral-100 flex h-14 justify-center p-2 rounded-2xl w-14 mr-3"><img src={ethem} /></span>
              <p className="dark:text-gray-200 font-semibold text-gray-800 text-right">Total Order Amount in ETM <span className="block text-2xl">5.25</span></p>
            </div>
            <div className="bg-neutral-50 p-2 pl-4 pr-4 rounded-2xl shadow-md dark:bg-neutral-800 flex items-center justify-between">
              <span className="align-middle bg-neutral-100 flex h-14 justify-center p-2 rounded-2xl w-14 mr-3"><img src={ethem} /></span>
              <p className="dark:text-gray-200 font-semibold text-gray-800 text-right">Total Order Amount in ETM <span className="block text-2xl">5.25</span></p>
            </div>
          </div>
          <div className="grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-8  mt-4 lg:mt-4 mb-3">
             <div className="bg-neutral-50 p-2 pl-4 pr-4 rounded-2xl shadow-md dark:bg-neutral-800 flex items-center justify-between">
              <span className="align-middle bg-neutral-100 flex h-14 justify-center p-2 rounded-2xl w-14 mr-3"><img src={ethem} /></span>
              <p className="dark:text-gray-200 font-semibold text-gray-800 text-right">Total Order Amount in ETM <span className="block text-2xl">5.25</span></p>
            </div>
            <div className="bg-neutral-50 p-2 pl-4 pr-4 rounded-2xl shadow-md dark:bg-neutral-800 flex items-center justify-between">
              <span className="align-middle bg-neutral-100 flex h-14 justify-center p-2 rounded-2xl w-14 mr-3"><img src={ethem} /></span>
              <p className="dark:text-gray-200 font-semibold text-gray-800 text-right">Total Order Amount in ETM <span className="block text-2xl">5.25</span></p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default PageDashboard;
