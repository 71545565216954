import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Web3 from 'web3';
import Select from 'react-select';

import Pagination from "shared/Pagination/PaginationHandler";
import TabFilters from "components/Filters/TabFilters";
import CustomerCard from "components/Customers/CustomerCard";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import PageHeaderWithSearch from "shared/PageHeaderWithSearch/PageHeaderWithSearch";
import { Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";

export interface Props {
	className?: string;
}

const filterBy = [
	{ name: "Active", id: "active" },
	{ name: "InActive", id: "inactive" },
];

const sortBy = [
	{ name: "Name (ASC)", id: "name-asc" },
	{ name: "Name (DESC)", id: "name-desc" }
];

const CustomerList: FC<Props> = ({ className = "" }) => {

	const { customerList } = useUserApi()

	const [customers, setCustomers] = useState<any>([]);
	// for pagination
	const limit: number = 12;
	const { showLoader, hideLoader } = useAppLoader();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState<number>(1);
	const [searchKey, setSearchKey] = useState<string>('');
	const [isOpen, setIsOpen] = useState(false);
	const [filter, setFilter] = useState<any>({});

	useEffect(() => {
		listCustomer()
	}, [currentPage])

	useEffect(() => {
		console.log('Collection Filter => ',filter)
		if (customers) {
			if (currentPage == 1) {
				listCustomer();
			} else {
				setCurrentPage(1);
			}
		}
	}, [filter, searchKey]);

	const listCustomer = () => {
		let params: any = {
			usePaging: true,
			pageSize: limit,
			pageNumber: currentPage
		}
		console.log('filter: ', filter)
		if(filter?.radioBtnFiltrations){
			if(filter.radioBtnFiltrations.filter && filter.radioBtnFiltrations.filter == 'active'){
				params['isActive'] = 1;
			}
			if(filter.radioBtnFiltrations.filter && filter.radioBtnFiltrations.filter == 'inactive'){
				params['isActive'] = 0;
			}
		}

		if(searchKey){
			params.searchText = searchKey
		}

		console.log('params => ', params);
		showLoader();
		customerList(params, (message: string, resp: any) => {
			hideLoader();
			setCustomers(resp.customers);
			setTotalPage(Math.ceil(resp.totalRecord / limit));
		}, (message: string) => {
			hideLoader();
			toast.error(message)
			console.log(message);
		})
	}


	return (
		<div className={`nc-PageCustomer  ${className}`} data-nc-id="CustomerList" >
			<Helmet>
				<title>Customers</title>
			</Helmet>
			<PageHeaderWithSearch title="Customers" onSearch={setSearchKey} searchKey={searchKey} />

			<div className="container py-16 lg:pb-28 lg:pt-20 space-y-20 lg:space-y-28">
				<main>
					<div className={`flex flex-col relative`}>
						<div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
							<span className="flex flex-shrink-0 text-right items-center">
								<ButtonSecondary className="w-auto !pr-16 ml-2 border-primary-500 focus:ring-0 focus:ring-primary-200" sizeClass="pl-4 py-2.5 sm:pl-6" onClick={() => {setIsOpen(!isOpen);}}>

									<svg
										className={`w-4 h-4 sm:w-6 sm:h-6`}
										viewBox="0 0 24 24"
										fill="none"

									>
										<path
											d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M19.87 17.12L18.87 16.12"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>

									<span className="block truncate ml-2.5">Filter</span>
									<span className="absolute top-1/2 -translate-y-1/2 right-5">
										<ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpen ? "rotate-180" : "" }`} aria-hidden="true" />
									</span>
                                </ButtonSecondary>
                            </span>
                        </div>

                        <Transition
                            show={isOpen}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
						>
							<div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
							<TabFilters name="Category Filters"
								radioBtnFiltrations={[
									{
										key: 'filter',
										options: filterBy
									}/*,
									{
										key: 'sortBy',
										options: sortBy
									}*/
								]} onApplyFilter={setFilter} />
						</Transition>
					</div>

					<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-8 gap-y-10  mt-8 lg:mt-10">
						{customers?.map((customer: any, index: any) => (
							<div key={index}>
								<CustomerCard data={customer} />
							</div>
						))}
					</div>

					{customers?.length == 0 && (
						<h2 className="font-medium text-2xl text-center w-full">No Result Found!</h2>
					)}

					{customers?.length > 0 && totalPage > 1 && (
						<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
							<Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
						</div>
					)}
				</main>
			</div>
		</div>
	);
};

export default CustomerList;
