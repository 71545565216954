import { SAGA_ACTIONS } from '../../../../_config';
import { useApiCall } from '../../common/appApiCallHook';
import {
    BlankReq,
} from '../../../interfaces/ApiReqRes'


export function useCommonApi() {

    const callApi = useApiCall();

    const fileUpload = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.FILE_UPLOAD, data, onSuccess, onError);
    }

    const getBlockchainNetworks = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_BLOCKCHAIN_NETWORKS, data, onSuccess, onError);
    }

    const getDateRangeTypes = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_DATE_RANGE_TYPES, data, onSuccess, onError);
    }

    const saveContractTxn = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.SAVE_CONTRACT_TXN, data, onSuccess, onError);
    }

    const saveContractDeployTxn = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.SAVE_CONTRACT_CREATE_UPDATE_TXN, data, onSuccess, onError);
    }

    const getReport = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_REPORT, data, onSuccess, onError);
    }

    const postReport = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.POST_REPORT, data, onSuccess, onError);
    }

    const deleteReport = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DELETE_REPORT, data, onSuccess, onError);
    }

    const getReportType = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_REPORT_TYPE, data, onSuccess, onError);
    }

    const updateMarketPlaceFees = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.UPDATE_MARKETPLACE_FEES, data, onSuccess, onError);
    }

    const getActivities = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.ACTIVITY, data, onSuccess, onError);
    }

    const getContactUs = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_CONTACTUS, data, onSuccess, onError);
    }

    const deleteContactUs = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DELETE_CONTACTUS, data, onSuccess, onError);
    }

    const getCountries = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_COUNTRY, data, onSuccess, onError);
    }

    const getLikeUnlike = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_LIKE_UNLIKE, data, onSuccess, onError);
    }

    const postLikeUnlike = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.POST_LIKE_UNLIKE, data, onSuccess, onError);
    }

    const getFavorite = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_FAVORITE, data, onSuccess, onError);
    }

    const postFavorite = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.POST_FAVORITE, data, onSuccess, onError);
    }

    const getFollowunfollow = (data:any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_FOLLOW_UNFOLLOW, data, onSuccess, onError);
    }

    const postFollowunfollow = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.POST_FOLLOW_UNFOLLOW, data, onSuccess, onError);
    }

    const dashboardGetCount = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DASHBOARD_GET_COUNT, data, onSuccess, onError);
    }

    const dashboardGetOrderRevenue = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DASHBOARD_GET_ORDER_REVENUE, data, onSuccess, onError);
    }

    const dashboardGetProduct = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DASHBOARD_GET_PRODUCT, data, onSuccess, onError);
    }

    return {
        fileUpload: fileUpload,
        getBlockchainNetworks: getBlockchainNetworks,
        getDateRangeTypes,
        saveContractTxn,
        getReport: getReport,
        postReport: postReport,
        deleteReport: deleteReport,
        getReportType: getReportType,
        updateMarketPlaceFees: updateMarketPlaceFees,
        getActivities: getActivities,
        getContactUs: getContactUs,
        deleteContactUs: deleteContactUs,
        getCountries: getCountries,
        getLikeUnlike: getLikeUnlike,
        postLikeUnlike: postLikeUnlike,
        getFavorite: getFavorite,
        postFavorite: postFavorite,
        getFollowunfollow: getFollowunfollow,
        postFollowunfollow: postFollowunfollow,
        saveContractDeployTxn,
        dashboardGetCount,
        dashboardGetOrderRevenue,
        dashboardGetProduct
    }
}