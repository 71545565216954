import React, { FC, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import catImg from 'images/nfts/collection.png'
import { nftsImgs } from "contains/fakeData";
import ItemTypeImageIcon from "../ItemTypeImageIcon";
import ItemTypeEditIcon from "../ItemTypeEditIcon";
import ItemTypeDeleteIcon from "../ItemTypeDeleteIcon";
import LikeButton from "../LikeButton";
import Prices from "../Prices";
import { ClockIcon } from "@heroicons/react/outline";
import ItemTypeVideoIcon from "../ItemTypeVideoIcon";
import ItemCustomFieldImageIcon from 'components/Customfield/ItemCustomFieldImageIcon';
import ItemTypeViewIcon from "components/ItemTypeViewIcon";
import PriceText from "components/PriceText";

export interface CardOrderProps {
	className?: string;
	data: any;
}

const OrderCard: FC<CardOrderProps> = ({ className = "", data }) => {

	const navigate = useNavigate()

	return (
		<div className={`relative rounded-xl hover:shadow-lg hover:bg-neutral-50 border border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800 dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200`}>

			<div className="flex items-center w-full" onClick={() => navigate(`/order-details/${data?.orderID}`)}>
				<div className="w-20 custom-fld-icon mr-4">
					<ItemCustomFieldImageIcon />
				</div>
				<div className="w-full">
					<div className="flex justify-between">
						<h3 className={`text-sm font-medium category-name`}>{""}</h3>
						<h3 className={`text-sm font-medium`}>{''}</h3>
					</div>
					<h2 className={`font-semibold mb-1 flex items-center`}> <span className="font-normal text-lime-500 text-2xl">#{data?.orderNo}</span></h2>
					{/* <h2 className={`font-semibold mb-1 flex items-center`}>Payment Currency- <span className="font-normal ml-2 text-lime-500">{item?.currency}</span></h2> */}
					<PriceText price={data?.cryptoOrderAmount} network={data?.currency} className="w-2/5 mt-4" />
					{/* <h2 className={`font-semibold mb-1 flex items-center mt-2`}>Action-
						<span className="font-normal ml-2">
							<Link to={`/order-details/${item?.orderID}`}><ItemTypeViewIcon /></Link>
						</span>
					</h2> */}
				</div>
				<div className="flex justify-between pt-1.5">
					<div className="option">
						<h4 className={`text-sm font-medium item-status ` + (data?.isCouponUsed == 1 ? 'bg-gray-500 text-white' : 'bg-red-300 text-white')}>{data?.isCouponUsed == 1 ? "Offline" : "Online"}</h4>
					</div>
				</div>
			</div>
		</div>
	)
};

export default OrderCard;
