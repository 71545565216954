import { Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import ItemCustomFieldImageIcon from 'components/Customfield/ItemCustomFieldImageIcon';
import FormItem from 'components/FormItem';
import Label from 'components/Label/Label';
import TabFilters from "components/Filters/TabFilters";
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Select from 'react-select'
import ButtonDanger from 'shared/Button/ButtonDanger';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import Pagination from "shared/Pagination/PaginationHandler";

const sortBy = [
    { name: "Name (ASC)", id: "name-asc", direction: 'asc' },
    { name: "Name (DESC)", id: "name-desc", direction: 'desc' },
    { name: "Parent Category (ASC)", id: "parent-asc", direction: 'asc' },
    { name: "Parent Category (DESC)", id: "parent-desc", direction: 'desc' },
    /* { name: "Products (ASC)", id: "products", direction:'asc '},
    { name: "Products (DESC)", id: "products", direction:'desc '}, */
];
const sortAnotherBy = [
    { name: "Name (ASC)", id: "name-asc", direction: 'asc' },
    { name: "Name (DESC)", id: "name-desc", direction: 'desc' },
    { name: "Parent Category (ASC)", id: "parent-asc", direction: 'asc' },
    { name: "Parent Category (DESC)", id: "parent-desc", direction: 'desc' },
    /* { name: "Products (ASC)", id: "products", direction:'asc '},
    { name: "Products (DESC)", id: "products", direction:'desc '}, */
];

const AirdropsListsTwo = ({ className = "" }) => {
    const [preSalesList, setPreSalesList] = useState<any>([
        {
            publicKey: "0xb22f…2de9",
            quantity: "5",
            collection: "Art Gallery",
            collectionType: "ERC721 (NFT)",
            status: 0,
        },
        {
            publicKey: "0xb32f…2ge0",
            quantity: "2",
            collection: "Art Gallery",
            collectionType: "ERC7821 (NFT)",
            status: 1,
        },
        {
            publicKey: "0xb32f…2fe5",
            quantity: "8",
            collection: "Art Gallery",
            collectionType: "ERC7821 (NFT)",
            status: 0,
        },
        {
            publicKey: "0xb32f…2ge0",
            quantity: "6",
            collection: "Art Gallery",
            collectionType: "ERC7821 (NFT)",
            status: 1,
        }
    ])

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [isOpen, setIsOpen] = useState(false);
    const [filter, setFilter] = useState<any>({});
    const [collectionList, setCollectionList] = useState([]);
    const [collectionTypeList, setCollectionTypeList] = useState([]);
    const [networkList, setNetworkList] = useState([]);

    const { register, control, handleSubmit, reset, formState: { errors } } = useForm();


    const onSubmit = (data: any) => { }
    const onSearch = (data?: any) => { }

    return (
        <React.Fragment>
            <div className={`flex flex-col relative ${className}`}>
                <div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
                    <div className="relative flex w-full text-sm md:text-base hiddenScrollbar items-center">

                    </div>
                    <span className="flex flex-shrink-0 text-right items-center">
                        <Link className="sm:space-x-2" to="/airdrops/new-two">
                            <ButtonPrimary>Add New</ButtonPrimary>
                        </Link>
                        <ButtonSecondary
                            className="w-auto !pr-16 ml-2 border-primary-500 focus:ring-0 focus:ring-primary-200"
                            sizeClass="pl-4 py-2.5 sm:pl-6"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        >
                            <svg
                                className={`w-4 h-4 sm:w-6 sm:h-6`}
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M19.87 17.12L18.87 16.12"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>

                            <span className="block truncate ml-2.5">Filter</span>
                            <span className="absolute top-1/2 -translate-y-1/2 right-5">
                                <ChevronDownIcon
                                    className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpen ? "rotate-180" : ""
                                        }`}
                                    aria-hidden="true"
                                />
                            </span>
                        </ButtonSecondary>
                    </span>
                </div>

                <Transition
                    show={isOpen}
                    enter="transition-opacity duration-150"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
                    {/* <TabFilters onApplyCollection={onApplyCollection} onSearch={onSearch} /> */}
                    <TabFilters name="Air Drops List Filters"
                        radioBtnFiltrations={[
                            {
                                key: 'wallet address',
                                options: collectionList
                            }, {
                                key: 'collection',
                                options: collectionTypeList
                            }, {
                                key: 'status',
                                options: networkList
                            }
                        ]} onApplyFilter={onSearch} />
                </Transition>
            </div>

            <div className="grid sm:grid-cols-2 gap-x-8 gap-y-8  mt-8 lg:mt-10">
                {preSalesList && preSalesList.length > 0 ? (
                    <React.Fragment>
                        {preSalesList.map((item: any, index: number) => {
                            return (
                                <div key={'cstfld-' + index} className={`relative rounded-xl hover:shadow-lg hover:bg-neutral-50 border border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800 dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200`}>

                                    <div className="flex items-center w-full">
                                        <div className="w-20 custom-fld-icon mr-4">
                                            <ItemCustomFieldImageIcon />
                                        </div>
                                        <div className="w-full">
                                            <div className="flex justify-between">
                                                <h3 className={`text-sm font-medium category-name`}></h3>
                                                <h3 className={`text-sm font-medium`}>{''}</h3>
                                            </div>
                                            <h2 className={`font-semibold mb-1 flex items-center`}>Public Key - <span className="font-normal ml-2">{item?.publicKey}</span></h2>
                                            <h2 className={`font-semibold mb-1 flex items-center`}>Quantity- <span className="font-normal ml-2">{item?.quantity}</span></h2>
                                            <h2 className={`font-semibold mb-1 flex items-center`}>Collection- <span className="font-normal ml-2">{item?.collection}</span></h2>
                                            <h2 className={`font-semibold mb-1 flex items-center`}>Collection Type- <span className="font-normal ml-2">{item?.collectionType}</span></h2>
                                        </div>
                                        <div className="flex justify-between pt-1.5">
                                            <div className="option">
                                                <h4 className={`text-sm font-medium item-status ` + (item.staus === 1 ? 'bg-lime-500 text-white' : 'bg-red-300 text-white')}>{item.status === 1 ? "Active" : "InActive"}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </React.Fragment>
                ) : (
                    <div className="noproductfoundgraphics">
                        No registered user yet.
                    </div>
                )}

                {preSalesList?.length > 0 && totalPage > 1 && (
                    <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                        <Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
                    </div>
                )}
            </div>

        </React.Fragment>
    )
}

export default AirdropsListsTwo