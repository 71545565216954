import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Pagination from "shared/Pagination/PaginationHandler";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useProductApi } from "_common/hooks/api/productApiHook";
import CategoryNFT from "components/Category/CategoryNFT";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import TabFilters from "components/Filters/TabFilters";
import { ChevronDownIcon } from "@heroicons/react/outline";
import PageHeaderWithSearch from "shared/PageHeaderWithSearch/PageHeaderWithSearch";
import { Transition } from "@headlessui/react";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface Props {
	
}

const CategoryList: FC<Props> = ({  }) => {

	const productApi = useProductApi();

	const [categories, setCategories] = useState<any>(null);
	// for pagination
	const limit: number = 12;
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState<number>(1);

	const [sort, setSort] = useState<string>('');
	const [searchKey, setSearchKey] = useState<string>('');

	const [isOpen, setIsOpen] = React.useState(false);
	const [filter, setFilter] = useState<any>({});

	const sortBy = [
		{ name: "Name (ASC)", id: "name-asc", direction:'asc' },
		{ name: "Name (DESC)", id: "name-desc", direction:'desc' },
		{ name: "Parent Category (ASC)", id: "parent-asc" , direction:'asc'},
		{ name: "Parent Category (DESC)", id: "parent-desc" , direction:'desc'},
		/* { name: "Products (ASC)", id: "products", direction:'asc '},
		{ name: "Products (DESC)", id: "products", direction:'desc '}, */
	];

	const getCategories = () => {
		let params: any = {
			usePaging: true,
			pageSize: limit,
			pageNumber: currentPage,
			
		}
		if(searchKey){
			params.searchText = searchKey
		}
		switch (filter?.radioBtnFiltrations?.sortBy) {
			case 'name-asc':
				params.sortingBy = 'categoryName';
				params.sortingOrder = 'asc';
				break;
			case 'name-desc':
				params.sortingBy = 'categoryName';
				params.sortingOrder = 'desc';
				break;
			case 'parent-asc':
				params.sortingBy = 'parentCategoryName';
				params.sortingOrder = 'asc'; // desc
				break;
			case 'parent-desc':
				params.sortingBy = 'parentCategoryName';
				params.sortingOrder = 'desc'; // desc
				break;
			default:
		}

		console.log('params => ', params);
		productApi.getCategories(params, (message: string, resp: any) => {
			setCategories(resp.categorydm);
			setTotalPage(Math.ceil(resp.totalRecord / limit));
			console.log(resp)
		}, (message: string) => {
			console.log(message);
		})
	}

	const deleteCategory = (id: any) => {
		return new Promise((resolve: any, reject: any) => {
			productApi.deleteCategory({
				categoryID: id
			}, (message: string, resp: any) => {
				resolve(message)
			}, (message: string) => {
				reject(message);
			})
		})
	}

	const categoryDeletehandler = (category: any) => {
		console.log(category)
		toast((t) => (
			<span>
				Are you sure?
				<br />
				You want to <b className="text-danger">delete</b> Category{" "}
				<b>{category.categoryName}</b>
				<br />
				<hr className="mb-3 mt-3" />
				<div className="align-middle flex justify-end">
					<ButtonPrimary className="bg-black m-1 sm:h-9" onClick={() => { toast.dismiss(t.id) }} >Cancel</ButtonPrimary>
					<ButtonPrimary
						className="bg-red-500 m-1 sm:h-9"
						onClick={() => {
							toast.dismiss(t.id);
							toast.promise(
								deleteCategory(category.categoryID), {
								loading: "Deleting...",
								success: (msg) => {
									getCategories();
									return <b>{category.categoryName} Deleted Successfully!</b>
								},
								error: (msg) => { return <b>{msg}</b> },
							}, { duration: 2000 });
						}}
					>
						Delete
					</ButtonPrimary>

				</div>
			</span>
		),
			{ duration: 95000 }
		);
	}


	const onApplyFilter = (data:any) => {
		console.log('Category Filter =>',data)
		setFilter(data)
	}

	useEffect(() => {
		console.log('on apply filter =>',filter);
		if (categories) {
			if (currentPage == 1) {
				getCategories();
			} else {
				setCurrentPage(1);
			}
		}
	},[filter])

	const onSearch = (key: string) => {
		setSearchKey(key);


	}

	/* useEffect(() => {
		if (categories) {
			if (currentPage == 1) {
				getCategories();
			} else {
				setCurrentPage(1);
			}
		}
	}, [sort]) */


	useEffect(() => {
		if (categories) {
			if (currentPage == 1) {
				getCategories();
			} else {
				setCurrentPage(1);
			}
		}

	}, [searchKey])


	useEffect(() => {
		getCategories();
	}, [currentPage])


	return (
		<div className={`nc-PageCollection`} data-nc-id="CategoryList" >
			<Helmet>
				<title>Category</title>
			</Helmet>

			<PageHeaderWithSearch title="Categories" onSearch={setSearchKey} searchKey={searchKey} />

			<div className="container-full py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28 pl-10 pr-10">
				
					<div className={`flex flex-col relative`}>
						<div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
							<span>{/* left side */}</span>
							<span className="flex flex-shrink-0 text-right items-center">
								<Link className="sm:space-x-2" to="/category/new">
									<ButtonPrimary>Add New</ButtonPrimary>
								</Link>
								<ButtonSecondary className="w-auto !pr-16 ml-2 border-primary-500 focus:ring-0 focus:ring-primary-200" sizeClass="pl-4 py-2.5 sm:pl-6" onClick={() => { setIsOpen(!isOpen); }}>

									<svg
										className={`w-4 h-4 sm:w-6 sm:h-6`}
										viewBox="0 0 24 24"
										fill="none"

									>
										<path
											d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M19.87 17.12L18.87 16.12"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>


									<span className="block truncate ml-2.5">Filter</span>
									<span className="absolute top-1/2 -translate-y-1/2 right-5">
										<ChevronDownIcon
											className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpen ? "rotate-180" : ""
												}`}
											aria-hidden="true"
										/>
									</span>
								</ButtonSecondary>
							</span>
						</div>

						<Transition
							show={isOpen}
							enter="transition-opacity duration-150"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity duration-150"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
							<TabFilters name="Category Filters"
								radioBtnFiltrations={[
									{
										key: 'sortBy',
										options: sortBy
									},
									/* {
										key: 'mySort2',
										options: [{ name: "Recently listed", id: "Recently-listed" }, { name: "Ending soon", id: "Ending-soon" }]
									} */
								]} onApplyFilter={onApplyFilter}/* onApplySort={onApplySort} onSearch={onSearch} */ />
						</Transition>
					</div>



					<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-8 gap-y-10  mt-8 lg:mt-10 custom-xs-col-2">
						{categories?.map((cat: any, index: any) => (
							<CategoryNFT data={cat} key={index} onDelete={categoryDeletehandler} />
						))}
					</div>
					{categories?.length == 0 && (
						<h2 className="font-medium text-2xl text-center w-full">No Result Found!</h2>
					)}

					{categories?.length > 0 && totalPage > 1 && (
						<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
							<Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
						</div>
					)}

				

			</div>
		</div>
	);
};

export default CategoryList;
