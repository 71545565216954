import * as yup from "yup";

import { REG_EXP, ProductFileAcceptTypes, ProductPlaceholderAcceptTypes } from '../_common/_constant'

export const CollectionPresaleFormSchemaValidation = yup.object().shape({
  enablePreSaleRegistration: yup.boolean(),
  enablePresales: yup.boolean(),
  saleStartDate: yup.date().typeError('Please enter valid date').required('Start date required'),
  saleEndDate: yup.date()
    .typeError('Please enter valid date')
    .required('End date required')
    .min(yup.ref('saleStartDate'), "End date can't be before Start date"),
  cryptoPrice: yup.number()
    .typeError('Please enter valid price')
    .required('Price required')
    .moreThan(0, 'Price should be greater than 0'),
  presaleTotalQuantity: yup.number()
    .typeError('Percentage must be a number')
    .required('Quantity required')
    .moreThan(0, 'Quanity should be greater than 0'),
  description: yup.string()
}).required();

export const CollectionGenesisFormSchemaValidation = yup.object().shape({
  isGenesisEnable: yup.boolean(),
  saleStartDate: yup.date()
    .typeError('Please enter valid date')
    .required('Start date required'),
  saleEndDate: yup.date()
    .typeError('Please enter valid date')
    .required('End date required')
    .min(yup.ref('saleStartDate'), "End date can't be before Start date"),
  cryptoPrice: yup.number()
    .typeError('Please enter valid price')
    .required('Price required')
    .moreThan(0, 'Price should be greater than 0'),
  genesisTotalSupply: yup.number()
    .typeError('Percentage must be a number')
    .required('Quantity required')
    .moreThan(0, 'Quanity should be greater than 0'),
  description: yup.string()
}).required();

export const CollectionPayoutGroupFormSchemaValidation = yup.object().shape({
  payoutGroup: yup.object()
    .shape({
      label: yup.string().required('Payout group is required'),
      value: yup.string().required('Payout group is required'),
    })
    .typeError('Please select any payout group')
    .required('Payout group is required')
}).required();

export const PayoutGroupAddFormSchemaValidation = yup.object().shape({
  collection: yup.object()
    .shape({
      label: yup.string().required('Collection is required'),
      value: yup.string().required('Collection is required'),
    })
    .typeError('Please select any collection')
    .required('Collection is required'),
  payoutGroupName: yup.string().required('Name required!'),
  isDefault: yup.boolean(),
  lstItem: yup.array().of(
    yup.object().shape({
      itemName: yup.string(), //.required('Name is required'),
      email: yup.string().email('Invalid email'), //.required('Email is required'),
      cryptoAddress: yup.string().matches(REG_EXP.Valid_Metamask_Wallet_Address, 'Invalid wallet address').required('Wallet address is required'),
      payoutPercent: yup.number()
        .typeError('Percentage must be a number')
        .required('Percentage is required')
        .min(0.01, 'Minimum value 0.01')
    })
  ),
}).required();

export const ProductPriceFormSchemaValidation = yup.object().shape({
  priceType: yup.string(),
  auctionType: yup.object().nullable().when('priceType', {
    is: (priceType: string) => +priceType === 2,
    then: yup.object().shape({
      label: yup.string(),
      value: yup.number(),
    }).typeError('Auction type is required').required('Auction type is required')
  }),
  cryptoStartPrice: yup.number().typeError('Please enter valid price').required('Price required').moreThan(0, 'Price should be greater than 0'),
  cryptoEndPrice: yup.number().nullable().when('auctionType', {
    is: (auctionType: any) => auctionType && auctionType.value === 2,
    then: yup.number().typeError('Please enter valid price').required('End price is required')
  }),
  cryptoReservePrice: yup.number().nullable().when('auctionType', {
    is: (auctionType: any) => auctionType && auctionType.value === 1,
    then: yup.number().typeError('Please enter valid price').required('Reserve price is required')
  }),
  useDateRange: yup.boolean(),
  dateRangeType: yup.object().nullable()
    .when('useDateRange', {
      is: true,
      then: yup.object().shape({
        label: yup.string(),
        value: yup.number(),
      }).typeError('Date range type is required').required('Date range type is required')
    }),
  dateFrom: yup.date().typeError('Please enter valid date').required('Start date required'),
  dateTo: yup.date().typeError('Please enter valid date').required('End date required').min(yup.ref('dateFrom'), "End date can't be before Start date"),
  payoutGroup: yup.object().shape({
    label: yup.string(),
    value: yup.number(),
  }).nullable(),
  charityName: yup.string(),
  charityPercentage: yup.mixed().nullable(),
  charityLink: yup.string().url('Link must be a valid URL')
  /*payoutGroup: yup.object().shape({
    label: yup.string().required('Payout group is required'),
    value: yup.string().required('Payout group is required'),
  }).typeError('Please select any Payout Group').required('Payout group is required'),
  */
  //.required('Percentage is required')      
});
//.required();

export const ProductRedemptionFormSchemaValidation = yup.object().shape({
  relationId: yup.number(),
  product: yup.object()
    .shape({
      label: yup.string().required('Product is required'),
      value: yup.string().required('Product is required'),
    })
    .typeError('Please select any Product')
    .required('Product is required'),
  startDate: yup.date()
    .typeError('Please enter valid date')
    .required('Start date required'),
  endDate: yup.date()
    .typeError('Please enter valid date')
    .required('End date required')
    .min(yup.ref('startDate'), "End date can't be before Start date"),
  quantity: yup.number()
    .typeError('Please enter valid quantity')
    .required('Quantity required')
    .moreThan(0, 'Quanity should be greater than 0'),
}).required();

export const ProductPreviewFormSchemaValidation = yup.object().shape({
  isShowPreviewInFrontend: yup.boolean()
}).required()

export const ProductFormSchemaValidation = yup.object().shape({
  productName: yup.string().required('Name required'),
  collection: yup.object()
    .shape({
      label: yup.string().required('Collection is required'),
      value: yup.string().required('Collection is required'),
    })
    .typeError('Please select any collection')
    .required('Collection is required'),
  category: yup.object()
    .shape({
      label: yup.string().required('Category is required'),
      value: yup.string().required('Category is required'),
    })
    .typeError('Please select any category')
    .required('Category is required'),
  subCategory: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }).nullable(),
  productType: yup.string().required('Type required'),
  productFileUrl: yup.string().nullable(),
  productThumbUrl: yup.string().nullable(),
  productFile: yup.mixed().when('productFileUrl', {
    is: (value: string) => !value,
    then: yup.mixed()
      .required('File required')
      .test("fileSize", "File Size is too large", (value) => value && value.length && value[0].size <= 4e+8 /* 40MB */)
      .test("fileType", "Unsupported File Format", (value) => {
        // console.log('value: ', value, ' ', ProductFileAcceptTypes.includes(value[0].type))
        const model3D = ['gltf', 'glb']
        if (value && value.length && !value[0].type) {
          const ext = value[0].name.split('.').pop()
          return model3D.includes(ext)
        } else {
          return value && value.length && ProductFileAcceptTypes.includes(value[0].type)
        }
      })
  }),
  productThumb: yup.mixed().when('productThumbUrl', {
    is: (value: string) => !value,
    then: yup.mixed()
      .required('File required')
      .test("fileSize", "File Size is too large", (value) => value && value.length && value[0].size <= 4e+8 /* 40MB */)
      .test("fileType", "Unsupported File Format", (value) => value && value.length && ProductPlaceholderAcceptTypes.includes(value[0].type))
  }),
  quantity: yup.number()
    .typeError('Invalid quantity')
    .required('Quanity required!')
    .min(1, 'Should be greater than 0'),
  productGroup: yup.object().nullable()
    .shape({
      label: yup.string().required('Group is required'),
      value: yup.string().required('Group is required'),
    })
    .typeError('Invalid Group'),
  description: yup.string().required('Description required'),
  metaTitle: yup.string(),
  metaDescription: yup.string(),
  // productFileUrl: string,
  // productThumbUrl: string,
  // compressedImageFileUrl: string,
  // compressedThumbFileUrl: string,
  blockchainNetwork: yup.object()
    .shape({
      label: yup.string().required('Network is required'),
      value: yup.string().required('Network is required'),
    })
    .typeError('Please select any network')
    .required('Network is required'),
  isUnlockableContent: yup.boolean(),
  unlockableContent: yup.string().nullable().when('isUnlockableContent', {
    is: true,
    then: yup.string().required('Content is required')
  }),
  isExplicitSensitiveContent: yup.boolean(),
  isEnableLazyMinting: yup.boolean(),
  isFreezeMetadata: yup.boolean(),
  isFeatured: yup.boolean(),
  isEdition: yup.boolean(),
  editionNumber: yup.string().when('isEdition', {
    is: true,
    then: yup.string().required('Edition number should not be blank!')
  }),
  // isCreatedByCustomer: boolean,
  productProperty: yup.array().of(
    yup.object().shape({
      traitTypeID: yup.number(),
      propertyName: yup.string().required('Name required'),
      propertyValue: yup.string().required('Value required')
    }).notRequired()
  ),
  productLevel: yup.array().of(
    yup.object().shape({
      traitTypeID: yup.number(),
      levelName: yup.string().required('Name required'),
      minValue: yup.number().required('Min value required'),
      maxValue: yup.number()
        .moreThan(yup.ref('minValue'), 'Should be greater than min value')
        .required('Max value required')
    })
  ),
  productStat: yup.array().of(
    yup.object().shape({
      traitTypeID: yup.number(),
      statName: yup.string().required('Name required'),
      minValue: yup.number().required('Min value required'),
      maxValue: yup.number()
        .moreThan(yup.ref('minValue'), 'Should be greater than min value')
        .required('Max value required')
    })
  ),
  creatorFee: yup.array().of(
    yup.object().shape({
      earningWalletAddress: yup.string()
        .when('earningPercentage', {
          is: (earningPercentage: any) => earningPercentage,
          then: yup.string()
            .matches(REG_EXP.Valid_Metamask_Wallet_Address, 'Invalid wallet address')
            .typeError('Invalid wallet address')
            .required('Wallet address is required')
        }),
      earningPercentage: yup.number()
        .when('earningWalletAddress', {
          is: (earningWalletAddress: any) => earningWalletAddress,
          then: yup.number()
            .typeError('Percentage must be a number')
            .required('Percentage is required')
            .min(0.01, 'Minimum value 0.1')
        })
    }, [['earningWalletAddress', 'earningPercentage']])
  ),
  productBoosts: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Name required'),
      type: yup.object().shape({
        label: yup.string().required('Boost type required'),
        value: yup.string().required('Boost type required'),
      }).required('Type required'),
      parcentageValue: yup.number().max(100, "Maximum value should be 100"),
      minValue: yup.number(),
      maxValue: yup.number().when('type', ((data: any) => {
        const fieldValue = data.value?.value || data.value;
        //console.log("Data type", data.value, data.value.value, fieldValue);
        console.log("Data type", fieldValue, Math.abs(fieldValue));
        if (Math.abs(fieldValue) === 0)
          return yup.number().moreThan(yup.ref('minValue'), 'Should be greater than min value')
        else
          return yup.number();
      }))
      //maxValue: yup.number().moreThan(yup.ref('minValue'), 'Should be greater than min value')
    })
  ),
  productDates: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Name required'),
      dateValue: yup.date().nullable().typeError('Please enter valid date').required('Date value is required')
    })
  )
})

export const CouponFormSchemaValidation = yup.object().shape({
  couponName: yup.string().required('Name required!'),
  couponCode: yup.string().required('Code required!'),
  quantity: yup.number()
    .typeError('Invalid quantity')
    .required('Quantity required!')
    .min(1, 'Should not be less than 1'),
  discountType: yup.object()
    .shape({
      label: yup.string().required('Discount type is required'),
      value: yup.string().required('Discount type is required'),
    })
    .typeError('Please select any discount type')
    .required('Discount type is required'),
  discountValue: yup.number().when('discountType', {
    is: (value: any) => value && value.hasOwnProperty('value') ? +value['value'] === 1 : false,
    then: yup.number().typeError('Invalid value').required('Value required!')
  }),
  discountPercentage: yup.number().when('discountType', {
    is: (value: any) => value && value.hasOwnProperty('value') ? +value['value'] === 2 : false,
    then: yup.number()
      .typeError('Invalid value')
      .required('Value required!')
      .max(100, 'Should not be greater than 100')
  })
    .typeError('Invalid value')
    .required('Value required!'),
  isActive: yup.boolean(),
  startDate: yup.date()
    .nullable()
    .typeError('Please enter valid date')
    .required('Start date required')
    .max(yup.ref('expiryDate'), "Start date should be less than end date"),
  expiryDate: yup.date()
    .nullable()
    .typeError('Please enter valid date')
    .required('End date required')
    .min(yup.ref('startDate'), "End date can't be before Start date"),
  isLifetimeOffer: yup.boolean(),
  couponType: yup.object()
    .shape({
      label: yup.string().required('Type is required'),
      value: yup.number().required('Type is required'),
    })
    .typeError('Please select any type')
    .required('Type is required'),
  customers: yup.array().nullable().when('couponType', {
    is: (value: any) => value && +value['value'] === 2 ? true : false,
    then: yup.array().of(
      yup.object().shape({
        label: yup.string().required('Customer required'),
        value: yup.string().required('Customer required'),
      })
    )
      .typeError('Please select any customer')
      .min(1, 'Please select any coustomer')
  }),
  products: yup.array().nullable().when('couponType', {
    is: (value: any) => value && +value['value'] === 3 ? true : false,
    then: yup.array().of(
      yup.object().shape({
        label: yup.string().required('Product required'),
        value: yup.string().required('Product required'),
      })
    )
      .typeError('Please select any product')
      .min(1, 'Please select any product')
  }),
  description: yup.string().required('Description required!'),
})

export const CustomerAddFormSchemaValidation = yup.object().shape({
  firstName: yup.string().required('First name required'),
  lastName: yup.string().required('Last name required'),
  cryptoAddress: yup.string().matches(REG_EXP.Valid_Metamask_Wallet_Address, { message: 'Invalid wallet address', excludeEmptyString: true }),
  emailID: yup.string().email('Invalid email').required('Email required'),
  password: yup.string().required('Password required'),
  phoneNo: yup.string(),
  description: yup.string(),
  address1: yup.string(),
  address2: yup.string(),
  country: yup.string(),
  state: yup.string(),
  city: yup.string(),
  zipCode: yup.string(),
})

export const EditFeesFormSchemaValidation = yup.object().shape({
  ownerFee: yup.array().of(
    yup.object().shape({
      feesTypeID: yup.number().required('Type id required!'),
      blockChainNetworkID: yup.number().required('Blockchain required!'),
      walletAddress: yup.string().matches(REG_EXP.Valid_Metamask_Wallet_Address, 'Invalid wallet address').required('Wallet address is required'),
      value: yup.number()
        .typeError('Value must be a number')
        .required('Value is required')
        .moreThan(0, 'Should be more than 0')
    })
  ),
}).required();

export const PreSaleAddFormSchemaValidation = yup.object().shape({
  collection: yup.object()
    .shape({
      label: yup.string().required('Collection is required'),
      value: yup.string().required('Collection is required'),
    })
    .typeError('Please select any collection')
    .required('Collection is required'),
  preSaleRegistartions: yup.array().of(
    yup.object().shape({
      cryptoAddress: yup.string()
        .required('Wallet address is required')
        .matches(REG_EXP.Valid_Metamask_Wallet_Address, 'Invalid wallet address'),
      approvedQty: yup.number()
        .required('Quantity is required')
        .typeError('Quantity must be a number')
        .moreThan(0, 'Should be more than 0')
    })
  ),
}).required();

export const CustodialWalletFormSchemaValidation = yup.object().shape({
  enableCustodialWallet: yup.boolean(),
  apiKeyForCustodialWallet: yup.string().when('enableCustodialWallet', {
    is: true,
    then: yup.string().required('api key is required')
  })
});


export const GalleryFormSchemaValidation = yup.object().shape({
  galleryName: yup.string().required('Name required!'),
  galleryCode: yup.string().required('Code required!'),
  galleryDescription: yup.string().required('Description required!'),
  galleryIndustryType: yup.object()
    .shape({
      label: yup.string().required('Industry is required'),
      value: yup.number().required('Industry is required'),
    })
    .typeError('Please select any industry')
    .required('Industry is required'),
  wallCount: yup.number()
    .typeError('Invalid count')
    .required('Wall count required!')
    .min(1, 'Should not be less than 1'),
  adsBoxCount: yup.number()
    .typeError('Invalid count')
    .required('Box count required!')
    .min(1, 'Should not be less than 1'),
  /*collection: yup.object()
    .shape({
      label: yup.string(), //.required('Collection is required'),
      value: yup.number(), //.required('Collection is required'),
    }),*/
    /*.typeError('Please select any collection')
    .required('Collection is required'),*/
  isPrivate: yup.boolean(),
  galleryPassword: yup.string().when('isPrivate', {
    is: true,
    then: yup.string().required('Password required')
  }),
  isActive: yup.boolean(),

})

export const BasketFormSchemaValidation = yup.object().shape({
  galleryID: yup.object()
  .shape({
    label: yup.string().required('Gallery is required'),
    value: yup.string().required('Gallery is required'),
  })
  .typeError('Please select any gallery')
  .required('Gallery is required'),
  galleryBasketName: yup.string().required('Name required'),
  galleryBasketDescription: yup.string().required('Description required'),
  basketType: yup.number().required('required'),
  isCreatedByCustomer: yup.number().required('required'),
  basketItems:  yup.array().of(
    yup.object().shape({
      item: yup.object().shape({
        label: yup.string(), //.required('Product is required'),
        value: yup.number() //.required('Product is required'),
      })
      .notRequired()
      /*.typeError('Please select any product')
      .required('Product is required'),*/
    })
  )
})