import React, { FC, useEffect, useRef, useState } from "react";
import Label from "components/Label/Label";
import Select from 'react-select'
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonDanger from "shared/Button/ButtonDanger";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Switch from "shared/Switch/Switch";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import FormItem from "components/FormItem";

import { CustomerForm } from '../../_common/interfaces/FormProps'
import { CustomerAddFormSchemaValidation } from '../../utils/form-validation'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";

export interface CustomerMktAddUpdateProps {
  handleCancel: () => void;
  customerData?: any;
  classNames?: string;
}

const DetailsCustomerMkt: FC<CustomerMktAddUpdateProps> = ({ handleCancel, customerData = {}, classNames='' }) => {

  const { customerAdd } = useUserApi()

  const { showLoader, hideLoader } = useAppLoader();
  
  const { control, handleSubmit, formState: { errors } } = useForm<CustomerForm>({
      defaultValues: {
        firstName: '',
        lastName: '',
        cryptoAddress: '',
        emailID: '',
        password: '',
        phoneNo: '',
        description: '',
        address1: '',
        address2: '',
        country: '',
        state: '',
        city: '',
        zipCode: ''
      },
      resolver: yupResolver(CustomerAddFormSchemaValidation)
  });

  const customerSubmitted = (data: any) => {
    console.log('data: ', data)
    showLoader();
    customerAdd(data, (message: string, resp: any) => {
      hideLoader();
      if ( resp.successful ) {
        toast.success(message)
        handleCancel();
      } else {
        toast.error(message)
      }
    }, (message: string, resp: any) => {
      hideLoader();
      toast.error(message)

    })
  }


  return (
    <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              { customerData && customerData['customerID'] ? 'Update Customer' : 'Create Customer' }
            </h2>
            {/* <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              You can set preferred display name, create your profile URL and
              manage other personal settings.
            </span> */}
          </div>
          
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          <form className="" onSubmit={handleSubmit(customerSubmitted)}>
            <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                  <FormItem label="First Name" isRequired={true}>
                      <Controller
                          name={"firstName"}
                          control={control}
                          render={({field}) => (
                            <Input {...field} placeholder={'First name'} autoComplete={'new-password'} />
                          )}
                      />
                      { 
                          errors && errors.firstName && errors.firstName.message &&  
                          (<p className='text-red-400'>
                              {errors.firstName.message}
                          </p>)
                      }
                  </FormItem>
                  <FormItem label="Last Name" isRequired={true}>
                      <Controller
                          name={"lastName"}
                          control={control}
                          render={({field}) => (
                            <Input {...field} placeholder={'Last name'} autoComplete={'new-password'} />
                          )}
                      />
                      { 
                          errors && errors.lastName && errors.lastName.message &&  
                          (<p className='text-red-400'>
                              {errors.lastName.message}
                          </p>)
                      }
                  </FormItem>

                  <FormItem label="Email" isRequired={true}>
                      <Controller
                          name={"emailID"}
                          control={control}
                          render={({field}) => (
                            <Input {...field} placeholder={'Email address'} autoComplete={'new-password'} />
                          )}
                      />
                      { 
                          errors && errors.emailID && errors.emailID.message &&  
                          (<p className='text-red-400'>
                              {errors.emailID.message}
                          </p>)
                      }
                  </FormItem>
                  <FormItem label="Password" isRequired={true}>
                      <Controller
                          name={"password"}
                          control={control}
                          render={({field}) => (
                            <Input {...field} type={'password'} placeholder={'Password'} autoComplete={'new-password'} />
                          )}
                      />
                      { 
                          errors && errors.password && errors.password.message &&  
                          (<p className='text-red-400'>
                              {errors.password.message}
                          </p>)
                      }
                  </FormItem>
                  <FormItem label="Phone No">
                      <Controller
                          name={"phoneNo"}
                          control={control}
                          render={({field}) => (
                            <Input {...field} placeholder={'Phone no'} autoComplete={'new-password'} />
                          )}
                      />
                      { 
                          errors && errors.phoneNo && errors.phoneNo.message &&  
                          (<p className='text-red-400'>
                              {errors.phoneNo.message}
                          </p>)
                      }
                  </FormItem>
                  <FormItem label="Crypto Address">
                      <Controller
                          name={"cryptoAddress"}
                          control={control}
                          render={({field}) => (
                            <Input {...field} placeholder={'Crypto address'} autoComplete={'new-password'} />
                          )}
                      />
                      { 
                          errors && errors.cryptoAddress && errors.cryptoAddress.message &&  
                          (<p className='text-red-400'>
                              {errors.cryptoAddress.message}
                          </p>)
                      }
                  </FormItem>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-1 gap-5 sm:gap-2.5">
                  <FormItem label="Description">
                      <Controller
                          name={"description"}
                          control={control}
                          render={({field}) => (
                            <Textarea {...field} placeholder={'description here'} autoComplete={'new-password'} />
                          )}
                      />
                      { 
                          errors && errors.description && errors.description.message &&  
                          (<p className='text-red-400'>
                              {errors.description.message}
                          </p>)
                      }
                  </FormItem>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                  <FormItem label="Address1">
                      <Controller
                          name={"address1"}
                          control={control}
                          render={({field}) => (
                            <Input {...field} placeholder={'Address here'} autoComplete={'new-password'} />
                          )}
                      />
                      { 
                          errors && errors.address1 && errors.address1.message &&  
                          (<p className='text-red-400'>
                              {errors.address1.message}
                          </p>)
                      }
                  </FormItem>
                  <FormItem label="Address2">
                      <Controller
                          name={"address2"}
                          control={control}
                          render={({field}) => (
                            <Input {...field} placeholder={'Address here'} autoComplete={'new-password'} />
                          )}
                      />
                      { 
                          errors && errors.address2 && errors.address2.message &&  
                          (<p className='text-red-400'>
                              {errors.address2.message}
                          </p>)
                      }
                  </FormItem>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                  <FormItem label="Country">
                      <Controller
                          name={"country"}
                          control={control}
                          render={({field}) => (
                            <Input {...field} placeholder={'country'} autoComplete={'new-password'} />
                          )}
                      />
                      { 
                          errors && errors.country && errors.country.message &&  
                          (<p className='text-red-400'>
                              {errors.country.message}
                          </p>)
                      }
                  </FormItem>
                  <FormItem label="State">
                      <Controller
                          name={"state"}
                          control={control}
                          render={({field}) => (
                            <Input {...field} placeholder={'state'} autoComplete={'new-password'} />
                          )}
                      />
                      { 
                          errors && errors.state && errors.state.message &&  
                          (<p className='text-red-400'>
                              {errors.state.message}
                          </p>)
                      }
                  </FormItem>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                  <FormItem label="City">
                      <Controller
                          name={"city"}
                          control={control}
                          render={({field}) => (
                            <Input {...field} placeholder={'city'} autoComplete={'new-password'} />
                          )}
                      />
                      { 
                          errors && errors.city && errors.city.message &&  
                          (<p className='text-red-400'>
                              {errors.city.message}
                          </p>)
                      }
                  </FormItem>
                  <FormItem label="Zip Code">
                      <Controller
                          name={"zipCode"}
                          control={control}
                          render={({field}) => (
                            <Input {...field} placeholder={'zip code'} autoComplete={'new-password'} />
                          )}
                      />
                      { 
                          errors && errors.zipCode && errors.zipCode.message &&  
                          (<p className='text-red-400'>
                              {errors.zipCode.message}
                          </p>)
                      }
                  </FormItem>
              </div>
              
              <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
                <ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
                <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Cancel</ButtonSecondary>
              </div>
            </div>
          </form>
        </div>
  );
};

export default DetailsCustomerMkt;
