import React from "react";


export interface props {
    title: string;
    subtitle?: string;
}

const PageHeader: React.FC<props> = ({ title , subtitle}) => {
    return (
        <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
                {title}
            </h2>
            {subtitle && (
                <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                    {subtitle}
                </span>
            )}

        </div>
    );
};

export default PageHeader;