import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function useProductApi() {

  const callApi = useApiCall();

  const getCategories = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.GET_CATEGORIES, data, onSuccess, onError);
  }

  const addUpdateCategory = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.ADD_UPDATE_CATEGORY, data, onSuccess, onError);
  }

  const deleteCategory = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.DELETE_CATEGORY, data, onSuccess, onError);
  }

  const getCustomFieldType = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CUSTOMFIELD.GET_TYPE, data, onSuccess, onError);
  }

  const saveCustomfield = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CUSTOMFIELD.SAVE, data, onSuccess, onError);
  }

  const getCustomfields = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CUSTOMFIELD.GET, data, onSuccess, onError);
  }

  const deleteCustomfield = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CUSTOMFIELD.DELETE, data, onSuccess, onError);
  }  

  const getProducts = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.GET, data, onSuccess, onError);
  }

  const getOwnerNFTs = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.GET_OWNER_NFT, data, onSuccess, onError);
  }

  const listRedemptionProducts = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.LIST_REDEMPTION, data, onSuccess, onError);
  }

  const mapProductPayout = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.MAPPRODUCTPAYOUTGROUP, data, onSuccess, onError);
  }

  const publishProducts = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.PUBLISH, data, onSuccess, onError);
  }  

  const deleteProducts = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.DELETE, data, onSuccess, onError);
  } 
  
  const featuredProducts = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.FEATURE, data, onSuccess, onError);
  }
  
  const deleteRedemptionProduct = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.DELETE_REDEMPTION, data, onSuccess, onError);
  }

  const assignRedemptionProduct = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.ASSIGN_REDEMPTION, data, onSuccess, onError);
  }

  const deletePreviewImage = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.DELETE_PREVIEW_IMAGE, data, onSuccess, onError);
  }

  const assignPreviewProduct = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.ASSIGN_PREVIEW_PRODUCT, data, onSuccess, onError);
  }

  const updateProductPrice = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.UPDATE_PRODUCT_PRICE, data, onSuccess, onError);
  }

  const listProductGroup = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.PRODUCT_GROUP_LIST, data, onSuccess, onError);
  }

  const addProduct = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.PRODUCT_ADD, data, onSuccess, onError);
  }

  const updateProduct = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.PRODUCT_UPDATE, data, onSuccess, onError);
  }

  const getMyNft = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.GET_NFT, data, onSuccess, onError);
  }


  const getOwnerByNft = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PRODUCT.GET_OWNER_BY_NFT, data, onSuccess, onError);
  }
  

  return {
    getCategories: getCategories,
    addUpdateCategory: addUpdateCategory,
    deleteCategory: deleteCategory,

    getCustomFieldType: getCustomFieldType,
    saveCustomfield: saveCustomfield,
    getCustomfields: getCustomfields,
    deleteCustomfield: deleteCustomfield,

    addProduct,
    updateProduct,
    getProducts: getProducts,
    mapProductPayout: mapProductPayout,
    publishProducts: publishProducts,
    deleteProducts: deleteProducts,
    featuredProducts: featuredProducts,
    deleteRedemptionProduct,
    assignRedemptionProduct,
    deletePreviewImage,
    assignPreviewProduct,
    listRedemptionProducts,
    updateProductPrice,
    listProductGroup,
    getOwnerNFTs,
    getMyNft,
    getOwnerByNft,
  }
}